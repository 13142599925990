import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../api/api-local';

//Component
import ValidateFormData from '../../helpers/validateFormData';
import InputIcon from '../../components/Formulario/InputIcon';
import Spinner from '../../components/loading/Loading';
import useForm from '../../customHooks/useForm';

//Libs
import { Col, Row, Button, Form, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';

const EsqueciSenha = props => {
   //Variaveis
   const { mudarTela } = props;

   const initialValues = {
      email: '',
   };

   //states
   const [isLoading, setIsLoading] = useState(false);
   const history = useHistory();

   //Functios
   const { values, errors, handleChange } = useForm(
      initialValues,
      alterarSenha,
      ValidateFormData,
   );

   function alterarSenha(event) {
      event.preventDefault();

      setIsLoading(true);

      const objUser = {
         email: values.email,
      };

      api
         .post('/usuario/esqueciminhasenha', objUser)
         .then(response => {
            setIsLoading(true);

            if (response.status === 200) {
               setTimeout(() => {
                  setIsLoading(false);
                  history.replace('/');
                  toast.success('Email enviado com sucesso');
               }, 3000);
            }
         })
         .catch(error => {
            setIsLoading(false);

            error.response.data.error.map(i => {
               toast.error(i.message);
            });
         });
   }

   return (
      <>
         <Col
            lg="7"
            md="12"
            className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
               <h4 className="text-left ml-2">
                  <span>
                     Leva apenas{' '}
                     <span className="text-success">alguns segundos</span> para
                     recuperar sua senha
                  </span>
               </h4>
               <div>
                  <Form onSubmit={alterarSenha} noValidate>
                     <Row form>
                        <Col md={12}>
                           <FormGroup className="text-left">
                              <InputIcon
                                 type="text"
                                 label="Digite seu email cadastrado"
                                 size={12}
                                 name="email"
                                 handleChange={handleChange}
                                 value={values.email || ''}
                                 id="email"></InputIcon>
                              {errors.email && (
                                 <p className="is-danger align-message m-0">
                                    {errors.email}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                     </Row>

                     <div className="mt-4 mb-2 d-flex ml-2 align-items-center justify-content-between row">
                        <h5 className="mb-1">
                           Lembrou a senha?{' '}
                           <button
                              onClick={() => {
                                 mudarTela('login');
                              }}
                              className="text-primary border-0 bg-white">
                              Entrar
                           </button>
                        </h5>
                        <div className="mb-3">
                           <Button
                              disabled={isLoading && true}
                              color="primary"
                              type="submit"
                              className="btn-wide btn-pill btn-shadow btn-hover-shine"
                              size="lg">
                              Alterar senha
                           </Button>
                        </div>
                     </div>
                  </Form>
               </div>
            </Col>
         </Col>
         {isLoading && <Spinner />}
      </>
   );
};

export default EsqueciSenha;
