import React, { useEffect, useRef, useState } from 'react';
import { mask as masker, unMask } from 'remask';
import { useField } from '@unform/core';

const Input = ({ name, disabled, onChangeExtra, ...rest }) => {
   const inputRef = useRef(null);

   //States
   const { fieldName, error, registerField } = useField(name);
   const [value, setValue] = useState();

   //Controlando mascaras
   const onChange = ev => {
      const originalValue = unMask(ev.target.value);

      if (ev.target.name === '4' || ev.target.name === '5') {
         const maskedValue = masker(originalValue, [
            '(99) 9999-9999',
            '(99) 9 9999-9999',
         ]);
         setValue(maskedValue);
         return;
      } else if (ev.target.name === '6' || ev.target.name === '7') {
         const maskedValue = masker(originalValue, ['(99) 9999-9999']);
         setValue(maskedValue);
      } else {
         setValue(ev.target.value);
      }

      onChangeExtra(ev.target.value);
   };

   useEffect(() => {
      registerField({
         name: fieldName,
         ref: inputRef.current,
         path: 'value',
      });
   }, [fieldName, registerField]);

   return (
      <input
         name={name}
         disabled={disabled}
         className="input-unform"
         onChange={onChange}
         value={value}
         ref={inputRef}
         {...rest}
      />
   );
};

export default Input;
