import React, { useEffect, useState } from 'react';

import api from '../../../../api/api-local';

import { useLocation } from 'react-router-dom';

//Components
import { useAuth } from '../../../../customHooks/AuthContext';
import ModalCustom from '../../../../components/Modal/Modal';
import Spinner from '../../../../components/loading/Loading';

//Libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import {
   DropdownToggle,
   Nav,
   DropdownMenu,
   DropdownItem,
   UncontrolledButtonDropdown,
} from 'reactstrap';

const MenuLojas = () => {
   const location = useLocation();
   const { usuario, sigIn } = useAuth();
   const [isOpen, setIsOpen] = useState(false);
   const [listLoja, setListLojas] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const { statusJornadaCadastroPlataforma } = usuario;

   const statusEnabled =
      statusJornadaCadastroPlataforma?.configuracaoLojaConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPersonalizacaoConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPremioConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoProgramaConcluida;

   const handleIsOpen = async () => {
      if (!isOpen) {
         try {
            setIsLoading(true);
            const { data } = await api.get('loja');
            setListLojas(data);
         } catch (error) {
         } finally {
            setIsLoading(false);
         }
      }
      setIsOpen(!isOpen);
   };

   const handleChange = async ({ lojaId, nomeRazaoSocial }) => {
      try {
         setIsLoading(true);
         handleIsOpen();
         const { data } = await api.post('trocarloja', {
            lojaId,
         });

         const { token, plataforma, usuario, etapaConfiguracaoPlataforma } =
            data;

         usuario.plataforma = plataforma;
         usuario.etapaConfiguracaoPlataforma = etapaConfiguracaoPlataforma;

         localStorage.setItem('@Fideleco:token', token);
         localStorage.setItem('@Fideleco:user', JSON.stringify(usuario));

         toast.success(`Agora você esta logado com a loja ${nomeRazaoSocial}`);

         window.location.reload();
      } catch (error) {
         setIsOpen(true);
      } finally {
         setIsLoading(false);
      }
   };

   if (!statusEnabled) return <></>;

   return (
      <>
         <Nav className="header-megamenu">
            <li className="btn-group nav-item">
               <a href="#!" onClick={handleIsOpen} className="nav-link">
                  <i className="nav-link-icon pe-7s-repeat mr-0"></i>
                  Trocar Loja
               </a>
            </li>
            <UncontrolledButtonDropdown nav inNavbar>
               <DropdownToggle nav>
                  <i className="nav-link-icon pe-7s-users mr-0"></i>
                  Loja(s)
                  <FontAwesomeIcon
                     className="mr-2 opacity-5 ml-1"
                     icon={faAngleDown}
                  />
               </DropdownToggle>
               <DropdownMenu className="rm-pointers">
                  <div className="dropdown-menu-header">
                     <div className="dropdown-menu-header-inner bg-fideleco">
                        <div className="menu-header-content">
                           <h5 className="menu-header-title text-white font-weight-bold">
                              {usuario.lojas[0].nomeRazaoSocial}
                           </h5>
                           <h6 className="menu-header-subtitle"> </h6>
                        </div>
                     </div>
                  </div>
                  <div className="scroll-area-xs">
                     <PerfectScrollbar>
                        <DropdownItem>
                           {usuario.lojas[0].nomeRazaoSocial}
                        </DropdownItem>
                     </PerfectScrollbar>
                  </div>
               </DropdownMenu>
            </UncontrolledButtonDropdown>
         </Nav>
         <ModalCustom
            isOpen={isOpen}
            titleModal={`Selecione uma loja`}
            style={{ display: 'none' }}>
            <div className="containt-lojas">
               {listLoja?.map((i, index) => (
                  <div
                     key={index}
                     className="style-button-lojas"
                     data-lojaid={i.lojaId}
                     onClick={() => handleChange(i)}>
                     {i.nomeRazaoSocial}
                  </div>
               ))}
               <div onClick={handleIsOpen} className="style-button-lojas">
                  Fechar
               </div>
            </div>
         </ModalCustom>
         {isLoading && <Spinner />}
      </>
   );
};

export default MenuLojas;
