const SET_VALUE_NEXT_STEP =  "VALUE_NEXT_STEPS"


const valueNextStepWizard = (valueStepWizard) =>{
  return {
    type: SET_VALUE_NEXT_STEP,
    valueStepWizard
  } 
}

export const fideActions ={
  valueNextStepWizard
}
export default function reducer(state = {
    valueStepWizard: false,
    
  }, action) {
    switch (action.type) {
        case SET_VALUE_NEXT_STEP:
            return {
                ...state,
                valueStepWizard: action.valueStepWizard
            };
            default:
    }
    return state;
  }
  