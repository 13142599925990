export default function validateAdmin(values) {
   let errors = {};

   if (!values.senha) {
      errors.senha = 'O campo senha é obrigatório';
   }

   if (values.senha !== values.passwordConfirm) {
      errors.passwordConfirm = 'Senhas não conferem';
   }
   else if (values.senha.length > 100){
     errors.senha = 'Insira até 100 dígitos';
   }

   if (!values.email) {
      errors.email = 'O campo email é obrigatório';
   } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Digite um email válido!';
   }

   if (!values.nome) {
      errors.nome = 'O campo nome é obrigatório';
   }

   if (!values.celular) {
      errors.celular = 'O campo celular é obrigatório';
   }

   if (!values.limitePontuacaoMensal) {
      errors.limitePontuacaoMensal =
         'O Limite de pontuação mensal é obrigatório';
   }
   // if (!values.limitePontuacaoMensal) {
   //   errors.limitePontuacaoMensal = 'O campo Limite de pontuação é obrigatório';
   // }

   // if (!values.celular) {
   //   errors.celular = 'O campo telefone é obrigatório';
   // }
   return errors;
}
