export const MainNavAdmin = [
   { id: 1, icon: 'pe-7s-config', label: 'Configurações do programa' },
   {
      parentId: 1,
      label: 'Gerenciar usuários',
      to: '/configuracao/gerenciar-usuarios',
   },
   {
      parentId: 1,
      label: 'Gerenciar minhas lojas',
      to: '/configuracao/gerenciar-lojas',
   },
   {
      parentId: 1,
      label: 'Personalizar programa de fidelidade',
      to: '/configuracao/personalizar-programa-fidelidade',
   },
   {
      parentId: 1,
      label: 'Configurar programa de fidelidade',
      to: '/configuracao/configurar-programa-de-fidelidade',
   },
   {
      parentId: 1,
      label: 'Gerenciar Banner',
      to: '/configuracao/gerenciar-banner',
   },
   {
      parentId: 1,
      label: 'Gerar Cupom Vale Ponto',
      to: '/configuracao/gerar-cupom',
   },
];

export const MainNavOperator = [
   {
      id: 1,
      icon: 'pe-7s-dashboard',
      label: 'Menu Principal',
      to: '/home-admin',
   },
];

export const MainNavRelatorios = [
   { id: 1, icon: 'pe-7s-graph2', label: 'Relatórios' },
   {
      parentId: 1,
      label: 'Extrato Geral',
      to: '/relatorios/extrato-geral',
   },
   {
      parentId: 1,
      label: 'Meus clientes',
      to: '/relatorios/meus-clientes',
   },
   {
      parentId: 1,
      label: 'Aniversariantes',
      to: '/relatorios/aniversariantes',
   },

   {
      parentId: 1,
      label: 'Resgate de Prêmios',
      to: '/relatorios/resgate-premios',
   },

   {
      parentId: 1,
      label: 'Cupom vale pontos',
      to: '/relatorios/cupom-vale-pontos',
   },
];
