import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const WizardStep4 = () => {
   const history = useHistory();
   const pageInicial = () => {
      history.replace('/');
   };
   return (
      <Fragment>
         <div className="form-wizard-content">
            <div className="no-results">
               <div className="sa-icon sa-success animate">
                  <span className="sa-line sa-tip animateSuccessTip" />
                  <span className="sa-line sa-long animateSuccessLong" />
                  <div className="sa-placeholder" />
                  <div className="sa-fix" />
               </div>
               <div className="results-subtitle mt-4">Finalizado!</div>
               <div className="results-title">
                  Você chegou à última etapa de cadastro para as configurações
                  da sua conta!
               </div>
               <div className="mt-3 mb-3" />
               <div className="text-center">
                  <Button
                     color="success"
                     onClick={pageInicial}
                     size="lg"
                     className="btn-shadow btn-wide">
                     Ir para a plataforma{' '}
                  </Button>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default WizardStep4;
