import React, { useState, useEffect } from 'react';
import api from '../../../api/api-local';
import { toast } from 'react-toastify';

//Components
import validateBanner from '../../../helpers/validateFormDataBanner';
import InputFile from '../../../components/Formulario/InputFile';
import InputIcon from '../../../components/Formulario/InputIcon';
import { useAuth } from '../../../customHooks/AuthContext';
import Spinner from '../../../components/loading/Loading';
import ModalCustom from '../../../components/Modal/Modal';
import PageTitle from '../../layout/AppMain/PageTitle';
import useForm from '../../../customHooks/useForm';
import LoadingButton from '../../../components/LoadingButton';
import PaginationFilterTable from '../../../components/PaginationFilterTable';
import { TextField } from '@material-ui/core';

//Libs
import { Row, Col, Card, CardBody, Button, Form, FormGroup } from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Tooltip from '../../../components/tooltip';
import Table from '../../../components/FilterTable';

const config = {
   headers: {
      'Content-Type': 'multipart/form-data',
   },
};

const ListaBanner = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [listBanner, setListBanner] = useState([]);
   const [dataSource, setDataSource] = useState([]);
   const [imgData, setImgData] = useState(null);
   const [picture, setPicture] = useState([]);
   const [edit, setEdit] = useState(false);
   const [termoBusca, setTermoBusca] = useState('');
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const { usuario, signOut } = useAuth();

   const initialValues = {
      descricao: '',
      lojaId: usuario.lojas[0].lojaId,
      link: '',
      ordem: '',
   };

   const { values, errors, handleChange, handleSubmit, setValues } = useForm(
      initialValues,
      formManageAwards,
      validateBanner,
   );

   const handleChangePerson = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   const columns = [
      {
         dataIndex: 'titulo',
         title: 'Título',
         filters: dataSource.map(banner => ({
            text: banner.titulo,
            value: banner.titulo,
         })),
         onFilter: (value, record) => record.titulo.indexOf(value) === 0,
         sorter: (a, b) => (a.titulo < b.titulo ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 250,
      },
      {
         dataIndex: 'descricao',
         title: 'Descrição',
         filters: dataSource.map(banner => ({
            text: banner.descricao,
            value: banner.descricao,
         })),
         onFilter: (value, record) => record.descricao.indexOf(value) === 0,
         sorter: (a, b) => (a.descricao < b.descricao ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 350,
      },

      {
         dataIndex: 'ordem',
         title: 'Ordem',
         filters: dataSource.map(banner => ({
            text: banner.ordem,
            value: banner.ordem,
         })),
         onFilter: (value, record) => record.ordem.indexOf(value) === 0,
         sorter: (a, b) => (a.ordem < b.ordem ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 10,
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 20,
         align: 'center',
         render: banner => (
            <div key={banner.link}>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon mr-2 text-info lnr-pencil mr-3"
                  onClick={() => {
                     updateBanner(banner);
                  }}
               />
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     deleteBanner(banner);
                  }}
               />
            </div>
         ),
      },
   ];

   const filtros = [
      {
         dataIndex: 'titulo',
         nome: 'Título',
         placeholder: 'Digite o título',
         mask: 'text',
      },
      {
         dataIndex: 'descricao',
         nome: 'Descricão',
         placeholder: 'Digite a descricão',
         mask: 'text',
      },
   ];

   const openModal = () => {
      if (!values.nome) {
         setEdit(false);
      } else {
         setValues(initialValues);
         setEdit(true);
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      setValues(initialValues);
      setIsOpen(false);
      setPicture([]);
      setImgData(null);
   };

   function formManageAwards() {
      setIsLoading(true);
      try {
         const formData = new FormData();
         formData.append('lojaId', values.lojaId);
         formData.append('descricao', values.descricao);
         formData.append('ordem', values.ordem);
         formData.append('titulo', values.titulo);
         formData.append('imagem', picture);
         if (!edit) {
            api.post('lojabanner', formData, config)
               .then(response => {
                  setIsLoading(true);
                  if (response.status === 200) {
                     setValues(initialValues);
                     setPicture([]);
                     setImgData(null);
                     setIsLoading(false);
                     setIsOpen(false);
                     toast.success('Banner adicionado com sucesso');
                     //Atualizando componente de listagem
                     getListBanner();
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });

                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         } else {
            if (values.senha === '') {
               delete values.senha;
            }
            formData.append('lojaBannerId', values.lojaBannerId);
            api.put('lojabanner', formData, config)
               .then(response => {
                  setIsLoading(true);

                  if (response.status === 200) {
                     setValues(initialValues);
                     setPicture([]);
                     setImgData(null);
                     setIsOpen(false);
                     setIsLoading(false);
                     toast.success('Banner alterado com sucesso!');
                     //Atualizando componente de listagem
                     getListBanner();
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         }
      } catch (error) {
         console.log(error);
      }
   }

   const updateBanner = banner => {
      setEdit(true);
      setIsOpen(true);
      setIsLoading(true);

      try {
         const getId = banner.lojaBannerId;

         api.get(`lojabanner/${getId}`).then(response => {
            if (response.status === 200 && getId) {
               setIsLoading(false);

               const { titulo, descricao, ordem } = response.data;
               const valuesOk = {
                  titulo: titulo,
                  descricao: descricao,
                  ordem: ordem,
               };

               setValues(response.data);
               setPicture(response.data.imagem);
            }
         });
      } catch (error) {
         setIsLoading(false);
      }
   };

   const deleteBanner = banner => {
      setIsLoading(true);
      try {
         const getId = banner.lojaBannerId;
         api.delete(`lojabanner/${getId}`)
            .then(response => {
               if (response.status === 200) {
                  setIsLoading(false);
                  toast.success('Banner excluído com sucesso');
                  //Atualizando componente de listagem
                  getListBanner();
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } catch (error) {
         console.log(error);
      }
   };

   const getListBanner = async (currentPage, pageSize) => {
      setIsLoading(true);
      try {
         const getApi = await api.get('lojabanner/paginacao', {
            params: {
               pagina: currentPage,
               tamanho: pageSize,
               ...termoBusca,
            },
         });
         if (getApi.status === 200) {
            const { data } = getApi;
            setListBanner(data.data);
            setDataSource(data.data);
            setIsLoading(false);

            setPagination(state => ({
               ...state,
               current: data.currentPage,
               total: data.totalItems,
            }));
         }
      } catch (error) {
         setIsLoading(false);
         toast.warning(error.response.data.error[0].message);
      }
   };

   const onChangePicture = e => {
      setPicture(e.target.files[0]);
      if (e.target.files) {
         const reader = new FileReader();

         reader.addEventListener('load', () => {
            setImgData(reader.result);
         });
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getListBanner(pagination.current, pagination.pageSize);
      }, 1);
   };

   useEffect(() => {
      getListBanner(pagination.current, pagination.pageSize);
   }, [edit]);

   return (
      <>
         <PageTitle
            heading="Gerenciar Banners"
            subheading="Gerencie seus Banners"
            icon="pe-7s-shopbag icon-gradient bg-fideleco">
            {' '}
            <div>
               <ModalCustom
                  itleModal={
                     edit ? 'Atualizar Banner' : 'Cadastre um novo Banner'
                  }
                  buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
                  buttonIcon={
                     <i
                        onClick={closeModal}
                        className="pe-7s-shopbag btn-icon-wrapper font-weight-bold">
                        {' '}
                     </i>
                  }
                  buttonName="Cadastrar Banner"
                  buttonSize="lg"
                  toggleFunc={openModal}
                  isOpen={modalIsOpen}
                  closeModal={closeModal}
                  size="lg">
                  <Form
                     onSubmit={handleSubmit}
                     noValidate
                     className="text-left">
                     <Row form>
                        <Col md={6}>
                           <FormGroup>
                              <label className="m-0 p-0">Título</label>
                              <Tooltip
                                 texto="Título para o banner"
                                 id="titulo"
                              />

                              <InputIcon
                                 maxLength={100}
                                 type="text"
                                 size={12}
                                 name="titulo"
                                 handleChange={handleChange}
                                 value={values.titulo || ''}
                              />
                           </FormGroup>
                        </Col>
                        <Col md={6}>
                           <FormGroup>
                              <label className="m-0 p-0">
                                 Ordem para o Banner
                              </label>
                              <Tooltip texto="Ordem para o Banner" id="ordem" />

                              <InputIcon
                                 type="number"
                                 size={12}
                                 name="ordem"
                                 handleChange={handleChange}
                                 value={values.ordem || ''}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row form>
                        <Col md={12}>
                           <FormGroup>
                              <label className="m-0 p-0">
                                 Descrição do Banner
                              </label>
                              <Tooltip
                                 texto="Este texto de descrição irá aparecer dentro do Banner."
                                 id="descricao"
                              />
                              <InputIcon
                                 maxLength={180}
                                 type="text"
                                 size={12}
                                 name="descricao"
                                 handleChange={handleChange}
                                 value={values.descricao || ''}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row className="d-flex justify-content-center mb-3" form>
                        <div>
                           {imgData !== null || picture?.length > 0 ? (
                              <div>
                                 <div className="banner-wrapper">
                                    <img
                                       className="playerProfilePic_home_tile"
                                       alt=""
                                       src={
                                          imgData ||
                                          `data:image/*;base64,${picture}`
                                       }
                                    />
                                    {values.descricao && (
                                       <div className="banner-descricao">
                                          {values.descricao}
                                       </div>
                                    )}
                                 </div>
                                 <InputFile
                                    type="file"
                                    style={{
                                       width: '200px',
                                       padding: '10px',
                                       margin: '0',
                                       borderRadius: '5px',
                                       backgroundColor: 'transparent',
                                       color: '#495057',
                                       borderStyle: 'dashed',
                                       borderColor: '#495057',
                                       textTransform: 'uppercase',
                                       textAlign: 'center',
                                       display: 'flex',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                       cursor: 'pointer',
                                    }}
                                    text="Mudar imagem"
                                    size={12}
                                    handleChange={onChangePicture}
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                    name="logo"
                                    id="profilePic"
                                 />
                              </div>
                           ) : (
                              <Col
                                 style={{
                                    padding: '0',
                                    margin: '0',
                                 }}
                                 md={12}>
                                 <InputFile
                                    type="file"
                                    style={{
                                       width: '200px',
                                       height: '200px',
                                       padding: '10px',
                                       margin: '0',
                                       borderRadius: '5px',
                                       backgroundColor: 'transparent',
                                       color: '#495057',
                                       borderStyle: 'dashed',
                                       borderColor: '#495057',
                                       textTransform: 'uppercase',
                                       textAlign: 'center',
                                       display: 'flex',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                       cursor: 'pointer',
                                    }}
                                    text="Clique e escolha imagem do Banner"
                                    size={12}
                                    handleChange={onChangePicture}
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                    name="logo"
                                    id="profilePic"
                                 />
                              </Col>
                           )}
                        </div>
                     </Row>
                     <Button color="link" onClick={closeModal}>
                        {' '}
                        Cancelar{' '}
                     </Button>
                     <Button color="primary" type="submit" disabled={isLoading}>
                        {isLoading ? (
                           <>
                              {edit ? 'Atualizando' : 'Criando'}{' '}
                              {<LoadingButton size={15} />}
                           </>
                        ) : (
                           <>{edit ? 'Atualizar' : 'Criar'} </>
                        )}{' '}
                     </Button>
                  </Form>
               </ModalCustom>
            </div>
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <div className="table-responsive">
                           <PaginationFilterTable
                              columns={columns}
                              rowKey={record => record.nome}
                              dataSource={dataSource}
                              pagination={pagination}
                              loading={isLoading}
                              onChange={handleTableChange}
                              handleSubmit={getListBanner}
                              filterChildren={[
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.link}
                                    onChange={e =>
                                       handleChangePerson(
                                          'link',
                                          e.target.value,
                                       )
                                    }
                                    label="Link"
                                 />,
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.titulo}
                                    onChange={e =>
                                       handleChangePerson(
                                          'titulo',
                                          e.target.value,
                                       )
                                    }
                                    label="Título"
                                 />,
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.ordem}
                                    onChange={e =>
                                       handleChangePerson(
                                          'ordem',
                                          e.target.value,
                                       )
                                    }
                                    label="Ordem"
                                 />,
                              ]}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default ListaBanner;
