import React, { useState, useCallback } from 'react';

//Components
import InputIcon from '../../components/Formulario/InputIcon';
import { useAuth } from '../../customHooks/AuthContext';
import ModalCustom from '../../components/Modal/Modal';
import api from '../../api/api-local';

//Files
import RevelaSenhaIcon from '../../assets/icons/eye-open-red.svg';
import EscondeSenhaIcon from '../../assets/icons/eye-close-red.svg';

//Libs
import { Col, Row, Button, Form } from 'reactstrap';
import Spinner from '../../components/loading/Loading';
import { TextField, Grid } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneIcon from '@material-ui/icons/Phone';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

const Login = props => {
   //Variaveis
   const { mudarTela } = props;
   const { sigIn, usuario } = useAuth();

   //States
   const [isLoading, setIsLoading] = useState(false);
   const [mostraSenha, setMostraSenha] = useState(false);
   const [isOpen, setIsOpen] = useState(false);
   const [listLoja, setListLoja] = useState(null);
   const [login, setLogin] = useState({
      celular: '',
      senha: '',
   });

   //Functions
   const handleInputChange = event => {
      const { name, value } = event.target;
      setLogin(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   // @note login
   const handleSubmit = useCallback(
      async event => {
         event.preventDefault();
         setIsLoading(true);

         try {
            const response = await api
               .post('login', {
                  celular: login.celular,
                  senha: login.senha,
               })
               .catch(err => {
                  setIsLoading(false);

                  err.response.data.error.map(error => {
                     return toast.error(error.message);
                  });
                  setIsOpen(false);
               });
            const { usuario } = response.data;
            if (usuario.lojas.length === 1) {
               setIsOpen(false);
               setIsLoading(false);

               sigIn({
                  celular: login.celular,
                  senha: login.senha,
               });
            } else {
               setIsLoading(false);
               setIsOpen(true);
            }
            setListLoja(usuario.lojas);
         } catch (err) {
            console.log(err);
         }
      },
      [sigIn, login],
   );
   const selectLojaId = event => {
      sigIn({
         celular: login.celular,
         senha: login.senha,
         lojaId: event.target.dataset.lojaid,
      });
   };
   return (
      <>
         <Col
            lg="8"
            md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box ">
               <h4 className="mb-0 text-left">
                  <span>Digite seu login e senha</span>
               </h4>
               <h6 className="mt-3 text-left">
                  Não tem conta?{' '}
                  <button
                     className="text-primary border-0 bg-white"
                     onClick={() => {
                        mudarTela('register');
                     }}>
                     {' '}
                     Registre-se agora{' '}
                  </button>
               </h6>
               <Row className="divider" />
               <div>
                  <Form onSubmit={handleSubmit}>
                     <Row form>
                        <Col md={6} className="mb-3">
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'baseline',
                                 marginTop: '8px',
                              }}>
                              <Grid item>
                                 <PhoneIcon className="mr-1" />
                              </Grid>
                              <InputMask
                                 mask="(99) 99999-9999"
                                 value={login.celular}
                                 onChange={handleInputChange}
                                 name="celular">
                                 {inputProps => (
                                    <TextField
                                       {...inputProps}
                                       type="text"
                                       style={{ width: '100%' }}
                                       label="Celular (Seu login)"
                                    />
                                 )}
                              </InputMask>
                           </div>
                        </Col>

                        <Col md={6} className="mb-3">
                           <div
                              style={{
                                 display: 'flex',
                                 alignItems: 'center',
                              }}>
                              <VpnKeyIcon className="mr-1 mt-4" />
                              <InputIcon
                                 name="senha"
                                 label="Senha"
                                 type={mostraSenha ? 'text' : 'password'}
                                 size={12}
                                 handleChange={handleInputChange}
                                 value={login.senha}
                                 id="password">
                                 <img
                                    src={
                                       mostraSenha
                                          ? RevelaSenhaIcon
                                          : EscondeSenhaIcon
                                    }
                                    alt="Mostrar senha"
                                    className="mostra-senha-icon senha-login"
                                    onClick={() => setMostraSenha(!mostraSenha)}
                                 />
                              </InputIcon>
                           </div>
                        </Col>
                     </Row>

                     <Row className="divider mt-5" />
                     <div className="d-flex align-items-center">
                        <div className="mr-auto">
                           <a
                              href="#"
                              onClick={() => {
                                 mudarTela('esqueciSenha');
                              }}
                              className="btn-lg btn btn-link">
                              Recuperar Senha
                           </a>{' '}
                           <Button
                              color="primary"
                              type="submit"
                              className="btn-wide btn-pill btn-shadow btn-hover-shine"
                              size="lg">
                              Entrar
                           </Button>
                        </div>
                     </div>
                  </Form>
               </div>
            </Col>
         </Col>
         <ModalCustom
            isOpen={isOpen}
            titleModal={`Selecione uma loja`}
            style={{ display: 'none' }}>
            <div className="containt-lojas">
               {listLoja?.map((i, index) => (
                  <div
                     key={index}
                     className="style-button-lojas"
                     data-lojaid={i.lojaId}
                     onClick={selectLojaId}>
                     {i.nomeRazaoSocial}
                  </div>
               ))}
            </div>
         </ModalCustom>
         {isLoading && <Spinner />}
      </>
   );
};

export default Login;
