import React from 'react';
import { FlexBox, InputSearch } from './styles';
import { Table } from 'antd';
import 'antd/dist/antd.css';

const Tables = props => {
   const {
      columns,
      dataSource,
      campoFiltro,
      size,
      rowKey,
      pagination,
      onChange,
      loading,
   } = props;

   return (
      <FlexBox style={{ overflowY: 'auto' }}>
         <div className="search">
            <InputSearch>{campoFiltro}</InputSearch>
         </div>

         <Table
            pagination={pagination}
            rowKey={rowKey}
            columns={columns}
            dataSource={dataSource}
            onChange={onChange}
            size={size}
            loading={loading}
            bordered
         />
      </FlexBox>
   );
};
export default Tables;
