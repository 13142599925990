import React from 'react';
import Icon from '../../assets/icons/error_outline-24px.svg';
import { Tooltip } from 'antd';

const TooltipPng = ({ id, texto }) => {
   return (
      <Tooltip title={texto} color="#fd5958" key={id}>
         <img
            className="ml-1 mb-1"
            style={{ width: '15px', cursor: 'pointer' }}
            id={id}
            src={Icon}
            alt=""
         />
      </Tooltip>
   );
};

export default TooltipPng;
