import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PageTitle from '../../layout/AppMain/PageTitle';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Button as ButtonBootstrap } from 'reactstrap';
import CheckIcon from '@material-ui/icons/Check';
import api from '../../../api/api-local';

//Libs
import { Row, Col, CardBody } from 'reactstrap';
import Loading from '../../../components/loading/Loading';

const useStyles = makeStyles(theme => ({
   '@global': {
      ul: {
         margin: 0,
         padding: 0,
         listStyle: 'none',
      },
   },
   subTitleHeaderStyle: {
      color: '#4fbaac',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 24,
      letterSpacing: 1,
   },
   appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
   },
   toolbar: {
      flexWrap: 'wrap',
   },
   toolbarTitle: {
      flexGrow: 1,
   },
   link: {
      margin: theme.spacing(1, 1.5),
   },
   heroContent: {
      padding: theme.spacing(1, 0, 2),
   },
   cardHeader: {
      backgroundColor: '#ef5350',
      color: '#212121',
      fontWeight: 'bold!important',
   },
   cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      color: '#342B4B',
      marginBottom: '-1em',
      fontWeight: 'bold',
      fontSize: '12px',
   },
   subTitleFooterStyle: {
      fontWeight: 'bold',
      color: '#342B4B',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      fontSize: '13px',
   },
   buttonSubmitPlan: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
   },
   buttonContentStyle: {
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      justifyItems: 'center',
      alignItems: 'center',
      width: '200px',
   },
   footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
         paddingTop: theme.spacing(6),
         paddingBottom: theme.spacing(6),
      },
   },
}));

const Planos = () => {
   const [listaPlanos, setListaPlanos] = useState([]);
   const classes = useStyles();

   const tiers = [
      {
         id: 1,
         title: listaPlanos[1]?.nome,
         subTitleNextPlan: listaPlanos[1]?.descricao
            ?.split('<br />')
            .splice(0, 1),
         price: listaPlanos[1]?.valor,
         subTitleFooter: 'Cobrado Mensalmente',
         colorTitle: '#faf1d2',
         linkAssinatura: listaPlanos[1]?.linkAssinatura,

         description: listaPlanos[1]?.descricao?.split('<br />').splice(1),
         atual: listaPlanos[1]?.atual,
      },
      {
         id: 2,
         title: listaPlanos[2]?.nome,
         subTitleNextPlan: listaPlanos[2]?.descricao
            ?.split('<br />')
            .splice(0, 1),

         linkAssinatura: listaPlanos[2]?.linkAssinatura,
         price: listaPlanos[2]?.valor,
         colorTitle: '#c8ecf8',
         subTitleFooter: 'Cobrado Mensalmente',
         description: listaPlanos[2]?.descricao?.split('<br />').splice(1),
         atual: listaPlanos[2]?.atual,
      },
      {
         id: 3,
         title: listaPlanos[3]?.nome,
         subTitleNextPlan: listaPlanos[3]?.descricao
            ?.split('<br />')
            .splice(0, 1),
         linkAssinatura: listaPlanos[3]?.linkAssinatura,

         price: listaPlanos[3]?.valor,
         colorTitle: '#cef0ef',
         subTitleFooter: 'Cobrado Mensalmente',
         description: listaPlanos[3]?.descricao?.split('<br />').splice(1),
         atual: listaPlanos[3]?.atual,
      },
      {
         id: 4,
         title: listaPlanos[4]?.nome,
         subTitleNextPlan: listaPlanos[4]?.descricao
            ?.split('<br />')
            .splice(0, 1),
         linkAssinatura: listaPlanos[4]?.linkAssinatura,

         price: listaPlanos[4]?.valor,
         colorTitle: '#d0c1f1',
         subTitleFooter: 'Cobrado Mensalmente',
         description: listaPlanos[4]?.descricao?.split('<br />').splice(1),
         atual: listaPlanos[4]?.atual,
      },
   ];

   useEffect(() => {
      api.get('plano').then(response => setListaPlanos(response.data));
   }, []);

   return (
      <>
         <PageTitle
            heading="Planos"
            subheading="Selecione uma das opções abaixo"
            icon="pe-7s-credit icon-gradient bg-fideleco"
         />
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <h5 className="mr-4">
                     Plano Atual:{' '}
                     <strong>
                        {listaPlanos.filter(plano => plano.atual)[0]?.nome ||
                           ''}
                     </strong>
                  </h5>
               </Col>
            </Row>
            <Row>
               <Col md="12">
                  <CssBaseline />
                  {/* Hero unit */}
                  <>
                     {/*<Container
                                 maxWidth="sm"
                                 component="main"
                                 className={classes.heroContent}>
                                 <Typography
                                    component="h5"
                                    variant="h5"
                                    align="center"
                                    color="textPrimary"
                                    gutterBottom>
                                    Planos Fideleco
                                 </Typography>
                                 <Typography
                                    variant="span"
                                    align="center"
                                    color="textSecondary"
                                    component="p">
                                    Selecione uma das opções abaixo
                                 </Typography>
                              </Container>*/}
                     <div
                        className="teste"
                        style={{
                           maxWidth: '100%',
                           paddingTop: 20,
                           paddingLeft: '0 !important',
                           paddingRight: '0 !important',
                        }}>
                        <Grid
                           container
                           spacing={2}
                           style={{
                              display: 'flex',
                              alignItems: 'strech',
                              justifyContent: 'space-around',
                              marginBottom: '20px',
                              width: '100%',
                              minWidth: '230px',
                              flexWrap: 'wrap',
                              flex: 1,
                           }}
                           lg={12}
                           xs={12}
                           sm={6}
                           md={12}>
                           {tiers.map(tier => (
                              <Grid
                                 style={{
                                    width: '20%',
                                    minWidth: '230px',
                                 }}
                                 item
                                 key={tier.title}>
                                 {tier?.title ? (
                                    <Card
                                       style={{
                                          borderRadius: 25,
                                          height: '100%',
                                       }}>
                                       <CardHeader
                                          className
                                          title={tier.title}
                                          subheader={tier.subheader}
                                          style={{
                                             backgroundColor: tier.colorTitle,
                                             fontWeight: 'bold',
                                             height: 100,
                                          }}
                                          titleTypographyProps={{
                                             align: 'center',
                                          }}
                                          subheaderTypographyProps={{
                                             align: 'center',
                                          }}
                                          action={
                                             tier.title === 'Pro' ? (
                                                <StarIcon />
                                             ) : null
                                          }
                                          className={classes.cardHeader}
                                       />
                                       <CardContent
                                          style={{
                                             height: 'auto',
                                             padding: '10px 20px 20px',
                                          }}>
                                          <div className={classes.cardPricing}>
                                             <p>{tier.subTitleHeader}</p>
                                          </div>
                                          <div
                                             className={
                                                (classes.cardPricing,
                                                classes.subTitleHeaderStyle)
                                             }>
                                             <h5
                                                className={
                                                   classes.subTitleHeaderStyle
                                                }>
                                                {tier.price}/mês
                                             </h5>
                                          </div>
                                          <div
                                             className={
                                                classes.subTitleFooterStyle
                                             }>
                                             <p>{tier.subTitleFooter}</p>
                                          </div>
                                          <div
                                             className={
                                                classes.buttonSubmitPlan
                                             }>
                                             {tier.linkAssinatura && (
                                                <a
                                                   style={{
                                                      textDecoration: 'none',
                                                   }}
                                                   href={`${tier.linkAssinatura}`}
                                                   target="_blank">
                                                   <ButtonBootstrap
                                                      color="primary"
                                                      type="submit"
                                                      className={`${classes.buttonContentStyle} btn-wide btn-pill btn-shadow btn-hover-shine`}
                                                      style={{
                                                         padding: '12px 6px',
                                                         fontSize: '14px',
                                                         visibility: `${
                                                            tier.linkAssinatura
                                                               ? 'visible'
                                                               : 'hidden !important'
                                                         }`,
                                                      }}
                                                      size="lg">
                                                      Comece agora{' '}
                                                      <i className="pe-7s-angle-right-circle"></i>
                                                   </ButtonBootstrap>
                                                </a>
                                             )}
                                          </div>
                                          {tier.subTitleNextPlan ? (
                                             <div
                                                style={{
                                                   fontWeight: 'bold',
                                                   fontSize: '14px',
                                                   marginTop: '2em',
                                                   textAlign: 'initial',
                                                }}>
                                                {tier.subTitleNextPlan}
                                             </div>
                                          ) : (
                                             ''
                                          )}

                                          <ul
                                             style={{
                                                marginTop: `${
                                                   tier.subTitleNextPlan
                                                      ? '5px'
                                                      : '25px'
                                                }`,
                                             }}>
                                             {tier?.description?.map(line => (
                                                <Typography
                                                   style={{
                                                      fontSize: '12px',
                                                      fontWeight: 'bold',
                                                      lineHeight: '18px',
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                         'flex-start',
                                                      margin: '10px 0',
                                                   }}
                                                   component="li"
                                                   variant="subtitle1"
                                                   align="left"
                                                   key={line}>
                                                   <CheckIcon
                                                      style={{
                                                         color: '#42e242',
                                                         marginRight: '5px',
                                                      }}
                                                   />
                                                   {line}
                                                </Typography>
                                             ))}
                                          </ul>
                                       </CardContent>

                                       <CardActions>
                                          <Button
                                             fullWidth
                                             variant={tier.buttonVariant}
                                             color="primary">
                                             {tier.buttonText}
                                          </Button>
                                       </CardActions>
                                    </Card>
                                 ) : (
                                    <Loading />
                                 )}
                              </Grid>
                           ))}
                        </Grid>
                     </div>
                     {/* Footer */}

                     {/* End footer */}
                  </>
               </Col>
            </Row>
         </CSSTransitionGroup>
      </>
   );
};

export default Planos;
