import styled from 'styled-components';

export const FlexBox = styled.div`
   width: 100%;
   display: flex;
   align-items: flex-end;
   flex-direction: column;

   .search {
      display: flex;
      justify-content: flex-end;
   }

   @media (max-width: 400px) {
      .ant-table-wrapper {
         overflow-x: scroll;
      }
   }
   > div {
      width: 100%;

      .editar {
         cursor: pointer;
      }
   }
   .ant-input-group-addon {
      button {
         font-weight: bold;
         border: none;
         background-color: #fd5958;

         :hover {
            background-color: #dc3545;
         }
      }
   }

   .ant-table-pagination {
      .ant-pagination-item {
         :hover {
            color: black;
            border: 2px solid #fd5958;
         }

         span {
            color: black;
         }

         ::selection {
            color: black;
         }
      }

      .ant-pagination-item-active {
         color: black;
         border: 2px solid #fd5958;

         :hover {
            color: black;
            border: 2px solid #fd5958;
         }
      }

      .ant-pagination-item-active a {
         color: #fd5958;
      }

      .ant-pagination-next {
         .ant-pagination-item-link {
            :hover {
               border: 2px solid #fd5958;
               color: black;
            }
         }
      }

      .ant-select-selector {
         :hover {
            border: 2px solid #fd5958;
            color: black;
         }
      }

      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
         .ant-select-selector {
         border: 2px solid #fd5958;
         box-shadow: 0 0 0 0;
         color: black;
      }

      .ant-select {
         .ant-pagination-options-size-changer {
            background-color: red;
            .ant-select-single {
               .ant-select-show-arrow {
                  border: 2px solid #fd5958;
                  color: black;
               }
            }
         }
      }
   }
`;

export const InputSearch = styled.div`
   input {
      :hover {
         border: 1px solid rgb(253, 89, 88);
      }
      :focus {
         border: 1px solid rgb(253, 89, 88);
         box-shadow: 0 0 0 0;
      }
   }

   .ant-input-group-addon {
      :hover {
         border: 1px solid rgb(253, 89, 88);
      }

      ::selection {
         color: red;
      }

      text-decoration: none;
      outline: none;
   }
`;
