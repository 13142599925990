import React, { useState, useEffect } from 'react';
//Components
import validateFormProgramaFidelidade from '../../../helpers/validateFormProgramaFidelidade';
import InputIcon from '../../../components/Formulario/InputIcon';
import Regulamento from '../../usuario/regulamento/Regulamento';
import { useAuth } from '../../../customHooks/AuthContext';
import ModalCustom from '../../../components/Modal/Modal';
import PageTitle from '../../layout/AppMain/PageTitle';
import useForm from '../../../customHooks/useForm';
import Textarea from '../../../components/Formulario/Textarea';
import TableSemFilter from '../../../components/Table';
import api from '../../../api/api-local';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

//images
import optionFidelidade1 from '../../../assets/icons/conf-fidelidade-1.svg';
import optionFidelidade2 from '../../../assets/icons/conf-fidelidade-2.svg';
import optionFidelidade3 from '../../../assets/icons/conf-fidelidade-3.svg';
import optionFidelidade4 from '../../../assets/icons/conf-fidelidade-4.svg';
//Libs
import {
   Radio,
   RadioGroup,
   FormControlLabel,
   FormControl,
   FormLabel,
} from '@material-ui/core';
import {
   Col,
   Row,
   Button,
   Form,
   FormGroup,
   Card,
   CardBody,
   Input,
} from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Tooltip from '../../../components/tooltip';
import Spinner from '../../../components/loading/Loading';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Divider } from 'antd';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
   formControl: {
      marginRight: theme.spacing(1),
      width: '100%',
   },
   selectEmpty: {},
}));

const ConfigProgramaFidelidade = () => {
   const classes = useStyles();
   const { Option } = Select;
   const { usuario, signOut } = useAuth();
   const [disabledField, setDisabledField] = useState(false);
   const [disabledChoosePremiumType, setDisabledChoosePremiumType] =
      useState(false);
   const [listaInputsValidos, setListaInputsValidos] = useState(null);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [listPagamentos, setListPagamentos] = useState([]);
   const [valueCheckboxRegulamento, setValueCheckboxRegulamento] =
      useState(false);

   const [habilitarValePonto, setHabilitarValePonto] = useState(false);
   const [valueTipoPontuacao, setValueTipoPontuacao] = useState();

   const [objDataDiferenciada, setObjDataDiferenciada] = useState({
      dataInicio: '',
      dataFim: '',
      fator: '',
   });

   const [objFormaPagamentoPlataforma, setObjFormaPagamentoPlataforma] =
      useState({
         descricao: '',
         fator: '',
      });

   const [objClassificacaoCliente, setObjClassificacaoCliente] = useState({
      descricao: '',
      fator: '',
      meta: '',
   });

   const [objAgrupamento, setObjAgrupamento] = useState({
      descricao: '',
      fator: '',
   });

   const [regraControleResgateCliente, setRegraControleResgateCliente] =
      useState({
         tempoValidacaoRetroativa: '',
         quantidadePontuacaoGanha: '',
         tipoPontuacao: '',
      });

   const [listaDataDiferenciada, setListaDataDiferenciada] = useState([]);
   const [listaFormaPagamentoPlataforma, setListaFormaPagamentoPlataforma] =
      useState([]);

   const [listaClassificacaoCliente, setListaClassificacaoCliente] = useState(
      [],
   );
   const [listaAgrupamento, setListaAgrupamento] = useState([]);
   const { statusJornadaCadastroPlataforma } = usuario;

   const statusEnabled =
      statusJornadaCadastroPlataforma?.configuracaoLojaConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPersonalizacaoConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPremioConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoProgramaConcluida;

   const columnsDataDiferenciada = [
      {
         dataIndex: 'dataInicio',
         title: 'Data início',
         width: 200,
         key: 'dataInicio',
      },

      {
         dataIndex: 'dataFim',
         title: 'Data fim',
         width: 200,
         key: 'dataFim',
      },

      {
         dataIndex: 'fator',
         title: 'Fator',
         width: 200,
         key: 'fator',
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 80,
         align: 'center',
         render: obj => (
            <div>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     removeObjDataDiferenciada(obj);
                  }}
               />
            </div>
         ),
      },
   ];

   const columnsFormaPagamentoPlataforma = [
      {
         dataIndex: 'descricao',
         title: 'Descrição',
         width: 200,
         key: 'descricao',
      },

      {
         dataIndex: 'fator',
         title: 'Fator',
         width: 200,
         key: 'fator',
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 80,
         align: 'center',
         render: obj => (
            <div>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     removeObjFormaPagamentoPlataforma(obj);
                  }}
               />
            </div>
         ),
      },
   ];

   const columnsClassificacaoCliente = [
      {
         dataIndex: 'descricao',
         title: 'Descrição',
         width: 200,
         key: 'descricao',
      },

      {
         dataIndex: 'fator',
         title: 'Fator',
         width: 200,
         key: 'fator',
      },

      {
         dataIndex: 'meta',
         title: 'Meta',
         width: 200,
         key: 'meta',
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 80,
         align: 'center',
         render: obj => (
            <div>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     removeObjClassificacaoCliente(obj);
                  }}
               />
            </div>
         ),
      },
   ];

   const columnsAgrupamento = [
      {
         dataIndex: 'descricao',
         title: 'Descrição',
         width: 200,
         key: 'descricao',
      },

      {
         dataIndex: 'fator',
         title: 'Fator',
         width: 200,
         key: 'fator',
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 80,
         align: 'center',
         render: obj => (
            <div>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     removeObjAgrupamento(obj);
                  }}
               />
            </div>
         ),
      },
   ];

   const initialValues = {
      configuracaoSaldoPremio: null,
      configuracaoPremio: null,
      fatorConversao: '',
      pontuacaoCadastro: 0,
      pontuacaoIndicacao: 0,
      validadeCupomValePremio: 0,
      pontuacaoExpiracao: 0,
      valorPontuacaoMaximaDia: null,
      quantidadePontuacaoMaximaDia: '',
      regulamentoExtra: '',
      plataformaId: '',
      metaAniversarioCadastro: '',
      metaPontuacao: '',
      pontuacaoAniversario: '',
      pontuacaoAniversarioCadastro: '',
      pontuacaoMetaPontuacao: '',
      permitePontuacaoCadastroIncompleto: '',

      quantidadeDiaClassificacao: '',
      habilitarValePonto: habilitarValePonto,
      regraControleResgateCliente: {
         tempoValidacaoRetroativa: '',
         quantidadePontuacaoGanha: '',
         tipoPontuacao: '',
      },
   };

   const { values, errors, handleChange, handleSubmit, setValues } = useForm(
      initialValues,
      formControlUser,
      validateFormProgramaFidelidade,
   );

   const openModal = tipo => {
      setIsOpen(true);
   };

   const closeModal = () => {
      setIsOpen(false);
   };

   const handleChangeCheckboxRegulamento = () => {
      setValueCheckboxRegulamento(!valueCheckboxRegulamento);
   };

   const handleHabilitarValePonto = () => {
      setHabilitarValePonto(!habilitarValePonto);
   };

   const handleRegraControleResgateCliente = event => {
      const { name, value } = event.target;
      console.log(value);
      if (value != '') {
         setRegraControleResgateCliente(prevState => ({
            ...prevState,
            [name]: value,
         }));
      } else {
         toast.info('Selecione uma opção');
      }
   };

   function formControlUser() {
      setIsLoading(true);

      const datasDiferenciadasFormatadas = [];

      for (let key in listaDataDiferenciada) {
         const dataInicio = moment(
            listaDataDiferenciada[key].dataInicio,
            'DD/MM/YYYY',
         );
         const dataFim = moment(
            listaDataDiferenciada[key].dataFim,
            'DD/MM/YYYY',
         );

         datasDiferenciadasFormatadas.push({
            ...listaDataDiferenciada[key],
            dataInicio: dataInicio.format('YYYY-MM-DD'),
            dataFim: dataFim.format('YYYY-MM-DD'),
         });
      }

      const valuesPlataformaId = {
         configuracaoSaldoPremio: parseInt(values.configuracaoSaldoPremio),
         fatorConversao: parseInt(values.fatorConversao),
         pontuacaoCadastro: values.pontuacaoCadastro,
         pontuacaoIndicacao: values.pontuacaoIndicacao,
         validadeCupomValePremio: values.validadeCupomValePremio,
         pontuacaoExpiracao: values.pontuacaoExpiracao,
         valorPontuacaoMaximaDia: parseInt(values.valorPontuacaoMaximaDia),
         quantidadePontuacaoMaximaDia: parseInt(
            values.quantidadePontuacaoMaximaDia,
         ),
         regulamentoExtra: values.regulamentoExtra,
         permitePontuacaoCadastroIncompleto: true,
         pontuacaoAniversario: 1,
         pontuacaoAniversarioCadastro: values.pontuacaoAniversarioCadastro,

         dataDiferenciada: datasDiferenciadasFormatadas,
         formaPagamentoPlataforma: listaFormaPagamentoPlataforma,
         quantidadeDiaClassificacao: values.quantidadeDiaClassificacao,
         classificacaoCliente: listaClassificacaoCliente,
         agrupamento: listaAgrupamento,
         regraControleResgateCliente: regraControleResgateCliente,
         habilitarValePonto: habilitarValePonto,
      };

      api.put('plataforma/configurar', valuesPlataformaId)
         .then(response => {
            if (response.status === 200) {
               setIsLoading(false);
               toast.success('Dados alterados com sucesso!');
               getListaPagamentos();
               updateUser(usuario.plataforma.plataformaId);

               api.get('/plataforma/configuracao/statusjornadacadastro')
                  .then(response => {
                     if (response.status === 200) {
                        const { data } = response;
                        const usuario = localStorage.getItem('@Fideleco:user');
                        const parsedUsuario = JSON.parse(usuario);
                        console.log('data', data);
                        parsedUsuario.statusJornadaCadastroPlataforma = data;
                        localStorage.setItem(
                           '@Fideleco:user',
                           JSON.stringify(parsedUsuario),
                        );

                        window.location.reload();
                     }
                  })
                  .catch(error => {
                     setIsLoading(false);
                     if (error.response.status === 401) {
                        toast.warning('Sessão expirada. Entre novamente', {
                           autoClose: 3000,
                        });
                        signOut();
                     } else {
                        toast.warning(error.response.data.error[0].message);
                     }
                  });
            }
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               error.response.data.error.map(i => toast.error(i.message));
            }
         });
   }

   const updateUser = plataformaId => {
      setIsLoading(true);
      try {
         api.get(`plataforma/${plataformaId}`).then(response => {
            if (response.status === 200 && plataformaId) {
               console.log('dataaa', response.data);
               setValues(response.data);
               setDisabledField(!!response.data.fatorConversao);
               setListaInputsValidos(response.data.configuracaoPlataforma);
               setDisabledChoosePremiumType(
                  response.data.configuracaoPlataforma
                     .configuracaoSaldoPremioLojaHabilitada,
               );
               setValueCheckboxRegulamento(
                  Boolean(response.data.regulamentoExtra),
               );
               setHabilitarValePonto(response.data.habilitarValePonto);

               setRegraControleResgateCliente(
                  response.data.regraControleResgateCliente,
               );

               setValueTipoPontuacao(
                  response.data.regraControleResgateCliente.tipoPontuacao,
               );

               setListaFormaPagamentoPlataforma(
                  response.data.formaPagamentoPlataforma.map(formaPagamento => {
                     return {
                        ...formaPagamento,
                        id: formaPagamento.formaPagamentoPlataformaId,
                     };
                  }),
               );

               console.log(
                  'get dataDiferenciada',
                  response.data.dataDiferenciada,
               );

               setListaDataDiferenciada(
                  response.data.dataDiferenciada.map(dataDiferenciada => {
                     const dataInicio = moment(
                        dataDiferenciada.dataInicio,
                        'YYYY/MM/DD',
                     );

                     const dataFim = moment(
                        dataDiferenciada.dataFim,
                        'YYYY/MM/DD',
                     );

                     return {
                        ...dataDiferenciada,
                        dataInicio: dataInicio.format('DD-MM-YYYY'),
                        dataFim: dataFim.format('DD-MM-YYYY'),
                        id: dataDiferenciada.dataDiferenciadaId,
                     };
                  }),
               );

               setListaClassificacaoCliente(
                  response.data.classificacaoCliente.map(
                     classificacaoCliente => {
                        return {
                           ...classificacaoCliente,
                           id: classificacaoCliente.classificacaoClienteId,
                        };
                     },
                  ),
               );
               setListaAgrupamento(
                  response.data.agrupamento.map(agrupamento => {
                     return {
                        ...agrupamento,
                        id: agrupamento.agrupamentoId,
                     };
                  }),
               );
            }

            setIsLoading(false);
         });
      } catch (error) {
         setIsLoading(false);
         toast.warning(error.response.data.error[0].message);
      }
   };

   //Pegando a lista de forma de pagamentos
   const getListaPagamentos = () => {
      try {
         api.get('/formapagamentoplataforma')
            .then(response => {
               if (response.status === 200) {
                  const { data } = response;
                  console.log('data', response);
                  setListPagamentos(data);
               }
            })
            .catch(error => {
               toast.warning(error.response.data.error[0].message);
            });
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(async () => {
      updateUser(usuario.plataforma.plataformaId);
      getListaPagamentos();
   }, []);

   /* --------------------- Data Diferenciada---------------------- */

   // Defenido um objeto para colocar na listaDataDiferenciada
   const handleDataDiferenciada = event => {
      const { name, value } = event.target;
      setObjDataDiferenciada(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   // Incluido novo objeto defenido na listaDataDiferenciada para ser enviado ao banco
   const addObjDataDiferenciada = event => {
      const id = uuidv4();

      if (
         objDataDiferenciada.dataInicio &&
         objDataDiferenciada.dataFim &&
         objDataDiferenciada.fator != ''
      ) {
         const dataInicio = moment(
            objDataDiferenciada.dataInicio,
            'DD/MM/YYYY',
         );
         const dataFim = moment(objDataDiferenciada.dataFim, 'DD/MM/YYYY');

         if (dataInicio > dataFim) {
            toast.info('A data de ínicio deve ser antes da data de fim');

            return;
         }

         setListaDataDiferenciada([
            ...listaDataDiferenciada,
            {
               ...objDataDiferenciada,
               dataInicio: dataInicio.format('DD/MM/YYYY'),
               dataFim: dataFim.format('DD/MM/YYYY'),
               id: id,
               dataDiferenciadaId: 0,
            },
         ]);

         setObjDataDiferenciada({
            dataInicio: '',
            dataFim: '',
            fator: '',
         });
      } else {
         toast.info(
            'Preencha todos os campos antes de tentar incluir na tabela',
         );
      }
   };

   //Removendo objeto da listaDataDiferenciada
   const removeObjDataDiferenciada = obj => {
      console.log(obj);
      //Remover da listaDataDiferenciada o obj passado
      const novaLista = listaDataDiferenciada.filter(item => {
         if (item.id != obj.id) {
            return item;
         }
      });
      //Add nova novaLista com item ja exluido
      setListaDataDiferenciada(novaLista);
   };

   /* --------------------- Forma de Pagamentos---------------------- */

   // Defenido um objeto para colocar na listaDataDiferenciada
   const handleFormaPagamentoPlataforma = event => {
      const { name, value } = event.target;
      setObjFormaPagamentoPlataforma(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   // Incluido novo objeto defenido na listaDataDiferenciada para ser enviado ao banco
   const addObjFormaPagamentoPlataforma = event => {
      const id = uuidv4();
      if (
         objFormaPagamentoPlataforma.descricao &&
         objFormaPagamentoPlataforma.fator != ''
      ) {
         setListaFormaPagamentoPlataforma([
            ...listaFormaPagamentoPlataforma,
            {
               ...objFormaPagamentoPlataforma,
               id: id,
               formaPagamentoPlataformaId: 0,
            },
         ]);
         //Limpando os campos
         setObjFormaPagamentoPlataforma({
            descricao: '',
            fator: '',
         });
      } else {
         toast.info(
            'Preencha todos os campos antes de tentar incluir na tabela',
         );
      }
   };

   //Removendo objeto da listaFormaPagamentoPlataforma
   const removeObjFormaPagamentoPlataforma = obj => {
      console.log(obj);
      //Remover da listaFormaPagamentoPlataforma o obj passado
      const novaLista = listaFormaPagamentoPlataforma.filter(item => {
         if (item.id != obj.id) {
            return item;
         }
      });
      //Add nova novaLista com item ja exluido
      setListaFormaPagamentoPlataforma(novaLista);
   };

   /* --------------------- Classificacão Cliente---------------------- */

   // Defenido um objeto para colocar na listaDataDiferenciada
   const handleClassificacaoCliente = event => {
      const { name, value } = event.target;
      setObjClassificacaoCliente(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   // Incluido novo objeto defenido na listaDataDiferenciada para ser enviado ao banco
   const addObjClassificacaoCliente = event => {
      const id = uuidv4();
      if (
         objClassificacaoCliente.descricao &&
         objClassificacaoCliente.fator != ''
      ) {
         setListaClassificacaoCliente([
            ...listaClassificacaoCliente,
            {
               ...objClassificacaoCliente,
               id: id,
               classificacaoClienteId: 0,
            },
         ]);
         //Limpando campos
         setObjClassificacaoCliente({
            descricao: '',
            fator: '',
            meta: '',
         });
      } else {
         toast.info(
            'Preencha todos os campos antes de tentar incluir na tabela',
         );
      }
   };

   //Removendo objeto da listaClassificacaoCliente
   const removeObjClassificacaoCliente = obj => {
      console.log(obj);
      //Remover da listaClassificacaoCliente o obj passado
      const novaLista = listaClassificacaoCliente.filter(item => {
         if (item.id != obj.id) {
            return item;
         }
      });
      //Add nova novaLista com item ja exluido
      setListaClassificacaoCliente(novaLista);
   };

   /* --------------------- Agrupamento ---------------------- */

   // Defenido um objeto para colocar na listaDataDiferenciada
   const handleAgrupamento = event => {
      const { name, value } = event.target;
      setObjAgrupamento(prevState => ({
         ...prevState,
         [name]: value,
      }));
   };

   // Incluido novo objeto defenido na listaDataDiferenciada para ser enviado ao banco
   const addObjAgrupamento = event => {
      const id = uuidv4();
      if (objAgrupamento.descricao && objAgrupamento.fator != '') {
         setListaAgrupamento([
            ...listaAgrupamento,
            {
               ...objAgrupamento,
               id: id,
               agrupamentoId: 0,
            },
         ]);
         //Limpando campos
         setObjAgrupamento({
            descricao: '',
            fator: '',
         });
      } else {
         toast.info(
            'Preencha todos os campos antes de tentar incluir na tabela',
         );
      }
   };

   //Removendo objeto da listaAgrupamento
   const removeObjAgrupamento = obj => {
      console.log(obj);
      //Remover da listaAgrupamento o obj passado
      const novaLista = listaAgrupamento.filter(item => {
         if (item.id != obj.id) {
            return item;
         }
      });
      //Add nova novaLista com item ja exluido
      setListaAgrupamento(novaLista);
   };

   return (
      <>
         <PageTitle
            heading="Configurar Programa de Fidelidade"
            subheading="Configure o seu Programa de fidelidade"
            icon="pe-7s-users icon-gradient bg-fideleco">
            <ModalCustom
               buttonClass="d-none"
               titleModal="Regulamento"
               isOpen={modalIsOpen}
               closeModal={closeModal}
               size="lg">
               <Regulamento />
            </ModalCustom>
         </PageTitle>

         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <Form onSubmit={handleSubmit}>
                           <Row form>
                              <FormControl
                                 className="w-100 mb-5"
                                 component="fieldset">
                                 <FormLabel className="mb-3" component="legend">
                                    <b>ATENÇÃO:</b> ESTA ESCOLHA PODERÁ SER
                                    MUDADA À PARTIR DO PLANO FIDELE PRATA
                                    <Tooltip
                                       texto="Definir como será a gestão de Saldo de
                                    Prêmios é importante mesmo que você possua
                                    apenas uma única filial (unidade/loja) neste
                                    momento. Você pode escolher uma dentre 4
                                    modalidades: 'Saldo e Prêmios unificados'
                                    (onde os Pontos ganhos por seus
                                    clientes e os prêmios disponíveis são os
                                    mesmos para todas as suas filiais, pondendo
                                    assim o cliente pontuar em uma filial e
                                    resgatar seus pontos em outra), 'Saldo e
                                    Prêmios individuais' (onde o cliente que
                                    pontuar em determinada filial deverá
                                    resgatar seus pontos apenas nela), 'Apenas
                                    Saldo unificado' (onde o cliente pode
                                    pontuar em quaisquer filiais, porém os
                                    prêmios para resgate de seus pontos são
                                    específicos de cada filial) e 'Apenas Prêmio
                                    unificado' (onde os clientes somente podem
                                    utilizar seus ponto na filial onde pontuou,
                                    porém pode resgatar exatamente os mesmos
                                    prêmios em todas as filiais)."
                                       id="configuracaoSaldoPremio"
                                    />
                                 </FormLabel>

                                 <RadioGroup
                                    className="d-flex justify-content-center"
                                    aria-label="gender"
                                    name="configuracaoSaldoPremio"
                                    value={
                                       values.configuracaoSaldoPremio?.toString() ||
                                       values.configuracaoSaldoPremio
                                    }
                                    onChange={handleChange}
                                    row
                                    defaultValue="top">
                                    <FormControlLabel
                                       value="1"
                                       labelPlacement="top"
                                       disabled={
                                          disabledChoosePremiumType === false
                                             ? true
                                             : false
                                       }
                                       label={
                                          <>
                                             <img
                                                width="200px"
                                                src={optionFidelidade1}
                                             />
                                             <div>
                                                Saldo e Prêmios Unificados
                                             </div>
                                          </>
                                       }
                                       control={<Radio />}
                                    />
                                    <FormControlLabel
                                       value="2"
                                       control={<Radio />}
                                       disabled={
                                          disabledChoosePremiumType === false
                                             ? true
                                             : false
                                       }
                                       label={
                                          <>
                                             <img
                                                width="200px"
                                                src={optionFidelidade2}
                                             />
                                             <div>
                                                Saldo e Prêmios Individuais
                                             </div>
                                          </>
                                       }
                                       labelPlacement="top"
                                    />
                                    <FormControlLabel
                                       value="3"
                                       control={<Radio />}
                                       disabled={
                                          disabledChoosePremiumType === false
                                             ? true
                                             : false
                                       }
                                       label={
                                          <>
                                             <img
                                                width="200px"
                                                src={optionFidelidade3}
                                             />
                                             <div> Apenas Saldo Unificado</div>
                                          </>
                                       }
                                       labelPlacement="top"
                                    />
                                    <FormControlLabel
                                       value="4"
                                       control={<Radio />}
                                       disabled={
                                          disabledChoosePremiumType === false
                                             ? true
                                             : false
                                       }
                                       label="Top"
                                       label={
                                          <>
                                             <img
                                                width="200px"
                                                src={optionFidelidade4}
                                             />
                                             <div>
                                                Apenas Prêmios Unificados
                                             </div>
                                          </>
                                       }
                                       labelPlacement="top"
                                    />
                                 </RadioGroup>
                              </FormControl>
                              <Col md={6} className="mb-5">
                                 <FormGroup className="text-left">
                                    <label className="m-0 p-0">
                                       Fator de Conversão
                                    </label>
                                    <Tooltip
                                       texto="É a conta matemática que define o valor
                                       em reais (R$) que um cliente deve gastar
                                       para que tenha direito a 1 (um) ponto.
                                       Desta forma, um fator muito baixo poderá
                                       gerar muitos ponto e um fator muito alto
                                       poderá desanimar os clientes. O
                                       equilíbrio é a melhor saída. O ideal é
                                       que o cliente ganhe, pelo menos, 1 (um)
                                       ponto em toda compra, ou seja: se a média
                                       de consumo do cliente é de R$20,00, o seu
                                       Fator de Conversão ficará entre R$10,00 e
                                       R$20,00. Evite números quebrados como
                                       R$12,50. Prefira valores cheios como
                                       R$5,00 – R$10,00 – R$20,00 – R$50,00.
                                       Esta configuração não pode ser alterada
                                       depois de salvo."
                                       id="fatorConversao"
                                    />
                                    <InputIcon
                                       type="number"
                                       size={12}
                                       handleChange={handleChange}
                                       name="fatorConversao"
                                       value={values.fatorConversao}
                                       disabled={statusEnabled}
                                    />
                                    {errors.fatorConversao && (
                                       <p className="is-danger align-message">
                                          {errors.fatorConversao}
                                       </p>
                                    )}
                                    <div>
                                       A cada <b>R$ {values.fatorConversao}</b>,
                                       o cliente Receberá
                                       <b> 1 ponto</b>.{' '}
                                       <b>
                                          Atenção: após salvar o valor, o mesmo
                                          não poderá ser alterado!
                                       </b>{' '}
                                    </div>
                                 </FormGroup>
                              </Col>
                              <Col md={6} className="mb-5">
                                 <FormGroup className="text-left">
                                    <label className="m-0 p-0">
                                       Pontuação de boas-vindas
                                    </label>
                                    <Tooltip
                                       texto="Configure a bonificação automática para os clientes que aderem ao seu Programa de Fidelidade. Assim, quando ele se cadastrar, ganhará automaticamente uma quantidade definida de ponto. A bonificação estimula a aderência dos clientes. Caso não tenha bonificação, informe 0 (zero) para desligar essa função."
                                       id="pontuacaoCadastro"
                                    />

                                    <InputIcon
                                       type="number"
                                       size={12}
                                       handleChange={handleChange}
                                       name="pontuacaoCadastro"
                                       value={values.pontuacaoCadastro}
                                    />
                                    {errors.pontuacaoCadastro && (
                                       <p className="is-danger align-message">
                                          {errors.pontuacaoCadastro}
                                       </p>
                                    )}
                                    <div>
                                       <b>
                                          {values.pontuacaoCadastro} Ponto(s)
                                       </b>
                                       . Informe 0 (zero) para desligar essa
                                       função.
                                    </div>
                                 </FormGroup>
                              </Col>
                              <Col md={6} className="mb-5">
                                 <FormGroup className="text-left">
                                    <label className="m-0 p-0">
                                       Ponto por indicação
                                    </label>
                                    <Tooltip
                                       texto="Configure a bonificação automática para
                                       os clientes que indicam amigos para
                                       aderirem ao Programa de Fidelidade. Ou
                                       seja, quando um cliente A (já cadastrado)
                                       indicar um amigo B e este também se
                                       cadastrar no Programa de Fidelidade, no
                                       momento em que o amigo B realizar seu
                                       primeiro consumo em sua empresa o cliente
                                       A ganhará automaticamente a quantidade
                                       indicada de ponto. Caso você não deseje
                                       fornecer essa bonificação, informe 0
                                       (zero) para desligar essa função."
                                       id="pontuacaoIndicacao"
                                    />

                                    <InputIcon
                                       type="number"
                                       size={12}
                                       handleChange={handleChange}
                                       name="pontuacaoIndicacao"
                                       value={values.pontuacaoIndicacao}
                                    />
                                    {errors.pontuacaoIndicacao && (
                                       <p className="is-danger align-message">
                                          {errors.pontuacaoIndicacao}
                                       </p>
                                    )}
                                    <div>
                                       <b>
                                          {values.pontuacaoIndicacao} Ponto(s)
                                       </b>
                                       . Informe 0 (zero) para desligar essa
                                       função.
                                    </div>
                                 </FormGroup>
                              </Col>
                              <Col md={6} className="mb-5">
                                 <FormGroup className="text-left">
                                    <label className="m-0 p-0">
                                       Expiração de Pontos
                                    </label>

                                    <Tooltip
                                       texto="Os ponto ganhos podem ser trocados por vouchers em quantos dias? Após ganhar ponto, o cliente possui uma quantidade limitada de tempo para utilizá-los. Indique neste campo o valor desse limite (em dias corridos). Caso o cliente não utilize seus pontos ganhos dentro desse tempo, seus pontos ganhos expirarão."
                                       id="pontuacaoExpiracao"
                                    />

                                    <InputIcon
                                       type="number"
                                       size={12}
                                       handleChange={handleChange}
                                       name="pontuacaoExpiracao"
                                       value={values.pontuacaoExpiracao}
                                    />
                                    {errors.pontuacaoExpiracao && (
                                       <p className="is-danger align-message">
                                          {errors.pontuacaoExpiracao}
                                       </p>
                                    )}
                                    <div>
                                       {' '}
                                       <b>{values.pontuacaoExpiracao} Dia(s)</b>
                                       . Informe 0 (zero) para desligar essa
                                       função.
                                    </div>
                                 </FormGroup>
                              </Col>
                              <Col md={6} className="mb-5">
                                 <FormGroup className="text-left">
                                    <label className="m-0 p-0">
                                       Validade dos vouchers
                                    </label>

                                    <Tooltip
                                       texto="Defina a validade (em dias) dos vouchers solicitados pelos clientes. Quando um cliente resgata um prêmio, ele recebe um ponto, que deve ser entregue na loja (para receber o prêmio em si). Esta validade define quantos dias ele possui entre o resgate no site e a retirada do prêmio na loja. Caso esse limite não exista, informe 0 (zero) para tornar a validade ilimitada."
                                       id="validadeCupomValePremio"
                                    />

                                    <InputIcon
                                       type="number"
                                       size={12}
                                       handleChange={handleChange}
                                       name="validadeCupomValePremio"
                                       value={values.validadeCupomValePremio}
                                    />
                                    {errors.validadeCupomValePremio && (
                                       <p className="is-danger align-message">
                                          {errors.validadeCupomValePremio}
                                       </p>
                                    )}
                                    <div>
                                       <b>
                                          {values.validadeCupomValePremio}{' '}
                                          Dia(s)
                                       </b>
                                       . Informe 0 (zero) para desligar essa
                                       função.
                                    </div>
                                 </FormGroup>
                              </Col>

                              {listaInputsValidos?.configuracaoPontuacaoMaximaDiaHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Pontuação máxima por dia de
                                       </label>
                                       <Tooltip
                                          texto="Defina a quantidade máxima de pontos que
                                       um cliente pode ganhar por dia. Caso não
                                       tenha limite, informe 0 (zero) para
                                       desligar esta função."
                                          id="valorPontuacaoMaximaDia"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="valorPontuacaoMaximaDia"
                                          value={values.valorPontuacaoMaximaDia}
                                       />
                                       {errors.valorPontuacaoMaximaDia && (
                                          <p className="is-danger align-message">
                                             {errors.valorPontuacaoMaximaDia}
                                          </p>
                                       )}
                                       <div>
                                          {' '}
                                          <b>
                                             {values.valorPontuacaoMaximaDia}{' '}
                                             ponto(s) por cliente
                                          </b>
                                          . Informe 0 (zero) para desligar essa
                                          função.
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoQuantidadeVezesClientePontuadoDiaHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Lançamentos máximos por dia
                                       </label>
                                       <Tooltip
                                          texto="Quantas vezes por dia seu cliente pode ser pontuado no mesmo dia? Informe 0 (zero) para não limitar a quantidade de pontuações diárias."
                                          id="quantidadePontuacaoMaximaDia"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="quantidadePontuacaoMaximaDia"
                                          value={
                                             values.quantidadePontuacaoMaximaDia
                                          }
                                       />
                                       {errors.quantidadePontuacaoMaximaDia && (
                                          <p className="is-danger align-message">
                                             {
                                                errors.quantidadePontuacaoMaximaDia
                                             }
                                          </p>
                                       )}
                                       <div>
                                          {' '}
                                          <b>
                                             {' '}
                                             {
                                                values.quantidadePontuacaoMaximaDia
                                             }{' '}
                                          </b>
                                          vezes por dia. Informe 0 (zero) para
                                          não limitar a quantidade de pontuações
                                          diárias.
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoBonificaoAniversarioHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Meta de consumo anual
                                       </label>
                                       <Tooltip
                                          texto="Defina uma meta de consumo (em reais) ao seu cliente. Informe 0 (zero) para não definir meta."
                                          id="metaAniversarioCadastro"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="metaAniversarioCadastro"
                                          value={values.metaAniversarioCadastro}
                                       />
                                       <div>
                                          <b>
                                             R$ {values.metaAniversarioCadastro}
                                             .
                                          </b>{' '}
                                          Defina uma meta de consumo anual para
                                          o seu cliente.
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoBonificaoMetaHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Acumule e ganhe
                                       </label>
                                       <Tooltip
                                          texto="Defina uma meta bônus ao seu cliente de forma que, ao atingi-la, ele receberá ponto(s)extra(s). Informe 0 (zero) para não definir meta."
                                          id="metaPontuacao"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="metaPontuacao"
                                          value={values.metaPontuacao}
                                       />
                                       <div>
                                          A cada{' '}
                                          <b> R$ {values.metaPontuacao} </b>, o
                                          cliente receberá ponto(s) extras no
                                          programa. Defina a pontuação extra em
                                          "Atingimento do Acumule e ganhe".
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoBonificaoAniversarioHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Atingimento da Meta de consumo anual
                                       </label>

                                       <Tooltip
                                          texto="Ao atingir a 'Meta de consumo anual', quantos pontos o seu cliente ganhará como gratificação? Informe 0 (zero) para não pontuar."
                                          id="pontuacaoAniversario"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="pontuacaoAniversario"
                                          value={values.pontuacaoAniversario}
                                       />
                                       <div>
                                          {' '}
                                          <b>
                                             {values.pontuacaoAniversario}{' '}
                                             ponto(s)
                                          </b>
                                          . Quantos pontos o cliente ganhará ao
                                          atingir a "Meta de consumo anual"?
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoBonificaoAniversarioHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Feliz Aniversário
                                       </label>
                                       <Tooltip
                                          texto="Quantos pontos o seu cliente ganhará como presente de aniversário? Esta pontuação será lançada independe se houver atividade recente dele ou não. Informe 0 (zero) para não pontuar."
                                          id="pontuacaoAniversarioCadastro"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="pontuacaoAniversarioCadastro"
                                          value={
                                             values.pontuacaoAniversarioCadastro
                                          }
                                       />
                                       <div>
                                          {' '}
                                          <b>
                                             {' '}
                                             {
                                                values.pontuacaoAniversarioCadastro
                                             }{' '}
                                             ponto(s)
                                          </b>
                                          Defina a quantidade de pontos que o
                                          cliente receberá no dia do
                                          aniversário.
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoBonificaoAniversarioHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Pontuação por cadastro realizado
                                       </label>

                                       <Tooltip
                                          texto="Quantas vezes por dia seu cliente pode
                                          ser pontuado? Informe 0 (zero) para não
                                          limitar a quantidade de pontuações
                                          diárias."
                                          id="pontuacaoAniversarioCadastro"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="pontuacaoCadastro"
                                          value={values.pontuacaoCadastro}
                                       />
                                       <div>
                                          {' '}
                                          <b>
                                             {values.pontuacaoCadastro} ponto(s)
                                             por cliente
                                          </b>
                                          . Informe 0 (zero) para desligar essa
                                          função.
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoBonificaoMetaHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Atingimento do Acumule e ganhe
                                       </label>
                                       <Tooltip
                                          texto="Ao atingir a 'Acumule e ganhe', quantos pontos o seu cliente ganhará como gratificação? Informe 0 (zero) para não pontuar."
                                          id="pontuacaoMetaPontuacao"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="pontuacaoMetaPontuacao"
                                          value={values.pontuacaoMetaPontuacao}
                                       />
                                       <div>
                                          {' '}
                                          <b>
                                             {' '}
                                             {
                                                values.pontuacaoMetaPontuacao
                                             }{' '}
                                             ponto(s)
                                          </b>
                                          Quantos pontos o cliente ganhará ao
                                          atingir a meta de "Acumule e ganhe"?
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoBonificaoMetaHabilitada && (
                                 <Col md={6} className="mb-5">
                                    <FormGroup className="text-left">
                                       <label className="m-0 p-0">
                                          Permitir pontuação de cadastro
                                          incompleto
                                       </label>

                                       <Tooltip
                                          texto="Defina a quantidade máxima de ponto que
                                          um cliente pode ganhar por dia. Caso não
                                          tenha limite, informe 0 (zero) para
                                          desligar esta função."
                                          id="permitePontuacaoCadastroIncompleto"
                                       />

                                       <InputIcon
                                          type="number"
                                          size={12}
                                          handleChange={handleChange}
                                          name="permitePontuacaoCadastroIncompleto"
                                          value={
                                             values.permitePontuacaoCadastroIncompleto
                                          }
                                       />
                                       <div>
                                          {' '}
                                          <b>
                                             {
                                                values.permitePontuacaoCadastroIncompleto
                                             }{' '}
                                             ponto(s) por cliente
                                          </b>
                                          . Informe 0 (zero) para desligar essa
                                          função.
                                       </div>
                                    </FormGroup>
                                 </Col>
                              )}

                              {listaInputsValidos?.configuracaoRegraControleResgateClienteHabilitada && (
                                 <>
                                    <Divider orientation="left mt-5">
                                       <span className="font-weight-bold">
                                          Controle de resgate do voucher
                                       </span>

                                       <Tooltip
                                          texto="Nestes dois campos você vai definir a condição para que o seu cliente tenha o direito de resgatar vouchers. Você determinará quantos pontos ele precisa atingir e quanto tempo ele tem para acumular essa meta de pontos."
                                          id="01"
                                       />
                                    </Divider>
                                    <Col md={4} className="mb-5">
                                       <FormGroup className="text-left">
                                          <label className="m-0 p-0">
                                             Dias
                                          </label>

                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={
                                                handleRegraControleResgateCliente
                                             }
                                             name="tempoValidacaoRetroativa"
                                             value={
                                                regraControleResgateCliente.tempoValidacaoRetroativa
                                             }
                                          />

                                          {errors.tempoValidacaoRetroativa && (
                                             <p className="is-danger align-message ml-0">
                                                {
                                                   errors.tempoValidacaoRetroativa
                                                }
                                             </p>
                                          )}
                                       </FormGroup>
                                    </Col>

                                    <Col md={4} className="mb-5">
                                       <FormGroup className="text-left">
                                          <label className="m-0 p-0">
                                             Meta
                                          </label>

                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={
                                                handleRegraControleResgateCliente
                                             }
                                             name="quantidadePontuacaoGanha"
                                             value={
                                                regraControleResgateCliente.quantidadePontuacaoGanha
                                             }
                                          />

                                          {errors.quantidadePontuacaoGanha && (
                                             <p className="is-danger align-message ml-0">
                                                {
                                                   errors.quantidadePontuacaoGanha
                                                }
                                             </p>
                                          )}
                                       </FormGroup>
                                    </Col>

                                    <Col md={4} className="mb-5">
                                       <FormGroup className="text-left">
                                          <label className="mb-2">
                                             Tipo de pontuação
                                          </label>

                                          <FormControl
                                             className={classes.formControl}>
                                             <Select
                                                onChange={
                                                   handleRegraControleResgateCliente
                                                }
                                                name="tipoPontuacao"
                                                displayEmpty
                                                value={
                                                   regraControleResgateCliente.tipoPontuacao
                                                }
                                                defaultValue={258}
                                                className={classes.selectEmpty}
                                                inputProps={{
                                                   'aria-label':
                                                      'Without label',
                                                }}>
                                                <MenuItem disabled value="">
                                                   <em>
                                                      Selecione uma opção...
                                                   </em>
                                                </MenuItem>
                                                {listPagamentos.map(
                                                   pagamento => (
                                                      <MenuItem
                                                         value={
                                                            pagamento.formaPagamentoPlataformaId
                                                         }>
                                                         {pagamento.descricao}
                                                      </MenuItem>
                                                   ),
                                                )}
                                             </Select>
                                          </FormControl>
                                       </FormGroup>
                                    </Col>
                                 </>
                              )}
                           </Row>

                           {listaInputsValidos?.configuracaoBonificaoDataHabilitada && (
                              <>
                                 <Divider orientation="left mt-5">
                                    <span className="font-weight-bold">
                                       Bonificação em datas comemorativas
                                    </span>
                                    <Tooltip
                                       texto="Defina as datas especiais em que o cliente ganhará mais pontos em cada consumo. Por exemplo: ao configurar o dia 24/02 com o fator 3 (TRÊS), nesta data todos os pontos lançados serão multiplicados por três, ou seja, serão triplicados.
                                       Como outro exemplo, a seguinte regra: entre os dias 01/07 e 20/07 o cliente, quando pontuado, terá seus pontos multiplicados pelo fator 2 (DOIS); que fará com que os pontos recebidos sejam duplicados. Utilize o botão 'Adicionar' para inserir as configurações."
                                       id="02"
                                    />
                                 </Divider>

                                 <Row form>
                                    <Col md={4}>
                                       <FormGroup
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'start',
                                             flexDirection: 'column',
                                          }}>
                                          <label>Data inicio</label>
                                          <InputMask
                                             mask="99/99/9999"
                                             placeholder="28/12/1999"
                                             value={
                                                objDataDiferenciada.dataInicio
                                             }
                                             onChange={handleDataDiferenciada}
                                             name="dataInicio">
                                             {inputProps => (
                                                <TextField
                                                   size={12}
                                                   {...inputProps}
                                                   type="text"
                                                />
                                             )}
                                          </InputMask>
                                       </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                       <FormGroup
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'start',
                                             flexDirection: 'column',
                                          }}>
                                          <label>Data fim</label>
                                          <InputMask
                                             mask="99/99/9999"
                                             placeholder="28/12/1999"
                                             value={objDataDiferenciada.dataFim}
                                             onChange={handleDataDiferenciada}
                                             name="dataFim">
                                             {inputProps => (
                                                <TextField
                                                   size={12}
                                                   {...inputProps}
                                                   type="text"
                                                />
                                             )}
                                          </InputMask>
                                       </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                       <FormGroup>
                                          <label className="m-0">Fator</label>
                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={
                                                handleDataDiferenciada
                                             }
                                             name="fator"
                                             placeholder="1"
                                             value={objDataDiferenciada.fator}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Row>

                                 <Row className="d-flex align-itens-center justify-content-center">
                                    <Col md={12}>
                                       <button
                                          type="button"
                                          onClick={addObjDataDiferenciada}
                                          className="btn btn-primary mb-3">
                                          Adicionar
                                       </button>

                                       <TableSemFilter
                                          columns={columnsDataDiferenciada}
                                          dataSource={listaDataDiferenciada}
                                          size="small"
                                          loading={isLoading}
                                       />
                                    </Col>
                                 </Row>
                              </>
                           )}

                           {listaInputsValidos?.configuracaoBonificaoTipoConsumoHabilitada && (
                              <>
                                 <Divider orientation="left">
                                    <span className="font-weight-bold">
                                       Bonificação por tipo de consumo
                                    </span>

                                    <Tooltip
                                       texto="Defina aqui tipos de bonificação diferenciada por consumo realizado por seus clientes. Por exemplo você pode definir que compras do tipo pagamento em dinheiro podem valer 2x mais Pontos, assim quando um cliente consome R$100 em sua empresa e paga em cartão de crédito ele ganharia 10 Pontos, mas se pagar em dinheiro ele ganharia 20 Pontos. Ou você ainda pode definir que compras do tipo serviço valem 3x mais Pontos que as compras do tipo produto, assim quando um cliente consome R$100 em produtos da sua empresa ele ganharia 10 Pontos, mas se consumir em serviços ele ganharia 30 Pontos."
                                       id="03"
                                    />
                                 </Divider>

                                 <Row form>
                                    <Col md={6}>
                                       <FormGroup>
                                          <label className="m-0">
                                             Descrição da Forma de Pagamento
                                          </label>
                                          <InputIcon
                                             type="text"
                                             size={12}
                                             handleChange={
                                                handleFormaPagamentoPlataforma
                                             }
                                             name="descricao"
                                             placeholder="Crédito"
                                             value={
                                                objFormaPagamentoPlataforma.descricao
                                             }
                                          />
                                       </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                       <FormGroup>
                                          <label className="m-0">Fator</label>
                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={
                                                handleFormaPagamentoPlataforma
                                             }
                                             name="fator"
                                             placeholder="1"
                                             value={
                                                objFormaPagamentoPlataforma.fator
                                             }
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Row>
                                 <Row className="d-flex align-itens-center justify-content-center">
                                    <Col md={12}>
                                       <button
                                          type="button"
                                          onClick={
                                             addObjFormaPagamentoPlataforma
                                          }
                                          className="btn btn-primary mb-3">
                                          Adicionar
                                       </button>

                                       <TableSemFilter
                                          columns={
                                             columnsFormaPagamentoPlataforma
                                          }
                                          dataSource={
                                             listaFormaPagamentoPlataforma
                                          }
                                          size="small"
                                          loading={isLoading}
                                       />
                                    </Col>
                                 </Row>
                              </>
                           )}

                           {listaInputsValidos?.configuracaoBonificaoTipoClienteHabilitada && (
                              <>
                                 <Divider orientation="left">
                                    <span className="font-weight-bold">
                                       Classificação do cliente
                                    </span>
                                    <Tooltip
                                       texto="Defina aqui a configuração de classificação de seus clientes. Por exemplo: O cliente A atingindo a meta no período configurado, será classificado como cliente Ouro."
                                       id="04"
                                    />
                                 </Divider>

                                 <Row form>
                                    <Col md={3}>
                                       <FormGroup>
                                          <label className="m-0">
                                             Descrição
                                          </label>
                                          <InputIcon
                                             type="text"
                                             size={12}
                                             handleChange={
                                                handleClassificacaoCliente
                                             }
                                             name="descricao"
                                             value={
                                                objClassificacaoCliente.descricao
                                             }
                                          />
                                       </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                       <FormGroup>
                                          <label className="m-0">Fator</label>
                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={
                                                handleClassificacaoCliente
                                             }
                                             name="fator"
                                             placeholder="1"
                                             value={
                                                objClassificacaoCliente.fator
                                             }
                                          />
                                       </FormGroup>
                                    </Col>

                                    <Col md={3}>
                                       <FormGroup>
                                          <label className="m-0">Meta</label>
                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={
                                                handleClassificacaoCliente
                                             }
                                             name="meta"
                                             value={
                                                objClassificacaoCliente.meta
                                             }
                                          />
                                       </FormGroup>
                                    </Col>

                                    <Col md={3} className="mb-0">
                                       <FormGroup className="text-left">
                                          <label className="m-0 p-0">
                                             Dias para avaliação
                                          </label>

                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={handleChange}
                                             name="quantidadeDiaClassificacao"
                                             value={
                                                values.quantidadeDiaClassificacao
                                             }
                                          />

                                          {errors.quantidadeDiaClassificacao && (
                                             <p className="is-danger align-message ml-0">
                                                {
                                                   errors.quantidadeDiaClassificacao
                                                }
                                             </p>
                                          )}
                                       </FormGroup>
                                    </Col>
                                 </Row>
                                 <Row className="d-flex align-itens-center justify-content-center">
                                    <Col md={12}>
                                       <button
                                          type="button"
                                          onClick={addObjClassificacaoCliente}
                                          className="btn btn-primary mb-3">
                                          Adicionar
                                       </button>

                                       <TableSemFilter
                                          columns={columnsClassificacaoCliente}
                                          dataSource={listaClassificacaoCliente}
                                          size="small"
                                          loading={isLoading}
                                       />
                                    </Col>
                                 </Row>
                              </>
                           )}

                           {/*listaInputsValidos?.configuracaoClassificacaoClienteHabilitada && (
                              <>
                                 <Divider orientation="left">
                                    <span className="font-weight-bold">
                                       Classificação de Cliente
                                    </span>
                                 </Divider>

                                 <Row form>
                                    <Col md={6}>
                                       <FormGroup>
                                          <label className="m-0">
                                             Descrição
                                          </label>
                                          <InputIcon
                                             type="text"
                                             size={12}
                                             handleChange={handleAgrupamento}
                                             name="descricao"
                                             value={objAgrupamento.descricao}
                                          />
                                       </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                       <FormGroup>
                                          <label className="m-0">Fator</label>
                                          <InputIcon
                                             type="number"
                                             size={12}
                                             handleChange={handleAgrupamento}
                                             name="fator"
                                             placeholder="1"
                                             value={objAgrupamento.fator}
                                          />
                                       </FormGroup>
                                    </Col>
                                 </Row>
                                 <Row className="d-flex align-itens-center justify-content-center mb-5">
                                    <Col md={6}>
                                       <button
                                          type="button"
                                          onClick={addObjAgrupamento}
                                          className="btn btn-primary mb-3">
                                          Adicionar
                                       </button>

                                       <TableSemFilter
                                          columns={columnsAgrupamento}
                                          dataSource={listaAgrupamento}
                                          size="small"
                                          loading={isLoading}
                                       />
                                    </Col>
                                 </Row>
                              </>
                           )*/}

                           <Row className="mt-5" form>
                              <Col md={12}>
                                 <FormGroup className="text-left">
                                    <span className="text-justify ">
                                       <span className="text-primary">
                                          Obs!
                                       </span>{' '}
                                       O Regulamento do seu Programa de
                                       Fidelidade é feito automaticamente
                                       baseado em suas configurações. Para
                                       visualizá-lo,{' '}
                                       <a
                                          href="#"
                                          onClick={() => {
                                             openModal('regulamento');
                                          }}>
                                          clique aqui
                                       </a>
                                       .
                                    </span>
                                    <br />
                                    <span className="text-justify">
                                       <Input
                                          onChange={
                                             handleChangeCheckboxRegulamento
                                          }
                                          checked={valueCheckboxRegulamento}
                                          type="checkbox"
                                       />
                                       <span className="text-primary ml-3">
                                          Atenção!
                                       </span>{' '}
                                       Ao clicar em salvar, as configurações
                                       desta página serão salvas
                                       automaticamente. Caso deseje escrever o
                                       seu próprio regulamento, marque esta
                                       opção.
                                    </span>
                                 </FormGroup>
                              </Col>
                           </Row>
                           {valueCheckboxRegulamento && (
                              <Row className="mt-3 mb-5">
                                 <Col md={12}>
                                    <label htmlFor="Regulamento extra">
                                       Regulamento extra
                                    </label>

                                    <Tooltip
                                       texto="Neste campo você poderá colocar regulamentos extras ao seu Programa de Fidelidade. "
                                       id="regulamentoExtra"
                                    />
                                    <Textarea
                                       className={'form-control'}
                                       height={8}
                                       onChange={handleChange}
                                       name="regulamentoExtra"
                                       value={values.regulamentoExtra}
                                    />
                                 </Col>
                              </Row>
                           )}
                           <div className="d-flex align-items-center mt-3">
                              <div className="mr-auto">
                                 <Button
                                    disabled={isLoading}
                                    color="primary"
                                    type="submit"
                                    className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                    size="lg">
                                    Salvar
                                 </Button>
                              </div>
                           </div>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default ConfigProgramaFidelidade;
