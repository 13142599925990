import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button } from 'reactstrap';
import { Checkbox } from 'antd';

// helpers
import * as cepData from 'cep-promise';
import removeAllSpecialChars from '../../../helpers/removeAllSpecialChars';
import fileToBase64 from '../../../helpers/fileToBase64';

// components
import LoadingButton from '../../../components/LoadingButton';
import InputFormik from '../../../components/Formik/Input';
import TextArea from '../../../components/Formik/Textarea';
import Select from '../../../components/Formik/Select';
import Flex from '../../../components/utils/Flex';
import Tooltip from '../../../components/tooltip';
import ImagensApp from './ImagensApp';

import { toast } from 'react-toastify';

export const Column = ({ md, className, children }) => (
   <Col style={{ textAlign: 'left' }} md={md} className={className}>
      {children}
   </Col>
);

const FormLoja = ({
   isEditing,
   isLoading,
   closeModal,
   urlHotsite,
   gmtOptions,
   meioComunicacaoOptions,
   initialValuesLoja = null,
   handleSubmit,
}) => {
   const formRef = useRef(null);
   const [isCPF, setIsCPF] = useState(true);
   const [isUpdateMeioComunicacao, setIsUpdateMeioComunicacao] =
      useState(false);

   const [showUpdateMeioComunicacao, setShowUpdateMeioComunicacao] =
      useState(true);

   useEffect(() => {
      if (!isEditing) {
         setShowUpdateMeioComunicacao(true);
      } else if (isEditing && isUpdateMeioComunicacao) {
         setShowUpdateMeioComunicacao(true);
      } else {
         setShowUpdateMeioComunicacao(false);
      }
   }, [isEditing, isUpdateMeioComunicacao]);

   const meiosComunicacaoOptions =
      meioComunicacaoOptions?.map(({ meioComunicacaoId, descricao }) => ({
         value: meioComunicacaoId,
         descricao,
      })) || [];

   const meiosComunicacaoDescricoes =
      meioComunicacaoOptions?.reduce(
         (obj, { meioComunicacaoId, descricao }) => ({
            ...obj,
            [meioComunicacaoId]: descricao,
         }),
         {},
      ) || {};

   const DESCRICAOMAXLENGTH = 500;

   const initialValues = initialValuesLoja
      ? initialValuesLoja
      : {
           lojaId: null,
           cpfCnpj: '',
           nomeRazaoSocial: '',
           nomeFantasia: '',
           descricao: '',
           gmt: '-3',
           urlEcommerce: '',
           caminhoLoja: '',
           cep: '',
           logradouro: '',
           numero: '',
           bairro: '',
           complemento: '',
           cidade: '',
           uf: '',
           lojaMeioComunicacaoId: [],
           lojaMeioComunicacaoDescricao: {},
           imagemDestaque: '',
           imagemDetalhe: '',
           imagemDestaqueFile: null,
           imagemDetalheFile: null,
        };

   const validationSchema = Yup.object().shape({
      cpfCnpj: Yup.string()
         .min(14, 'Número inválido.')
         .required('CPF ou CNPJ é obrigatório.'),
      nomeRazaoSocial: Yup.string()
         .min(
            3,
            `Campo ${
               isCPF ? 'Nome' : 'Razão Social'
            } deve contar pelo menos 3 (três) caractéres.`,
         )
         .required(`Campo ${isCPF ? 'Nome' : 'Razão Social'} é obrigatório.`),
      descricao: Yup.string()
         .min(3, 'Descrição deve contar pelo menos 3 (três) caractéres.')
         .required('Campo descrição é obrigatório.'),
      caminhoLoja: Yup.string().required(
         'Personalizar endereço da loja é obrigatório',
      ),
   });

   const onCPFChange = e => {
      const { value } = e.target;
      const cleanValue = removeAllSpecialChars(value);

      setIsCPF(cleanValue.length <= 11);
   };

   const onCEPChange = async e => {
      const { value } = e.target;
      const cleanValue = removeAllSpecialChars(value);

      if (cleanValue.length === 8) {
         const dataCEP = await cepData(cleanValue);
         if (dataCEP) {
            formRef.current.setFieldValue('logradouro', dataCEP.street);
            formRef.current.setFieldValue('bairro', dataCEP.neighborhood);
            formRef.current.setFieldValue('cidade', dataCEP.city);
            formRef.current.setFieldValue('uf', dataCEP.state);
         }
      }
   };

   const anchorDivRef = useRef(null);

   const onImagemDestaqueChange = async file => {
      if (!formRef.current) return;

      var reader = new FileReader();

      //Read the contents of Image File.
      reader.readAsDataURL(file);
      reader.onload = function (e) {
         //Initiate the JavaScript Image object.
         var image = new Image();

         //Set the Base64 string return from FileReader as source.
         image.src = e.target.result;

         //Validate the File Height and Width.
         image.onload = function () {
            var width = this.width;
            var height = this.height;
            console.log('', height);
            console.log('', width);
            if (width > 187 || height > 285) {
               toast.warning(
                  'O tamanho da imagem está divergente do tamanho indicado de 187x285',
               );
            }
         };
      };

      const base64 = await fileToBase64(file);
      formRef.current.setFieldValue('imagemDestaqueFile', file);
      formRef.current.setFieldValue('imagemDestaque', base64);

      setTimeout(() => {
         anchorDivRef.current.scrollIntoView({
            top: 20000,
            behavior: 'smooth',
         });
      }, 1);
   };

   const onImagemDetalheChange = async file => {
      if (!formRef.current) return;

      var reader = new FileReader();

      //Read the contents of Image File.
      reader.readAsDataURL(file);
      reader.onload = function (e) {
         //Initiate the JavaScript Image object.
         var image = new Image();

         //Set the Base64 string return from FileReader as source.
         image.src = e.target.result;

         //Validate the File Height and Width.
         image.onload = function () {
            var width = this.width;
            var height = this.height;
            console.log('', height);
            console.log('', width);
            if (width > 187 || height > 285) {
               toast.warning(
                  'O tamanho da imagem está divergente do tamanho indicado de 187x285',
               );
            }
         };
      };

      const base64 = await fileToBase64(file);

      formRef.current.setFieldValue('imagemDetalheFile', file);
      formRef.current.setFieldValue('imagemDetalhe', base64);

      setTimeout(() => {
         anchorDivRef.current.scrollIntoView({
            top: 20000,
            behavior: 'smooth',
         });
      }, 1);
   };

   return (
      <>
         <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
            innerRef={formRef}>
            {props => {
               return (
                  <Form>
                     <Row className="mb-3" form>
                        <Column md={isCPF ? 6 : 4}>
                           <label>CPF ou CNPJ</label>
                           <Tooltip
                              texto="Informe o identificador de fidelidade do
                                    loja. Neste campo pode ser informado o CPF
                                    ou CNPJ para identifica-lo."
                              id="person"
                           />
                           <InputFormik
                              maxLength={18}
                              type="text"
                              name="cpfCnpj"
                              cpfCnpj
                              onChange={onCPFChange}
                           />
                        </Column>
                        <Column md={isCPF ? 6 : 4}>
                           <label>{isCPF ? 'Nome' : 'Razão Social'}</label>
                           <Tooltip
                              texto="Razão social ou o Nome é o registro
                                 sob o qual uma pessoa jurídica ou física se individualiza
                                 e exerce suas atividades."
                              id="nomeRazaoSocial"
                           />
                           <InputFormik
                              size={12}
                              type="text"
                              name="nomeRazaoSocial"
                           />
                        </Column>

                        {!isCPF && (
                           <Column md={4}>
                              <label>Nome fantasia</label>
                              <Tooltip
                                 texto="Nome fantasia, nome de fantasia, também
                                 chamado nome de marca, nome comercial ou
                                 nome de fachada, é a designação popular de
                                 título de estabelecimento utilizada por uma
                                 instituição, seja pública ou privada, sob o
                                 qual ela se torna conhecida do público."
                                 id="nomeFantasia"
                              />
                              <InputFormik
                                 size={12}
                                 type="text"
                                 name="nomeFantasia"
                              />
                           </Column>
                        )}
                     </Row>
                     <Row className="mb-3" form>
                        <Column md={12}>
                           <Flex>
                              <Flex flexDirection="row" alignItems="center">
                                 <label>Descrição</label>
                                 <Tooltip
                                    texto="Essa descrição irá fica visível em seu hotsite, na aba Quem somos."
                                    id="descricao"
                                 />
                              </Flex>
                              <TextArea
                                 type="text"
                                 name="descricao"
                                 maxLength={DESCRICAOMAXLENGTH}
                              />

                              <p style={{ alignSelf: 'flex-end' }}>
                                 {props.values.descricao.length || 0}/
                                 {DESCRICAOMAXLENGTH}
                              </p>
                           </Flex>
                        </Column>
                     </Row>
                     <Row className="mb-3" form>
                        <Column md={6}>
                           <label>CEP</label>
                           <Tooltip
                              texto="Informe o CEP da loja para identificar sua
                                    localização geográfica."
                              id="cep"
                           />
                           <InputFormik
                              mask="cep"
                              type="text"
                              name="cep"
                              onChange={onCEPChange}
                              cep
                              maxLength={9}
                           />
                        </Column>
                        <Column md={6}>
                           <label>UF</label>
                           <Tooltip
                              texto="Informe o Estado (UF) referente ao endereço
                                    da loja."
                              id="state"
                           />
                           <InputFormik type="text" name="uf" />
                        </Column>
                     </Row>
                     <Row className="mb-3" form>
                        <Column md={6}>
                           <label>Cidade</label>
                           <Tooltip
                              texto="Informe a Cidade referente ao endereço da
                                    loja."
                              id="city"
                           />
                           <InputFormik type="text" name="cidade" />
                        </Column>
                        <Column md={6}>
                           <label>Bairro</label>
                           <Tooltip
                              texto="Informe o bairro referente ao endereço da
                                    loja."
                              id="neighborhood"
                           />
                           <InputFormik type="text" name="bairro" />
                        </Column>
                     </Row>
                     <Row className="mb-3" form>
                        <Column md={6}>
                           <label>Logradouro</label>
                           <Tooltip
                              texto="Informe a rua referente ao endereço da
                                    loja."
                              id="street"
                           />
                           <InputFormik type="text" name="logradouro" />
                        </Column>
                        <Column md={6}>
                           <label>Número</label>
                           <Tooltip
                              texto="Informe o Número referente ao endereço da
                                    loja."
                              id="numero"
                           />
                           <InputFormik type="text" name="numero" />
                        </Column>
                     </Row>
                     <Row className="mb-3" form>
                        <Column md={12}>
                           <label>Complemento</label>
                           <Tooltip
                              texto="Informe o Complemento referente ao endereço
                                    da loja."
                              id="complemento"
                           />
                           <InputFormik type="text" name="complemento" />
                        </Column>
                     </Row>
                     <Row className="mb-3" form>
                        <Column md={12}>
                           <label>Complete o endereço da loja</label>
                           <Tooltip
                              texto="Este campo permite a você, defenir como deverá ser rota da sua loja. "
                              id="caminhoLoja"
                           />
                           <div className="d-flex">
                              <label className="url-ecommerce">
                                 {urlHotsite}
                              </label>
                              <input
                                 class="input-formik"
                                 type="text"
                                 lowerCase
                                 name="caminhoLoja"
                                 disabled={isEditing}
                                 value={props?.values?.caminhoLoja}
                                 onChange={e => {
                                    formRef.current.setFieldValue(
                                       'caminhoLoja',
                                       e.target.value,
                                    );
                                 }}
                              />
                           </div>
                        </Column>
                     </Row>
                     <Row className="mb-3">
                        <Column md={6}>
                           <label>Endereço e-commerce</label>
                           <Tooltip
                              texto="Caso possua um site, coloque endereço para que seu cliente acesse"
                              id="urlEcommerce"
                           />
                           <InputFormik type="text" name="urlEcommerce" />
                        </Column>
                     </Row>
                     <Row className="mb-3" form>
                        <Column md={12} className="mb-5">
                           <Flex>
                              <label className="mb-2">Fuso horário (GMT)</label>
                              <Select
                                 name="gmt"
                                 options={gmtOptions}
                                 defaultValue={gmtOptions[1].value}
                                 placeholder="Selecione uma opção..."
                              />
                           </Flex>
                        </Column>
                     </Row>
                     {isEditing && (
                        <Row>
                           <Column md={12}>
                              <Checkbox
                                 className="mb-3"
                                 checked={isUpdateMeioComunicacao}
                                 onChange={() =>
                                    setIsUpdateMeioComunicacao(state => !state)
                                 }>
                                 Deseja alterar sua lista de meios de
                                 comunicação?
                              </Checkbox>
                              <Tooltip
                                 texto="Ao optar por sim, você deverá adiconar todos os meios de cumunicação anteriores já adicinado, pois uma nova lista será incluída e ficará diponível no seu hotsite."
                                 id="valueCheckComunicacao"
                              />
                           </Column>
                        </Row>
                     )}
                     {showUpdateMeioComunicacao && (
                        <Row className="mb-3" form>
                           <Column md={12}>
                              <Flex>
                                 <div>
                                    <label className="mb-2">
                                       Meios de Comunicação
                                    </label>
                                    <Tooltip
                                       texto="Informe ao menos um meio de contato."
                                       id="meioComunicacaoId"
                                    />
                                 </div>
                                 <Select
                                    id="meioComunicacaoId"
                                    name="lojaMeioComunicacaoId"
                                    mode="multiple"
                                    options={meiosComunicacaoOptions}
                                    defaultValue={
                                       props.values.lojaMeioComunicacaoId || []
                                    }
                                    placeholder="Selecione ao menos uma opção"
                                 />
                              </Flex>
                           </Column>
                        </Row>
                     )}

                     <Row>
                        {props.values.lojaMeioComunicacaoId?.map(
                           lojaMeioComunicacaoId => {
                              const desc =
                                 meiosComunicacaoDescricoes[
                                    lojaMeioComunicacaoId
                                 ];

                              return (
                                 <Column md={6}>
                                    <label className="mt-3">{desc}</label>

                                    <InputFormik
                                       key={lojaMeioComunicacaoId}
                                       name={`lojaMeioComunicacaoDescricao[${lojaMeioComunicacaoId}]`}
                                       disabled={
                                          isEditing
                                             ? !isUpdateMeioComunicacao
                                             : false
                                       }
                                       type={
                                          desc === 'E-mail' ? 'email' : 'text'
                                       }
                                       celular={desc === 'Celular'}
                                       telefone={
                                          desc === 'Telefone' ||
                                          desc === 'Whatsapp'
                                       }
                                    />
                                 </Column>
                              );
                           },
                        )}
                     </Row>
                     <Row className="mt-5">
                        <ImagensApp
                           formikProps={props}
                           onImagemDestaqueChange={onImagemDestaqueChange}
                           onImagemDetalheChange={onImagemDetalheChange}
                        />
                     </Row>
                     <Row className="mt-5">
                        <div ref={anchorDivRef} />
                        <Button color="link" onClick={closeModal}>
                           Cancelar
                        </Button>
                        <Button
                           id="submit-btn"
                           color="primary"
                           style={{ minWidth: '100px', marginRight: '10px' }}
                           type="submit"
                           disabled={isLoading || !props.isValid}>
                           {!isLoading && (
                              <>{isEditing ? 'Atualizar' : 'Criar'} </>
                           )}
                           {isLoading && (
                              <>
                                 {isEditing ? 'Atualizando' : 'Criando'}{' '}
                                 {<LoadingButton size={15} />}
                              </>
                           )}
                        </Button>
                        {!props.isValid && (
                           <Tooltip
                              texto="Preencha todos os campos obrigatórios."
                              id="submit-btn"
                           />
                        )}
                     </Row>
                  </Form>
               );
            }}
         </Formik>
      </>
   );
};

export default React.memo(FormLoja);
