import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Table } from 'antd';

export default function PaginationFilterTable({
   filterChildren,
   columns,
   record,
   dataSource,
   pagination,
   loading,
   onChange,
   handleSubmit,
}) {
   const handleS = e => {
      e.preventDefault();
      handleSubmit(pagination.current, pagination.pageSize);
   };
   return (
      <>
         {!!filterChildren?.length && (
            <form onSubmit={handleS}>
               <Row>
                  {filterChildren.map(child => (
                     <Col>{child}</Col>
                  ))}
                  <Col md={12}>
                     <div className="d-flex justify-content-end mt-2 mb-2">
                        <Button color="primary" size="md">
                           Buscar
                        </Button>
                     </div>
                  </Col>
               </Row>
            </form>
         )}
         <Table
            columns={columns}
            rowKey={record}
            dataSource={dataSource}
            pagination={pagination}
            loading={loading}
            onChange={onChange}
         />
      </>
   );
}
