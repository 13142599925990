import React, { Fragment, useState } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAuth } from '../../../../customHooks/AuthContext';

import {
   DropdownToggle,
   DropdownMenu,
   Nav,
   Col,
   Row,
   Button,
   NavItem,
   NavLink,
   UncontrolledButtonDropdown,
} from 'reactstrap';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiWhatsapp } from 'react-icons/si';
import city3 from '../../../../assets/utils/images/dropdown-header/city3.jpg';
import avatar1 from '../../../../assets/icons/icone-usuario.svg';
import { useHistory } from 'react-router-dom';
import SuporteVarejista from '../../../usuario/suporte/suporte';
import Modal from 'react-modal';
const customStyles = {
   content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
   },
};
Modal.setAppElement('#root');
const UserBox = () => {
   const history = useHistory();

   const { signOut, usuario } = useAuth();
   const [modalIsOpen, setIsOpen] = useState(false);

   const closeModal = () => {
      setIsOpen(false);
   };

   const openModal = () => {
      setIsOpen(true);
   };

   const alterarSenha = () => {
      history.replace('/usuario/alterar-senha');
   };

   const pageRegulamento = () => {
      history.replace('/usuario/regulamento');
   };
   const planos = () => {
      history.replace('/usuario/planos');
   };

   const pageWhatsapp = () => {
      window.location.href =
         'https://api.whatsapp.com/send/?phone=5517997189136&text=Ol%C3%A1%2C+gostaria+de+um+atendimento+do+Fideleco%21&app_absent=0';
   };
   return (
      <Fragment>
         <div className="header-btn-lg pl-5 m-0 p-0">
            <div className="widget-content p-0">
               <div className="widget-content-wrapper ml-0">
                  <div className="widget-content-left">
                     <UncontrolledButtonDropdown>
                        <DropdownToggle color="link" className="p-0">
                           <img
                              width={25}
                              style={{ borderRadius: '10%' }}
                              src={avatar1}
                              alt=""
                           />
                           <FontAwesomeIcon
                              className="mr-2 ml-2 opacity-8"
                              icon={faAngleDown}
                           />
                        </DropdownToggle>
                        <DropdownMenu
                           right
                           className="rm-pointers dropdown-menu-lg">
                           <div className="dropdown-menu-header">
                              <div className="dropdown-menu-header-inner bg-info">
                                 <div
                                    className="menu-header-image opacity-2"
                                    style={{
                                       backgroundImage: 'url(' + city3 + ')',
                                    }}
                                 />
                                 <div className="menu-header-content text-left">
                                    <div className="widget-content p-0">
                                       <div className="widget-content-wrapper d-flex">
                                          <div className="widget-content-left ml-3 mr-2">
                                             <img
                                                width={25}
                                                style={{ borderRadius: '10% ' }}
                                                src={avatar1}
                                                alt=""
                                             />
                                          </div>
                                          <div className="widget-content-right">
                                             <div className="widget-heading">
                                                {usuario.nome}
                                             </div>
                                          </div>
                                          <div className="widget-content-right ml-2 mr-0">
                                             <Button
                                                className="btn-pill btn-shadow btn-shine"
                                                onClick={signOut}
                                                color="focus">
                                                {' '}
                                                Logout{' '}
                                             </Button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="">
                              {/* <PerfectScrollbar>
                        <Nav vertical>
                          <NavItem className="nav-item-header">
                            Minha Conta
                          </NavItem>
                          <NavItem>
                            <NavLink href="#">
                              Conta
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#">Trocar a minha senha</NavLink>
                          </NavItem>
                        </Nav>
                      </PerfectScrollbar> */}
                           </div>

                           <div className="grid-menu grid-menu-2col">
                              <Row className="no-gutters">
                                 <Col sm="6">
                                    <Button
                                       onClick={alterarSenha}
                                       className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                       outline
                                       color="info">
                                       <i className="pe-7s-chat icon-gradient bg-malibu-beach btn-icon-wrapper mb-2">
                                          {' '}
                                       </i>
                                       Alterar Senha
                                    </Button>
                                 </Col>
                                 {/*<Col sm="6">
                                    <Button
                                       className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                       outline
                                       color="danger">
                                       <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                                          {' '}
                                       </i>
                                       <b>Ajuda e Suporte</b>
                                    </Button>
                                 </Col>*/}
                                 <Col sm="6">
                                    <Button
                                       onClick={pageRegulamento}
                                       className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                       outline
                                       color="danger">
                                       <i className="pe-7s-ribbon icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                                          {' '}
                                       </i>
                                       <b>Regulamento</b>
                                    </Button>
                                 </Col>
                                 <Col sm="6">
                                    <Button
                                       onClick={pageWhatsapp}
                                       className="btn-icon-vertical btn-transition btn-transition-alt pt-0 pb-2"
                                       outline
                                       color="success">
                                       <i className=" icon-gradient bg-happy-itmeo btn-icon-wrapper mb-1">
                                          <SiWhatsapp size={25} />
                                       </i>
                                       <b>Suporte WhatsApp</b>
                                    </Button>
                                 </Col>
                                 <Col sm="6">
                                    <Button
                                       onClick={planos}
                                       className="btn-icon-vertical btn-transition btn-transition-alt pt-0 pb-2"
                                       outline
                                       color="danger">
                                       <i className="pe-7s-network icon-gradient bg-happy-itmeo btn-icon-wrapper mb-1">
                                          {' '}
                                       </i>
                                       <b>Planos</b>
                                    </Button>
                                 </Col>
                                 {/*<Col sm="6">
                                    <Button
                                      onClick={openModal}
                                       className="btn-icon-vertical btn-transition btn-transition-alt pt-0 pb-2"
                                       outline
                                       color="danger">
                                       <i className="pe-7s-network icon-gradient bg-happy-itmeo btn-icon-wrapper mb-1">
                                          {' '}
                                       </i>
                                       <b>
                                          Suporte mensagem
                                       </b>
                                    </Button>
                                 </Col>*/}
                              </Row>
                           </div>
                        </DropdownMenu>
                     </UncontrolledButtonDropdown>
                  </div>
                  <div className="widget-content-left  mr-3 header-user-info">
                     <div className="widget-heading text-left">
                        {usuario.nome.split(' ')[0]}
                     </div>
                     <div className="widget-subheading">{usuario.lojas[0].descricaoPerfil}</div>
                  </div>
               </div>
            </div>
         </div>

         <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal">
            <SuporteVarejista />
         </Modal>
      </Fragment>
   );
};

export default UserBox;
