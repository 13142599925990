import React from 'react';
import Router from './routes';
import './styles/index.scss';
import './assets/base.scss';
import { AuthProvider } from './customHooks/AuthContext';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import FreshChat from './api/freshChat';

import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
const store = configureStore();

function App() {
   const isToken = localStorage.getItem('@Fideleco:token');
   return (
      <Provider store={store}>
         <AuthProvider>
            <ToastContainer
               position="top-right"
               hideProgressBar={false}
               autoClose={5000}
               newestOnTop={true}
               closeOnClick={false}
               draggable={false}
               rtl={false}
            />
            {/* <Dashboards /> */}
            <Router />
            {/* OCUTANDO CHAT, CASO NÃO ESTEJA LOGADO */}
            {isToken && <FreshChat />}
         </AuthProvider>
      </Provider>
   );
}

export default App;
