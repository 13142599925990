import styled from 'styled-components';

export const Wrapper = styled.div`
   text-align: left !important;
   padding: 40px 20px;

   h1 {
      font-size: 22px;
      font-weight: bold;
      margin: 0 0 40px;
   }

   h2 {
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0;
   }

   p {
      font-size: 14px;
      margin: 10px 0;
   }
`;
