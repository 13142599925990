import React, { useState, useEffect, useReducer, useRef } from 'react';
import api from '../../../api/api-local';

//Components
import { useAuth } from '../../../customHooks/AuthContext';
import ModalCustom from '../../../components/Modal/Modal';
import Spinner from '../../../components/loading/Loading';
import PageTitle from '../../layout/AppMain/PageTitle';
import Table from '../../../components/FilterTable';
import {
   initialStateAddress,
   reducerFormAddress,
} from '../../../store/reducerHooks/formReduceAdmin';
import FormLoja from './FormLoja';
import { TextField } from '@material-ui/core';

//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody } from 'reactstrap';

import PaginationFilterTable from '../../../components/PaginationFilterTable';

import { toast } from 'react-toastify';

import _ from 'lodash';

// hooks
import useLoja from './useLoja';

const listaGmt = [
   { descricao: 'GMT -2 (Fernando de Noronha)', value: '-2' },
   {
      descricao:
         'GMT -3 (Brasilia, São Paulo, Salvador, Fortaleza, Belém, Maceió, Recife, etc)',
      value: '-3',
   },
   {
      descricao:
         'GMT -4 (Boa Vista, Campo Grande, Cuiabá, Manaus, Rio Branco, etc)',
      value: '-4',
   },
];

const ListaLoja = () => {
   const [addressState, dispatch] = useReducer(
      reducerFormAddress,
      initialStateAddress,
   );

   const { signOut } = useAuth();

   const [listMeioComunicacao, setListMeioComunicacao] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [dataSource, setDataSource] = useState([]);
   const [isEditing, setIsEditing] = useState(false);
   const [plataforma, setPlataforma] = useState();
   const [initialValuesLoja, setInitialValuesLoja] = useState(null);
   const [termoBusca, setTermoBusca] = useState({});

   const { createLoja, editLoja, getLoja } = useLoja();

   const handleSubmit = async data => {
      setIsLoading(true);

      try {
         isEditing ? await editLoja(data) : await createLoja(data);

         toast.success(
            `Loja ${isEditing ? 'alterada' : 'adicionada'} com sucesso.`,
         );
         setIsOpen(false);
         setInitialValuesLoja(null);
         getLojaList(pagination.current, pagination.pageSize);
      } catch (error) {
         const errorMessages = error?.response?.data?.error || [];

         errorMessages.forEach(({ message }) => {
            toast.error(message);
         });
      } finally {
         setIsLoading(false);
      }
   };

   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const openModal = tipo => {
      if (tipo === 'cadastrar') {
         setIsEditing(false);
      } else {
         setIsEditing(true);
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      dispatch({
         type: 'INITIAL_VALUES',
      });

      setIsOpen(false);
      setIsEditing(false);
      setInitialValuesLoja(null);
   };

   const updateLoja = async loja => {
      setIsLoading(true);

      try {
         const result = await getLoja(loja.lojaId);
         setInitialValuesLoja(result);
         setIsEditing(true);
         setIsOpen(true);
      } catch (error) {
         console.error(error);
         toast.error(error?.response?.data?.error[0]?.message);

         if (error.status === 401) {
            localStorage.removeItem('@Fideleco:token');
            localStorage.removeItem('@Fideleco:user');
            window.location.reload();
         }
      } finally {
         setIsLoading(false);
      }
   };

   const deleteLoja = loja => {
      try {
         const getId = loja.lojaId;
         api.delete(`loja/${getId}`)
            .then(response => {
               setIsLoading(true);

               if (response.status === 200) {
                  setTimeout(() => {
                     /*history.go(0);*/
                     setIsLoading(false);

                     toast.success('Loja excluído com sucesso');
                     //Atualizando componente de listagem
                     getLojaList(pagination.current, pagination.pageSize);
                  }, 2000);
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } catch (error) {
         console.log(error);
      }
   };

   const getLojaList = async (currentPage, pageSize) => {
      setIsLoading(true);
      api.get('loja/paginacao', {
         params: {
            pagina: currentPage,
            tamanho: pageSize,
            ...termoBusca,
         },
      })
         .then(response => {
            const { data } = response;

            setDataSource(data.data);

            setPagination(state => ({
               ...state,
               current: data.currentPage,
               total: data.totalItems,
            }));
         })
         .catch(error => console.error(error))
         .finally(() => setIsLoading(false));
   };

   const getMeioComunicacao = async () => {
      try {
         const getApi = await api.get('/meiocomunicacao');
         if (getApi.status === 200) {
            setListMeioComunicacao(getApi.data);
         }
      } catch (error) {
         const erro = error.response.data.error;
         toast.warning(error.response.data.error[0].message);

         if (erro[0].message == 'Token inválido') {
            toast.error('Você precisa logar novamente!');
            localStorage.removeItem('@Fideleco:token');
            setTimeout(() => {
               document.location.reload(true);
            }, [3000]);
         }
      }
   };

   const columns = [
      {
         dataIndex: 'nomeRazaoSocial',
         title: 'Nome',
         filters: dataSource.map(loja => ({
            text: loja.nomeRazaoSocial,
            value: loja.nomeRazaoSocial,
         })),
         onFilter: (value, record) =>
            record.nomeRazaoSocial.indexOf(value) === 0,
         sorter: (a, b) => (a.nomeRazaoSocial < b.nomeRazaoSocial ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         dataIndex: 'cidade',
         title: 'Cidade',
         filters: dataSource.map(loja => {
            return {
               text: loja.cidade,
               value: loja.cidade,
            };
         }),
         onFilter: (value, record) => record.cidade.indexOf(value) === 0,
         sorter: (a, b) => (a.cidade < b.cidade ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         dataIndex: 'uf',
         title: 'Estado',
         filters: dataSource.map(loja => ({
            text: loja.uf,
            value: loja.uf,
         })),
         onFilter: (value, record) => record.uf.indexOf(value) === 0,
         sorter: (a, b) => (a.uf < b.uf ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 100,
         align: 'center',
         render: loja => (
            <div key={loja.lojaId}>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon mr-2 text-info lnr-pencil mr-3"
                  onClick={() => {
                     updateLoja(loja);
                  }}
               />
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     deleteLoja(loja);
                  }}
               />
            </div>
         ),
      },
   ];

   const filtros = [
      {
         dataIndex: 'nomeRazaoSocial',
         nome: 'Nome',
         placeholder: 'Digite o nome',
         mask: 'text',
      },

      {
         dataIndex: 'cidade',
         nome: 'Cidade',
         placeholder: 'Digite a cidade',
         mask: 'text',
      },

      {
         dataIndex: 'uf',
         nome: 'Estado',
         placeholder: 'Digite o estado',
         mask: 'text',
      },
   ];

   const handleChangePerson = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   useEffect(() => {
      getLojaList(pagination.current, pagination.pageSize);
      getMeioComunicacao();
   }, [isEditing]);

   useEffect(() => {
      const { plataforma } = JSON.parse(localStorage.getItem('@Fideleco:user'));
      setPlataforma(plataforma);
   }, []);

   const handleTableChange = async pagination => {
      setPagination(pagination);

      setTimeout(() => {
         getLojaList(pagination.current, pagination.pageSize);
      }, 1);
   };

   const urlHotsite = plataforma?.urlHotsite
      ? `${plataforma?.urlHotsite}/`
      : '';

   return (
      <>
         <PageTitle
            heading="Gerenciar Lojas"
            subheading="Gerencie suas lojas"
            icon="pe-7s-shopbag icon-gradient bg-fideleco">
            <div>
               <ModalCustom
                  titleModal={
                     isEditing ? 'Atualizar loja' : 'Cadastre uma nova loja'
                  }
                  buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
                  buttonIcon={
                     <i
                        onClick={closeModal}
                        className="pe-7s-shopbag btn-icon-wrapper font-weight-bold"></i>
                  }
                  buttonName="Cadastrar loja"
                  buttonSize="lg"
                  toggleFunc={() => {
                     openModal('cadastrar');
                  }}
                  isOpen={modalIsOpen}
                  closeModal={closeModal}
                  size="lg">
                  <FormLoja
                     isEditing={isEditing}
                     isLoading={isLoading}
                     urlHotsite={urlHotsite}
                     gmtOptions={listaGmt}
                     meioComunicacaoOptions={listMeioComunicacao}
                     handleSubmit={handleSubmit}
                     closeModal={closeModal}
                     initialValuesLoja={initialValuesLoja}
                  />
               </ModalCustom>
            </div>
         </PageTitle>

         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <div className="table-responsive">
                           <PaginationFilterTable
                              columns={columns}
                              rowKey={record => record.nome}
                              dataSource={dataSource}
                              pagination={pagination}
                              loading={isLoading}
                              onChange={handleTableChange}
                              handleSubmit={getLojaList}
                              filterChildren={[
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.nome}
                                    onChange={e =>
                                       handleChangePerson(
                                          'nome',
                                          e.target.value,
                                       )
                                    }
                                    label="Nome"
                                 />,
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.cidade}
                                    onChange={e =>
                                       handleChangePerson(
                                          'cidade',
                                          e.target.value,
                                       )
                                    }
                                    label="Cidade"
                                 />,
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.estado}
                                    onChange={e =>
                                       handleChangePerson(
                                          'estado',
                                          e.target.value,
                                       )
                                    }
                                    label="Estado"
                                 />,
                              ]}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default React.memo(ListaLoja);
