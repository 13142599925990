import React, { useState, useEffect } from 'react';
import { FlexBox, InputSearch } from './styles';
import { mask as masker, unMask } from 'remask';
import { Input, Table, Select } from 'antd';
import 'antd/dist/antd.css';

export default function Tables(props) {
   const {
      columns,
      data,
      onChange,
      pagination,
      filtros = [''],
      filterValue,
      setFilterValue,
      selectedFilter,
      setSelectedFilter,
   } = props;

   const [dataSource, setDataSource] = useState(data);

   const { Option } = Select;

   useEffect(() => {
      setDataSource(data);
   }, [data]);

   function handleChangeFilter(value) {
      setSelectedFilter(value);
   }

   const selectAfter = (
      <Select
         defaultValue={`${filtros[0].dataIndex}`}
         style={{ width: 100 }}
         onChange={handleChangeFilter}>
         {filtros.map((filtro, index) => (
            <Option className="text-left" key={index} value={filtro.dataIndex}>
               {filtro.nome}
            </Option>
         ))}
      </Select>
   );

   let typeMask = filtros.filter(
      filtro => filtro.dataIndex === selectedFilter && filtro,
   )[0]?.mask;

   const handleFilterInput = value => {
      setFilterValue(value);
   };

   const FilterByNameInput = (
      <Input
         value={filterValue}
         onChange={e => {
            //Pegando valor do input e tranformando ele em minusculo
            const originalValue = unMask(e.target.value);

            //Aplicando mascara conforme o tipo passado pela lista de filtros
            const maskedValue =
               typeMask == 'text'
                  ? e.target.value
                  : typeMask == 'date'
                  ? masker(originalValue, ['99/99/9999'])
                  : typeMask == 'pessoa' &&
                    masker(originalValue, [
                       '999.999.999-99',
                       '99.999.999/9999-99',
                    ]);

            handleFilterInput(maskedValue);

            // const filteredData = data.filter(entry =>
            //    String(entry[`${selectedFilter}`])
            //       .toLowerCase()
            //       .includes(maskedValue.toLowerCase()),
            // );

            // setDataSource(filteredData);
         }}
         placeholder={
            filtros.filter(
               filtro => filtro.dataIndex === selectedFilter && filtro,
            )[0]?.placeholder
         }
         addonAfter={selectAfter}
         className="mb-3"
      />
   );

   return (
      <FlexBox>
         <div className="search">
            <InputSearch>{FilterByNameInput}</InputSearch>
         </div>

         <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            rowKey={record =>
               Object.keys(record)[0] && record[Object.keys(record)[0]]
            }
            pagination={pagination}
            onChange={onChange}
         />
      </FlexBox>
   );
}
