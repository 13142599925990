import React from 'react';

const Textarea = ({ className, name, onChange, value, height }) => {
   return (
      <>
         <textarea
            rows={height}
            className={className}
            name={name}
            onChange={onChange}
            value={value}
         />
      </>
   );
};

export default Textarea;
