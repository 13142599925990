import React, { useState, useEffect } from 'react';

import ListaMeses from '../../assets/json/meses.json';
import api_local from '../../api/api-local';

import useDebounce from '../../customHooks/useDebounce';

//Components
import { useAuth } from '../../customHooks/AuthContext';
import Spinner from '../../components/loading/Loading';
import ModalCustom from '../../components/Modal/Modal';
import PageTitle from '../layout/AppMain/PageTitle';
import { helpersUntil } from '../../helpers/until';

//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import Tables from '../../components/RelatorioTable';
import { toast } from 'react-toastify';
import { Select } from 'antd';

const Aniversariantes = () => {
   const [dataSource, setDataSource] = useState([]);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [valueFilter, setValueFilter] = useState('');

   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const { Option } = Select;
   const { signOut } = useAuth();

   //Fechando modal
   const closeModal = () => {
      setIsOpen(false);
   };

   //Controlando estado do filter
   const onChangeFilter = e => {
      setValueFilter(e);
   };

   //Buntao de busca
   const handleSearch = confirm => {
      getListAniversariantes(
         pagination.current,
         pagination.pageSize,
         valueFilter,
      );
      confirm();
   };

   //Buntao que limpar input
   const handleReset = () => {
      setValueFilter('');
      getListAniversariantes(pagination.current, pagination.pageSize);
   };

   //Controlando e pegando valor do filtro da tabela
   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ confirm }) => (
         <div className="row d-flex justify-content-center flex-column pr-4 pl-4">
            <div className="row mt-2">
               <div className="col-12 mb-4">
                  <Select
                     className="w-100 text-left"
                     value={valueFilter}
                     onChange={onChangeFilter}>
                     {ListaMeses.map(mes => {
                        return (
                           <Option className="text-left" value={mes.valor}>
                              {mes.descricao}
                           </Option>
                        );
                     })}
                  </Select>
               </div>
            </div>
            {/*<InputAntd
               placeholder={`Buscar ${dataIndex}`}
               value={valueFilter}
               onChange={onChangeFilter}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />*/}
            <div className="row mb-4">
               <div className="col-6">
                  <Button
                     type="primary"
                     onClick={() => handleSearch(confirm, dataIndex)}
                     icon={<SearchOutlined />}
                     size="small"
                     style={{ width: 90 }}>
                     Buscar
                  </Button>
               </div>
               <div className="col-6">
                  <Button
                     onClick={() => handleReset()}
                     size="small"
                     style={{ width: 90 }}>
                     Limpar
                  </Button>
               </div>
            </div>
         </div>
      ),
   });

   const columns = [
      {
         title: 'Nome',
         dataIndex: 'nome',
         width: 150,
      },

      {
         title: 'Mês',
         dataIndex: 'mes',
         filters: [
            {
               text: 'Março',
               value: 3,
            },
            {
               text: 'Abril',
               value: 4,
            },
         ],
         sorter: (a, b) => (a.mes < b.mes ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         ...getColumnSearchProps('mes'),
         width: 150,
      },

      {
         title: 'Dia',
         dataIndex: 'dia',
         width: 150,
      },

      {
         title: 'Idade',
         dataIndex: 'idade',
         width: 150,
      },

      {
         title: 'Data de Nascimento',
         dataIndex: 'dataNasc',
         width: 150,
      },
   ];

   const filtros = [
      {
         dataIndex: 'nome',
         nome: 'Nome',
         placeholder: 'Digite o nome',
         mask: 'text',
      },
      {
         dataIndex: 'mes',
         nome: 'Mês',
         placeholder: 'Digite o mês',
         mask: 'text',
      },
      {
         dataIndex: 'dia',
         nome: 'Dia',
         placeholder: 'Digite o dia',
         mask: 'text',
      },

      {
         dataIndex: 'idade',
         nome: 'Idade',
         placeholder: 'Digite a idade',
         mask: 'text',
      },

      {
         dataIndex: 'dataNasc',
         nome: 'Data de nascimento',
         placeholder: 'Digite a data',
         mask: 'date',
      },
   ];

   const [prevFilterValue, setPrevFilterValue] = useState('');
   const [filterValue, setFilterValue] = useState('');
   const [selectedFilter, setSelectedFilter] = useState(
      `${(filtros && filtros[0].dataIndex) || ''}`,
   );

   const debouncedFilterValue = useDebounce(filterValue, 500);

   const handleTableChange = async (pagination, _filters, _sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getListAniversariantes(pagination.current, pagination.pageSize);
      }, 1);
   };

   const getListAniversariantes = async (currentPage, pageSize) => {
      try {
         setIsLoading(true);

         const filtro = selectedFilter === 'dataNasc' ? 'data' : selectedFilter;
         const value =
            selectedFilter === 'dataNasc'
               ? filterValue.split('/').reverse().join('-')
               : filterValue;

         api_local
            .get('/relatorio/cliente/aniversariante', {
               params: {
                  pagina: currentPage,
                  tamanho: pageSize,
                  [filtro]: value,
               },
            })
            .then(response => {
               if (response.status === 200) {
                  setIsLoading(false);

                  if (response.data.data.length < 1) {
                     toast.error('Nenhum cliente encontrado!');
                  }

                  const dados = response.data.data.map((cliente, index) => {
                     //Formatando data para formato BR
                     const dataNascimentoFormatada =
                        helpersUntil.converterDateStringForDateBr(
                           cliente.dataNascimento,
                        );
                     return {
                        key: cliente.index,
                        id: cliente.index,
                        nome: cliente.nome,
                        mes: cliente.mes,
                        dia: cliente.dia,
                        idade: cliente.idade,
                        dataNasc: dataNascimentoFormatada,
                     };
                  });

                  setDataSource(dados);

                  setPagination(state => ({
                     ...state,
                     current: response.data.currentPage,
                     total: response.data.totalItems,
                  }));
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });

         /*if (getApi.status === 200) {
            setIsLoading(false);

            setListClient(getApi.data);
         }*/
      } catch (error) {
         console.log(error);
      }
   };

   const exportar = () => {
      api_local
         .get('/relatorio/cliente/aniversariante/exportar', {
            params: {
               mes: valueFilter,
            },
            responseType: 'arraybuffer',
            headers: {
               Accept:
                  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            },
         })
         .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatorio aniversariantes.csv');
            document.body.appendChild(link);
            link.click();
            setIsLoading(false);
         })
         .catch(error => {
            console.log(error);
            setIsLoading(false);
         });
   };

   useEffect(() => {
      getListAniversariantes(pagination.current, pagination.pageSize);
   }, []);

   // roda ao mudar os filtros
   useEffect(() => {
      if (prevFilterValue !== debouncedFilterValue) {
         getListAniversariantes(pagination.current, pagination.pageSize);
         setPrevFilterValue(debouncedFilterValue);
      }
   }, [debouncedFilterValue, selectedFilter]);

   return (
      <>
         <PageTitle
            heading="Aniversariantes"
            subheading="Gerar seus relatórios"
            icon="pe-7s-display1 icon-gradient bg-fideleco">
            <ModalCustom
               titleModal={'Configurando exportação'}
               buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
               buttonIcon={
                  <i className="pe-7s-display1 btn-icon-wrapper font-weight-bold"></i>
               }
               buttonName={'Exportar Planilha'}
               buttonSize="lg"
               buttonColor="primary"
               toggleFunc={() => {
                  exportar();
               }}
               isOpen={modalIsOpen}
               closeModal={closeModal}
               size="md"></ModalCustom>
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col>
                  <Card className="main-card mb-2 text-left">
                     <CardBody className="table-relatorio">
                        <Tables
                           columns={columns}
                           data={dataSource}
                           rowKey={record => record.nome}
                           pagination={pagination}
                           filtros={filtros}
                           onChange={handleTableChange}
                           filterValue={filterValue}
                           setFilterValue={setFilterValue}
                           selectedFilter={selectedFilter}
                           setSelectedFilter={setSelectedFilter}
                        />
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default Aniversariantes;
