import React from 'react';

import { useAuth } from '../../../customHooks/AuthContext';

import { Row, Button, UncontrolledCollapse, CardBody, Card } from 'reactstrap';

import { RiLogoutCircleRLine } from 'react-icons/ri';
import { SiWhatsapp } from 'react-icons/si';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import avatar1 from '../../../assets/icons/icone-usuario.svg';
import { useHistory } from 'react-router-dom';

const HeaderMenuLeft = () => {
   const history = useHistory();
   const { signOut, usuario } = useAuth();

   const alterarSenha = () => {
      history.replace('/usuario/alterar-senha');
   };

   const pageRegulamento = () => {
      history.replace('/usuario/regulamento');
   };
   const planos = () => {
      history.replace('/usuario/planos');
   };

   const pageWhatsapp = () => {
      window.location.href =
         'https://api.whatsapp.com/send/?phone=5517997189136&text=Ol%C3%A1%2C+gostaria+de+um+atendimento+do+Fideleco%21&app_absent=0';
   };

   return (
      <div className="d-flex flex-column" style={{ width: '220px' }}>
         <div className="widget-content p-0 w-100 d-flex mb-2">
            <div className="widget-content-wrapper ml-0 pb-1" id="toggler">
               <div className="widget-content-left" id="toggler">
                  <img
                     width={20}
                     style={{ borderRadius: '10%', marginRight: '1px' }}
                     src={avatar1}
                     alt=""
                     id="toggler"
                  />

                  <FontAwesomeIcon
                     className="mr-2 ml-2 opacity-8"
                     color="#FD5958"
                     id="toggler"
                     icon={faAngleDown}
                  />
               </div>

               <div className="d-flex flex-column">
                  <span className="text-left">
                     {usuario.nome.split(' ')[0]}
                  </span>
                  <small className="widget-subheading">Administrador</small>
               </div>
            </div>
            <span>
               <RiLogoutCircleRLine
                  onClick={signOut}
                  style={{ color: '#444054' }}
                  size={30}
               />
            </span>
         </div>

         <UncontrolledCollapse toggler="#toggler">
            <Card>
               <CardBody>
                  <div className="grid-menu grid-menu-2col">
                     <Row className="grid-menu-user">
                        <Button
                           onClick={alterarSenha}
                           className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                           outline
                           color="info">
                           <i className="pe-7s-chat icon-gradient bg-malibu-beach btn-icon-wrapper mb-2">
                              {' '}
                           </i>
                           Alterar Senha
                        </Button>

                        {/*<Button
                           className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                           outline
                           color="danger">
                           <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                              {' '}
                           </i>
                           <b>Ajuda e Suporte</b>
                        </Button>*/}

                        <Button
                           onClick={pageRegulamento}
                           className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                           outline
                           color="danger">
                           <i className="pe-7s-ribbon icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                              {' '}
                           </i>
                           <b>Regulamento</b>
                        </Button>

                        <Button
                           onClick={pageWhatsapp}
                           className="btn-icon-vertical btn-transition btn-transition-alt pt-0 pb-2"
                           outline
                           color="success">
                           <i className=" icon-gradient bg-happy-itmeo btn-icon-wrapper mb-1">
                              <SiWhatsapp size={25} />
                           </i>
                           <b>Suporte WhatsApp</b>
                        </Button>

                        <Button
                           onClick={planos}
                           className="btn-icon-vertical btn-transition btn-transition-alt pt-0 pb-2"
                           outline
                           color="danger">
                           <i className="pe-7s-network icon-gradient bg-happy-itmeo btn-icon-wrapper mb-1">
                              {' '}
                           </i>
                           <b>Planos</b>
                        </Button>
                     </Row>
                  </div>
               </CardBody>
            </Card>
         </UncontrolledCollapse>
      </div>
   );
};

export default HeaderMenuLeft;
