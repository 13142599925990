import React from 'react';
import Icon from '../../assets/icons/error_outline-24px.svg';

const TooltipPng = props => {
   const { id } = props;
   return (
      <img
         className="ml-1 mb-1"
         style={{ width: '15px' }}
         id={id}
         src={Icon}
         alt=""
      />
   );
};

export default TooltipPng;
