import React from 'react';

import { Grid, TextField } from '@material-ui/core';
import { Children } from 'react';

const InputIcon = props => {
   const {
      icon,
      label,
      id,
      type,
      size,
      handleChange,
      name,
      error,
      value,
      disabled,
      maxLength,
   } = props;

   return (
      <Grid container spacing={1} alignItems="flex-end">
         <Grid item>{icon}</Grid>
         <Grid item xs={size}>
            <TextField
               id={id}
               value={!value && typeof value !== 'number' ? '' : value}
               disabled={disabled}
               name={name}
               error={error}
               type={type}
               onChange={handleChange}
               label={label}
               color="primary"
               inputProps={maxLength && { maxLength }}
               fullWidth
            />
         </Grid>
         {props.children}
      </Grid>
   );
};

export default InputIcon;
