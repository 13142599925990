import React, { useState, useEffect } from 'react';

//libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Tooltip } from 'reactstrap';
import { useDispatch } from 'react-redux';
//Components
import useForm from '../../../customHooks/useForm';
import Spinner from '../../../components/loading/Loading';
import { fideActions } from '../../../reducers/FideDataOption';

import validateFormFidelidade from '../../../helpers/validateFormFidelidade';
import api from '../../../api/api-local';
import { toast } from 'react-toastify';
import { useAuth } from '../../../customHooks/AuthContext';
import InputIcon from '../../../components/Formulario/InputIcon';
import InputFile from '../../../components/Formulario/InputFile';
import TooltipPng from '../../../components/tooltipPng/TooltipPng';

import { Popconfirm } from 'antd';

import {
   Row,
   Col,
   Form,
   FormGroup,
   Card,
   Label,
   Button,
   CardBody,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { StepControllerContainer } from '../styles';
import PictureContext from '../../../context/PictureContext.jsx';

const config = {
   headers: {
      'Content-Type': 'multipart/form-data',
   },
};

const WizardStep2 = ({ handleNext, handleBack }) => {
   const history = useHistory();
   const pictureContext = React.useContext(PictureContext);

   const [picture, setPicture] = useState([]);
   const [imgData, setImgData] = useState(null);
   const { usuario, signOut } = useAuth();
   const [isLoading, setIsLoading] = useState(false);

   const [tooltipOpen01, setTooltipOpen01] = useState(false);
   const toggleTooltip01 = () => setTooltipOpen01(!tooltipOpen01);

   const pageInicial = () => {
      history.replace('/');
   };

   const initialValues = {
      nome: '',
      plataformaId: usuario.plataforma.plataformaId,
      path: '',
      cor: '#F6585',
      corTexto: 'white',
   };

   const { values, handleChange, errors, handleSubmit, setValues } = useForm(
      initialValues,
      formControlUser,
      validateFormFidelidade,
   );

   function formControlUser() {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('nome', values.nome === false ? '' : values.nome); //append the values with key, value pair
      formData.append('plataformaId', values.plataformaId);
      formData.append('path', values.path);
      formData.append('cor', values.cor || '#F6585');
      formData.append('corTexto', values.corTexto || 'white');
      formData.append('logo', picture);

      api.put('plataforma/personalizar', formData, config)
         .then(response => {
            setIsLoading(true);
            if (response.status === 200) {
               setIsLoading(false);
               handleNext(3);
               toast.success('Dados alterados com sucesso!');
               localStorage.setItem('@Fideleco:step3values', '');
               localStorage.setItem('@Fideleco:step3picture', '');
               localStorage.setItem('@Fideleco:step3pictureName', '');
            }
         })
         .catch(error => {
            setIsLoading(false);
            error.response.data.error.map(i => {
               toast.error(i.message);
            });
         });
   }

   const updateUser = plataformaId => {
      try {
         setIsLoading(true);

         api.get(`plataforma/${plataformaId}`)
            .then(response => {
               if (response.status === 200 && plataformaId) {
                  setIsLoading(false);

                  setValues(response.data);
                  setPicture(response.data.logo);

                  const backupPicture = localStorage.getItem(
                     '@Fideleco:step3picture',
                  );

                  if (backupPicture) {
                     const file = pictureContext.picture;

                     setPicture(file);
                     setImgData(backupPicture);
                  }

                  const backupValues = localStorage.getItem(
                     '@Fideleco:step3values',
                  );

                  if (backupValues) setValues(JSON.parse(backupValues));
               }
            })
            .catch(error => {
               toast.warning(error.response.data.error[0].message);
               setIsLoading(false);
            });
      } catch (error) {}
   };

   const onChangePicture = e => {
      const ok = Array.from(e.target.files);
      setPicture(e.target.files[0]);
      localStorage.setItem(
         '@Fideleco:step3pictureName',
         e.target.files[0].name,
      );

      pictureContext.picture = e.target.files[0];

      if (e.target.files) {
         const reader = new FileReader();

         reader.addEventListener('load', base64 => {
            setImgData(reader.result);
            localStorage.setItem('@Fideleco:step3picture', reader.result);
         });
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const backStep = () => {
      const backupValues = {
         nome: values.nome,
         plataformaId: values.plataformaId,
         path: values.path,
         cor: values.cor,
         corTexto: values.corTexto,
      };

      localStorage.setItem(
         '@Fideleco:step3values',
         JSON.stringify(backupValues),
      );

      usuario.etapaConfiguracaoPlataforma.etapaConfiguracaoPlataformaId = 0;
      localStorage.setItem('@Fideleco:user', JSON.stringify(usuario));
      handleBack(0);
   };

   useEffect(() => {
      updateUser(usuario.plataforma.plataformaId);
   }, []);

   return (
      <>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <CardBody>
                     <Form style={{ padding: '20px' }} onSubmit={handleSubmit}>
                        <Row form>
                           <Col md={6} className="mb-3">
                              <InputIcon
                                 type="text"
                                 label="Nome da plataforma"
                                 size={12}
                                 handleChange={handleChange}
                                 name="nome"
                                 value={values.nome || ''}
                              />
                           </Col>

                           <Col md={6} className="mb-3">
                              <FormGroup>
                                 <InputIcon
                                    id="inputColor"
                                    type="color"
                                    label="Cor predominante"
                                    size={12}
                                    handleChange={handleChange}
                                    name="cor"
                                    value={values.cor || '#F65858'}>
                                    <Label
                                       style={{
                                          cursor: 'pointer',
                                          color: '#FD595',
                                          paddingLeft: '5px',
                                       }}
                                       for="inputColor">
                                       Escolher
                                    </Label>
                                 </InputIcon>
                              </FormGroup>
                           </Col>

                           <Col md={6} className="mb-3">
                              <FormGroup>
                                 <InputIcon
                                    id="cor-texto"
                                    type="color"
                                    label="Cores de texto de botões"
                                    size={12}
                                    handleChange={handleChange}
                                    name="corTexto"
                                    value={values.corTexto || '#F65858'}>
                                    <Label
                                       style={{
                                          cuwrsor: 'pointer',
                                          color: '#FD5958',
                                          paddingLeft: '5px',
                                       }}
                                       htmlFor="cor-texto">
                                       Escolher
                                    </Label>
                                 </InputIcon>

                                 {errors.corTexto && (
                                    <p className="is-danger align-message">
                                       {errors.corTexto}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>

                           {/*<Col md={6} className="mb-3">
                              <FormGroup>
                                 <InputFile
                                    type="file"
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                    size={10}
                                    handleChange={onChangePicture}
                                    name="logo"
                                    id="profilePic"
                                 />
                              </FormGroup>
                           </Col>*/}
                        </Row>

                        <Row form>
                           <Col md={12} className="mb-5">
                              <FormGroup>
                                 <label>Veja como está ficando:</label>
                                 <TooltipPng id="vejacomoestaficando" />
                                 <Tooltip
                                    placement="right"
                                    isOpen={tooltipOpen01}
                                    target="vejacomoestaficando"
                                    toggle={toggleTooltip01}>
                                    Escolha qual será a cor predominante no
                                    website do seu Programa de Fidelidade.
                                    Escolha uma cor que esteja presente em sua
                                    logomarca ou fachada comercial e que deixa
                                    sua logomarca bem visível.
                                 </Tooltip>
                                 <div className="mb-3">
                                    <div
                                       className="style-cor-predominante"
                                       style={{
                                          background: `${
                                             values.cor || '#F65858'
                                          }`,
                                          boxShadow:
                                             '0 0.46875rem 2.1875rem rgb(85 2 1 / 5%)',
                                          height: '80px',
                                          borderRadius: '2px',
                                          position: 'relative',
                                          padding: '5px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'start',
                                       }}>
                                       {imgData !== null ||
                                       picture?.length > 0 ? (
                                          <img
                                             className="playerProfilePic_home_tile"
                                             alt="logotipo"
                                             style={{
                                                width: '140px',
                                                height: '90%',
                                                position: 'relative',
                                                left: '1rem',
                                             }}
                                             src={
                                                imgData ||
                                                `data:image/*;base64,${picture}`
                                             }
                                          />
                                       ) : (
                                          <InputFile
                                             type="file"
                                             style={{
                                                padding: '10px',
                                                margin: '0',
                                                marginLeft: '1rem',
                                                borderRadius: '5px',
                                                backgroundColor: 'transparent',
                                                color: '#495057',
                                                borderStyle: 'dashed',
                                                borderColor: '#495057',
                                                textTransform: 'uppercase',
                                                textAlign: 'center',
                                                display: 'block',
                                                cursor: 'pointer',
                                             }}
                                             text="Clique e escolha sua Logotipo"
                                             accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                             size={10}
                                             handleChange={onChangePicture}
                                             name="logo"
                                             id="profilePic"
                                          />
                                       )}
                                    </div>
                                    <InputFile
                                       type="file"
                                       style={{
                                          margin: '0',
                                          color: '#FD5958',
                                          cursor: 'pointer',
                                       }}
                                       text="Alterar imagem"
                                       accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                       size={10}
                                       handleChange={onChangePicture}
                                       name="logo"
                                       id="profilePic"
                                    />
                                 </div>
                              </FormGroup>
                           </Col>
                        </Row>

                        <StepControllerContainer>
                           <Button
                              color="primary"
                              onClick={backStep}
                              className="btn-wide btn-pill btn-shadow btn-hover-shine"
                              size="lg">
                              Voltar
                           </Button>

                           <Popconfirm
                              title="Você tem certeza? Você perderá todos os campos preenchidos"
                              onConfirm={() => history.replace('/')}
                              okText="Sim"
                              cancelText="Permanecer no cadastro">
                              <Button
                                 color="primary"
                                 type="submit"
                                 className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                 size="lg">
                                 Cancelar
                              </Button>
                           </Popconfirm>

                           <Button
                              color="primary"
                              type="submit"
                              className="btn-wide btn-pill btn-shadow btn-hover-shine"
                              size="lg">
                              Salvar
                           </Button>
                        </StepControllerContainer>
                     </Form>
                  </CardBody>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default WizardStep2;
