import React from 'react';
import { Wrapper } from './styles';

const Contrato = () => {
   return (
      <Wrapper>
         <h1>CONTRATO DE SOFTWARE COMO SERVIÇO</h1>
         <h2>1. DAS PARTES</h2>
         <p>
            1.1 São partes do presente contrato: de um lado a pessoa jurídica de
            direito privado FIDELECO SERVICOS DIGITAIS LTDA, devidamente
            inscrita no CNPJ sob nº 41.002.166/0001-24, com sede na cidade de
            São José do Rio Preto, à Avenida Doutor Aniloel Nazareth, nº 5650,
            Sala 09, Mansur Daud, por seus representantes infra assinados,
            doravante denominada <strong>CONTRATADA</strong> e do outro lado, a
            PESSOA JURIDICA que se submete a este instrumento, doravante
            denominado simplesmente
            <strong>CONTRATANTE</strong>, celebram o presente contrato, que se
            regerá pelas cláusulas e condições abaixo.
         </p>
         <h2>2. DO OBJETO</h2>
         <p>
            2.1 O presente contrato tem por objeto regular os direitos e
            obrigações relativos à utilização do sistema de fidelidade e
            satisfação de clientes FIDELECO, na modalidade de Software como
            Serviço. Nessa contratação, estão incluídos a aplicação FIDELECO.
         </p>
         <p>
            2.2 O Software Fideleco é ofertado na modalidade SaaS – Software
            como Serviço – e disponibiliza uma plataforma digital para que a
            CONTRATANTE, crie e gerencie vantagens e programas de fidelidade aos
            consumidores nela cadastrados. Ainda, o Software utiliza
            transmissões criptografadas e todas as informações são armazenadas
            em ambientes seguros.
         </p>
         <p>
            2.3. Ainda, o presente instrumento possui 2 (dois) anexos de
            serviços independentes que poderão ser contratados por meio do
            sistema de créditos. Os referidos serviços serão regidos pelos
            termos deste contrato, bem como pelas condições especificadas nos
            anexos para cada serviço.
         </p>
         <h2>3. DA VIGÊNCIA, RENOVAÇÃO E RESCISÃO</h2>
         <p>
            3.1. Este contrato terá vigência conforme a modalidade escolhida na
            contratação (mensal ou semestral) e será renovado automaticamente
            pelo mesmo período do plano contratado, sendo que os valores serão
            corrigidos anualmente pelo índice do IPCA.
         </p>
         <p>
            3.2. Durante a vigência do contrato, a CONTRATANTE somente poderá
            alterar o pacote de serviços contratados caso queira adquirir um
            pacote de maior valor (upgrade).
         </p>
         <p>
            3.3. A opção pela forma de pagamento da diferença entre o valor dos
            pacotes será apresentada no momento da nova contratação e seguirá as
            possibilidades previstas na seção décima deste instrumento.
         </p>
         <p>
            3.4. A CONTRATANTE pode rescindir o presente contrato a qualquer
            momento. Contudo, se houver qualquer fatura em aberto, esta deverá
            ser integralmente paga na data de sua rescisão.
         </p>

         <h2>4. DO VALOR E FORMA DE PAGAMENTO E VIGÊNCIA DO CONTRATO</h2>
         <p>
            4.1. O preço a ser pago pela CONTRATANTE será aquele indicado pelo
            Plano contratado e Crédito escolhido para envio de comunicações
            (E-mail Marketing e SMS) pela CONTRATANTE, constantes da página de
            contratação do produto.
         </p>
         <p>
            4.2. A CONTRATADA cobra e recebe pagamentos para o uso do Serviço
            FIDELÊCO, o valor cobrado será atualizado pela aplicação do IPC-A
            acumulado no período.
         </p>
         <p>
            4.3. A CONTRATANTE concorda em fornecer à CONTRATADA informações de
            contato e de cobrança completas e precisas.
         </p>
         <p>
            4.4 As faturas a serem pagas serão disponibilizadas através do
            e-mail utilizado pela CONTRATANTE cadastrado na plataforma.
         </p>

         <h2>5. DO NÃO PAGAMENTO E SUSPENSÃO</h2>
         <p>
            5.1. Além de quaisquer outros direitos aqui concedidos à CONTRATADA,
            a mesma se reserva o direito de suspender ou rescindir este Contrato
            e seu acesso ao Serviço se sua conta ficar inadimplente (pagamento
            atrasado) por 30 dias.
         </p>
         <p>
            5.2. Sobre valores pagos em atraso haverá incidência de multa de 2%
            (dois por cento) e juros de 1% (um por cento) ao mês, calculados
            desde a data do vencimento até a do efetivo pagamento, sem prejuízo
            de qualquer outro direito da CONTRATADA sob este Contrato.
         </p>
         <p>
            5.3. Na existência de débitos superiores a 30 dias, a CONTRATADA
            poderá, a seu livre critério, inscrever a CONTRATANTE, neste caso
            também devedora, em cadastro ou serviço de proteção ao crédito (SPC,
            SERASA, CARTÓRIOS DE PROTESTO). No caso de cobrança extrajudicial da
            dívida, por meio de advogados ou outras empresas especializadas,
            haverá o acréscimo de 20% (vinte por cento) a título de honorários
            advocatícios, acrescido de custas e emolumentos antecipados pela
            CONTRATADA para efetuar a cobrança de pleno direito, nos termos do
            Artigo 389 do Código Civil Brasileiro.
         </p>

         <h2>6. OBRIGAÇÕES E LIMITAÇÃO DA RESPONSABILIDADE DA CONTRATADA</h2>
         <p>
            6.1. A CONTRATADA garante apenas e tão somente o uso regular do
            software como serviço FIDELECO, pelo prazo contratado, de acordo com
            suas funcionalidades descritas no “link”.
         </p>
         <p>
            6.2. Oferecer suporte à CONTRATANTE para utilização da plataforma,
            conforme plano contratado.
         </p>
         <p>
            6.3. Em razão da complexidade e impossibilidade de isenção de falhas
            nos sistemas de informática, por sua própria natureza, as partes
            acordam que a CONTRATADA não poderá, em hipótese alguma, ser
            responsabilizado por invasões ou alterações, decorrentes da ação de
            terceiros, incluindo, mas sem se limitar, a hackers e/ou crackers. A
            CONTRATADA neste caso deverá reparar qualquer alteração indevida,
            desde que a mesma seja passível de reparação pela CONTRATADA, no
            prazo compatível com a complexidade do problema.
         </p>
         <p>
            6.4. A CONTRATADA não será responsável por qualquer insucesso
            comercial ou perda de receita em razão da implantação de programas
            de fidelidade, ou má utilização da plataforma, não sendo ainda
            responsável por resultados produzidos pelo contratante ou quaisquer
            terceiros, conforme descrito na cláusula 6.8.
         </p>
         <p>
            6.5. Em circunstância alguma qualquer uma das partes e/ou seus
            licenciadores serão responsáveis perante qualquer pessoa por
            quaisquer danos indiretos, punitivos, incidentais, consequenciais ou
            de outro tipo ou espécie (incluindo perda de dados, receita, lucro
            cessante, uso ou outra vantagem econômica) resultantes ou de alguma
            forma relacionados a este serviço, incluindo sem limitação o uso ou
            incapacidade de usar o serviço, ou por qualquer conteúdo obtido do
            serviço ou por intermédio dele, qualquer interrupção, imprecisão,
            erro ou omissão, independentemente de causa no conteúdo, mesmo que a
            parte da qual se tente reaver os danos, ou de seus licenciadores,
            tenham sido avisados previamente sobre a possibilidade de tais
            danos.
         </p>
         <p>
            6.6. Em nenhuma circunstância qualquer uma das partes poderá ser
            responsabilizada por prejuízos advindos de caso fortuito ou de força
            maior, tampouco de eventos atribuíveis, exclusivamente, a terceiros.
         </p>
         <p>
            6.7. A CONTRATADA é um software online que possui diversas
            finalidades para que a CONTRATANTE crie e gerencie programas de
            fidelidades aos consumidores, e não possui qualquer responsabilidade
            sobre a qualidade, quantidade, eventuais vícios, defeitos, riscos
            dentre outros problemas que tragam prejuízo ao consumidor final,
            pois o serviço prestado pela CONTRATADA se refere apenas e
            exclusivamente à disponibilização de um sistema de fidelização de
            clientes, sem qualquer gerência sobre controle de qualidade do
            produto ou serviço prestado pela CONTRATANTE.{' '}
         </p>
         <p>6.8. A CONTRATADA NÃO É UMA INTERMEDIADORA DE VENDAS.</p>
         <p>
            6.9. A CONTRATANTE terá acesso APENAS aos dados dos consumidores que
            realizarem transação com ela e enquanto esses permitirem tal acesso,
            podendo ser solicitado a exclusão dos dados através do canal de
            suporte ((17) 99718-9136 / atendimento@fideleco.com.br)
         </p>

         <h2>7. ACORDO DE NÍVEL DE SERVIÇO </h2>
         <p>
            7.1 Entende-se por acordo de nível de serviço ou SLA (Service Level
            Agreement), para efeito do presente contrato, o tempo de
            disponibilidade dos serviços e resolução das demandas, do Data
            Center, sendo certo que tal acordo representa um indicador de
            excelência técnica, uma vez que em informática não existe garantia
            integral (100%) de nível de serviço.{' '}
         </p>
         <p>
            7.2 A CONTRATADA, desde que observadas as obrigações a cargo do
            CONTRATANTE previstas no presente contrato, objetiva oferecer e se
            propõe a manter em cada mês civil, um SLA de 95%.{' '}
         </p>
         <p>
            7.2.1 Entendem-se como serviços prestados sujeitos à garantia de
            desempenho (SLA), para efeitos do presente contrato:{' '}
         </p>
         <p>
            7.2.1.1 Manter os servidores e o link de acesso em funcionamento
            ininterrupto.
         </p>

         <p>
            7.3 A CONTRATADA ficará desobrigada de cumprimento do SLA nas
            seguintes hipóteses:{' '}
         </p>
         <p>
            a. Falha na conexão (“link”) fornecida pela empresa de
            telecomunicações encarregada da prestação do serviço, sem culpa da
            CONTRATADA;{' '}
         </p>
         <p>
            b. Falhas de utilização, de responsabilidade do CONTRATANTE, ou
            sobrecarga do servidor causada por utilização não otimizada.{' '}
         </p>
         <p>
            c. Falhas causadas por mau funcionamento no servidor ou do sistema
            operacional.{' '}
         </p>
         <p>
            d. As interrupções necessárias para ajustes técnicos ou manutenção,
            que serão informadas com antecedência e se realizarão,
            preferencialmente, em horários noturnos, de baixo movimento.{' '}
         </p>
         <p>
            e. As interrupções diárias necessárias para ajustes técnicos ou
            manutenção, com duração de até 10 minutos, não serão informadas.{' '}
         </p>
         <p>
            f. As intervenções emergenciais decorrentes da necessidade de
            preservar a segurança do servidor, destinadas a evitar ou fazer
            cessar a atuação de “hackers” ou destinadas a implementar correções
            de segurança.{' '}
         </p>
         <p>
            g. Suspensão da prestação dos serviços contratados por determinação
            de autoridades competentes, ou por descumprimento de cláusulas do
            presente contrato.{' '}
         </p>
         <p>
            7.3.1 Se os serviços forem suspensos temporariamente em razão de
            quaisquer das condições elencadas nas cláusulas “a” a “g” supra,
            esta suspensão NÃO SERÁ computada para fins de verificar o
            cumprimento ou não do SLA pela CONTRATADA.
         </p>
         <p>
            7.4. Na hipótese de do servidor ficar indisponível por um período
            maior do que estabelecido no item 7.2, a CONTRATADA concederá um
            desconto na próxima fatura.
         </p>

         <h2>8. DAS RESTRIÇÕES DE USO </h2>
         <p>
            8.1. Este contrato autoriza a utilização dos serviços oferecidos
            através da aplicação FIDELÊCO, por meio da Internet. A autorização
            mencionada é pessoal, intransferível e exclusiva.
         </p>
         <p>
            8.2. A CONTRATANTE não pode a menos que haja expressa, formal e
            inequívoca autorização, por escrito, por parte da CONTRATADA, (i)
            licenciar, sublicenciar, vender, revender, transferir, atribuir,
            distribuir ou explorar comercialmente ou tornar o Serviço ou o seu
            conteúdo disponível para terceiros; (ii) modificar ou criar obras
            derivadas do Serviço ou do seu conteúdo; (iii) criar “links” da
            Internet para o Serviço ou criar “quadros” ou “espelhar” qualquer
            conteúdo em outro servidor ou dispositivo sem fio ou baseado na
            Internet ou (iv) fazer engenharia reversa ou acessar o Serviço com o
            intuito de (a) criar um produto ou serviço concorrente, (b) criar um
            produto usando ideias, recursos, funções ou elementos gráficos
            semelhantes aos do Serviço, ou (c) copiar ideias, recursos, funções
            ou elementos gráficos do Serviço. As licenças de usuário não podem
            ser compartilhadas ou usadas por mais de um Usuário.
         </p>
         <p>
            8.3. A CONTRATANTE só poderá usar o Serviço para seus objetivos
            comerciais internos e devendo ainda cumprir todas as obrigações
            dispostas neste contrato.
         </p>
         <p>
            8.4. Em caso de uso indevido, pela CONTRATANTE ou terceiros, do
            código de usuário da CONTRATANTE e da senha privativa, a CONTRATADA
            poderá rescindir, independentemente de notificação prévia, o
            presente Contrato, sem que a CONTRATANTE ou terceiros façam jus a
            qualquer tipo de indenização ou ressarcimento (art. 421 do Código
            Civil).
         </p>

         <h2>9. DAS RESPONSABILIDADES DOS DADOS CADASTRAIS</h2>
         <p>
            9.1. A CONTRATANTE é responsável por seus dados cadastrais
            fornecidos e que serão utilizados durante o uso do FIDELÊCO, bem
            como por todas as atividades que ocorrem nas suas contas de usuário
            e deve obedecer a todas as leis municipais, estaduais, federais e
            internacionais aplicáveis, tratados e regulamentações relativos ao
            uso do serviço, incluindo aqueles relativos à privacidade dos dados,
            às comunicações internacionais e à transmissão de dados técnicos ou
            pessoais e deverá
         </p>
         <p>
            9.2. Caberá a CONTRATANTE notificar a CONTRATADA imediatamente sobre
            qualquer uso não autorizado de senhas ou contas ou qualquer outra
            suspeita de violação da segurança; Relatar à CONTRATADA
            imediatamente e empregar esforços razoáveis para cessar
            imediatamente qualquer cópia ou distribuição de Conteúdo do qual
            você ou seus Usuários tenham conhecimento ou suspeita; e Não se
            fazer passar por outro usuário da CONTRATADA nem fornecer
            informações de identidade falsas para obter acesso ao Serviço ou
            para usá-lo. a CONTRATANTE informará a CONTRATADA todos os dados
            necessários para seu cadastramento, comprometendo-se a fornecer
            informações verdadeiras, corretas, atuais e completas sobre si
            mesmo, no momento do seu cadastramento, responsabilizando-se civil e
            criminalmente por estas informações.
         </p>
         <p>
            9.3. A CONTRATANTE deverá fornecer e manter somente dados
            verdadeiros, atualizados e completos, declarando-se ciente de que a
            utilização de dados falsos, inválidos, incorretos ou de terceiros,
            são de sua inteira responsabilidade, podendo acarretar a rescisão do
            contrato firmado entre a CONTRATADA e a CONTRATANTE e
            consequentemente, o CANCELAMENTO IMEDIATO DO PLANO CONTRATADO, SEM
            PRÉVIA NOTIFICAÇÃO, e, ainda, caracterizar a prática de ato ilícito,
            sujeitando-o às penalidades previstas em lei.
         </p>
         <p>
            9.4. A CONTRATANTE deverá informar à CONTRATADA sempre que ocorrerem
            alterações nas informações cadastrais, incluindo, mas não se
            limitando a qualquer mudança no endereço de e-mail para os quais
            deverão ser enviados os boletos de pagamento, bem como de alterações
            de telefones ou nome da pessoa de contato, que deverão ser
            informadas em qualquer dos meios disponibilizados pela CONTRATADA
            para atendimento ao Cliente, conforme constantes na página
            <a href="https://www.fideleco.com.br">
               https://www.fideleco.com.br.
            </a>
         </p>

         <h2>10. OBRIGAÇÕES DA CONTRATANTE</h2>
         <p>
            10.1. Constituem obrigações da CONTRATANTE, além das demais
            obrigações previstas neste instrumento:
         </p>
         <p>
            10.2. Efetuar, no vencimento, o pagamento de créditos, taxas e/ou
            anuidades pactuadas.
         </p>
         <p>
            10.3. Administrar em caráter de exclusividade e sem qualquer
            solidariedade com a CONTRATADA, o código de usuário e a respectiva
            senha privativa, assumindo integralmente toda e qualquer
            responsabilidade pelo uso dos mesmos, bem por ações realizadas por
            meio destes, incluindo, mas não se limitando aos encargos econômicos
            resultantes de tal uso, especialmente no tocante à perda de dados,
            contaminação por vírus, invasão de redes, furto de dados ou
            informações, envio de mensagens eletrônicas ofensivas e/ou
            inoportunas a outros usuários da Internet e demais condutas que
            venham a prejudicar outros usuários e/ou sistemas conectados à
            Internet, bem como por quaisquer outras ações que estejam em
            desacordo com a legislação vigente.
         </p>
         <p>
            10.4. Abster-se de fazer uso do serviço regulado pelo presente
            instrumento, para propagar ou manter conteúdos que: (a) violem a
            lei, a moral, os bons costumes, a propriedade intelectual, os
            direitos à honra, à vida privada, à imagem, à intimidade pessoal e
            familiar; (b) estimulem a prática de condutas ilícitas ou contrárias
            à moral e aos bons costumes; (c) incitem a prática de atos
            discriminatórios, seja em razão de sexo, raça, religião, crenças,
            idade ou qualquer outra condição; (d) coloquem à disposição ou
            possibilitem o acesso a mensagens, produtos ou serviços ilícitos,
            violentos, pornográficos, degradantes; (e) enviem mensagens
            coletivas de e-mail (SPAM) a grupos de usuários deste ou de outros
            provedores, ofertando produtos ou serviços de qualquer natureza,
            próprios ou de outrem, que não sejam de interesse dos destinatários
            ou que não tenham o expresso consentimento destes, aderindo assim a
            política Anti-Spam da Contratada; (f) induzam ou possam induzir a um
            estado inaceitável de ansiedade ou temor; (g) induzam ou incitem
            práticas perigosas, de risco ou nocivas para a saúde e para o
            equilíbrio psíquico; (h) sejam falsos, ambíguos, inexatos,
            exagerados ou extemporâneos, de forma que possam induzir a erro
            sobre seu objeto ou sobre as intenções ou propósitos do comunicador;
            (i) violem o sigilo das comunicações; (j) constituam publicidade
            ilícita, enganosa ou desleal, em geral, que configurem concorrência
            desleal; (k) veiculem, incitem ou estimulem a pedofilia (l)
            incorporem vírus ou outros elementos físicos ou eletrônicos que
            possam danificar ou impedir o normal funcionamento da rede, do
            sistema ou dos equipamentos informáticos (hardware e software) de
            terceiros ou que possam danificar os documentos eletrônicos e
            arquivos armazenados nestes equipamentos informáticos, (m) obtenham
            ou tentam obter acesso não autorizado a outros sistemas ou redes de
            computadores, (n) reproduzir, vender e distribuir de produtos sem a
            devida autorização e o pagamento dos direitos autorais.
         </p>
         <p>
            10.5. Assumir integralmente, sem solidariedade da CONTRATADA, seja a
            que título for, a responsabilidade por todas as obrigações assumidas
            no item acima, bem como por todos pelos serviços e informações que
            prestar, a partir do objeto deste Contrato. Desta forma a
            CONTRATANTE assume total e exclusiva responsabilidade
            administrativa, civil e penal, pelo conteúdo dos E-MAILS/SMSs que
            vier a encaminhar, devendo responder por todos os danos e prejuízos
            causados que o mau uso do plano ora contratado vier a causar à
            CONTRATADA, e/ou a terceiros. Sendo a CONTRATADA, compelida a
            participar de qualquer processo judicial ou administrativo
            relacionada à responsabilidade ora assumida pela CONTRATANTE, fica a
            CONTRATANTE obrigada a ressarcir a CONTRATADA, dos ônus legais e
            financeiros em que a CONTRATADA, vier a incorrer, sem prejuízo de
            eventuais perdas e danos.
         </p>

         <h2>11. DAS INFORMAÇÕES E DADOS DA CONTA</h2>
         <p>
            11.1. A CONTRATADA não assume responsabilidade por qualquer
            exclusão, correção, alteração, destruição, dano, perda ou falha no
            armazenamento de quaisquer “Dados do Cliente” que sejam efetivados a
            partir de um acesso autorizado, mediante utilização de login e senha
            cadastrados nas bases de informação da CONTRATANTE.
         </p>
         <p>
            11.2. No caso deste Contrato ser rescindido, a CONTRATADA manterá
            disponível pelo prazo máximo de 90 dias, a partir da data de
            cancelamento da conta, os “Dados do Cliente”, ou seja, os dados dos
            consumidores que são vinculados ao seu negócio. A CONTRATADA se
            reserva o direito de reter, remover e/ou descartar Dados do Cliente
            sem aviso, em decorrência de qualquer violação, de sua parte, aos
            termos do presente documento, incluindo, mas não se limitando, a
            falta de pagamento pelos serviços contratados. Após a rescisão por
            justa causa, seu direito de acessar ou usar os Dados do Cliente
            cessa imediatamente, e a CONTRATADA não terá mais nenhuma obrigação
            em manter ou encaminhar quaisquer “Dados do Cliente”.
         </p>
         <p>
            11.3 A CONTRATANTE poderá solicitar à CONTRATADA os “Dados do
            Cliente” em formato CSV. Porém, a CONTRATANTE assume que não
            utilizará os dados para nenhum uso que viole qualquer princípio ou
            regra estabelecida pela Constituição Federal ou pela Lei Geral de
            Proteção de Dados (13.709/2018).
         </p>

         <h2>12. DA PROTEÇÃO DE DADOS</h2>
         <p>
            12.1. A política de uso, tratamento e proteção dos dados coletados
            entre as partes está amparada e deverá ocorrer em conformidade com a
            Lei Geral de Proteção de Dados Brasileira, doravante LGPD (Lei nº
            13.709/18 e suas respectivas alterações previstas na Lei nº
            13.853/19), bem como os preceitos constitucionais previstos na
            Constituição da República Federativa do Brasil de 1988, ressaltando
            que toda relação de dados entre as partes terá como fundamento o
            respeito à privacidade; a autodeterminação informativa; liberdade de
            expressão, de informação, de comunicação e de opinião; a
            inviolabilidade da intimidade, da honra e da imagem; desenvolvimento
            econômico e tecnológico e a inovação; a livre iniciativa, a livre
            concorrência e a defesa do consumidor; (Art. 2º LGPD).
         </p>
         <p>
            12.2. Conforme expresso nos Termos de Uso, na “Política de
            Privacidade” e no presente contrato, a CONTRATADA é uma
            intermediadora, por meio da qual o usuário utilizará a plataforma
            Fidelêco a fim de localizar empresas que possuem programas de
            Fidelidade. Portanto, a CONTRATADA se enquadrada nesta relação como
            CONTROLADORA e OPERADORA dos dados dos consumidores finais, pois
            toma decisões sobre os dados e também realiza a coleta e tratamento.
         </p>
         <p>
            12.3. A Contratante, autoriza expressamente a Contratada{' '}
            <strong>
               a coletar, usar, armazenar, tratar, ceder ou utilizar as
               informações derivadas do uso dos serviços, do site e de sua
               plataforma, incluindo todas as informações preenchidas pela
               Contratante no momento em que realizar ou atualizar seu cadastro,
               além de outras expressamente descritas neste Contrato de Licença
               de Uso, com a finalidade de: (i) desenvolver novos produtos,
               conteúdo e funcionalidades, bem como aperfeiçoar e divulgar os
               Serviços prestados; (ii) analisar, gerar relatórios a respeito da
               performance da Contratante, de forma direta ou indireta; (iii)
               agregar, analisar, tratar e anonimizar os dados obtidos; (iv)
               preparar materiais para treinamentos internos e externos; (v)
               disponibilizar dados para empresas do mesmo grupo econômico ou
               para plataformas e ferramentas contratadas; (vi) utilizar as
               informações em anúncios publicitários com veiculação em qualquer
               tipo de mídia; e (vii) outras atividades previstas neste
               instrumento ou em quaisquer dos outros que integram e
               complementam indissociavelmente este documento
            </strong>
            .
         </p>
         <p>
            12.4. A Contratante confere por meio destes termos autorização
            livre, específica, expressa e informada a respeito da utilização e
            compartilhamento dos dados com empresas parceiras da CONTRATADA.
         </p>
         <p>
            12.5. A Contratada compromete-se tratar os dados coletados da
            maneira adequada, preservando a segurança e promovendo a
            conscientização dos operadores da importância da proteção dos dados,
            utilizando-os com responsabilidade e para as finalidades por ela
            estabelecidas, além da adoção de medidas mitigadoras que garantam a
            impossibilidade de acessos indevidos, salientados os princípios de
            governança e boas práticas.
         </p>
         <p>
            12.6. A Contratante declara ter lido e compreendido os termos e
            disposições desta seção, e que está ciente de seu inteiro teor,
            aceitando todas as suas condições.{' '}
         </p>

         <h2>13. DA TITULARIDADE DE PROPRIEDADE INTELECTUAL</h2>
         <p>
            13.1. A CONTRATADA detém a titularidade exclusiva de todos os
            Direitos de Propriedade Intelectual da Tecnologia do FIDELÊCO, do
            seu conteúdo e do serviço, ou a eles relacionados, bem como de
            quaisquer sugestões, ideias, solicitações de aprimoramentos,
            comentários, recomendações ou outras informações fornecidas pela
            CONTRATANTE ou por outrem e que estejam relacionados ao serviço.
            Este Contrato não é uma venda e não lhe concede nenhum direito de
            propriedade do serviço, à tecnologia da CONTRATADA, ou aos seus
            Direitos de Propriedade Intelectual. O nome da CONTRATADA, o
            logotipo da CONTRATADA e os nomes de produto associados ao Serviço
            são marcas comerciais da CONTRATADA, e nenhum direito ou licença é
            concedido para usá-los.
         </p>

         <h2>14. DA CONFIDENCIALIDADE</h2>
         <p>
            14.1. Cada uma das Partes obriga-se por si ou por qualquer pessoa a
            elas ligadas a manter sigilo e confidencialidade sobre quaisquer
            informações, documentos ou dados técnicos de propriedade da outra
            parte (inclusive listagem de cadastro de endereços eletrônicos,
            banco de dados, nome, endereços, características dos clientes e
            consumidores) susceptíveis ou não de proteção legal, a que tiver
            acesso, seja em virtude da presente contratação como em virtude da
            permanência em suas instalações.
         </p>
         <p>
            14.2. Esta obrigação subsistirá pelo período de 02 (dois) anos
            contados da data do término ou rescisão do presente termo. As partes
            se obrigam a respeitar a confidencialidade dos dados e informações
            que a outra parte repasse ou facilite para o cumprimento do presente
            termo, bem como de tudo a que se refira ao serviço ora contratado ou
            à sua execução. Em caso de violação desta obrigação por qualquer uma
            das partes ou por qualquer de seus empregados, prepostos ou
            representantes, a outra parte estará legitimada a reclamar e exigir,
            conforme a legislação aplicável, a correspondente indenização por
            todos os danos e prejuízos ocasionados.
         </p>

         <h2>15. DA RESCISÃO POR JUSTA CAUSA</h2>
         <p>
            15.1. Qualquer violação das suas obrigações de pagamento ou uso não
            autorizado da Tecnologia ou FIDELÊCO será considerada uma violação
            material deste Contrato. A CONTRATADA, a critério dela, poderá
            rescindir a senha da CONTRATANTE se esta violar ou falhar em cumprir
            este contrato.
         </p>

         <h2>16. DA REATIVAÇÃO DE CONTA</h2>
         <p>
            16.1. A CONTRATANTE poderá, a qualquer tempo e sem qualquer ônus,
            reativar sua conta. Contudo, se essa reativação ocorrer após um
            período superior a 90 (noventa) dias do cancelamento da conta
            anterior, os “Dados do Cliente” não mais estarão disponíveis no
            sistema da CONTRATADA. Apenas dados cadastrais e de cobrança
            permanecerão nos sistemas da CONTRATADA.
         </p>

         <h2>17. DOS ATRASOS NA INTERNET</h2>
         <p>
            17.1. Os serviços da CONTRATADA podem estar sujeitos a limitações,
            atrasos e outros problemas inerentes ao uso da internet e de
            comunicações eletrônicas. A CONTRATADA não assume responsabilidade
            por atrasos, falhas de entrega ou outros danos resultantes de tais
            problemas.
         </p>

         <h2>
            18. DA INTERRUPÇÃO DO DIREITO DE UTILIZAÇÃO DE USO DO SOFTWARE A
            PEDIDO DE AUTORIDADES
         </h2>
         <p>
            18.1. A CONTRATANTE tem ciência de que em caso de ordem judicial ou
            autoridades competentes, a utilização do programa poderá ser
            suspenso, tanto pela CONTRATANTE quanto pela CONTRATADA.
         </p>

         <h2>19. DAS DISPOSIÇÕES GERAIS</h2>
         <p>
            19.1. Não se estabelecerá qualquer vínculo empregatício entre os
            funcionários da CONTRATADA e da CONTRATANTE.
         </p>
         <p>
            19.2. Toda e qualquer alteração na sistemática ou rotina dos
            serviços, que enseje na alteração deste Contrato, será processada
            pela CONTRATADA.
         </p>
         <p>
            19.3. A responsabilidade das partes cessa em caso fortuito ou de
            força maior e por atos governamentais.
         </p>
         <p>
            19.4. A tolerância ou o não exercício por qualquer das partes de
            direitos a eles assegurados neste Termo ou na lei em geral não
            importará em renúncia a esses direitos ou novação de obrigações.
         </p>
         <p>
            19.5. A CONTRATANTE tem ciência de que estes termos poderão ser
            alterados a qualquer momento, e no momento de novas contratações é
            obrigação da CONTRATANTE conferir o presente contrato.
         </p>
         <p>
            19.6. A CONTRATANTE, desde já, autoriza, de forma plena e
            irrevogável, a divulgação, pela CONTRATADA, em propagandas e
            publicidade (escrita, televisiva ou virtual) realizadas por esta, de
            informação de que a empresa CONTRATANTE utiliza o presente Software
            como Serviço, podendo, inclusive, apresentar o logotipo e as marcas
            de produtos ou de serviços da CONTRATANTE, abrindo esta mão de
            qualquer direito pertinente a isso.
         </p>
         <p>
            19.7. Na hipótese de ocorrer a extinção da oferta deste serviço, a
            continuidade da disponibilização em decorrência de contratos
            anteriormente celebrados ficará na dependência da disponibilidade
            técnica da CONTRATADA.
         </p>

         <h2>20. DO FORO</h2>
         <p>
            20.1. As partes elegem o Foro de São José do Rio Preto, Estado de
            São Paulo para dirimir quaisquer dúvidas oriundas deste contrato e
            anexos.
         </p>
         <br />
         <p>São José do Rio Preto/SP, 20 de julho de 2021.</p>
      </Wrapper>
   );
};

export default Contrato;
