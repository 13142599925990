import React, { useState, useEffect } from 'react';
import api_local from '../../api/api-local';

//Components
import ModalCustom from '../../components/Modal/Modal';
import Spinner from '../../components/loading/Loading';
import PageTitle from '../layout/AppMain/PageTitle';
import { useAuth } from '../../customHooks/AuthContext';
import { helpersUntil } from '../../helpers/until';

//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { toast } from 'react-toastify';
import { Row, Col, Card } from 'reactstrap';

const ExtratoGeral = () => {
   const { signOut } = useAuth();

   const [listExtratoGeral, setListExtratoGeral] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [total, setTotal] = useState();

   const getListExtratoGeral = async () => {
      setIsLoading(true);
      api_local
         .get('/relatorio/extratogeral')
         .then(response => {
            if (response.status === 200) {
               setIsLoading(false);
               const { loja, total } = response.data;

               setTotal(total);
               setListExtratoGeral(loja);
            }
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   useEffect(() => {
      getListExtratoGeral();
   }, []);

   return (
      <>
         <PageTitle
            heading="Extrato Geral"
            subheading="Gerar seus relatórios"
            icon="pe-7s-display1 icon-gradient bg-fideleco">
            {/*<ModalCustom
               titleModal={'Cadastrar novo usuário'}
               buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
               buttonIcon={
                  <i className="pe-7s-display1 btn-icon-wrapper font-weight-bold"></i>
               }
               buttonName={'Exportar Planilha'}
               buttonSize="lg"
               buttonColor="primary"
               toggleFunc={() => {}}
               isOpen={false}
               size="lg"
               closeModal={() => {}}></ModalCustom>*/}
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col>
                  <Card
                     className="main-card mb-2 text-left"
                     style={{ minHeight: '500px' }}>
                     <div className="extrato-container">
                        {listExtratoGeral.length != 0 ? (
                           <>
                              {listExtratoGeral.map((extrato, index) => (
                                 <div key={index} className="card-extrato">
                                    <header>{extrato.loja}</header>
                                    <div className="content-card-extrato">
                                       {extrato.formaPagamento.map(
                                          (forma, index) => (
                                             <div
                                                key={index}
                                                className="row-card-extrato">
                                                <h5>{forma.descricao}: </h5>
                                                <span>
                                                   {helpersUntil.converterValueStringForReal(
                                                      forma.total,
                                                   )}
                                                </span>
                                             </div>
                                          ),
                                       )}
                                    </div>
                                    {/*<footer className="footer-card-extrato">
                                       <h5>Total:</h5>
                                       <span>R$ 100,00</span>
                                    </footer>*/}
                                 </div>
                              ))}
                           </>
                        ) : (
                           <>
                              <span className="mt-5">
                                 Não existem clientes pontuados
                              </span>
                           </>
                        )}
                     </div>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default ExtratoGeral;
