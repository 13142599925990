import React from 'react';

import { Grid, TextField } from '@material-ui/core';
import Tooltip from '../../components/tooltip';

const InputIcon = props => {
   const {
      icon,
      label,
      tooltip,
      text,
      id,
      type,
      size,
      style,
      handleChange,
      name,
      error,
      value,
      disabled,
      justifyContent = 'start',
   } = props;

   return (
      <Grid
         container
         style={{
            display: 'flex',
            justifyContent: justifyContent,
         }}>
         <Grid
            style={{
               padding: '2px',
            }}>
            <label className="m-0" htmlFor={id} style={style}>
               {label || text}
            </label>
            {tooltip && <Tooltip texto={tooltip} id={name} />}
         </Grid>
         <Grid item xs={size}>
            <TextField
               style={{ display: 'none' }}
               id={id}
               value={value}
               disabled={disabled}
               name={name}
               error={error}
               type={type}
               onChange={handleChange}
               label={label}
               color="primary"
               fullWidth
            />
         </Grid>
      </Grid>
   );
};

export default InputIcon;
