import React from 'react';
import FreshChat from 'react-freshchat';

const token = process.env.REACT_APP_API_KEY_FRESHCHAT;
const user = JSON.parse(localStorage.getItem('@Fideleco:user'));

const solicitante = user
   ? {
        origem: 'Painel Fideleco',
        cod: user.usuarioId,
        nome: user.nome,
        email: user.email,
        celular: user.celular,
        path: user.plataforma.urlHotsite,
        url_atual: window.location.pathname,
     }
   : {
        status: 'Usuário não logado',
     };

const FreshChatApp = () => {
   return (
      <FreshChat
         token={token}
         onInit={widget => {
            widget.user.setFirstName(user.nome);
            widget.user.setProperties(solicitante);
         }}
      />
   );
};

export default FreshChatApp;
