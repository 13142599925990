import React from 'react';
import AppSidebar from "./AppSidebar/index";

function Layout(props) {
   return (
      <div>
         <div >
            <AppSidebar />
            <div>
               {props.children}
            </div>
         </div>
      </div>
   );
}

export default Layout;
