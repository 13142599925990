import React, { useState, useEffect } from 'react';
import api from '../../../api/api-local';

//Components
import validateAdmin from '../../../helpers/ValidateFormDataAdmin';
import InputIcon from '../../../components/Formulario/InputIcon';
import { useAuth } from '../../../customHooks/AuthContext';
import ModalCustom from '../../../components/Modal/Modal';
import Spinner from '../../../components/loading/Loading';
import PageTitle from '../../layout/AppMain/PageTitle';
import InputSelect from '../../../components/Selected';
import useForm from '../../../customHooks/useForm';
import Tooltip from '../../../components/tooltip';

//Libs
import { Row, Col, Card, CardBody, Button, Form, FormGroup } from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { TextField } from '@material-ui/core';
import TableSemFilter from '../../../components/Table';
import Table from '../../../components/FilterTable';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import PaginationFilterTable from '../../../components/PaginationFilterTable';

//Files
import EscondeSenhaIcon from '../../../assets/icons/eye-close-red.svg';
import RevelaSenhaIcon from '../../../assets/icons/eye-open-red.svg';
import LoadingButton from '../../../components/LoadingButton';

const ListaUsuario = () => {
   const { signOut } = useAuth();
   const history = useHistory();

   const [mostraConfirmaSenha, setMostraConfirmaSenha] = useState(false);
   const [mostraSenha, setMostraSenha] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [dataSource, setDataSource] = useState([]);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [termoBusca, setTermoBusca] = useState({});
   const [edit, setEdit] = useState(false);

   const [listPerfisUsuario, setListPerfisUsuario] = useState([]);
   const [selectedLoja, setSelectedLoja] = useState('vazio');
   const [listPerfis, setListPerfis] = useState([]);
   const [listLojas, setListLojas] = useState([]);
   const [selectedPerfil, setSelectedPerfil] = useState({
      perfilId: 1,
      descricao: 'Administrador',
   });

   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   //Valores iniciais do estado do formulario
   const initialValues = {
      nome: null,
      email: '',
      celular: '',
      limitePontuacaoMensal: '',
      senha: '',
      resgatarPremio: false,
      plataformaId: 1,
      exigirSenhaResgateCliente: false,
      perfilUsuarioLoja: [],
   };

   //States para manipular formularios
   const { values, errors, handleChange, handleSubmit, setValues } = useForm(
      initialValues,
      formControlUser,
      validateAdmin,
   );

   //Colunas da tabela de usuarios
   const columns = [
      {
         dataIndex: 'nome',
         title: 'Nome',
         filters: dataSource.map(user => ({
            text: user.nome,
            value: user.nome,
         })),
         onFilter: (value, record) => record.nome.indexOf(value) === 0,
         sorter: (a, b) => (a.nome < b.nome ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
         key: 'nome',
      },
      {
         dataIndex: 'email',
         title: 'E-mail',
         filters: dataSource.map(user => ({
            text: user.email,
            value: user.email,
         })),
         onFilter: (value, record) => record.email.indexOf(value) === 0,
         sorter: (a, b) => (a.email < b.email ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
         key: 'email',
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 120,
         align: 'center',
         render: user => (
            <div key={user.usuarioId}>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon mr-2 text-info lnr-pencil mr-3"
                  onClick={() => {
                     updateUser(user);
                  }}
               />
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     deleteUser(user);
                  }}
               />
            </div>
         ),
      },
   ];

   const handleChangePerson = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   //Definição dos campos de filtros
   const filtros = [
      {
         dataIndex: 'nome',
         nome: 'Nome',
         placeholder: 'Digite o nome',
         mask: 'text',
      },
      {
         dataIndex: 'email',
         nome: 'E-mail',
         placeholder: 'Digite o e-mail',
         mask: 'text',
      },
   ];

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getListaClientes(pagination.current, pagination.pageSize);
      }, 1);
   };

   //Colunas de ixibição dos perfis
   const columnsPerfis = [
      {
         dataIndex: 'lojaDescricao',
         title: 'Loja',
         width: 200,
         key: 'lojaDescricao',
      },

      {
         dataIndex: 'perfilDescricao',
         title: 'Perfil',
         width: 200,
         key: 'perfilDescricao',
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 80,
         align: 'center',
         render: perfil => (
            <div>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     removePerfilUsuario(perfil);
                  }}
               />
            </div>
         ),
      },
   ];

   //Abrindo a modal
   const openModal = () => {
      if (!values.nome) {
         setEdit(false);
      } else {
         setValues(initialValues);
         setEdit(true);
      }
      setIsOpen(true);
   };

   //Fechando a modal
   const closeModal = () => {
      setValues(initialValues);
      setListPerfis([]);
      getListLojas([]);
      setIsOpen(false);
   };

   //Pegando valor que vem do selected
   function handleChangeSelectedLoja(e) {
      //Somente execulta se o selecte tiver algum valor valido
      if (e.target.value !== 'vazio') {
         //add valor selecionado no state
         setSelectedLoja(JSON.parse(e.target.value));
      }
   }

   //Pegando valor que vem do selected
   function handleChangeSelectedPerfil(e) {
      //Somente execulta se o selecte tiver algum valor valido
      if (e.target.value !== 'vazio') {
         //add valor selecionado no state
         setSelectedPerfil(JSON.parse(e.target.value));
      }
   }

   //Adicionando novo perfil a lista de Perfis, para salvar no banco
   function addPerfilUsuario() {
      //Somente execulta se o selecte tiver algum valor valido
      if ((selectedLoja !== 'vazio') & (selectedPerfil !== 'vazio')) {
         //Add novo perfil defenido na lista
         setListPerfis([
            ...listPerfis,
            {
               lojaId: selectedLoja.lojaId,
               perfilId: selectedPerfil.perfilId,
               lojaDescricao: selectedLoja.descricao,
               perfilDescricao: selectedPerfil.descricao,
            },
         ]);

         //Removendo da lista do select a loja já utilizada pelo usuario
         const novaListaLojas = listLojas.filter(loja => {
            if (loja.lojaId != selectedLoja.lojaId) {
               return loja;
            }
         });
         //zerando state para não continuar salvando apos a lista de lojas acabar
         setSelectedLoja('vazio');

         //Atualizando a lista de Lojas sem a loja já selecionada
         setListLojas(novaListaLojas);
      }
   }

   //Excluir perfil da tabela e da lista de enviar para o banco
   function removePerfilUsuario(perfil) {
      //Remover da lista da tabela o item perfil(parametro)
      const novaListaPerfis = listPerfis.filter(perfilUser => {
         if (perfilUser.lojaId != perfil.lojaId) {
            return perfilUser;
         }
      });
      //Add nova novaListaPerfis sem o item exluido da tabela
      setListPerfis(novaListaPerfis);

      //Add o item removido a 'ListLojas', para que esse item retirado, volte para o selected para ser usado novamente
      setListLojas([
         ...listLojas,
         { lojaId: perfil.lojaId, nomeRazaoSocial: perfil.lojaDescricao },
      ]);
   }

   //Salando dados no banco
   function formControlUser() {
      const valuesOk = {
         nome: values.nome,
         celular: values.celular,
         email: values.email,
         resgatarPremio: !!values.resgatarPremio,
         senha: values.senha,
         limitePontuacaoMensal: values.limitePontuacaoMensal,
         exigirSenhaResgateCliente: values.exigirSenhaResgateCliente,
         plataformaId: values.plataformaId,
         perfilUsuarioLoja: listPerfis,
      };

      try {
         if (!edit) {
            const token = localStorage.getItem('@Fideleco:token');

            const config = {
               Authorization: `Bearer ${token}`,
            };
            setIsLoading(true);
            api.post('usuario', valuesOk, config)
               .then(response => {
                  if (response.status === 200) {
                     setIsOpen(false);
                     setTimeout(() => {
                        setIsLoading(false);
                        /*history.go(0);*/
                        toast.success('Usuário adicionado com sucesso!');
                        //Atualizando componente de listagem
                        getListaClientes();
                     }, 2000);
                  }
               })
               .catch(error => {
                  setIsLoading(false);

                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.error(error.response.data.error[0].message);
                  }
               });
         } else {
            if (values.senha === '') {
               delete values.senha;
            }

            valuesOk.usuarioId = values.usuarioId;
            setIsLoading(true);

            api.put('usuario', valuesOk)
               .then(response => {
                  if (response.status === 200) {
                     setIsOpen(false);
                     setTimeout(() => {
                        setIsLoading(false);

                        /*history.go(0);*/
                        toast.success('Usuário alterado com sucesso!');
                        //Atualizando componente de listagem
                        getListaClientes();
                     }, 2000);
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.error(error.response.data.error[0].message);
                  }
               });
         }
      } catch (error) {
         console.log(error);
      }
   }

   //Pegando os clientes
   const getListaClientes = async (currentPage, pageSize) => {
      setIsLoading(true);

      api.get('/usuario/paginacao', {
         params: {
            pagina: currentPage,
            tamanho: pageSize,
            ...termoBusca,
         },
      })
         .then(response => {
            if (response.status === 200) {
               setIsLoading(false);

               const { data } = response;
               setDataSource(data.data);

               setPagination(state => ({
                  ...state,
                  current: response.data.currentPage,
                  total: response.data.totalItems,
               }));
            }
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   const getListPerfilUsuarioLoja = async usuarioId => {
      setIsLoading(true);
      try {
         const getApi = await api.get(`/usuario/${usuarioId}`);
         if (getApi.status === 200) {
            const { data } = getApi;
            setIsLoading(false);
            return data.perfilUsuarioLoja;
         }
      } catch (error) {
         console.log('Erro ao buscar usuario por id');
         setIsLoading(true);
         return [];
      }
   };

   const removendoLojasSelecionadas = listaPerfisUsuarioLoja => {
      listaPerfisUsuarioLoja.filter(perfil => {
         const lojaAtual = listLojas.filter(
            loja => loja.lojaId === perfil.lojaId,
         );
         lojaAtual.filter(loja => {
            const indexLoja = listLojas.indexOf(loja);
            listLojas.splice(indexLoja, 1);
         });
      });

      setListPerfis(listaPerfisUsuarioLoja);
      //Add novaListaLojas ao state ListLojas sem os itens ja na tabela
      setListLojas(listLojas);
   };

   //Atualizando usuario
   const updateUser = async user => {
      //Add lista de perfilUsuarioLoja na tabela
      setIsLoading(true);
      setEdit(true);
      setIsOpen(true);
      setValues(user);
      console.log(user.usuarioId);

      //Pegando as lojas disponiveis da plataforma logada
      await getListLojas();

      //Pegando lista de perfis do usuario selecionado para editar
      try {
         const getApi = await api.get(`/usuario/${user.usuarioId}`);
         if (getApi.status === 200) {
            const { data } = getApi;
            setValues(data);
            //Remover do selected todos os itens que já estão na tabela
            removendoLojasSelecionadas(data.perfilUsuarioLoja);
         }
      } catch (error) {
         toast.error('Erro ao buscar usuario');
      } finally {
         setIsLoading(false);
      }
   };

   //Deletando usuario
   const deleteUser = user => {
      try {
         setIsLoading(true);
         const getId = user.usuarioId;
         api.delete(`usuario/${getId}`)
            .then(response => {
               if (response.status === 200) {
                  toast.success('Usuário excluído com sucesso');
                  setIsLoading(false);
                  setTimeout(() => {
                     history.go(0);
                  }, 2000);
               }
            })
            .catch(err => {
               setIsLoading(false);
               toast.error(
                  'Não é possível excluir, pois consta apenas um usuário ativo cadastrado na plataforma com perfil administrador.',
               );
            });
      } catch (error) {
         console.log(error);
      }
   };

   //Pegando a lista de perfis do banco
   const getListaPerfisUsuario = () => {
      try {
         api.get('/perfil')
            .then(response => {
               if (response.status === 200) {
                  const { data } = response;
                  setListPerfisUsuario(data);
               }
            })
            .catch(error => {
               toast.warning(error.response.data.error[0].message);
            });
      } catch (error) {
         console.log(error);
      }
   };

   //Pegando a lista de lojas desta plataforma do banco
   const getListLojas = async () => {
      try {
         const getApi = await api.get('loja');
         if (getApi.status === 200) {
            const { data } = getApi;

            setListLojas(data);
         }
      } catch (error) {}
   };

   useEffect(() => {
      getListaClientes(pagination.current, pagination.pageSize);
   }, [edit]);

   useEffect(() => {
      getListaPerfisUsuario();
      getListLojas();
   }, []);

   return (
      <>
         <PageTitle
            heading="Gerenciar usuários"
            subheading="Crie e gerencia seus usuários"
            icon="pe-7s-users icon-gradient bg-fideleco">
            <div>
               <ModalCustom
                  titleModal={`${
                     edit ? 'Alterar usuário' : 'Cadastrar novo usuário'
                  }`}
                  buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
                  buttonIcon={
                     <i
                        onClick={closeModal}
                        className="pe-7s-add-user btn-icon-wrapper font-weight-bold"></i>
                  }
                  buttonName="Criar usuário"
                  buttonSize="lg"
                  buttonColor="primary"
                  toggleFunc={openModal}
                  isOpen={modalIsOpen}
                  size="lg"
                  closeModal={closeModal}>
                  <Form
                     onSubmit={handleSubmit}
                     noValidate
                     className="text-left">
                     <Row className="mt-3" form>
                        <Col md={6}>
                           <FormGroup>
                              <label className="mb-0">Nome</label>
                              <InputIcon
                                 type="text"
                                 size={12}
                                 handleChange={handleChange}
                                 name="nome"
                                 value={values.nome || ''}
                              />
                              {errors.nome && (
                                 <p className="is-danger align-message ml-0">
                                    {errors.nome}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                        <Col md={6}>
                           <FormGroup>
                              <label>Celular</label>

                              <InputMask
                                 mask="(99) 99999-9999"
                                 value={values.celular || ''}
                                 onChange={handleChange}
                                 name="celular">
                                 {inputProps => (
                                    <TextField
                                       {...inputProps}
                                       type="text"
                                       style={{ width: '100%' }}
                                    />
                                 )}
                              </InputMask>

                              {errors.celular && (
                                 <p className="is-danger align-message ml-0">
                                    {errors.celular}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row className="mt-3" form>
                        <Col md={6}>
                           <FormGroup>
                              <label className="mb-0">E-Mail</label>
                              <InputIcon
                                 type="text"
                                 size={12}
                                 handleChange={handleChange}
                                 name="email"
                                 value={values.email || ''}
                              />
                              {errors.email && (
                                 <p className="is-danger align-message ml-0">
                                    {errors.email}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                        <Col md={6}>
                           <FormGroup>
                              <label className="mb-0">
                                 Limite de pontuação mensal
                              </label>
                              <InputIcon
                                 type="number"
                                 size={12}
                                 handleChange={handleChange}
                                 name="limitePontuacaoMensal"
                                 value={values.limitePontuacaoMensal}
                              />
                              {errors.limitePontuacaoMensal && (
                                 <p className="is-danger align-message ml-0">
                                    {errors.limitePontuacaoMensal}
                                 </p>
                              )}
                              <div>
                                 Informe 0 (zero) para desligar essa função.
                              </div>
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row className="mt-3" form>
                        <Col md={6}>
                           <FormGroup className="text-left">
                              <label className="mb-0">Senha</label>
                              <InputIcon
                                 type={mostraSenha ? 'text' : 'password'}
                                 size={12}
                                 name="senha"
                                 handleChange={handleChange}
                                 value={values.senha || ''}
                                 id="senha">
                                 <img
                                    src={
                                       mostraSenha
                                          ? RevelaSenhaIcon
                                          : EscondeSenhaIcon
                                    }
                                    alt="Mostrar senha"
                                    className="mostra-senha-icon"
                                    style={{ right: '3%' }}
                                    onClick={() => setMostraSenha(!mostraSenha)}
                                 />
                              </InputIcon>
                              {errors.senha && (
                                 <p className="is-danger align-message ml-0">
                                    {errors.senha}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                        <Col md={6}>
                           <FormGroup className="text-left">
                              <label className="mb-0">Confirme sua senha</label>

                              <InputIcon
                                 type={
                                    mostraConfirmaSenha ? 'text' : 'password'
                                 }
                                 size={12}
                                 handleChange={handleChange}
                                 value={values.passwordConfirm || ''}
                                 id="passwordConfirm"
                                 name="passwordConfirm">
                                 <img
                                    src={
                                       mostraConfirmaSenha
                                          ? RevelaSenhaIcon
                                          : EscondeSenhaIcon
                                    }
                                    alt="Mostrar senha"
                                    className="mostra-senha-icon"
                                    style={{ right: '3%' }}
                                    onClick={() =>
                                       setMostraConfirmaSenha(
                                          !mostraConfirmaSenha,
                                       )
                                    }
                                 />
                              </InputIcon>
                              {errors.passwordConfirm && (
                                 <p className="is-danger align-message ml-0">
                                    {errors.passwordConfirm}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row className="mt-3" form>
                        <Col md={6}>
                           <FormGroup>
                              <label>Lojas</label>
                              <InputSelect
                                 name="perfilUsuarioLoja"
                                 onChange={handleChangeSelectedLoja}>
                                 <option value="vazio">
                                    Selecione uma opção...
                                 </option>

                                 {listLojas.map((loja, index) => (
                                    <option
                                       key={index}
                                       value={JSON.stringify({
                                          lojaId: loja.lojaId,
                                          descricao: loja.nomeRazaoSocial,
                                       })}>
                                       {loja.nomeRazaoSocial}
                                    </option>
                                 ))}
                              </InputSelect>

                              {errors.perfilUsuarioLoja && (
                                 <p className="is-danger align-message ml-0">
                                    {errors.perfilUsuarioLoja}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>

                        <Col md={6}>
                           <FormGroup>
                              <label>Perfil</label>
                              <InputSelect
                                 name="perfil"
                                 onChange={handleChangeSelectedPerfil}>
                                 {listPerfisUsuario.map((v, index) => (
                                    <option
                                       key={index}
                                       value={JSON.stringify({
                                          perfilId: v.perfilId,
                                          descricao: v.descricao,
                                       })}>
                                       {v.descricao}
                                    </option>
                                 ))}
                              </InputSelect>
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row>
                        <Col md={12}>
                           <button
                              type="button"
                              onClick={addPerfilUsuario}
                              className="btn btn-primary">
                              Salvar
                           </button>
                        </Col>
                     </Row>
                     <Row className="mt-5 mb-5">
                        <Col md={12}>
                           <TableSemFilter
                              columns={columnsPerfis}
                              dataSource={listPerfis}
                              size="small"
                              loading={isLoading}
                           />
                        </Col>
                     </Row>
                     <Button
                        color="link"
                        disabled={isLoading}
                        onClick={closeModal}>
                        {' '}
                        Cancel{' '}
                     </Button>
                     <Button color="primary" disabled={isLoading} type="submit">
                        {isLoading ? (
                           <>
                              {edit ? 'Atualizando' : 'Criando'}{' '}
                              {<LoadingButton size={15} />}
                           </>
                        ) : (
                           <>{edit ? 'Atualizar' : 'Criar'} </>
                        )}{' '}
                     </Button>
                  </Form>
               </ModalCustom>
            </div>
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <PaginationFilterTable
                           columns={columns}
                           rowKey={record => record.nome}
                           dataSource={dataSource}
                           pagination={pagination}
                           loading={isLoading}
                           onChange={handleTableChange}
                           handleSubmit={getListaClientes}
                           filterChildren={[
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.nome}
                                 onChange={e =>
                                    handleChangePerson('nome', e.target.value)
                                 }
                                 label="Nome"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.email}
                                 onChange={e =>
                                    handleChangePerson('email', e.target.value)
                                 }
                                 label="Email"
                              />,
                           ]}
                        />
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default ListaUsuario;
