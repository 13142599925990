import React, { useContext, createContext, useCallback, useState } from 'react';
import api from '../api/api-local';
const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
   const [data, setData] = useState(() => {
      const token = localStorage.getItem('@Fideleco:token');
      const usuario = localStorage.getItem('@Fideleco:user');
      if (token && usuario) {
         return { token, usuario: JSON.parse(usuario) };
      }
      return {};
   });

   const sigIn = useCallback(async ({ celular, senha, lojaId }) => {
      const response = await api.post('login', {
         celular,
         senha,
         lojaId,
      });
      const {
         token,
         plataforma,
         usuario,
         etapaConfiguracaoPlataforma,
         configuracaoPlataforma,
         statusJornadaCadastroPlataforma,
      } = response.data;

      usuario.plataforma = plataforma;
      usuario.etapaConfiguracaoPlataforma = etapaConfiguracaoPlataforma;
      usuario.configuracaoPlataforma = configuracaoPlataforma;
      usuario.statusJornadaCadastroPlataforma = statusJornadaCadastroPlataforma;

      localStorage.setItem('@Fideleco:token', token);
      localStorage.setItem('@Fideleco:user', JSON.stringify(usuario));

      //recarregar a pagina para que o freshchat pegue os dados atualizado para o chat

      setData({ token, usuario });
   }, []);

   const signOut = useCallback(() => {
      localStorage.removeItem('@Fideleco:token');
      localStorage.removeItem('@Fideleco:user');
      //recarregar a pagina para que o freshchat pegue os dados atualizado para o chat
      // window.location.reload();
      setData({});
   }, []);
   return (
      <AuthContext.Provider
         value={{
            usuario: data.usuario,
            sigIn,
            signOut,
         }}>
         {children}
      </AuthContext.Provider>
   );
};

const useAuth = () => {
   const context = useContext(AuthContext);
   if (!context) {
      throw new Error('Usuário errado');
   }
   return context;
};

export { AuthProvider, useAuth };
