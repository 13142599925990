export default function validateFormCadastroLogista(values) {
   let errors = {};
   if (!values.email) {
      errors.email = 'O campo email é obrigatório';
   } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Digite um email válido!';
   }

   if (!values.senha) {
      errors.senha = 'O campo senha é obrigatório';
   } else if (values.senha.length > 100) {
      errors.senha = 'A senha precisa ter até 100 caracteres';
   }

   if (values.senha !== values.passwordConfirm) {
      errors.passwordConfirm = 'Senhas não conferem';
   }

   if (!values.nome) {
      errors.nome = 'O campo nome é obrigatório';
   }
   if (!values.celular) {
      errors.celular = 'O campo celular é obrigatório';
   } else if (values.senha.length > 100) {
      errors.senha = 'Você precisa digitar até 100 caracteres';
   }

   if (!values.enderecoWebSite) {
      errors.enderecoWebSite =
         'O campo endereço website fideleco é obrigatório';
   }

   if (!values.termoPrivacidade) {
      errors.termoPrivacidade = 'ATENÇÃO: Você precisa aceitar os termos!';
   }
   return errors;
}
