import React from 'react';

// components
import InputFile from '../../../components/Formik/InputFile';
import { Column } from './FormLoja';

const InputImages = props => {
   const { formikProps } = props;

   return (
      <>
         <Column
            md={6}
            className="d-flex flex-column justify-content-center align-items-center">
            <p>Imagem principal para o App: </p>
            <small>Tamanho indicado: 187x285 </small>
            {!!formikProps.values.imagemDestaque && (
               <div>
                  <div className="banner-wrapper">
                     <img
                        style={{
                           width: 187,
                           height: 285,
                           objectFit: 'cover',
                           border: '4px dashed #3339',
                           margin: '10px 0',
                        }}
                        className="playerProfilePic_home_tile"
                        alt=""
                        src={formikProps.values.imagemDestaque}
                     />
                  </div>
               </div>
            )}
            <InputFile
               type="file"
               style={{
                  width: '200px',
                  padding: '10px',
                  margin: '0',
                  borderRadius: '5px',
                  backgroundColor: 'transparent',
                  color: '#495057',
                  borderStyle: 'dashed',
                  borderColor: '#495057',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
               }}
               justifyContent="center"
               label={
                  !!formikProps.values.imagemDestaque
                     ? 'Alterar imagem'
                     : 'Escolher imagem'
               }
               onChange={props.onImagemDestaqueChange}
               accept="image/x-png,image/gif,image/jpeg,image/jpg"
               name="imagemDestaqueInput"
            />
         </Column>
         <Column
            md={6}
            className="d-flex flex-column justify-content-center align-items-center">
            <p>Imagem secundária para o App: </p>
            <small>Tamanho indicado: 187x285 </small>
            {!!formikProps.values.imagemDetalhe && (
               <div>
                  <div className="banner-wrapper">
                     <img
                        style={{
                           width: 187,
                           height: 285,
                           objectFit: 'cover',
                           border: '4px dashed #3339',
                           margin: '10px 0',
                        }}
                        className="playerProfilePic_home_tile"
                        alt=""
                        src={formikProps.values.imagemDetalhe}
                     />
                  </div>
               </div>
            )}
            <InputFile
               type="file"
               style={{
                  width: '200px',
                  padding: '10px',
                  margin: '0',
                  borderRadius: '5px',
                  backgroundColor: 'transparent',
                  color: '#495057',
                  borderStyle: 'dashed',
                  borderColor: '#495057',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
               }}
               justifyContent="center"
               label={
                  !!formikProps.values.imagemDetalhe
                     ? 'Alterar imagem'
                     : 'Escolher imagem'
               }
               onChange={props.onImagemDetalheChange}
               accept="image/x-png,image/gif,image/jpeg,image/jpg"
               name="imagemDetalheInput"
            />
         </Column>
      </>
   );
};

export default InputImages;
