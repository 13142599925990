import React, { useState, useEffect } from 'react';
//libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { toast } from 'react-toastify';
//Components
import PageTitle from '../layout/AppMain/PageTitle';

import { Atalhos } from './atalhos';
import { Link } from 'react-router-dom';

import { useAuth } from '../../customHooks/AuthContext';

import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { CustumRow } from './styles';

const HomeAdmin = () => {
   const { usuario } = useAuth();
   const [isAdmin, setIsAdmin] = useState([]);
   const { statusJornadaCadastroPlataforma } = usuario;

   const cardsEnabled =
      statusJornadaCadastroPlataforma?.configuracaoLojaConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPersonalizacaoConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPremioConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoProgramaConcluida;

   useEffect(() => {
      usuario.lojas[0].perfilId === 2
         ? setIsAdmin(Atalhos.splice(0, 2))
         : setIsAdmin(Atalhos);
   }, []);

   const alert = () => {
      return toast.warning(
         'Para acessar essa etapa é necessário preencher as configurações de prêmio e programa, disponíveis no menu a esquerda',
      );
   };

   return (
      <>
         <PageTitle
            heading={`Olá, ${usuario.nome}!`}
            subheading={`Você esta logado com a loja ${usuario.lojas[0].nomeRazaoSocial.trim()}.`}
            className="style-text-home"
            icon="pe-7s-home icon-gradient bg-fideleco"
         />

         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            {/*<Row>
               <Col md="3">
                  <a
                     style={{ textDecoration: 'none' }}
                     target="_blank"
                     href={usuario.plataforma.urlHotsite}>
                     <Card
                        className="main-card mb-3 text-left text-white customize-card-home"
                        style={{
                           backgroundColor: '#FA657E',
                           cursor: 'pointer',
                        }}>
                        <CardBody style={{ height: '120px' }}>
                           <CardTitle className="text-white">
                              Acessar Hotsite
                           </CardTitle>
                           <CardText>
                              Clique aqui para acessar o seu Hotsite
                           </CardText>
                        </CardBody>
                     </Card>
                  </a>
               </Col>
               <Col md="3">
                  <Card
                     className="main-card mb-3 text-left text-white customize-card-home"
                     style={{ backgroundColor: '#FA657E', cursor: 'pointer' }}>
                     <CardBody style={{ height: '120px' }}>
                        <Link
                           style={{ color: 'white', textDecoration: 'none' }}
                           to="/clientes/validar-premios">
                           <CardTitle className="text-white">
                              Validar Prêmio
                           </CardTitle>
                           <CardText>
                              Valide seus prêmios e premie seus clientes
                           </CardText>
                        </Link>
                     </CardBody>
                  </Card>
               </Col>
               <Col md="3">
                  <Card
                     className="main-card mb-3 text-left text-white customize-card-home"
                     style={{ backgroundColor: '#FA657E', cursor: 'pointer' }}>
                     <Link
                        style={{ color: 'white', textDecoration: 'none' }}
                        to="/clientes/pontuar">
                        <CardBody style={{ height: '120px' }}>
                           <CardTitle className="text-white">Pontuar</CardTitle>
                           <CardText>
                              Acesse aqui para começar a pontuar
                           </CardText>
                        </CardBody>
                     </Link>
                  </Card>
               </Col>
               <Col md="3">
                  <Card
                     className="main-card mb-3 text-left text-white customize-card-home"
                     style={{ backgroundColor: '#FA657E', cursor: 'pointer' }}>
                     <Link
                        style={{ color: 'white', textDecoration: 'none' }}
                        to="/clientes/estornar-clientes">
                        <CardBody style={{ height: '120px' }}>
                           <CardTitle className="text-white">
                              Estornar Pontuação
                           </CardTitle>
                           <CardText>
                              Acesse aqui para configurar o estorno da sua
                              pontuação
                           </CardText>
                        </CardBody>
                     </Link>
                  </Card>
               </Col>
            </Row>*/}

            <CustumRow
               className="d-flex justify-content-left"
               style={{
                  '@media (max-width: 768px)': {
                     justifyContent: 'center',
                     margin: 0,
                  },
               }}>
               <Card
                  className={`main-card m-2 text-left text-white customize-card-home`}
                  style={{
                     backgroundColor: '#FA657E',
                     opacity: 1,
                     cursor: 'pointer',
                     width: '15rem',
                     height: '10rem',
                  }}>
                  <a
                     style={{
                        color: 'white',
                        textDecoration: 'none',
                        cursor: 'pointer',
                     }}
                     target={'_blank'}
                     href={usuario.plataforma.urlHotsite}>
                     <CardBody>
                        <CardTitle className="text-white">
                           Acessar Hotsite
                        </CardTitle>
                        <CardText>
                           Clique aqui para acessar o seu Hotsite
                        </CardText>
                     </CardBody>
                  </a>
               </Card>
               {isAdmin.map(atalho => (
                  <Card
                     className={`main-card m-2 text-left text-white ${
                        cardsEnabled ? 'customize-card-home' : ''
                     }`}
                     style={{
                        backgroundColor: '#FA657E',
                        opacity: cardsEnabled ? 1 : 0.4,
                        cursor: cardsEnabled ? 'pointer' : 'default',
                        width: '15rem',
                        height: '10rem',
                     }}>
                     {!cardsEnabled ? (
                        <a
                           style={{
                              color: 'white',
                              textDecoration: 'none',
                              cursor: cardsEnabled ? 'pointer' : 'default',
                           }}
                           onClick={alert}>
                           <CardBody>
                              <CardTitle className="text-white">
                                 {atalho.title}
                              </CardTitle>
                              <CardText>{atalho.subtitle}</CardText>
                           </CardBody>
                        </a>
                     ) : (
                        <Link
                           style={{
                              color: 'white',
                              textDecoration: 'none',
                              cursor: cardsEnabled ? 'pointer' : 'default',
                           }}
                           to={atalho.to}>
                           <CardBody>
                              <CardTitle className="text-white">
                                 {atalho.title}
                              </CardTitle>
                              <CardText>{atalho.subtitle}</CardText>
                           </CardBody>
                        </Link>
                     )}
                  </Card>
               ))}
            </CustumRow>
         </CSSTransitionGroup>
      </>
   );
};

export default HomeAdmin;
