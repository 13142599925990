import React, { useState, useEffect } from 'react';

//libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Tooltip } from 'reactstrap';

//Components
import validateFormFidelidade from '../../helpers/validateFormFidelidade';
import TooltipPng from '../../components/tooltipPng/TooltipPng';
import InputIcon from '../../components/Formulario/InputIcon';
import InputFile from '../../components/Formulario/InputFile';
import { useAuth } from '../../customHooks/AuthContext';
import Spinner from '../../components/loading/Loading';
import PageTitle from '../layout/AppMain/PageTitle';
import useForm from '../../customHooks/useForm';
import api from '../../api/api-local';
import { toast } from 'react-toastify';

import { Row, Col, Form, FormGroup, Card, Label, Button } from 'reactstrap';

const config = {
   headers: {
      'Content-Type': 'multipart/form-data',
   },
};

const CustomProgramaFidelidade = () => {
   const [picture, setPicture] = useState([]);
   const [imgData, setImgData] = useState(null);
   const { usuario, signOut } = useAuth();
   const [isLoading, setIsLoading] = useState(false);

   const [tooltipOpen01, setTooltipOpen01] = useState(false);
   const toggleTooltip01 = () => setTooltipOpen01(!tooltipOpen01);

   const initialValues = {
      nome: '',
      plataformaId: usuario.plataforma.plataformaId,
      cor: '',
      corTexto: '',
   };

   const { values, errors, handleChange, handleSubmit, setValues } = useForm(
      initialValues,
      formControlUser,
      validateFormFidelidade,
   );

   function formControlUser() {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('nome', values.nome === false ? '' : values.nome); //append the values with key, value pair
      formData.append('plataformaId', values.plataformaId);

      formData.append('cor', values.cor);
      formData.append('corTexto', values.corTexto);
      formData.append('logo', picture);

      api.put('plataforma/personalizar', formData, config)
         .then(response => {
            if (response.status === 200) {
               setTimeout(() => {
                  setIsLoading(false);
                  toast.success('Dados alterados com sucesso!');
               }, 2000);
            }
         })
         .catch(error => {
            setIsLoading(false);
            error.response.data.error.map(i => {
               toast.error(i.message);
            });
         });
   }

   const updateUser = plataformaId => {
      try {
         setIsLoading(true);

         api.get(`/plataforma/personalizar/${plataformaId}`)
            .then(response => {
               if (response.status === 200 && plataformaId) {
                  setIsLoading(false);
                  setValues(response.data);
                  setPicture(response.data.logo);
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 2000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } catch (error) {}
   };

   //Transformando a img para base64
   const onChangePicture = e => {
      const element = Array.from(e.target.files);

      //Aquivo de img com todas infos
      var file = element[0];

      //salvando file no estado para mandar para o banco
      setPicture(file);

      //Setando valor no values para não da erro de campo em branco
      setValues(values => ({
         ...values,
         logo: file,
      }));

      //Convertendo img files para base64
      if (e.target.files) {
         var reader = new FileReader();
         reader.onloadend = function () {
            //salbado base64
            setImgData(reader.result);
         };
         reader.readAsDataURL(file);
      }
   };

   useEffect(() => {
      updateUser(usuario.plataforma.plataformaId);
   }, []);
   return (
      <>
         <PageTitle
            heading="Personalizar Programa de Fidelidade"
            subheading="Personalize sua loja"
            icon="pe-7s-users icon-gradient bg-fideleco"></PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <Form style={{ padding: '20px' }} onSubmit={handleSubmit}>
                        <Row form>
                           <Col md={6} className="mb-3">
                              <InputIcon
                                 type="text"
                                 label="Nome da plataforma"
                                 size={12}
                                 handleChange={handleChange}
                                 name="nome"
                                 value={values.nome || ''}
                              />

                              {errors.nome && (
                                 <p className="is-danger align-message">
                                    {errors.nome}
                                 </p>
                              )}
                           </Col>

                           <Col md={6} className="mb-3">
                              <FormGroup>
                                 <InputIcon
                                    id="inputColor"
                                    type="color"
                                    label="Cor predominante"
                                    size={12}
                                    handleChange={handleChange}
                                    name="cor"
                                    value={values.cor || '#F65858'}>
                                    <Label
                                       style={{
                                          cursor: 'pointer',
                                          color: '#FD5958',
                                          paddingLeft: '5px',
                                       }}
                                       htmlFor="inputColor">
                                       Escolher
                                    </Label>
                                 </InputIcon>

                                 {errors.cor && (
                                    <p className="is-danger align-message">
                                       {errors.cor}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>

                           <Col md={6} className="mb-3">
                              <FormGroup>
                                 <InputIcon
                                    id="cor-texto"
                                    type="color"
                                    label="Cores de texto de botões"
                                    size={12}
                                    handleChange={handleChange}
                                    name="corTexto"
                                    value={values.corTexto || '#F65858'}>
                                    <Label
                                       style={{
                                          cuwrsor: 'pointer',
                                          color: '#FD5958',
                                          paddingLeft: '5px',
                                       }}
                                       htmlFor="cor-texto">
                                       Escolher
                                    </Label>
                                 </InputIcon>

                                 {errors.corTexto && (
                                    <p className="is-danger align-message">
                                       {errors.corTexto}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>

                           <Col md={6} className="mb-3">
                              <FormGroup>
                                 <InputFile
                                    type="file"
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                    size={10}
                                    handleChange={onChangePicture}
                                    name="logo"
                                    id="profilePic"
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row form>
                           <Col md={12} className="mb-5">
                              <FormGroup>
                                 <label>Veja como está ficando:</label>
                                 <div className="mb-3">
                                    <div
                                       className="style-cor-predominante"
                                       style={{
                                          background: `${
                                             values.cor || '#F65858'
                                          }`,
                                          boxShadow:
                                             '0 0.46875rem 2.1875rem rgb(85 2 1 / 5%)',
                                          height: '80px',
                                          borderRadius: '2px',
                                          position: 'relative',
                                          padding: '5px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'start',
                                       }}>
                                       {imgData !== null ||
                                       picture?.length > 0 ? (
                                          <img
                                             className="playerProfilePic_home_tile"
                                             alt="logotipo"
                                             style={{
                                                width: '140px',
                                                height: '90%',
                                                position: 'relative',
                                                left: '1rem',
                                             }}
                                             src={
                                                imgData ||
                                                `data:image/*;base64,${picture}`
                                             }
                                          />
                                       ) : (
                                          <InputFile
                                             type="file"
                                             style={{
                                                padding: '10px',
                                                margin: '0',
                                                marginLeft: '1rem',
                                                borderRadius: '5px',
                                                backgroundColor: 'transparent',
                                                color: '#495057',
                                                borderStyle: 'dashed',
                                                borderColor: '#495057',
                                                textTransform: 'uppercase',
                                                textAlign: 'center',
                                                display: 'block',
                                                cursor: 'pointer',
                                             }}
                                             text="Clique e escolha sua Logotipo"
                                             accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                             size={10}
                                             handleChange={onChangePicture}
                                             name="logo"
                                             id="profilePic"
                                          />
                                       )}
                                    </div>

                                    <InputFile
                                       type="file"
                                       style={{
                                          margin: '0',
                                          color: '#FD5958',
                                          cursor: 'pointer',
                                       }}
                                       text="Alterar imagem"
                                       accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                       size={10}
                                       handleChange={onChangePicture}
                                       name="logo"
                                       id="profilePic"
                                    />
                                 </div>
                                 {errors.logo && (
                                    <p className="is-danger align-message m-0">
                                       {errors.logo}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>
                        </Row>

                        <div className="d-flex align-items-center">
                           <div className="mr-auto">
                              <Button
                                 color="primary"
                                 disabled={isLoading}
                                 type="submit"
                                 className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                 size="lg">
                                 Salvar
                              </Button>
                           </div>
                        </div>
                     </Form>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default CustomProgramaFidelidade;
