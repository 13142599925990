import React, { Fragment, useEffect } from 'react';
import Layout from '../pages/layout/Layout';
import MainHeader from '../components/header/Header';
import {
   Route as ReactDOMRoute,
   Redirect,
   Router,
   useHistory,
} from 'react-router-dom';
import { useAuth } from '../customHooks/AuthContext';
import AppHeader from '../pages/layout/AppHeader/';
import AppFooter from '../pages/layout/AppFooter/';
import { Alert } from 'reactstrap';

const user = JSON.parse(localStorage.getItem('@Fideleco:user'));
const isFase = /*user.etapaConfiguracaoPlataforma;*/ false;

const RoutePrivate = ({ isPrivate = false, component: Component, ...rest }) => {
   const { usuario } = useAuth();
   const history = useHistory();

   return (
      <>
         <ReactDOMRoute
            {...rest}
            render={({ ...props }) => {
               let hideHeader =
                  props.location.pathname === '/' ||
                  props.location.pathname === '/cadastro-admin' ||
                  props.location.pathname === '/cadastro/empresa' ||
                  props.location.pathname === '/404' ? null : (
                     <Fragment>
                        <AppHeader />
                     </Fragment>
                  );
               let hideAside =
                  props.location.pathname === '/' ||
                  props.location.pathname === '/cadastro-admin' ||
                  props.location.pathname === '/cadastro/empresa' ||
                  props.location.pathname === '/404' ? null : (
                     <Fragment>
                        <Layout {...props}></Layout>
                     </Fragment>
                  );
               return isPrivate === !!usuario ? (
                  <Fragment>
                     {hideHeader}
                     <div className="app-main">
                        {hideAside}
                        <div className="app-main__outer">
                           <div
                              className={`${
                                 props.location.pathname === '/'
                                    ? ''
                                    : 'app-main__inner'
                              }`}>
                              <Component />
                           </div>
                        </div>
                     </div>
                     <AppFooter />
                  </Fragment>
               ) : (
                  <Redirect
                     to={{
                        pathname:
                           (!isPrivate && '/home-admin') || (isPrivate && '/'),
                     }}
                  />
               );
            }}
         />
      </>
   );
};

export default RoutePrivate;
