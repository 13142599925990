import React, { useState, useEffect } from 'react';

//Components
import { MainNavAdmin, MainNavOperator, MainNavRelatorios } from './NavItems';
import { useAuth } from '../../customHooks/AuthContext';

//Libs
import MetisMenu from 'react-metismenu';
import { Alert } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
const SideBar = () => {
   const [newNavAdmin, setNewNavAdmin] = useState([]);
   const [newNavRelatorios, setNewNavRelatorios] = useState([]);

   const history = useHistory();
   const { signOut, usuario } = useAuth();
   const { statusJornadaCadastroPlataforma } = usuario;

   useEffect(() => {
      if (usuario?.configuracaoPlataforma?.cupomValePontoHabilitado)
         return setNewNavAdmin([...MainNavAdmin]);

      const nav = MainNavAdmin.filter(data => {
         if (data.to === '/configuracao/gerar-cupom') return;
         return data;
      });

      setNewNavAdmin([...nav]);
   }, []);

   useEffect(() => {
      if (usuario?.configuracaoPlataforma?.cupomValePontoHabilitado)
         return setNewNavRelatorios([...MainNavRelatorios]);

      const nav = MainNavRelatorios.filter(data => {
         if (data.to === '/relatorios/cupom-vale-pontos') return;
         return data;
      });

      setNewNavRelatorios([...nav]);
   }, []);

   useEffect(() => {
      if (!statusJornadaCadastroPlataforma) signOut();
   }, [statusJornadaCadastroPlataforma]);

   return (
      <div className="app-sidebar-menus">
         <MetisMenu
            content={[
               {
                  id: 1,
                  icon: 'pe-7s-dashboard',
                  label: 'Menu Principal',
                  to: '/home-admin',
               },
            ]}
            activeLinkFromLocation
            className="vertical-nav-menu mt-3"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
         />

         {statusJornadaCadastroPlataforma?.configuracaoLojaConcluida &&
            statusJornadaCadastroPlataforma?.configuracaoPersonalizacaoConcluida &&
            statusJornadaCadastroPlataforma?.configuracaoPremioConcluida &&
            statusJornadaCadastroPlataforma?.configuracaoProgramaConcluida && (
               <>
                  <MetisMenu
                     content={
                        usuario.lojas[0].perfilId === 1
                           ? newNavAdmin
                           : MainNavOperator
                     }
                     activeLinkFromLocation
                     className="vertical-nav-menu "
                     iconNamePrefix=""
                     classNameStateIcon="pe-7s-angle-down"
                  />

                  <MetisMenu
                     // content={
                     //    usuario.lojas[0].perfilId === 1
                     //       ? MainNavRelatorios
                     //       : MainNavRelatorios.slice(0, 2)
                     // }
                     content={
                        usuario.lojas[0].perfilId === 1
                           ? newNavRelatorios
                           : newNavRelatorios.slice(0, 2)
                     }
                     activeLinkFromLocation
                     className="vertical-nav-menu"
                     iconNamePrefix=""
                     classNameStateIcon="pe-7s-angle-down"
                  />
               </>
            )}

         {(!statusJornadaCadastroPlataforma?.configuracaoPremioConcluida ||
            !statusJornadaCadastroPlataforma?.configuracaoProgramaConcluida ||
            !statusJornadaCadastroPlataforma?.configuracaoLojaConcluida ||
            !statusJornadaCadastroPlataforma?.configuracaoPersonalizacaoConcluida) && (
            <Alert color="warning" className="mr-3 ml-3 mt-4">
               <p c>⚠️ Loja pendente de configurações:</p>
               <ul>
                  {!statusJornadaCadastroPlataforma?.configuracaoLojaConcluida && (
                     <li>
                        <Link to="/cadastro/empresa">Configurar Loja</Link>
                     </li>
                  )}
                  {!statusJornadaCadastroPlataforma?.configuracaoPersonalizacaoConcluida && (
                     <li>
                        <Link to="/cadastro/empresa">
                           Configurar Personalização
                        </Link>
                     </li>
                  )}
                  {!statusJornadaCadastroPlataforma?.configuracaoPremioConcluida && (
                     <li>
                        <Link to="/fidelizar/gerenciar-premios">
                           Configurar Prêmio
                        </Link>
                     </li>
                  )}
                  {!statusJornadaCadastroPlataforma?.configuracaoProgramaConcluida && (
                     <li>
                        <Link to="/configuracao/configurar-programa-de-fidelidade">
                           Configurar Programa
                        </Link>
                     </li>
                  )}
               </ul>
            </Alert>
         )}
      </div>
   );
};

export default SideBar;
