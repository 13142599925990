import React, { Fragment, useEffect, useState } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Card, Row, Col } from 'reactstrap';

import MultiStep from './Wisard';
import { connect } from 'react-redux';

import PageTitle from '../layout/AppMain/PageTitle';

const FormWizardVar1 = props => {
   const [state, setState] = useState({
      cSelected: [],
      dropdownOpen: false,
   });

   useEffect(() => {
      toggle = toggle.bind(this);
      onMouseEnter = onMouseEnter.bind(this);
      onMouseLeave = onMouseLeave.bind(this);
      onCheckboxBtnClick = onCheckboxBtnClick.bind(this);
   }, []);

   function toggle() {
      setState(prevState => ({
         dropdownOpen: !prevState.dropdownOpen,
      }));
   }

   function onMouseEnter() {
      setState({ dropdownOpen: true });
   }

   function onMouseLeave() {
      setState({ dropdownOpen: false });
   }

   function onCheckboxBtnClick(selected) {
      const index = state.cSelected.indexOf(selected);
      if (index < 0) {
         state.cSelected.push(selected);
      } else {
         state.cSelected.splice(index, 1);
      }
      setState({ cSelected: [...state.cSelected] });
   }

   return (
      <Fragment>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <div>
               <PageTitle
                  heading="Cadastro da empresa"
                  subheading="Realize as etapas para ter acesso à todas as configurações do Fideleco!"
                  icon="pe-7s-id text-primary"
               />
               <Row>
                  <Col md="12" lg="12">
                     <Card className="main-card mb-12">
                        <MultiStep
                           showNavigation={true}
                           valueNextStep={props.FideActionTypes.valueStepWizard}
                        />
                     </Card>
                  </Col>
               </Row>
            </div>
         </CSSTransitionGroup>
      </Fragment>
   );
};
function mapStateToProps(state) {
   const { FideActionTypes } = state;
   return {
      FideActionTypes,
   };
}

export default connect(mapStateToProps)(FormWizardVar1);
