import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from 'unform';

export default function InputSelect({ name, label, erro, ...rest }) {
   const selectRef = useRef(null);
   const { fieldName, defaultValue, registerField, error } = useField(name);

   useEffect(() => {
      registerField({
         name: fieldName,
         ref: selectRef.current,
         getValue: ref => {
            if (rest.isMulti) {
               if (!ref.state.value) {
                  return [];
               }
               return ref.state.value.map(option => option.value);
            }
            if (!ref.state.value) {
               return '';
            }
            return ref.state.value.value;
         },
      });
   }, [fieldName, registerField, rest.isMulti]);
   return (
      <div className="input-componente">
         <p className="input-title">{label}</p>
         <ReactSelect
            defaultValue={defaultValue}
            ref={selectRef}
            classNamePrefix="react-select"
            label={label}
            {...rest}
         />
         {erro && <div className="erro">{erro}</div>}
      </div>
   );
}
