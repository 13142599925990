const cleanNumericValues = value => {
   const valueWithoutLettersAndDots = String(value).replace(/[^0-9\,]+/g, '');
   const valueReplaceCommaWithDot = valueWithoutLettersAndDots.replace(
      ',',
      '.',
   );
   return valueReplaceCommaWithDot;
};

export default cleanNumericValues;
