import React, { useState, useEffect } from 'react';
import useForm from '../../../customHooks/useForm';
import api from '../../../api/api-local';
import { useAuth } from '../../../customHooks/AuthContext';

//Components
import validateFormGerarCupom from '../../../helpers/validateFormGerarCupom';
import InputIcon from '../../../components/Formulario/InputIcon';
import ModalCustom from '../../../components/Modal/Modal';
import Spinner from '../../../components/loading/Loading';
import PageTitle from '../../layout/AppMain/PageTitle';
import Table from '../../../components/FilterTable';

//Libs
import { Row, Col, Card, CardBody, Button, Form, FormGroup } from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { mask as masker, unMask } from 'remask';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import LoadingButton from '../../../components/LoadingButton';

const formataData = data => {
   return data.split('/').reverse().join('-');
};

const GerarCupom = () => {
   const { signOut } = useAuth();
   const [isLoading, setIsLoading] = useState(false);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [desativar, setDesativar] = useState(false);
   const [cpfCnpj, setCpfCnpj] = useState('');
   const [edit, setEdit] = useState(false);
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50'],
      total: 0,
   });
   const [allCupons, setAllCupons] = useState([]);
   const [cupomAtual, setCupomAtual] = useState(null);

   const initialValues = {
      nome: null,
      codigo: '',
      pontos: '',
      dataInicio: '',
      dataFim: '',
      valeClienteEspecifico: false,
      documentoCliente: cpfCnpj,
   };

   const { values, errors, handleChange, handleSubmit, setValues } = useForm(
      initialValues,
      formControlCupom,
      validateFormGerarCupom,
   );

   useEffect(() => {
      getCuponsValePonto(pagination.current, pagination.pageSize);
   }, []);

   const getCuponsValePonto = (currentPage, pageSize) => {
      setIsLoading(true);
      api.get('/valeponto', {
         params: {
            pagina: currentPage,
            tamanho: pageSize,
         },
      })
         .then(res => {
            setPagination(state => ({
               ...state,
               current: res.data.currentPage,
               total: res.data.totalItems,
            }));
            res.data.data.map(cupom => {
               cupom.dataValidadeFim = cupom.dataValidadeFim
                  .split('-')
                  .reverse()
                  .join('/');

               cupom.dataValidadeInicio = cupom.dataValidadeInicio
                  .split('-')
                  .reverse()
                  .join('/');
               return cupom;
            });
            setAllCupons(res.data.data);
            setIsLoading(false);
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   const openModal = () => {
      setIsOpen(true);
   };

   const closeModal = () => {
      setValues(initialValues);
      setIsOpen(false);
      setEdit(false);
      setDesativar(false);
   };

   function formControlCupom() {
      const {
         nome,
         codigo,
         pontos: ponto,
         documentoCliente: cpfCnpj,
         valePontoId,
         ativo,
      } = values;
      const dataValidadeInicio = formataData(values.dataInicio);
      const dataValidadeFim = formataData(values.dataFim);

      setIsLoading(true);
      if (edit) {
         api.put('/valeponto', {
            nome,
            codigo,
            ponto: Number(ponto),
            cpfCnpj,
            dataValidadeInicio,
            dataValidadeFim,
            valePontoId,
            ativo,
         })
            .then(res => {
               toast.success(res.data.message);
               setIsLoading(false);
               setIsOpen(false);
               setEdit(false);
               getCuponsValePonto(pagination.current, pagination.pageSize);
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } else {
         api.post('/valeponto', {
            nome,
            codigo,
            ponto,
            cpfCnpj,
            dataValidadeInicio,
            dataValidadeFim,
         })
            .then(res => {
               toast.success(res.data.message);
               setIsLoading(false);
               setIsOpen(false);
               getCuponsValePonto(pagination.current, pagination.pageSize);
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      }
   }

   const updateCupom = cupom => {
      setEdit(true);
      setIsOpen(true);
      setValues({
         nome: cupom.nome,
         codigo: cupom.codigo,
         pontos: cupom.ponto,
         dataInicio: cupom.dataValidadeInicio,
         dataFim: cupom.dataValidadeFim,
         valeClienteEspecifico: false,
         documentoCliente: cupom.cpfCnpj,
         valePontoId: cupom.valePontoId,
         ativo: cupom.ativo,
      });
   };

   const desativaModal = cupom => {
      if (cupom.ativo === false) {
         toast.info('Esse cupom já está inativo.');
         return;
      }
      setDesativar(true);
      setIsOpen(true);
      setCupomAtual(cupom);
   };

   const desativaCupom = () => {
      setIsLoading(true);

      const { nome, codigo, ponto, cpfCnpj, valePontoId } = cupomAtual;
      const dataValidadeInicio = formataData(cupomAtual.dataValidadeInicio);
      const dataValidadeFim = formataData(cupomAtual.dataValidadeFim);

      if (desativar) {
         api.put('/valeponto', {
            nome,
            codigo,
            ponto: Number(ponto),
            cpfCnpj,
            dataValidadeInicio,
            dataValidadeFim,
            valePontoId,
            ativo: false,
         })
            .then(res => {
               toast.success(res.data.message);
               setIsLoading(false);
               setIsOpen(false);
               setEdit(false);
               setDesativar(false);
               getCuponsValePonto(pagination.current, pagination.pageSize);
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } else {
         toast.error(
            'Não foi possível realizar esta operação. Tente novamente.',
         );
      }
   };

   const handleChangeMultMask = event => {
      const CPFMASK = '999.999.999-99';
      const CNPJMASK = '99.999.999/9999-99';

      const { value } = event.target;
      const originalValue = unMask(value);
      const maskedValue = masker(originalValue, [CPFMASK, CNPJMASK]);

      setCpfCnpj(maskedValue);
      setValues(state => ({ ...state, documentoCliente: maskedValue }));
   };

   const columns = [
      {
         title: 'Título',
         dataIndex: 'nome',
         sorter: (a, b) => (a.nome < b.nome ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '25%',
      },
      {
         title: 'Código',
         dataIndex: 'codigo',
         sorter: (a, b) => (a.codigo < b.codigo ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '20%',
      },
      {
         title: 'Pontos',
         dataIndex: 'ponto',
         sorter: (a, b) => (a.ponto < b.ponto ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '10%',
      },
      {
         title: 'Início em:',
         dataIndex: 'dataValidadeInicio',
         sorter: (a, b) =>
            a.dataValidadeInicio < b.dataValidadeInicio ? -1 : 1,
         sortDirections: ['descend', 'ascend'],
         width: '20%',
      },
      {
         title: 'Válido até:',
         dataIndex: 'dataValidadeFim',
         sorter: (a, b) => (a.dataValidadeFim < b.dataValidadeFim ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '20%',
      },
      {
         title: 'Status:',
         dataIndex: 'ativo',
         sorter: (a, b) => (a.ativo < b.ativo ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '10%',
         render: record => <>{record ? 'Ativo' : 'Inativo'}</>,
      },
      {
         title: '',
         key: 'operation',
         fixed: 'right',
         width: '10%',
         render: row => (
            <div className="d-flex flex-column">
               <Button className="my-1" onClick={() => updateCupom(row)}>
                  Editar
               </Button>
               <Button
                  disabled={!row.ativo}
                  className="my-1"
                  onClick={() => desativaModal(row)}>
                  Desativar
               </Button>
            </div>
         ),
      },
   ];

   const filtros = [
      {
         dataIndex: 'nome',
         nome: 'Nome',
         placeholder: 'Digite o nome do cupom',
         mask: 'text',
      },
      {
         dataIndex: 'codigo',
         nome: 'Código',
         placeholder: 'Digite o código do cupom',
         mask: 'text',
      },
      {
         dataIndex: 'ponto',
         nome: 'Pontos',
         placeholder: 'Digite a quantidade de pontos',
         mask: 'text',
      },
      {
         dataIndex: 'dataValidadeFim',
         nome: 'Validade',
         placeholder: 'Digite a data de validade',
         mask: 'date',
      },
   ];

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getCuponsValePonto(pagination.current, pagination.pageSize);
      }, 1);
   };

   return (
      <>
         <PageTitle
            heading="Gerar Cupom Vale Ponto"
            subheading="Crie novos cupons"
            icon="pe-7s-ticket icon-gradient bg-fideleco">
            <div>
               <ModalCustom
                  titleModal={`${
                     edit
                        ? 'Alterar cupom'
                        : desativar
                        ? 'Desativar cupom'
                        : 'Gerar novo cupom'
                  }`}
                  buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
                  buttonIcon={
                     <i
                        onClick={closeModal}
                        className="pe-7s-note btn-icon-wrapper font-weight-bold">
                        {' '}
                     </i>
                  }
                  buttonName={`${
                     edit
                        ? 'Alterar cupom'
                        : desativar
                        ? 'Desativar cupom'
                        : 'Gerar novo cupom'
                  }`}
                  buttonSize="lg"
                  buttonColor="primary"
                  toggleFunc={openModal}
                  isOpen={modalIsOpen}
                  size="lg"
                  closeModal={closeModal}>
                  {desativar ? (
                     <Row>
                        <Col className="text-left" md={12}>
                           <h4>
                              Confirma a desativação desse Cupom Vale Ponto?
                           </h4>
                           <div className="d-flex w-full">
                              <Button
                                 size="lg"
                                 className="m-2 py-2 px-5"
                                 color="danger"
                                 onClick={() => desativaCupom()}>
                                 <h5>Sim</h5>
                              </Button>
                              <Button
                                 size="lg"
                                 className="m-2 py-2 px-5"
                                 onClick={() => {
                                    setIsOpen(false);
                                    setDesativar(false);
                                 }}>
                                 <h5>Não</h5>
                              </Button>
                           </div>
                        </Col>
                     </Row>
                  ) : (
                     <Form
                        onSubmit={handleSubmit}
                        noValidate
                        className="text-left">
                        <Row form>
                           <Col md={12}>
                              <FormGroup>
                                 <label className="m-0">
                                    Nome Identificador
                                 </label>
                                 <InputIcon
                                    type="text"
                                    size={12}
                                    handleChange={handleChange}
                                    name="nome"
                                    value={values.nome || ''}
                                 />
                                 {errors.nome && (
                                    <p className="is-danger align-message">
                                       {errors.nome}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row form>
                           <Col md={6}>
                              <FormGroup>
                                 <label className="m-0">Código do Cupom</label>
                                 <InputIcon
                                    type="text"
                                    size={12}
                                    handleChange={handleChange}
                                    name="codigo"
                                    value={values.codigo || ''}
                                 />
                                 {errors.codigo && (
                                    <p className="is-danger align-message">
                                       {errors.codigo}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>
                           <Col md={6}>
                              <FormGroup>
                                 <label className="m-0">
                                    Quantidade de pontos
                                 </label>
                                 <InputIcon
                                    type="number"
                                    size={12}
                                    handleChange={handleChange}
                                    name="pontos"
                                    value={values.pontos || ''}
                                 />
                                 {errors.pontos && (
                                    <p className="is-danger align-message">
                                       {errors.pontos}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row form>
                           <Col md={6}>
                              <FormGroup
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    flexDirection: 'column',
                                 }}>
                                 <label>Data de Início</label>
                                 <InputMask
                                    mask="99/99/9999"
                                    placeholder="01/01/1999"
                                    value={values.dataInicio || ''}
                                    onChange={handleChange}
                                    handleChange={handleChange}
                                    name="dataInicio">
                                    {inputProps => (
                                       <TextField
                                          size={12}
                                          {...inputProps}
                                          type="text"
                                       />
                                    )}
                                 </InputMask>

                                 {errors.dataInicio && (
                                    <p className="is-danger align-message">
                                       {errors.dataInicio}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>
                           <Col md={6}>
                              <FormGroup
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    flexDirection: 'column',
                                 }}>
                                 <label>Data Final</label>
                                 <InputMask
                                    mask="99/99/9999"
                                    placeholder="01/01/1999"
                                    onChange={handleChange}
                                    handleChange={handleChange}
                                    value={values.dataFim || ''}
                                    id="dataFim"
                                    name="dataFim">
                                    {inputProps => (
                                       <TextField
                                          size={12}
                                          {...inputProps}
                                          type="text"
                                       />
                                    )}
                                 </InputMask>
                                 {errors.dataFim && (
                                    <p className="is-danger align-message">
                                       {errors.dataFim}
                                    </p>
                                 )}
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row form>
                           <Col md={12} className="ml-2 mt-2 mb-2">
                              <FormGroup check>
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          checked={values.valeClienteEspecifico}
                                          onChange={handleChange}
                                          name="valeClienteEspecifico"
                                       />
                                    }
                                    label="Caso deseje que este Cupom Vale VIP seja utilizado uma única vez por um único cliente, informe aqui o CPF/CNPJ deste cliente em especial."
                                 />
                              </FormGroup>
                           </Col>
                           {values.valeClienteEspecifico && (
                              <Col md={12} className="ml-2 mb-4">
                                 <label className="m-0">
                                    CPF/CNPJ do Cliente
                                 </label>
                                 <InputIcon
                                    type="text"
                                    size={12}
                                    handleChange={handleChangeMultMask}
                                    onChange={handleChangeMultMask}
                                    value={cpfCnpj}
                                    id="documentoCliente"
                                    name="documentoCliente"
                                 />
                                 {values.valeClienteEspecifico &&
                                    errors.documentoCliente && (
                                       <p className="is-danger align-message">
                                          {errors.documentoCliente}
                                       </p>
                                    )}
                              </Col>
                           )}
                        </Row>
                        <Row>
                           <Col md={12} className="ml-2 mt-2 mb-2">
                              <FormGroup check>
                                 <FormControlLabel
                                    control={
                                       <Checkbox
                                          checked={values.ativo}
                                          onChange={handleChange}
                                          name="ativo"
                                       />
                                    }
                                    label="Cupom Ativo"
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Button color="link" onClick={closeModal}>
                              {' '}
                              Cancelar{' '}
                           </Button>
                           <Button
                              color="primary"
                              type="submit"
                              disabled={isLoading}>
                              {isLoading ? (
                                 <>
                                    {edit ? 'Atualizando' : 'Criando'}{' '}
                                    {<LoadingButton size={15} />}
                                 </>
                              ) : (
                                 <>{edit ? 'Atualizar' : 'Criar'} </>
                              )}{' '}
                           </Button>
                        </Row>
                     </Form>
                  )}
               </ModalCustom>
            </div>
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <div className="table-responsive">
                           <Table
                              columns={columns}
                              filtros={filtros}
                              data={allCupons}
                              pagination={pagination}
                              onChange={handleTableChange}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default GerarCupom;
