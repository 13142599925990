import React, { useState, useEffect } from 'react';
import api from '../../../api/api-local';

// hooks
import { useAuth } from '../../../customHooks/AuthContext';
import cleanNumericValues from '../../../helpers/cleanNumericValues';

//Components
import InputCurrencyMasked from '../../../components/Formulario/InputCurrency';
import validatePremio from '../../../helpers/validateFormDataPremio';
import InputFile from '../../../components/Formulario/InputFile';
import InputIcon from '../../../components/Formulario/InputIcon';
import Spinner from '../../../components/loading/Loading';
import ModalCustom from '../../../components/Modal/Modal';
import PageTitle from '../../layout/AppMain/PageTitle';
import { helpersUntil } from '../../../helpers/until';
import Tooltip from '../../../components/tooltip';
import useForm from '../../../customHooks/useForm';
import Table from '../../../components/FilterTable';

//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import PaginationFilterTable from '../../../components/PaginationFilterTable';

import {
   Row,
   Col,
   Card,
   CardBody,
   Button,
   Form,
   FormGroup,
   Label,
} from 'reactstrap';
import LoadingButton from '../../../components/LoadingButton';

const config = {
   headers: {
      'Content-Type': 'multipart/form-data',
   },
};

const GerenciarPremios = () => {
   const history = useHistory();
   const { signOut } = useAuth();

   const [isLoading, setIsLoading] = useState(false);
   const [dataSource, setDataSource] = useState([]);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [imgData, setImgData] = useState(null);
   const [picture, setPicture] = useState([]);
   const [edit, setEdit] = useState(false);
   const [termoBusca, setTermoBusca] = useState('');
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const initialValues = {
      categoriaPremioId: '',
      titulo: '',
      descricaoResumida: '',
      descricaoDetalhada: '',
      precoCusto: '',
      precoVenda: '',
      quantidadePontoResgate: '',
      dataValidadeInicio: '',
      dataValidadeFim: '',
      quantidadeDisponivel: '',
      instrucaoResgate: '',
      regulamentoResgate: '',
      limiteResgateCliente: '',
      endereco: '',
   };

   const { values, errors, handleChange, handleSubmit, setValues } = useForm(
      initialValues,
      formManageAwards,
      validatePremio,
   );

   const columns = [
      {
         dataIndex: 'titulo',
         title: 'Titulo',
         filters: dataSource.map(premio => ({
            text: premio.titulo,
            value: premio.titulo,
         })),
         onFilter: (value, record) => record.titulo.indexOf(value) === 0,
         sorter: (a, b) => (a.titulo < b.titulo ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         dataIndex: 'dataValidadeInicio',
         title: 'Data início',
         filters: dataSource.map(premio => ({
            text: premio.dataValidadeInicio,
            value: premio.dataValidadeInicio,
         })),
         onFilter: (value, record) =>
            record.dataValidadeInicio.indexOf(value) === 0,
         sorter: (a, b) =>
            a.dataValidadeInicio < b.dataValidadeInicio ? -1 : 1,
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         dataIndex: 'dataValidadeFim',
         title: 'Data Fim',
         filters: dataSource.map(premio => ({
            text: premio.dataValidadeFim,
            value: premio.dataValidadeFim,
         })),
         onFilter: (value, record) =>
            record.dataValidadeFim.indexOf(value) === 0,
         sorter: (a, b) => (a.dataValidadeFim < b.dataValidadeFim ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 100,
         align: 'center',
         render: premio => (
            <div key={premio.premioId}>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon mr-2 text-info lnr-pencil mr-3"
                  onClick={() => {
                     updatePremio(premio);
                  }}
               />
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     deletePremio(premio);
                  }}
               />
            </div>
         ),
      },
   ];

   const filtros = [
      {
         dataIndex: 'titulo',
         nome: 'Título',
         placeholder: 'Digite o título',
         mask: 'text',
      },
      {
         dataIndex: 'dataValidadeInicio',
         nome: 'Data de início',
         placeholder: 'Digite data de início',
         mask: 'date',
      },
      {
         dataIndex: 'dataValidadeFim',
         nome: 'Data de final',
         placeholder: 'Digite data final',
         mask: 'date',
      },
   ];

   const openModal = () => {
      if (!values.nome) {
         setEdit(false);
      } else {
         setValues(initialValues);
         setEdit(true);
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      setValues(initialValues);
      setPicture([]);
      setIsOpen(false);
   };

   function formManageAwards() {
      setIsLoading(true);
      try {
         const formData = new FormData();
         formData.append('categoriaPremioId', 1);
         formData.append('titulo', values.titulo);
         formData.append('descricaoResumida', values.descricaoResumida);
         formData.append('descricaoDetalhada', values.descricaoDetalhada);
         formData.append('precoCusto', cleanNumericValues(values.precoCusto));
         formData.append('precoVenda', cleanNumericValues(values.precoVenda));
         formData.append(
            'quantidadePontoResgate',
            values.quantidadePontoResgate,
         );
         formData.append(
            'dataValidadeInicio',
            helpersUntil.converterDateString(values.dataValidadeInicio),
         );
         formData.append(
            'dataValidadeFim',
            helpersUntil.converterDateString(values.dataValidadeFim),
         );
         formData.append('quantidadeDisponivel', values.quantidadeDisponivel);
         formData.append('instrucaoResgate', values.instrucaoResgate);
         formData.append('regulamentoResgate', values.regulamentoResgate);
         formData.append('limiteResgateCliente', values.limiteResgateCliente);
         formData.append('endereco', values.endereco);
         formData.append('imagem', picture);
         if (!edit) {
            api.post('premio', formData, config)
               .then(response => {
                  setIsLoading(true);
                  if (response.status === 200) {
                     setValues(initialValues);
                     setPicture([]);
                     setImgData(null);
                     setIsOpen(false);
                     setIsLoading(false);

                     toast.success('Prêmio adicionado com sucesso');
                     //Atualizando componente de listagem
                     getlistPremios();
                  }

                  api.get('/plataforma/configuracao/statusjornadacadastro')
                     .then(response => {
                        if (response.status === 200) {
                           const { data } = response;
                           const usuario =
                              localStorage.getItem('@Fideleco:user');
                           const parsedUsuario = JSON.parse(usuario);
                           console.log('data', data);
                           parsedUsuario.statusJornadaCadastroPlataforma = data;
                           localStorage.setItem(
                              '@Fideleco:user',
                              JSON.stringify(parsedUsuario),
                           );

                           window.location.reload();
                        }
                     })
                     .catch(error => {
                        setIsLoading(false);
                        if (error.response.status === 401) {
                           toast.warning('Sessão expirada. Entre novamente', {
                              autoClose: 3000,
                           });
                           signOut();
                        } else {
                           toast.warning(error.response.data.error[0].message);
                        }
                     });
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         } else {
            setIsLoading(true);
            console.log('editando');
            if (values.senha === '') {
               delete values.senha;
            }
            formData.append('premioId', values.premioId);
            console.log('editando', formData);
            api.put('premio', formData, config)
               .then(response => {
                  setIsLoading(true);

                  if (response.status === 200) {
                     setValues(initialValues);
                     setPicture([]);
                     setImgData(null);
                     setIsOpen(false);
                     setIsLoading(false);
                     toast.success('Prêmio alterado com sucesso!');
                     //Atualizando componente de listagem
                     getlistPremios();
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         }
      } catch (error) {
         console.log(error);
      }
   }

   const updatePremio = premio => {
      setEdit(true);
      setIsOpen(true);
      try {
         const getId = premio.premioId;
         setIsLoading(true);

         api.get(`premio/${getId}`)
            .then(response => {
               if (response.status === 200 && getId) {
                  setIsLoading(false);

                  const {
                     categoriaPremioId,
                     titulo,
                     descricaoResumida,
                     descricaoDetalhada,
                     precoCusto,
                     precoVenda,
                     quantidadePontoResgate,
                     dataValidadeInicio,
                     dataValidadeFim,
                     quantidadeDisponivel,
                     instrucaoResgate,
                     regulamentoResgate,
                     limiteResgateCliente,
                     endereco,
                     premioId,
                  } = response.data;
                  const valuesOk = {
                     categoriaPremioId: categoriaPremioId,
                     titulo: titulo,
                     descricaoResumida: descricaoResumida,
                     descricaoDetalhada: descricaoDetalhada,
                     precoCusto: precoCusto,
                     precoVenda: precoVenda,
                     quantidadePontoResgate: quantidadePontoResgate,
                     dataValidadeInicio:
                        helpersUntil.converterDateStringForDateBr(
                           dataValidadeInicio,
                        ),
                     dataValidadeFim:
                        helpersUntil.converterDateStringForDateBr(
                           dataValidadeFim,
                        ),
                     quantidadeDisponivel: quantidadeDisponivel || '0',
                     instrucaoResgate: instrucaoResgate,
                     regulamentoResgate: regulamentoResgate,
                     limiteResgateCliente: limiteResgateCliente,
                     endereco: endereco,
                     premioId: premioId,
                  };
                  setValues(valuesOk);

                  setPicture(response.data?.imagem);
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } catch (error) {}
   };

   const deletePremio = premio => {
      setIsLoading(true);
      try {
         const getId = premio.premioId;
         api.delete(`premio/${getId}`)
            .then(response => {
               setIsLoading(true);

               if (response.status === 200) {
                  setIsLoading(false);

                  toast.success('Prêmio excluído com sucesso');
                  //Atualizando componente de listagem
                  getlistPremios();
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } catch (error) {
         console.log(error);
      }
   };

   const getlistPremios = async (currentPage, pageSize) => {
      try {
         const getApi = await api.get('premio/paginacao', {
            params: {
               pagina: currentPage,
               tamanho: pageSize,
               ...termoBusca,
            },
         });
         if (getApi.status === 200) {
            const { data } = getApi;
            //Salvando as datas em formatos BR
            data.data.map(dt => {
               const dataValidadeInicioFormatada =
                  helpersUntil.converterDateStringForDateBr(
                     dt.dataValidadeInicio,
                  );

               const dataValidadeFimFormatada =
                  helpersUntil.converterDateStringForDateBr(dt.dataValidadeFim);

               dt.dataValidadeInicio = dataValidadeInicioFormatada;
               dt.dataValidadeFim = dataValidadeFimFormatada;
               return dt;
            });
            console.log('data.data', data.data);

            setDataSource(data.data);

            setPagination(state => ({
               ...state,
               current: data.currentPage,
               total: data.totalItems,
            }));
         }
      } catch (error) {
         console.log(error);
      }
   };

   const onChangePicture = e => {
      const ok = Array.from(e.target.files);
      setPicture(e.target.files[0]);
      if (e.target.files) {
         const reader = new FileReader();

         reader.addEventListener('load', () => {
            setImgData(reader.result);
         });
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const handleChangeTermo = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   const handleTableChange = async (pagination, filters, sorter) => {
      console.log('0>');
      setPagination(pagination);

      setTimeout(() => {
         console.log('0>');
         getlistPremios(pagination.current, pagination.pageSize);
      }, 1);
   };

   useEffect(() => {
      getlistPremios(pagination.current, pagination.pageSize);
   }, [edit]);

   return (
      <>
         <PageTitle
            heading="Gerenciar Prêmios"
            subheading="Gerencie seus Prêmios"
            icon="pe-7s-shopbag icon-gradient bg-fideleco">
            {' '}
            <ModalCustom
               titleModal={
                  edit ? 'Atualizar Prêmio' : 'Cadastre um novo prêmio'
               }
               buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
               buttonIcon={
                  <i
                     onClick={closeModal}
                     className="pe-7s-shopbag btn-icon-wrapper font-weight-bold"></i>
               }
               buttonName="Cadastrar Prêmio"
               buttonSize="lg"
               toggleFunc={openModal}
               isOpen={modalIsOpen}
               closeModal={closeModal}
               size="lg">
               <Form onSubmit={handleSubmit} noValidate className="text-left">
                  <Row className="mb-3" form>
                     <Col md={12}>
                        <FormGroup>
                           <label>Titulo do prêmio</label>

                           <Tooltip
                              texto="Identifique seu prêmio por um nome de fácil
                              caracterização, como: “Pizza de Calabresa”.
                              Descreva seu prêmio de forma resumida, como:
                              “Pizza com calabresa e cebolas fatiadas, azeitonas
                              pretas, molho de tomate e orégano”. Por fim,
                              escolha a categoria que o prêmio está inserido,
                              como: “Alimentação”."
                              id="titulo"
                           />

                           <InputIcon
                              type="text"
                              size={12}
                              name="titulo"
                              handleChange={handleChange}
                              value={values.titulo || ''}
                           />
                           {errors.titulo && (
                              <p className="is-danger">{errors.titulo}</p>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>

                  <FormGroup className="mb-3">
                     <Label htmlFor="email">Descrição do Prêmio</Label>

                     <Tooltip
                        texto="Detalhar tecnicamente o seu prêmio é importante para não
                        gerar dúvidas ou confusões com seus clientes na hora do
                        resgate. Quanto mais descrito o prêmio for, menos
                        problemas e insatisfação você terá de seus clientes.
                        Coloque características como a cor, o peso, o formato, o
                        material, o que faz, como se utiliza, e outras
                        informações que possam diferenciar o prêmio."
                        id="descricaoDetalhada"
                     />

                     <textarea
                        className="form-control"
                        name="descricaoDetalhada"
                        onChange={handleChange}
                        value={values.descricaoDetalhada || ''}
                        required
                     />

                     {errors.descricaoDetalhada && (
                        <p className="is-danger">{errors.descricaoDetalhada}</p>
                     )}
                  </FormGroup>
                  <Row className="mb-3" form>
                     <Col md={4}>
                        <FormGroup>
                           <label className="m-0">Preço de custo</label>

                           <Tooltip
                              texto="Informe o valor de custo e venda do seu prêmio.
                              Estes valores não serão informados aos clientes,
                              mas usados para suas análises."
                              id="precoCusto"
                           />

                           <TextField
                              placeholder="R$ 00,00"
                              value={values.precoCusto}
                              onChange={handleChange}
                              style={{ marginLeft: '1px' }}
                              name="precoCusto"
                              id="formatted-numberformat-input"
                              style={{ paddingTop: '8px', width: '100%' }}
                              InputProps={{
                                 inputComponent: InputCurrencyMasked,
                              }}
                           />
                           {errors.precoCusto && (
                              <p className="is-danger align-message">
                                 {errors.precoCusto}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                     <Col md={4}>
                        <FormGroup>
                           <label className="m-0">Preço para venda</label>

                           <Tooltip
                              texto="Informe o valor de custo e venda do seu prêmio.
                              Estes valores não serão informados aos clientes,
                              mas usados para suas análises."
                              id="precoVenda"
                           />

                           <TextField
                              placeholder="R$ 00,00"
                              value={values.precoVenda}
                              onChange={handleChange}
                              name="precoVenda"
                              id="formatted-numberformat-input"
                              style={{ paddingTop: '8px', width: '100%' }}
                              InputProps={{
                                 inputComponent: InputCurrencyMasked,
                              }}
                           />

                           {errors.precoVenda && (
                              <p className="is-danger align-message">
                                 {errors.precoVenda}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                     <Col md={4}>
                        <FormGroup>
                           <label className="m-0">Pontos para resgate</label>

                           <Tooltip
                              texto="Defina aqui a quantidade de Pontos VIP necessária
                              para o resgate deste prêmio. Fique atento ao valor
                              de consumo para que o cliente tenha direito ao
                              resgate do prêmio. A taxa de retorno representa o
                              quanto você está retornando ao seu cliente a cada
                              compra feita. Por exemplo: em uma taxa de 1% você
                              está retornando R$ 1,00 em prêmios a cada R$
                              100,00 em compras."
                              id="quantidadePontoResgate"
                           />

                           <InputIcon
                              type="number"
                              size={12}
                              name="quantidadePontoResgate"
                              handleChange={handleChange}
                              value={values.quantidadePontoResgate || ''}
                           />
                           {errors.quantidadePontoResgate && (
                              <p className="is-danger align-message">
                                 {errors.quantidadePontoResgate}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>
                  <Row className="mb-3" form>
                     <Col md={6}>
                        <FormGroup
                           style={{
                              display: 'flex',
                              justifyContent: 'start',
                              flexDirection: 'column',
                           }}>
                           <div>
                              <label>Data validade de início</label>

                              <Tooltip
                                 texto="A validade é o período o qual o seu prêmio
                                 estará disponível no website de seu Programa de
                                 Fidelidade. O lançamento de um prêmio pode ser
                                 agendado (para isso, informe uma data futura no
                                 campo 'Data inicial'). O campo 'Data final' é
                                 utilizado para informar a data a partir da qual
                                 o prêmio será indisponibilizado no Programa de
                                 Fidelidade."
                                 id="dataValidadeInicio"
                              />
                           </div>
                           <InputMask
                              mask="99/99/9999"
                              placeholder="01/01/1999"
                              value={values.dataValidadeInicio}
                              onChange={handleChange}
                              name="dataValidadeInicio">
                              {inputProps => (
                                 <TextField
                                    size={12}
                                    {...inputProps}
                                    type="text"
                                 />
                              )}
                           </InputMask>
                           {errors.dataValidadeInicio && (
                              <p className="is-danger align-message">
                                 {errors.dataValidadeInicio}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                     <Col md={6}>
                        <FormGroup
                           style={{
                              display: 'flex',
                              justifyContent: 'start',
                              flexDirection: 'column',
                           }}>
                           <label>Data validade de fim</label>
                           <InputMask
                              mask="99/99/9999"
                              placeholder="01/01/1999"
                              value={values.dataValidadeFim}
                              onChange={handleChange}
                              name="dataValidadeFim">
                              {inputProps => (
                                 <TextField
                                    style={{ marginRight: '0' }}
                                    size={12}
                                    {...inputProps}
                                    type="text"
                                 />
                              )}
                           </InputMask>
                           {errors.dataValidadeFim && (
                              <p className="is-danger align-message">
                                 {errors.dataValidadeFim}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="mb-3" form>
                     <Col md={6}>
                        <FormGroup>
                           <label className="m-0">
                              Quantidade de prêmio disponível
                           </label>

                           <Tooltip
                              texto="Se você possui uma quantidade limitada de itens do
                              prêmio em questão, esta função é muito importante:
                              quando a quantidade distribuída de itens do prêmio
                              em questão atingir o limite estipulado neste
                              campo, o prêmio será automaticamente colocado como
                              indisponível no website do Programa de Fidelidade
                              de sua Filial."
                              id="quantidadeDisponivel"
                           />

                           <InputIcon
                              type="number"
                              size={12}
                              name="quantidadeDisponivel"
                              handleChange={handleChange}
                              value={values.quantidadeDisponivel || ''}
                           />
                           {errors.quantidadeDisponivel && (
                              <p className="is-danger align-message">
                                 {errors.quantidadeDisponivel}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                     <Col md={6}>
                        <FormGroup>
                           <label className="m-0">
                              Limite de resgate por cliente
                           </label>

                           <Tooltip
                              texto="Aqui você pode definir uma quantidade limite de
                              resgate deste prêmio por cliente. Ou seja, quantas
                              vezes o mesmo cliente pode resgatar este prêmio.
                              Ex: Quantidade limite de 02 significa que o mesmo
                              cliente só pode resgatar este prêmio duas vezes,
                              depois disso o prêmio se torna indisponível para
                              ele."
                              id="limiteResgateCliente"
                           />

                           <InputIcon
                              type="number"
                              size={12}
                              name="limiteResgateCliente"
                              handleChange={handleChange}
                              value={values.limiteResgateCliente || ''}
                           />
                           {errors.limiteResgateCliente && (
                              <p className="is-danger align-message">
                                 {errors.limiteResgateCliente}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="mb-3" form>
                     <Col md={6}>
                        <FormGroup>
                           <label>Instrução para o resgate</label>

                           <Tooltip
                              texto="Detalhe aqui os procedimento para o cliente
                              resgatar o seu prêmio. Informações importantes
                              como o local e os horários para retirada são
                              obrigatórias, por exemplo: “Prêmio disponível para
                              resgate de segunda à sexta-feira, das 9h00 às
                              18h00 em nossa loja”."
                              id="instrucaoResgate"
                           />

                           <InputIcon
                              type="text"
                              size={12}
                              name="instrucaoResgate"
                              handleChange={handleChange}
                              value={values.instrucaoResgate || ''}
                           />
                           {errors.instrucaoResgate && (
                              <p className="is-danger align-message">
                                 {errors.instrucaoResgate}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                     <Col md={6}>
                        <FormGroup>
                           <label>Regulamento do resgate</label>

                           <Tooltip
                              texto="O regulamento para o resgate deste prêmio deverá
                              ser claro, simples e direto, além de conter todas
                              as informações necessárias para o entendimento do
                              processo."
                              id="regulamentoResgate"
                           />

                           <InputIcon
                              type="text"
                              size={12}
                              name="regulamentoResgate"
                              handleChange={handleChange}
                              value={values.regulamentoResgate || ''}
                           />
                           {errors.regulamentoResgate && (
                              <p className="is-danger align-message">
                                 {errors.regulamentoResgate}
                              </p>
                           )}
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="mb-3" form>
                     <Col md={12}>
                        <FormGroup>
                           <label>Endereço completo</label>

                           <Tooltip
                              texto="Informe aqui o endereço no qual o cliente deverá
                              se dirigir para realizar a entrega do seu cupom
                              vale-prêmio e a retirada do prêmio. Ex: Rua Lorem
                              Ipsum, nº 10, Bairro Lorem Ipsum, São Paulo/SP"
                              id="endereco"
                           />

                           <InputIcon
                              type="text"
                              size={12}
                              name="endereco"
                              handleChange={handleChange}
                              value={values.endereco || ''}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="d-flex justify-content-center mb-3" form>
                     <div>
                        {imgData !== null || picture?.length > 0 ? (
                           <div>
                              <img
                                 className="playerProfilePic_home_tile"
                                 alt=""
                                 style={{
                                    width: '200px',
                                    height: '200px',
                                    paddingTop: '2em',
                                    paddingBottom: '2em',
                                 }}
                                 src={
                                    imgData || `data:image/*;base64,${picture}`
                                 }
                              />
                              <InputFile
                                 type="file"
                                 style={{
                                    width: '200px',
                                    padding: '10px',
                                    margin: '0',
                                    borderRadius: '5px',
                                    backgroundColor: 'transparent',
                                    color: '#495057',
                                    borderStyle: 'dashed',
                                    borderColor: '#495057',
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                 }}
                                 text="Mudar imagem"
                                 size={12}
                                 handleChange={onChangePicture}
                                 accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                 name="logo"
                                 id="profilePic"
                              />
                           </div>
                        ) : (
                           <Col
                              style={{
                                 padding: '0',
                                 margin: '0',
                              }}
                              md={12}>
                              <InputFile
                                 type="file"
                                 style={{
                                    width: '200px',
                                    height: '200px',
                                    padding: '10px',
                                    margin: '0',
                                    borderRadius: '5px',
                                    backgroundColor: 'transparent',
                                    color: '#495057',
                                    borderStyle: 'dashed',
                                    borderColor: '#495057',
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                 }}
                                 text="Clique e escolha imagem do premio"
                                 size={12}
                                 handleChange={onChangePicture}
                                 accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                 name="logo"
                                 id="profilePic"
                              />
                           </Col>
                        )}
                     </div>
                  </Row>
                  <Button color="link" onClick={closeModal}>
                     {' '}
                     Cancelar{' '}
                  </Button>
                  <Button color="primary" type="submit" disabled={isLoading}>
                     {isLoading ? (
                        <>
                           {edit ? 'Atualizando' : 'Criando'}{' '}
                           {<LoadingButton size={15} />}
                        </>
                     ) : (
                        <>{edit ? 'Atualizar' : 'Criar'} </>
                     )}{' '}
                  </Button>
               </Form>
            </ModalCustom>
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <div className="table-responsive">
                           <PaginationFilterTable
                              columns={columns}
                              rowKey={record => record.nome}
                              dataSource={dataSource}
                              pagination={pagination}
                              loading={isLoading}
                              onChange={handleTableChange}
                              handleSubmit={getlistPremios}
                              filterChildren={[
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.titulo}
                                    onChange={e =>
                                       handleChangeTermo(
                                          'titulo',
                                          e.target.value,
                                       )
                                    }
                                    label="Título"
                                 />,
                              ]}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default GerenciarPremios;
