export default function validateFormGerarCupom(values) {
   let errors = {};
   if (!values.nome) {
      errors.nome = 'Nome Identificador obrigatório';
   }
   if (!values.codigo) {
      errors.codigo = 'Código do Cupom obrigatório';
   }
   if (!values.pontos) {
      errors.pontos = 'Quantidade de pontos obrigatória';
   }
   if (!values.dataInicio) {
      errors.dataInicio = 'Informe a data de início';
   }
   if (!values.dataFim) {
      errors.dataFim = 'Informe a data final';
   }

   if (values.valeClienteEspecifico) {
      if (!values.documentoCliente) {
         errors.documentoCliente = 'Informe o CPF/CNPJ do cliente';
      }
   }

   return errors;
}
