import moment from 'moment';

export const helpersUntil = {
   converterDateString: value => {
      const momentObj = moment(value, 'DD/MM/YYYY');
      const momentConverter = momentObj.format('YYYY-MM-DD');
      return momentConverter;
   },

   converterDateStringForDateBr: value => {
      const momentObj = moment(value, 'YYYY-MM-DD');
      const momentConverter = momentObj.format('DD/MM/YYYY');
      return momentConverter;
   },

   converterValueStringForReal: value => {
      var dinheiro = value.toLocaleString('pt-br', {
         style: 'currency',
         currency: 'BRL',
      });
      return dinheiro;
   },
};
