export default function validatePremio(values) {
   let errors = {};
   if (!values.descricaoDetalhada) {
      errors.descricaoDetalhada = 'Campo descrição é obrigatório!';
   }
   if (!values.titulo) {
      errors.titulo = 'Campo título é obrigatório!';
   }
   if (!values.quantidadeDisponivel && values.quantidadeDisponivel !== 0) {
      errors.quantidadeDisponivel = 'Campo quantidade de prêmio é obrigatório!';
   }
   if (!values.limiteResgateCliente) {
      errors.limiteResgateCliente =
         'Campo limite para o resgate é obrigatório!';
   }
   if (!values.precoVenda) {
      errors.precoVenda = 'Campo obrigatório!';
   }
   if (!values.precoCusto) {
      errors.precoCusto = 'Campo obrigatório!';
   }
   if (!values.quantidadePontoResgate) {
      errors.quantidadePontoResgate = 'Campo obrigatório!';
   }
   if (!values.regulamentoResgate) {
      errors.regulamentoResgate = 'Campo regulamento é obrigatório!';
   }
   if (!values.instrucaoResgate) {
      errors.instrucaoResgate = 'Campo instrução é obrigatório!';
   }
   if (!values.dataValidadeFim) {
      errors.dataValidadeFim = 'Campo obrigatório!';
   }
   if (!values.dataValidadeInicio) {
      errors.dataValidadeInicio = 'Campo obrigatório!';
   }

   return errors;
}
