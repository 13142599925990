import React, { Fragment, useEffect, useState } from 'react';

//Components
import bg1 from '../../assets/utils/images/originals/login_logo.png';
import Login from './Login';
import Register from './Register';
import EsqueciSenha from './EsqueciSenha';

//Libs
import Slider from 'react-slick';
import { Row, Col } from 'reactstrap';

const InitialPages = () => {
   //Variaveis
   const [settings] = useState({
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      adaptiveHeight: true,
      rtl: true,
   });

   //States
   const [tela, setTela] = useState('login');

   useEffect(() => {
      if (window.location.search === '?cadastro') {
         setTela('register');
      }
   }, [window.location]);

   return (
      <Fragment>
         <div className="h-100 ">
            <Row className="h-100 no-gutters">
               <Col lg="4" className="d-none d-lg-block card_right">
                  <div className="slider-light">
                     <Slider {...settings}>
                        <img src={bg1} alt="" />
                     </Slider>
                  </div>
               </Col>

               {tela === 'login' && <Login mudarTela={setTela} />}
               {tela === 'register' && <Register mudarTela={setTela} />}
               {tela === 'esqueciSenha' && <EsqueciSenha mudarTela={setTela} />}
            </Row>
         </div>
      </Fragment>
   );
};

export default InitialPages;
