import React from 'react'

const Spinner = (props) => {
   return (
      <div className="container-spinner">

         <div {...props} data-testid="spinner" className='spinner' ><div /><div /><div /></div>
      </div>
   )
}

export default Spinner
