import React from 'react';
import {} from 'reactstrap';

import MegaMenu from '../../../pages/layout/AppHeader/Components/MegaMenu';
import MenuLojas from '../AppHeader/Components/MenuLojas';

const MenuLeft = () => {
   return (
      <div>
         <MenuLojas />
         <MegaMenu />
      </div>
   );
};

export default MenuLeft;
