import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../api/api-local';
import { toast } from 'react-toastify';
import { Popconfirm } from 'antd';
//Components
import validateFormCadastroLogista from '../../helpers/validateFormData';
import InputIcon from '../../components/Formulario/InputIcon';
import { useAuth } from '../../customHooks/AuthContext';
import Spinner from '../../components/loading/Loading';
import useForm from '../../customHooks/useForm';
import Tooltip from '../../components/tooltip';

//Libs
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import ModalCustom from '../../components/Modal/Modal';

//Files
import EscondeSenhaIcon from '../../assets/icons/eye-close-red.svg';
import RevelaSenhaIcon from '../../assets/icons/eye-open-red.svg';
import { Select, MenuItem, FormControl, TextField } from '@material-ui/core';
import Termos from '../../components/TermosPoliticas/termos';
import Contrato from '../../components/TermosPoliticas/contrato';

//Variaveis
const Register = props => {
   const history = useHistory();
   const { sigIn } = useAuth();

   const { mudarTela } = props;
   const initialValues = {
      nome: '',
      email: '',
      celular: '',
      enderecoWebSite: '',
      senha: '',
      comoConheceuId: '',
      termoPrivacidade: false,
      politicaDados: true,
   };

   const { values, errors, handleChange, handleSubmit } = useForm(
      initialValues,
      formCadastroLogista,
      validateFormCadastroLogista,
   );

   //States
   const [mostraConfirmaSenha, setMostraConfirmaSenha] = useState(false);
   const [getDataComoConheceu, setGetDataComoConheceu] = useState([]);
   const [mostraSenha, setMostraSenha] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [isOpen, setIsOpen] = useState(false);
   const [celular, setCelular] = useState('');
   const [token, setToken] = useState('');
   const [formValidTrue, setFormValidTrue] = useState(false);
   const [termosOuPoliticas, setTermosOuPoliticas] = useState('');
   const [openTermosOuPoliticas, setOpenTermosOuPoliticas] = useState('');

   const openModal = () => {
      setIsOpen(true);
   };

   const showTermosPoliticas = (e, component) => {
      e.preventDefault();

      if (component) {
         setTermosOuPoliticas(component);
      } else {
         setTermosOuPoliticas('');
      }

      setOpenTermosOuPoliticas(true);
   };

   //Functions
   const submitTokenValidation = () => {
      const dataCelular = {
         telefone: values.celular,
      };
      api.post('token/enviar', dataCelular)
         .then(response => {
            if (response.status === 200) {
               toast.success(response.data.message);
            }
         })
         .catch(error => {
            error.response.data.error.map(i => {
               toast.error(i.message);
            });
         });
   };

   const handleChangeToken = event => {
      const { value } = event.target;
      setToken(value);
   };

   const addUser = () => {
      api.post('plataforma', values)
         .then(response => {
            if (response.status === 200) {
               sigIn({
                  celular: values.celular,
                  senha: values.senha,
               });
               history.replace('/cadastro/empresa');
               toast.success('Usuário adicionado com sucesso');
            }
         })
         .catch(error => {
            setToken('');
            setCelular('');
            setIsOpen(false);
            error.response.data.error.map(i => {
               toast.error(i.message);
            });
         });
   };

   const validarToken = () => {
      const dataValidarToken = {
         telefone: values.celular,
         token: token,
      };
      api.post('token/validar', dataValidarToken)
         .then(response => {
            if (response.status === 200) {
               addUser();
               toast.success(response.data.message);
            }
         })
         .catch(error => {
            error.response.data.error.map(i => {
               toast.error(i.message);
            });
         });
   };

   useEffect(() => {
      if (Object.keys(errors).length === 0 && formValidTrue) {
         setIsOpen(true);
      }
   }, [errors, formValidTrue]);

   const validateInfos = async () => {
      const { email, celular, enderecoWebSite: path } = values;

      api.post('/plataforma/validarcadastrosimplificado', {
         email,
         celular,
         path,
      })
         .then(response => {
            setFormValidTrue(true);
            submitTokenValidation();
         })
         .catch(error => {
            toast.error(error.response.data.error[0].message);
         });
   };

   function formCadastroLogista() {
      validateInfos();
   }
   useEffect(() => {
      api.get('comoconheceu').then(response => {
         if (response.status === 200) {
            setGetDataComoConheceu(response.data);
         }
      });
   }, []);

   return (
      <>
         <Col
            lg="7"
            md="12"
            className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
               <h4 className="text-left ml-2">
                  <span>
                     Leva apenas{' '}
                     <span className="text-success">alguns segundos</span> para
                     fazer seu cadastro e criar seu programa de fidelidade
                  </span>
               </h4>
               <div>
                  <Form onSubmit={handleSubmit} noValidate>
                     <Row form>
                        <Col md={6}>
                           <FormGroup className="text-left">
                              <label className="mb-0 ml-2">Nome</label>
                              <InputIcon
                                 type="text"
                                 size={10}
                                 handleChange={handleChange}
                                 value={values.nome || ''}
                                 name="nome"></InputIcon>
                              {errors.nome && (
                                 <p className="is-danger align-message">
                                    {errors.nome}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                        <Col md={6}>
                           <FormGroup className="text-left">
                              <label className="mb-0 ml-2">
                                 Celular (Será o seu login)
                              </label>
                              <InputMask
                                 mask="(99) 99999-9999"
                                 value={values.celular}
                                 onChange={handleChange}
                                 name="celular">
                                 {inputProps => (
                                    <TextField
                                       {...inputProps}
                                       type="text"
                                       style={{
                                          width: '83%',
                                          marginLeft: '.5em',
                                       }}
                                    />
                                 )}
                              </InputMask>
                              {errors.celular && (
                                 <p className="is-danger align-message">
                                    {errors.celular}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row form>
                        <Col md={6}>
                           <FormGroup className="text-left">
                              <label className="mb-0 ml-2">Senha</label>
                              <InputIcon
                                 type={mostraSenha ? 'text' : 'password'}
                                 size={12}
                                 name="senha"
                                 handleChange={handleChange}
                                 value={values.senha || ''}
                                 id="senha">
                                 <img
                                    src={
                                       mostraSenha
                                          ? RevelaSenhaIcon
                                          : EscondeSenhaIcon
                                    }
                                    alt="Mostrar senha"
                                    className="mostra-senha-icon"
                                    onClick={() => setMostraSenha(!mostraSenha)}
                                 />
                              </InputIcon>
                              {errors.senha && (
                                 <p className="is-danger align-message">
                                    {errors.senha}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                        <Col md={6}>
                           <FormGroup className="text-left">
                              <label className="mb-0 ml-2">
                                 Confirme sua senha
                              </label>

                              <InputIcon
                                 type={
                                    mostraConfirmaSenha ? 'text' : 'password'
                                 }
                                 size={10}
                                 handleChange={handleChange}
                                 value={values.passwordConfirm || ''}
                                 id="passwordConfirm"
                                 name="passwordConfirm">
                                 <img
                                    src={
                                       mostraConfirmaSenha
                                          ? RevelaSenhaIcon
                                          : EscondeSenhaIcon
                                    }
                                    alt="Mostrar senha"
                                    className="mostra-senha-icon"
                                    onClick={() =>
                                       setMostraConfirmaSenha(
                                          !mostraConfirmaSenha,
                                       )
                                    }
                                 />
                              </InputIcon>
                              {errors.passwordConfirm && (
                                 <p className="is-danger align-message">
                                    {errors.passwordConfirm}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                     </Row>
                     <Row form>
                        <Col md={6}>
                           <FormGroup className="text-left">
                              <label className="mb-0 ml-2">E-Mail</label>
                              <InputIcon
                                 type="E-Mail"
                                 size={10}
                                 handleChange={handleChange}
                                 value={values.email || ''}
                                 name="email"
                              />
                              {errors.email && (
                                 <p className="is-danger align-message">
                                    {errors.email}
                                 </p>
                              )}
                           </FormGroup>
                        </Col>
                        <Col md={6} className="text-left">
                           <label className="mb-0 ml-2">
                              Como conheceu o Fideleco?
                           </label>
                           <FormControl
                              style={{ width: '83%', marginLeft: '.5em' }}>
                              <Select
                                 labelId="demo-controlled-open-select-label"
                                 id="demo-controlled-open-select"
                                 value={values.comoConheceuId || ''}
                                 onChange={handleChange}
                                 name="comoConheceuId">
                                 <MenuItem value={0} selected>
                                    Selecione uma opção
                                 </MenuItem>
                                 {getDataComoConheceu.map(i => (
                                    <MenuItem
                                       value={parseInt(i.comoConheceuId)}>
                                       {i.descricao}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                        </Col>
                     </Row>
                     <Col md={12} className="text-left p-2">
                        <FormGroup className="mt-3 text-left" check>
                           <label>
                              Endereço para o seu Website Fidelidade (Ex:
                              nomedaminhaempresa - ATENÇÃO: este campo não
                              poderá ser alterado depois de salvo)
                           </label>
                           <label className="title_url_style">
                              https://loja.fideleco.com.br/
                              {values.enderecoWebSite}
                           </label>
                           <InputIcon
                              size={6}
                              type="E-Mail"
                              handleChange={handleChange}
                              value={values.enderecoWebSite || ''}
                              name="enderecoWebSite"
                           />
                        </FormGroup>
                     </Col>
                     <FormGroup className="mt-3 ml-2 text-left" check>
                        <Input
                           onChange={handleChange}
                           checked={values.termoPrivacidade}
                           type="checkbox"
                           name="termoPrivacidade"
                           id="termoPrivacidade"
                        />
                        <Label htmlFor="exampleCheck" className="ml-3" check>
                           Ao se registrar, você concorda com os nossos{' '}
                           <a
                              href=" /"
                              onClick={e => showTermosPoliticas(e, 'termos')}>
                              Termos de Uso
                           </a>
                           , em receber emails e atualizações do Fideleco e
                           confirma que leu nossa Política de Privacidade e
                           nosso{' '}
                           <a
                              href=" /"
                              onClick={e => showTermosPoliticas(e, 'contrato')}>
                              Contrato.
                           </a>
                        </Label>
                        {errors.termoPrivacidade && (
                           <p className="is-danger align-message">
                              {errors.termoPrivacidade}
                           </p>
                        )}
                     </FormGroup>
                     <div className="mt-4 mb-2 d-flex ml-2 align-items-center row justify-content-between">
                        <h5 className="mb-1">
                           Já tem uma conta?{' '}
                           <a
                              onClick={() => {
                                 mudarTela('login');
                              }}
                              className="text-primary border-0 bg-white">
                              Entrar
                           </a>
                        </h5>
                     </div>
                     <Popconfirm
                        title="Você tem certeza? Você perderá todos os campos preenchidos"
                        onConfirm={() => mudarTela('login')}
                        okText="Sim"
                        cancelText="Permanecer no cadastro">
                        <Button
                           color="primary"
                           type="button"
                           className="btn-wide btn-pill btn-shadow btn-hover-shine mr-3"
                           size="lg">
                           Cancelar
                        </Button>
                     </Popconfirm>

                     <Button
                        color="primary"
                        type="submit"
                        className="btn-wide btn-pill btn-shadow btn-hover-shine"
                        size="lg">
                        Criar conta
                     </Button>
                  </Form>
                  <ModalCustom
                     isOpen={isOpen}
                     titleModal={`Valide o Token do celular`}
                     toggleFunc={openModal}
                     size="lg"
                     style={{ display: 'none' }}>
                     <Row form>
                        <Col md={6}>
                           <FormGroup className="mt-3 text-left" check>
                              <label className="mb-0 ml-2">
                                 Confirme o Token
                              </label>
                              <InputIcon
                                 type="text"
                                 handleChange={handleChangeToken}
                                 value={token || ''}
                                 name="token"
                              />
                           </FormGroup>

                           <Button onClick={validarToken}>
                              Validar Código
                           </Button>
                        </Col>
                     </Row>
                  </ModalCustom>
               </div>
            </Col>
         </Col>
         {isLoading && <Spinner />}
         <ModalCustom
            titleModal={
               termosOuPoliticas === 'termos' ? 'Termos de Uso' : 'Contrato'
            }
            isOpen={openTermosOuPoliticas}
            toggleFunc={showTermosPoliticas}
            size="lg">
            {termosOuPoliticas === 'termos' ? <Termos /> : <Contrato />}
            <Button onClick={() => setOpenTermosOuPoliticas(false)}>
               Fechar
            </Button>
         </ModalCustom>
      </>
   );
};

export default Register;
