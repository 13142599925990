import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Slider } from 'react-burgers';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import MenuRight from './MenuRight';

import SideBar from '../../../components/aside/Aside';
import HeaderMenuRight from './HeaderMenuRight';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {
   setEnableClosedSidebar,
   setEnableMobileMenu,
   setEnableMobileMenuSmall,
} from '../../../reducers/ThemeOptions';

const AppMobileMenu = props => {
   const [enableClosedSidebar, setEnableClosedSidebar] = useState(false);

   const [state, setState] = useState({
      active: false,
      mobile: false,
      left: false,
      right: false,
      activeSecondaryMenuMobile: false,
   });

   //OCUNTANDO SMALL

   const toggleDrawer = (anchor, open) => event => {
      setState({ ...state, [anchor]: open });
   };

   const onToggleX = () => {
      setEnableClosedSidebar(!enableClosedSidebar);
   };

   const listLeft = () => (
      <div>
         {/*<List>
            <ListItem button>
               <ListItemIcon>
                  <HeaderMenuRight onClick={handleDrawerClose} />
               </ListItemIcon>
               <ListItemText />
            </ListItem>
         </List>
         <Divider />*/}
         <List>
            <ListItem button>
               <ListItemIcon>
                  <SideBar />
               </ListItemIcon>
               <ListItemText />
            </ListItem>
         </List>
      </div>
   );

   const listRight = () => (
      <div>
         <List>
            <ListItem button>
               <ListItemIcon>
                  <HeaderMenuRight />
               </ListItemIcon>
               <ListItemText />
            </ListItem>
            <div style={{ borderBottom: '0.5px solid #b2b2b3' }}></div>
            <ListItem button>
               <ListItemIcon>
                  <MenuRight />
               </ListItemIcon>
               <ListItemText />
            </ListItem>
         </List>
      </div>
   );

   return (
      <Fragment>
         <div className="app-header__mobile-menu">
            <div onClick={toggleDrawer('left', true)}>
               <Slider
                  width={26}
                  lineHeight={2}
                  lineSpacing={5}
                  color="#6c757d"
                  active={enableClosedSidebar}
                  onClick={onToggleX}
               />
            </div>
         </div>
         <div className="app-header__menu">
            <span onClick={toggleDrawer('right', true)}>
               <Button
                  size="sm"
                  className={cx('btn-icon btn-icon-only', {
                     active: state.activeSecondaryMenuMobile,
                  })}
                  color="primary"
                  onClick={() =>
                     setState({
                        activeSecondaryMenuMobile: !state.activeSecondaryMenuMobile,
                     })
                  }>
                  <div className="btn-icon-wrapper">
                     <FontAwesomeIcon icon={faEllipsisV} />
                  </div>
               </Button>
            </span>
         </div>

         <React.Fragment key={'left'}>
            <SwipeableDrawer
               className="sidebarMobile"
               onClick={onToggleX}
               anchor={'left'}
               open={state['left']}
               onClose={toggleDrawer('left', false)}
               onOpen={toggleDrawer('left', true)}>
               {listLeft('left')}
            </SwipeableDrawer>
         </React.Fragment>

         <React.Fragment key={'right'}>
            <SwipeableDrawer
               className="sidebarMobile"
               anchor={'right'}
               open={state['right']}
               onClose={toggleDrawer('right', false)}
               onOpen={toggleDrawer('right', true)}>
               {listRight('right')}
            </SwipeableDrawer>
         </React.Fragment>
      </Fragment>
   );
};

const mapStateToProps = state => ({
   enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
   enableMobileMenu: state.ThemeOptions.enableMobileMenu,
   enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({
   setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
   setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
   setEnableMobileMenuSmall: enable =>
      dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);
