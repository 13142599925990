import React from 'react';

import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
   root: {
      display: 'flex',
      flexWrap: 'wrap',
   },
   input: {
      width: '100%',
   },
}));

export default function NativeSelects(props) {
   const { name, onChange, defaultValue, children, value } = props;
   const classes = useStyles();

   return (
      <div>
         <NativeSelect
            className={classes.input}
            defaultValue={defaultValue}
            onChange={onChange}
            value={value}
            inputProps={{
               name: name,
            }}>
            {children}
         </NativeSelect>
      </div>
   );
}
