import React from 'react';

function NotFound() {
  return (
    <>
    <div>Deu erro</div>
    </>
  );
}

export default React.memo(NotFound);
