import React from 'react';
import CurrencyInput from 'react-currency-input';

function InputCurrencyMasked(props) {
   const { inputRef, onChange, ...other } = props;
   return (
      <CurrencyInput
         {...other}
         ref={inputRef}
         onChangeEvent={onChange}
         decimalSeparator=","
         thousandSeparator="."
         prefix="R$ "
      />
   );
}

export default React.memo(InputCurrencyMasked);
