import React from 'react';

const Flex = ({
   flexDirection = 'column',
   justifyContent = 'flex-start',
   alignItems = 'flex-start',
   children,
}) => (
   <div
      style={{
         display: 'flex',
         flexDirection,
         justifyContent,
         alignItems,
      }}>
      {children}
   </div>
);

export default Flex;
