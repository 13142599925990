import React from 'react';

const InformacoesCadastrais = () =>{
  return (
    <>

    <div className="container-clientes">Informações Cadastrais</div>
    </>
  )
}

export default InformacoesCadastrais
