import React from 'react';
import { connect } from 'react-redux';

import HeaderLogo from '../AppLogo';
import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAuth } from '../../../customHooks/AuthContext';
import iconBaloon from '../../../assets/icons/icon-baloon.svg';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
   UncontrolledButtonDropdown,
} from 'reactstrap';
import MenuLojas from './Components/MenuLojas';

const Header = props => {
   const { usuario } = useAuth();

   const {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow,
   } = props;

   return (
      <>
         <CSSTransitionGroup
            component="div"
            className="app-header header-shadow"
            transitionName="HeaderAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}>
            <HeaderLogo />
            <div className="app-header__content header-mobile-open justify-content-between">
               <div className="app-header-right">
                  <MegaMenu />
               </div>

               <div className="app-header-right">
                  <MenuLojas />
                  <UserBox />
               </div>
            </div>
         </CSSTransitionGroup>
      </>
   );
};

const mapStateToProps = state => ({
   enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
   closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
   headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
   enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
