import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import SideBar from '../../../components/aside/Aside';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';

import { setEnableMobileMenu } from '../../../reducers/ThemeOptions';

const AppSidebar = props => {
   let {
      backgroundColor,
      enableBackgroundImage,
      enableSidebarShadow,
      backgroundImage,
      backgroundImageOpacity,
   } = props;

   return (
      <>
         {props.enableClosedSidebar ? (
            <></>
         ) : (
            <Fragment>
               <div className="sidebar-mobile-overlay" />
               <ReactCSSTransitionGroup
                  component="div"
                  className={cx('app-sidebar', backgroundColor, {
                     'sidebar-shadow': enableSidebarShadow,
                  })}
                  transitionName="SidebarAnimation"
                  transitionAppear={true}
                  transitionAppearTimeout={1500}
                  transitionEnter={false}
                  transitionLeave={false}>
                  <HeaderLogo />
                  <PerfectScrollbar
                     className="perfectScrollbar"
                     style={{
                        textAlign: 'left',
                        height: 'calc(100vh - 70px)',
                     }}>
                     <div className="app-sidebar__inner">
                        <SideBar />
                     </div>
                  </PerfectScrollbar>
                  <div
                     className={cx('app-sidebar-bg', backgroundImageOpacity)}
                     style={{
                        backgroundImage: enableBackgroundImage
                           ? 'url(' + backgroundImage + ')'
                           : null,
                     }}></div>
               </ReactCSSTransitionGroup>
            </Fragment>
         )}
      </>
   );
};

const mapStateToProps = state => ({
   enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
   enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
   enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
   enableMobileMenu: state.ThemeOptions.enableMobileMenu,
   backgroundColor: state.ThemeOptions.backgroundColor,
   backgroundImage: state.ThemeOptions.backgroundImage,
   backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({
   setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
