import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingButton = props => {
   const { style } = props;

   return (
      <>
         <LoadingOutlined style={style} spin />
      </>
   );
};

export default LoadingButton;
