import styled from 'styled-components';

export const StepControllerContainer = styled.div`
   display: flex !important;
   justify-content: flex-end;
   gap: 0.5rem;

   @media (max-width: 768px) {
      flex-direction: column !important;
      justify-content: flex-end !important;
   }
`;
