import React from 'react';
import { Wrapper } from './styles';

const Termos = () => {
   return (
      <Wrapper>
         <h1>TERMOS DE USO</h1>
         <p>
            O presente documento estabelece um conjunto de condições gerais e
            ações sobre o uso do Fidelêco, incluindo aqui o software, site e
            marca de propriedade do Fidelêco CNPJ 41.002.166/0001-24, denominado
            apenas como Fideleco.
         </p>
         <p>
            O Usuário encontrará nestes termos deveres e obrigações para
            utilização do Software e navegação no site.
         </p>
         <h2>DEFINIÇÕES</h2>
         <p>
            <strong>Usuários:</strong> todas as pessoas que acessam o site, ou
            que se cadastram no sistema Fidelêco, recebendo uma identificação
            individual e exclusiva a fim de participarem de programas de
            fidelidade dos estabelecimentos de “Comerciantes”.
         </p>
         <p>
            <strong>Comerciantes:</strong> estabelecimentos comerciais que
            contratam o Software Fidelêco para oferecer aos Usuários
            recompensas, vantagens e programas de fidelidade.
         </p>
         <h2>OBJETO</h2>
         <p>
            O objeto destes Termos de Uso diz respeito à disponibilização aos
            Usuários do site e software Fidelêco, o qual viabiliza que o
            Comerciante crie e gerencie programas de fidelidade e vantagens por
            meio da plataforma Fideleco e website, a fim de que o Usuário
            usufrua dos referidos programas. O Software Fidelêco é criado e
            disponibilizado pela FIDELECO EMPRESA DE SOFTWARE LTDA.
         </p>
         <h2>ACEITE</h2>
         <p>
            Ao acessar o site ou o Software, o Usuário deve ler o presente
            termo, bem como a política de privacidade, manifestando sua
            concordância com relação às suas disposições, clicando no botão
            indicado para esta finalidade.
         </p>
         <p>
            O Usuário demonstra sua aceitação livre, informada e expressa, sem
            quaisquer reservas acerca de todo o conteúdo deste documento. Na
            hipótese de não concordar com qualquer parte destes termos, o
            Usuário deve abster-se de utilizar a plataforma e o site do
            Fidelêco.
         </p>
         <h2>DA CAPACIDADE</h2>
         <p>
            O Usuário, ao utilizar a plataforma, garante que possui plena
            capacidade civil, além de ser maior de 18 (dezoito) anos de idade,
            sendo vedado qualquer utilização por menores de idade.
         </p>
         <p>
            Ainda, o Usuário tem ciência de que eventuais benefícios poderão
            variar ou ser condicionados à idade do Usuário, a fim de cumprir a
            legislação sobre a matéria.
         </p>
         <h2>SOBRE O FIDELÊCO</h2>
         <p>
            O Fidelêco é uma plataforma digital de relacionamento entre o
            Usuário e terceiros Comerciantes, e tem como finalidade
            disponibilizar um software no qual os Comerciantes que desejem
            fidelizar seus clientes por meio do consumo de um serviço ou
            produto, criem benefícios específicos e programas de fidelidade, que
            podem ou não incluir a distribuição de pontos, promoções e
            recompensas aos Usuários.
         </p>
         <p>
            O FIDELÊCO NÃO É UMA PLATAFORMA DE INTERMEDIAÇÃO DE VENDAS, não
            sendo, portanto, obrigada, direta ou indiretamente, a entregar
            qualquer tipo de produto ou serviço, tampouco responsável por
            qualquer falha na prestação de serviços prestados pelos
            Comerciantes, notificações enviadas aos Usuários, bem como outros
            danos causados por culpa exclusiva do Comerciante.
         </p>
         O Fidelêco compromete-se a envidar os melhores esforços para garantir o
         pleno funcionamento do Software, de modo a evitar, na medida do
         possível, intermitências na plataforma, bem como quaisquer falhas e/ou
         interferências.
         <p>
            O Usuário reconhece que o Fidelêco é uma plataforma tecnológica, que
            está sujeita a falhas, interrupções, atrasos e/ou erros, e que não
            podemos controlar tais fatores, mas vamos agir com diligência para
            manter as soluções e serviços disponibilizados funcionando
            corretamente.
         </p>
         No entanto, não somos responsáveis de nenhuma forma por tais
         interrupções, atrasos e erros, bem como por quaisquer danos,
         alegadamente ocasionados aos Usuários relacionados a tais interrupções,
         atrasos e erros.
         <p>
            Em razão da complexidade e impossibilidade de isenção de falhas nos
            sistemas de informática, por sua própria natureza, o Fidelêco não
            garante que o serviço será sempre, em todas as hipóteses, pontual,
            seguro, sem erros ou sem quaisquer interrupções.
         </p>
         <p>
            Ainda, caso o Usuário sofra eventuais perdas e danos em virtude de
            atos cometidos por terceiros, o Fidelêco não se responsabiliza por
            qualquer tipo de responsabilidade decorrente desta relação, sejam
            fiscais, trabalhistas, consumeristas ou de qualquer outra natureza,
            pois não há qualquer intermediação do Fidelêco nas negociações
            existentes entre o Usuário e o Comerciante.
         </p>
         <h2>PROGRAMA DE FIDELIDADE</h2>
         <p>
            Ao utilizar o software Fidelêco, o Comerciante poderá criar e
            gerenciar programas de fidelidade e o Usuário poderá participar
            destes programas. Estes programas de fidelidade são administrados
            pelos comerciantes, e também podem incluir o envio de notificações
            pelos Comerciantes aos Usuários.
         </p>
         <p>
            As recompensas disponibilizadas pelos Comerciantes são para fins
            exclusivamente promocionais, não configurando direito adquirido e
            independem do Fidelêco, sendo de total responsabilidade do
            Comerciante responsável.
         </p>
         <p>
            Os resgates das recompensas também são de responsabilidade do
            Comerciante, no que tange à quantidade, qualidade, estado,
            legitimidade, integridade, prazos e até a existência de tais
            recompensas. O Usuário isenta o Fidelêco de qualquer
            responsabilidade advinda da relação entre o Usuário e o Comerciante.
         </p>
         <p>
            O Usuário está limitado a somente uma Recompensa por resgate, exceto
            se o Comerciante estabelecer de forma diversa.
         </p>
         <h2>DAS OBRIGAÇÕES</h2>
         <p>São obrigações do Usuário:</p>
         <ul>
            <li>
               Observar e cumprir todas as leis, regulamentos e instruções
               aplicáveis;
            </li>
            <li>
               Não utilizar a plataforma para finalidade diversa da prevista
               nestes termos;
            </li>
            <li>
               Não divulgar spam; marketing multinível; publicidade irregular ou
               em desacordo às normas; propagadas e com conteúdo potencialmente
               ofensivo, obsceno, pornográfico, que promova o terrorismo;
               qualquer tipo de discriminação, independente de sexo, raça,
               religião, origem, cor, condição física, estado civil, idade,
               situação familiar, estado gravídico etc; e qualquer atentado aos
               direitos individuais;
            </li>
            <li>
               Responsabilizar-se por todas as informações, imagens e dados
               inseridos e enviados na plataforma, associados à sua conta,
               concordando que toda integridade, legitimidade, veracidade e
               adequação dos dados e imagens inseridos no Fidelêco serão de
               inteira responsabilidade dos Usuários; e{' '}
            </li>
            <li>Inserir dados e informações atualizadas;</li>
         </ul>
         <br />
         <p>O Usuário não pode:</p>
         <ul>
            <li>Resgatar suas recompensas em dinheiro;</li>
            <li>
               cumular recompensas, cupons, vales, ou qualquer forma de
               promoção, exceto se o Comerciante estabelecer de forma diversa;{' '}
            </li>
            <li>Vender qualquer tipo de recompensa; </li>
            <li>
               Tentar resgatar quaisquer recompensas violando estes Termos de
               Uso, ou as regras criadas pelo próprio Comerciante sobre o plano
               de fidelidade disponibilizado; e{' '}
            </li>
            <li>
               Inserir qualquer tipo de código malicioso (malware) na
               plataforma.
            </li>
         </ul>
         <p>
            Qualquer violação a estes termos o Usuário resultará em perda da sua
            recompensa.
         </p>
         <p>
            Ainda, você concorda em nos indenizar, e manter nossos
            diretores(as), conselheiros(as), empregados(as) e agentes
            isentos(as) de responsabilidade por todas e quaisquer reclamações,
            cobranças, prejuízos, responsabilidades e despesas (inclusive
            honorários advocatícios) decorrentes ou relacionados: ao uso dos
            nossos serviço de serviços ou bens obtidos por meio do uso dos
            nossos serviços; descumprimento ou violação de qualquer disposição
            destes termos; ou violação dos direitos de terceiros.
         </p>
         <h2>SOBRE A PROPRIEDADE INTELECTUAL</h2>
         <p>
            Ainda, estes termos não transferem para o Usuário qualquer direito
            de propriedade intelectual que o Fidelêco possua sobre os seus
            processos e sistemas e/ou qualquer direito intelectual que venha a
            criar, construir ou adquirir. Assim, o Usuário não deve, a qualquer
            tempo, reivindicar ou adquirir qualquer direito, título ou interesse
            sobre a propriedade intelectual do Fidelêco e
            reconhece/compromete-se a respeitar todos os direitos, títulos e
            interesses do Fidelêco, obrigando-se a não intentar qualquer ação
            que possa prejudicar ou questionar ou anular tais direitos.
         </p>
         <h2>SOBRE OS DADOS PESSOAIS</h2>
         <p>
            O Fidelêco trata seus dados conforme disposto em nossa Política de
            Privacidade, sempre em conformidade com a Lei 13.709/2018 – Lei
            Geral de Proteção de Dados Pessoais.
         </p>
         <p>
            Os dados e informações coletados serão armazenados em ambiente
            seguro e somente poderão ser acessadas por pessoas autorizadas e
            pelo Comerciante ao qual o Usuário se integrou por meio do programa
            de fidelidade disponibilizado.
         </p>
         <h2>SOBRE O CADASTRO NA PLATAFORMA</h2>
         <p>
            Você pode se cadastrar diretamente em nossa plataforma, ou utilizar
            serviços, websites ou aplicativos de terceiros. Porém, ao utilizar a
            plataforma por meio desses aplicativos de terceiros, você autoriza
            que o Fidelêco compartilhe e envie todas as informações do Usuário
            para esse terceiro (inclusive informações de Cadastro, login e
            senha).
         </p>
         <p>
            Ainda, ao acessar a plataforma via terceiros, o Usuário deve saber
            que todos seus dados compartilhados automaticamente ficam sob a
            responsabilidade da própria política de privacidade destes
            terceiros, não podendo haver responsabilidade do Fidelêco sobre a
            forma como aqueles utilizam suas informações.
         </p>
         <p>
            Tudo o que o terceiro faz é responsabilidade exclusivamente dele
            (informações, veracidade, disponibilidade, conteúdo, nota, opinião,
            comunicação, informes etc). O Fidelêco disponibiliza essa função por
            uma questão de praticidade para os Usuários.
         </p>
         <p>
            Por todos os motivos mencionados acima, recomendamos que você se
            cadastre direto pela plataforma do Fidelêco.
         </p>
         <h2>INDEPENDÊNCIA DAS CLÁUSULAS </h2>
         <p>
            No caso de eventual consideração de qualquer disposição destes
            Termos como ilegal, nula ou ineficaz, tal disposição deve, contudo,
            ser aplicável até ao limite máximo permitido pela lei aplicável, e a
            porção inexequível será considerada separada desses Termos. Porém,
            tal determinação não prejudica a validade e aplicabilidade de
            quaisquer outras disposições restantes.
         </p>
         <h2>DISPOSIÇÕES FINAIS E FORO</h2>
         <p>
            Caso ocorra do Fidelêco deixar de exercer ou executar quaisquer
            direitos ou disposições contidos neste termo, isso não constituirá
            renúncia dos mesmos.
         </p>
         <p>
            Ainda, ao utilizar os serviços disponibilizados, você também
            concorda com nossa POLÍTICA DE PRIVACIDADE.
         </p>
         <p>
            Eventuais dúvidas que possam surgir em virtude destes termos,
            contrato ou privacidade devem ser enviadas para CONTATO (17)
            99718-9136 ou atendimento@fideleco.com.br
         </p>
         <p>
            As Partes elegem, como único competente para a solução ou
            interpretação de cláusulas ou oriundas do presente termo, as que
            amigavelmente não puderem resolver, o Foro da Comarca de São José do
            Rio Preto, Estado de São Paulo, em prejuízo de qualquer outro por
            mais privilegiado que seja.
         </p>
      </Wrapper>
   );
};

export default Termos;
