import React from 'react';
import { useField } from 'formik';

const Textarea = props => {
   const [field, meta] = useField(props);

   const textareaStyles = {
      border: '1px solid #767676',
      borderRadius: '8px',
      padding: '8px',
      margin: '0 2px',
      width: props?.width || '100%',
      height: props?.height || 'auto',
   };

   return (
      <>
         <textarea
            id={props.name}
            // ref={ref}
            {...field}
            {...props}
            style={textareaStyles}
            autoComplete="off"
         />
         <label htmlFor={props?.id || props?.name} />
         {meta.error && meta.touched && (
            <div className="input-validation-error">{meta.error}</div>
         )}
      </>
   );
};

export default Textarea;
