import React from 'react';
import { useField } from 'formik';

const InputFormik = props => {
   const [field, meta, helpers] = useField(props);

   const onCallChange = async e => {
      const file = e.target.files[0];

      return props.onChange(file);
   };

   return (
      <>
         <label className="mt-2" htmlFor={props.name} style={props.style}>
            <p className="m-0">{props.label}</p>
            <input
               id={props.name}
               // ref={ref}
               {...field}
               {...props}
               onChange={e => onCallChange(e)}
               className={`input-unform ${props.disabled ? 'disabled' : ''}`}
               type="file"
               autoComplete="off"
               style={{ display: 'none' }}
            />
         </label>

         {meta.error && meta.touched && (
            <div className="input-validation-error">{meta.error}</div>
         )}
      </>
   );
};

export default InputFormik;
