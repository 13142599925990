import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import RoutePrivate from './Route';

//Components

// Pages
import InformacoesCadastrais from '../pages/informacoesCadastrais/InformacoesCadastrais';
import HomeAdmin from '../pages/dashboardAdmin/HomeAdmin';
import FormWizardVar1 from '../pages/cadastroAdmin/Form';
import InitialPages from '../pages/login/index';
import Error from '../pages/404';

//Fidelizar
import GerenciarPremios from '../pages/fidelizar/gerenciarPremios/GerenciarPremios';
import PontuarLote from '../pages/fidelizar/pontuarLote/PontuarLote';

//Configuração de fidelidade
import ConfigProgramaFidelidade from '../pages/configuracao/configurarProgramaFidelidade/ConfigurarProgramaFidelidade';
import CustomProgramaFidelidade from '../pages/customProgramaFidelidade/CustomProgramaFidelidade';
import ListaBanner from '../pages/configuracao/listaBanner/GerenciarBanner';
import ListaUsuario from '../pages/configuracao/listaUsuario/ListaUsuario';
import GerarCupom from '../pages/configuracao/gerarCupom/GerarCupom';
import ListaLoja from '../pages/configuracao/listaLoja/ListaLoja';
import ListaLojaNova from '../pages/configuracao/listaLoja/ListaLojaNova';
//Clientes
import ValidarPremiosClientes from '../pages/clientes/validarPremiosClientes/ValidarPremiosClientes';
import GerenciarClientes from '../pages/clientes/gerenciarClientes/GerenciarClientes';
import EstornarClientes from '../pages/clientes/estornarClientes/EstornarClientes';
import PontuarClientes from '../pages/clientes/pontuarClientes/PontuarClientes';

//Usuarios
import PageRegulamento from '../pages/usuario/regulamento/Regulamento';
import AlterarSenha from '../pages/usuario/alterarSenha/alterarSenha';
import Planos from '../pages/usuario/planos/planos';

//Relatorios
import MeusClientes from '../pages/relatorios/MeusClientes';
import Aniversariantes from '../pages/relatorios/Aniversariantes';
import ResgatePremios from '../pages/relatorios/ResgatePremios';
import CupomValePontos from '../pages/relatorios/CupomValePontos';
import ExtratoGeral from '../pages/relatorios/ExtratoGeral';

export default function routes() {
   return (
      <>
         <BrowserRouter>
            <Switch>
               <RoutePrivate path="/" component={InitialPages} exact />
               <RoutePrivate
                  path="/cadastro/empresa"
                  component={FormWizardVar1}
                  isPrivate
               />
               <RoutePrivate
                  path="/home-admin"
                  component={HomeAdmin}
                  isPrivate
               />
               <RoutePrivate
                  path="/informacoes-cadastrais"
                  component={InformacoesCadastrais}
                  isPrivate
               />

               {/*Fidelizar*/}

               <RoutePrivate
                  path="/fidelizar/gerenciar-premios"
                  component={GerenciarPremios}
                  isPrivate
               />
               <RoutePrivate
                  path="/fidelizar/pontuacao-lote"
                  component={PontuarLote}
                  isPrivate
               />

               {/*Configuração de fidelidade*/}
               <RoutePrivate
                  path="/configuracao/personalizar-programa-fidelidade"
                  component={CustomProgramaFidelidade}
                  isPrivate
               />
               <RoutePrivate
                  path="/configuracao/gerenciar-usuarios"
                  component={ListaUsuario}
                  isPrivate
               />
               <RoutePrivate
                  path="/configuracao/gerenciar-banner"
                  component={ListaBanner}
                  isPrivate
               />
               <RoutePrivate
                  path="/configuracao/configurar-programa-de-fidelidade"
                  component={ConfigProgramaFidelidade}
                  isPrivate
               />
               <RoutePrivate
                  path="/configuracao/gerenciar-lojas"
                  component={ListaLojaNova}
                  isPrivate
               />
               <RoutePrivate
                  path="/configuracao/gerar-cupom"
                  component={GerarCupom}
                  isPrivate
               />

               {/*Clientes */}
               <RoutePrivate
                  path="/clientes/pontuar"
                  component={PontuarClientes}
                  isPrivate
               />
               <RoutePrivate
                  path="/clientes/gerenciar-clientes"
                  component={GerenciarClientes}
                  isPrivate
               />
               <RoutePrivate
                  path="/clientes/estornar-clientes"
                  component={EstornarClientes}
                  isPrivate
               />
               <RoutePrivate
                  path="/clientes/validar-premios"
                  component={ValidarPremiosClientes}
                  isPrivate
               />

               {/* Usuarios */}
               <RoutePrivate
                  path="/usuario/alterar-senha"
                  component={AlterarSenha}
                  isPrivate
               />
               <RoutePrivate
                  path="/usuario/planos"
                  component={Planos}
                  isPrivate
               />
               <RoutePrivate
                  path="/usuario/regulamento"
                  component={PageRegulamento}
                  isPrivate
               />

               {/* Relatorios */}

               <RoutePrivate
                  path="/relatorios/meus-clientes"
                  component={MeusClientes}
                  isPrivate
               />

               <RoutePrivate
                  path="/relatorios/aniversariantes"
                  component={Aniversariantes}
                  isPrivate
               />

               <RoutePrivate
                  path="/relatorios/resgate-premios"
                  component={ResgatePremios}
                  isPrivate
               />

               <RoutePrivate
                  path="/relatorios/cupom-vale-pontos"
                  component={CupomValePontos}
                  isPrivate
               />

               <RoutePrivate
                  path="/relatorios/extrato-geral"
                  component={ExtratoGeral}
                  isPrivate
               />
               <RoutePrivate path="/404" component={Error} />
               <Redirect from="*" to="/404" />
            </Switch>
         </BrowserRouter>
      </>
   );
}
