import React from 'react';

const SuporteVarejista = () => {
   return (
      <>
         <div>
            Se- djavan
      </div>
      </>
   )
}

export default SuporteVarejista;
