import React, { useState, useEffect, useRef } from 'react';
import api_local from '../../api/api-local';

//Components
import { useAuth } from '../../customHooks/AuthContext';
import ModalCustom from '../../components/Modal/Modal';
import Spinner from '../../components/loading/Loading';
import PageTitle from '../layout/AppMain/PageTitle';
import { helpersUntil } from '../../helpers/until';
import Table from '../../components/FilterTable';

//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import PaginationFilterTable from '../../components/PaginationFilterTable';
import { TextField } from '@material-ui/core';
import moment from 'moment';

const CupomValePontos = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [dataSource, setDataSource] = useState([]);
   const [termoBusca, setTermoBusca] = useState({});
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const ref = useRef();

   const { signOut } = useAuth();

   const columns = [
      {
         title: 'Nome',
         dataIndex: 'nome',
         filters: dataSource.map(cupomValePonto => ({
            text: cupomValePonto.nome,
            value: cupomValePonto.nome,
         })),
         onFilter: (value, record) => record.nome.indexOf(value) === 0,
         sorter: (a, b) => (a.nome < b.nome ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 250,
      },

      {
         title: 'CPF/CNPJ',
         dataIndex: 'cpf',
         filters: dataSource.map(cupomValePonto => ({
            text: cupomValePonto.cpf,
            value: cupomValePonto.cpf,
         })),
         onFilter: (value, record) => record.cpf.indexOf(value) === 0,
         sorter: (a, b) => (a.cpf < b.cpf ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         title: 'Data inicial',
         dataIndex: 'dataIni',
         filters: dataSource.map(cupomValePonto => ({
            text: cupomValePonto.dataIni,
            value: cupomValePonto.dataIni,
         })),
         onFilter: (value, record) => record.dataIni.indexOf(value) === 0,
         sorter: (a, b) => (a.dataIni < b.dataIni ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         title: 'Data final',
         dataIndex: 'dataFin',
         filters: dataSource.map(cupomValePonto => ({
            text: cupomValePonto.dataFin,
            value: cupomValePonto.dataFin,
         })),
         onFilter: (value, record) => record.dataFin.indexOf(value) === 0,
         sorter: (a, b) => (a.dataFin < b.dataFin ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         title: 'Status',
         dataIndex: 'status',
         onFilter: (value, record) => record.status.indexOf(value) === 0,
         sorter: (a, b) => (a.status < b.status ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 50,
      },

      {
         title: 'Quantidade de Pontos',
         dataIndex: 'qtdPontos',
         onFilter: (value, record) => record.qtdPontos.indexOf(value) === 0,
         sorter: (a, b) => (a.qtdPontos < b.qtdPontos ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 20,
      },
   ];

   const filtros = [
      {
         dataIndex: 'nome',
         nome: 'Nome',
         placeholder: 'Digite o nome',
         mask: 'text',
      },
      {
         dataIndex: 'cpf',
         nome: 'CPF/CNPJ',
         placeholder: 'Digite o CPF ou CNPJ',
         mask: 'pessoa',
      },
      {
         dataIndex: 'dataIni',
         nome: 'Data inicial',
         placeholder: 'Digite o data inicial',
         mask: 'date',
      },

      {
         dataIndex: 'dataFin',
         nome: 'Data final',
         placeholder: 'Digite o data final',
         mask: 'date',
      },
   ];

   const handleChangeTermoBusca = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getCupomValePontos(pagination.current, pagination.pageSize);
      }, 1);
   };

   const getCupomValePontos = async (currentPage, pageSize) => {
      setIsLoading(true);

      const dataInicio = termoBusca.dataInicio
         ? moment(termoBusca.dataInicio).format('YYYY-MM-DD')
         : '';
      const dataFim = termoBusca.dataFim
         ? moment(termoBusca.dataFim).format('YYYY-MM-DD')
         : '';

      api_local
         .get('/relatorio/valeponto', {
            params: {
               ...termoBusca,
               pagina: currentPage,
               tamanho: pageSize,
               dataInicio,
               dataFim,
            },
         })
         .then(response => {
            if (response.status === 200) {
               setIsLoading(false);

               if (response.data.data.length < 1) {
                  toast.error('Nenhum cliente encontrado!');
               }
               const dados = response.data.data.map(cliente => {
                  //Formatando data para formato BR
                  const dataInicialFormatada =
                     helpersUntil.converterDateStringForDateBr(
                        cliente.dataValidadeInicio,
                     );

                  const dataFinalFormatada =
                     helpersUntil.converterDateStringForDateBr(
                        cliente.dataValidadeFim,
                     );
                  return {
                     key: cliente.id,
                     id: cliente.id,
                     nome: cliente.nome,
                     cpf: cliente.cpfCnpj,
                     dataIni: dataInicialFormatada,
                     dataFin: dataFinalFormatada,
                     status: cliente.ativo ? 'Ativo' : 'Inativo',
                     qtdPontos: cliente.ponto,
                  };
               });

               setDataSource(dados);

               setPagination(state => ({
                  ...state,
                  current: response.data.currentPage,
                  total: response.data.totalItems,
               }));
            }
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   const exportar = () => {
      api_local
         .get('/relatorio/valeponto/exportar', {
            responseType: 'arraybuffer',
            headers: {
               Accept:
                  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            },
         })
         .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatorio de Cupom vale pontos.csv');
            document.body.appendChild(link);
            link.click();
            setIsLoading(false);
         })
         .catch(error => {
            console.log(error);
            setIsLoading(false);
         });
   };

   useEffect(() => {
      getCupomValePontos(pagination.current, pagination.pageSize);
   }, []);

   return (
      <>
         <PageTitle
            heading="Cupom vale pontos"
            subheading="Gerar seus relatórios"
            icon="pe-7s-display1 icon-gradient bg-fideleco">
            <ModalCustom
               buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
               buttonIcon={
                  <i className="pe-7s-display1 btn-icon-wrapper font-weight-bold"></i>
               }
               buttonName={'Exportar Planilha'}
               buttonSize="lg"
               buttonColor="primary"
               toggleFunc={() => {
                  exportar();
               }}
               isOpen={false}
               size="lg"
            />
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col>
                  <Card className="main-card mb-2 text-left">
                     <CardBody className="table-relatorio">
                        <PaginationFilterTable
                           columns={columns}
                           rowKey={record => record.nome}
                           dataSource={dataSource}
                           pagination={pagination}
                           loading={isLoading}
                           onChange={handleTableChange}
                           handleSubmit={getCupomValePontos}
                           filterChildren={[
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.nome}
                                 onChange={e =>
                                    handleChangeTermoBusca(
                                       'nome',
                                       e.target.value,
                                    )
                                 }
                                 label="Nome"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.cpf}
                                 onChange={e =>
                                    handleChangeTermoBusca(
                                       'cpf',
                                       e.target.value,
                                    )
                                 }
                                 label="CPF"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.dataInicio}
                                 type="text"
                                 onFocus={e => (e.target.type = 'date')}
                                 onBlur={e => (e.target.type = 'text')}
                                 onChange={e =>
                                    handleChangeTermoBusca(
                                       'dataInicio',
                                       e.target.value,
                                    )
                                 }
                                 label="Data inicio"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca?.dataFim}
                                 type="text"
                                 onFocus={e => (e.target.type = 'date')}
                                 onBlur={e => (e.target.type = 'text')}
                                 onChange={e =>
                                    handleChangeTermoBusca(
                                       'dataFim',
                                       e.target.value,
                                    )
                                 }
                                 label="Data fim"
                              />,
                           ]}
                        />
                        {/* <Table
                           columns={columns}
                           data={dataSource}
                           rowKey={record => record.nome}
                           pagination={pagination}
                           filtros={filtros}
                           onChange={handleTableChange}
                        /> */}
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default CupomValePontos;
