import React, { useState } from 'react';
import InputFile from '../../../components/Formulario/InputFile';
import PageTitle from '../../layout/AppMain/PageTitle';
import { toast } from 'react-toastify';
import api from '../../../api/api-local';
import { useAuth } from '../../../customHooks/AuthContext';
import Tooltip from '../../../components/tooltip';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button, Form } from 'reactstrap';
import Spinner from '../../../components/loading/Loading';

const PontuarLote = () => {
   const { signOut } = useAuth();
   const [files, setFile] = useState();
   const [loading, setLoading] = useState(false);

   const onChangePicture = event => {
      setFile(event.target.files[0]);
   };
   const submitPontuacaoLote = event => {
      event.preventDefault();
      try {
         setLoading(true);
         const formData = new FormData();
         const config = {
            headers: {
               'Content-Type': 'multipart/form-data',
            },
         };
         formData.append('arquivo', files);
         api.post('pontuacao/lote', formData, config)
            .then(response => {
               if (response.status === 200) {
                  toast.success('Pontuação adicionada com sucesso!');
               }
            })
            .catch(error => {
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            })
            .finally(() => {
               setLoading(false);
            });
      } catch (error) {
         console.log(error);
      }
   };

   const [tooltipOpen01, setTooltipOpen01] = useState(false);

   const toggleTooltip01 = () => setTooltipOpen01(!tooltipOpen01);
   return (
      <>
         <PageTitle
            heading="Pontuar em lote"
            subheading="Pontue seus clientes em lote"
            icon="pe-7s-shopbag icon-gradient bg-fideleco"></PageTitle>

         <Row>
            <Col md="12">
               <Card className="main-card mb-3 text-left">
                  <CardBody>
                     <Form onSubmit={submitPontuacaoLote}>
                        <Row
                           form
                           style={{ display: 'flex', alignItems: 'center' }}>
                           <Col md={6}>
                              <div
                                 style={{
                                    maxWidth: '20rem',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    border: ' dashed 2px #6c757d',
                                    margin: 0,
                                    padding: '1rem',
                                    fontSize: '1rem',
                                    marginBottom: '.5rem',
                                 }}>
                                 <InputFile
                                    type="file"
                                    text="Clique aqui e escolha seu arquivo"
                                    style={{
                                       fontSize: '1rem',
                                       color: '#FD5958',
                                       cursor: 'pointer',
                                    }}
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                    size={10}
                                    handleChange={onChangePicture}
                                    tooltip="Arquivo suportado somente na extensão .CSV"
                                    name="logo"
                                    id="profilePic"
                                 />
                              </div>
                              <span
                                 style={{
                                    display: 'flex',
                                    marginLeft: '2px',
                                    justifyContent: 'left',
                                 }}>
                                 Arquivo selecionado: {files?.name}
                              </span>
                              <Link
                                 to="/exemplo_pontuacao_lote.csv"
                                 target="_blank"
                                 download>
                                 Exemplo do arquivo para a pontuação
                              </Link>
                           </Col>
                           <Col md={6} className="mt-5">
                              <div className="d-flex align-items-center">
                                 <div className="mr-auto">
                                    <Button
                                       color="primary"
                                       type="submit"
                                       className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                       size="lg">
                                       Adicionar Pontuação
                                    </Button>
                                 </div>
                              </div>
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
            {loading && <Spinner />}
         </Row>
      </>
   );
};

export default PontuarLote;
