export default function validateFormProgramaFidelidade(values) {
   let errors = {};
   if (!values.configuracaoSaldoPremio) {
      errors.configuracaoSaldoPremio = 'Campo obrigatório';
   }

   //  if (!values.configuracaoPremio) {
   //     errors.configuracaoPremio = 'Campo obrigatório';
   //  }

   // if (!values.configuracaoSaldoPremio) {
   //    errors.configuracaoSaldoPremio =
   //       'Campo obrigatório! Escolha ao menos uma opção acima';
   // }

   if (!values.fatorConversao) {
      errors.fatorConversao = 'Campo obrigatório';
   }

   if (!values.pontuacaoCadastro) {
      errors.pontuacaoCadastro = 'Campo obrigatório';
   }

   if (!values.pontuacaoIndicacao) {
      errors.pontuacaoIndicacao = 'Campo obrigatório';
   }

   if (!values.validadeCupomValePremio) {
      errors.validadeCupomValePremio = 'Campo obrigatório';
   }

   if (!values.pontuacaoExpiracao) {
      errors.pontuacaoExpiracao = 'Campo obrigatório';
   }

   // if (!values.valorPontuacaoMaximaDia) {
   //    errors.valorPontuacaoMaximaDia = 'Campo obrigatório';
   // }

   // if (!values.quantidadePontuacaoMaximaDia) {
   //    errors.quantidadePontuacaoMaximaDia = 'Campo obrigatório';
   // }

   return errors;
}
