import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalCustom = props => {
   const {
      children,
      titleModal,
      buttonName,
      buttonClass,
      buttonIcon,
      buttonSize,
      buttonColor,
      isOpen,
      toggleFunc,
      closeModal,
      childrenFooter,
      size,
      style,
   } = props;

   return (
      <span className="d-inline-block mb-2 mr-2">
         <Button
            color={buttonColor}
            className={buttonClass}
            size={buttonSize}
            onClick={toggleFunc}
            style={style}>
            {buttonIcon}
            {buttonName}
         </Button>
         <Modal
            isOpen={isOpen}
            size={size}
            toggle={toggleFunc}
            className={props.className}>
            <ModalHeader toggle={closeModal}>{titleModal}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            {childrenFooter ? (
               <ModalFooter>{childrenFooter}</ModalFooter>
            ) : null}
         </Modal>
      </span>
   );
};

export default ModalCustom;
