import React, { useState, useEffect } from 'react';
import api_local from '../../api/api-local';

//Components
import ModalCustom from '../../components/Modal/Modal';
import Spinner from '../../components/loading/Loading';
import PageTitle from '../layout/AppMain/PageTitle';
import { useAuth } from '../../customHooks/AuthContext';
import { helpersUntil } from '../../helpers/until';

//Libs
import { Row, Col, Card, CardBody, Table, Button } from 'reactstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { toast } from 'react-toastify';
import PaginationFilterTable from '../../components/PaginationFilterTable';
import { IconButton, TextField } from '@material-ui/core';
import { ZoomInOutlined } from '@material-ui/icons';
import moment from 'moment';
import { Tag } from 'antd';

const MeusClientes = () => {
   const [dataSource, setDataSource] = useState([]);
   const [clienteId, setClienteId] = useState('');
   const [lojaId, setLojaId] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [termoBusca, setTermoBusca] = useState({});
   const [relatorioDetalhes, setRelatorioDetalhes] = useState({});
   const [modalIsOpen, setIsOpen] = useState(false);
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const { signOut } = useAuth();

   const openModal = () => {
      setIsOpen(true);
   };

   const closeModal = () => {
      setIsOpen(false);
   };

   const columns = [
      {
         title: 'Nome',
         dataIndex: 'nome',
         filters: dataSource.map(cliente => ({
            text: cliente.nome,
            value: cliente.nome,
         })),
         render: (value, record) => (
            <span>
               {!record.possuiVinculoMultiLoja ? (
                  <>{record.nome}</>
               ) : (
                  <>
                     {record.nome}
                     <Tag color="red" key={record.nome}>
                        Duplicado
                     </Tag>
                  </>
               )}
            </span>
         ),
         onFilter: (value, record) => record.nome.indexOf(value) === 0,
         sorter: (a, b) => (a.nome < b.nome ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '20%',
      },
      {
         title: 'CPF/CNPJ',
         dataIndex: 'cpf',
         filters: dataSource.map(cliente => ({
            text: cliente.cpf,
            value: cliente.cpf,
         })),
         onFilter: (value, record) => record.cpf.indexOf(value) === 0,
         sorter: (a, b) => (a.cpf < b.cpf ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '15%',
      },

      {
         title: 'E-mail',
         dataIndex: 'email',
         filters: dataSource.map(cliente => ({
            text: cliente.email,
            value: cliente.email,
         })),
         onFilter: (value, record) => record.email.indexOf(value) === 0,
         sorter: (a, b) => (a.email < b.email ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '15%',
      },

      {
         title: 'Celular',
         dataIndex: 'celular',
         filters: dataSource.map(cliente => ({
            text: cliente.celular,
            value: cliente.celular,
         })),
         onFilter: (value, record) => record.celular.indexOf(value) === 0,
         sorter: (a, b) => (a.celular < b.celular ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '10%',
      },

      {
         title: 'Data de vínculo',
         dataIndex: 'dataVinculo',
         filters: dataSource.map(cliente => ({
            text: cliente.dataVinculo,
            value: cliente.dataVinculo,
         })),
         onFilter: (value, record) => record.dataVinculo.indexOf(value) === 0,
         sorter: (a, b) => (a.dataVinculo < b.dataVinculo ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '10%',
      },

      {
         title: 'Saldo de Pontos',
         dataIndex: 'saldoPontos',
         filters: dataSource.map(cliente => ({
            text: cliente.saldoPontos,
            value: cliente.saldoPontos,
         })),
         onFilter: (value, record) => record.saldoPontos.indexOf(value) === 0,
         sorter: (a, b) => (a.saldoPontos < b.saldoPontos ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         align: 'center',
         width: '10%',
      },

      {
         title: 'Pontos Resgatados',
         dataIndex: 'saldoPontosResgatados',
         filters: dataSource.map(cliente => ({
            text: cliente.saldoPontosResgatados,
            value: cliente.saldoPontosResgatados,
         })),
         onFilter: (value, record) =>
            record.saldoPontosResgatados.indexOf(value) === 0,
         sorter: (a, b) =>
            a.saldoPontosResgatados < b.saldoPontosResgatados ? -1 : 1,
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         align: 'center',
         width: '10%',
      },
      {
         title: 'Detalhamento',
         dataIndex: 'detalhamento',
         filters: dataSource.map(cliente => ({
            text: cliente.saldoPontosResgatados,
            value: cliente.saldoPontosResgatados,
         })),
         onClick: (value, record) => {
            handleOpenDetails(value, record);
         },
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         align: 'center',
         width: '10%',
      },
   ];

   const filtros = [
      {
         dataIndex: 'nome',
         nome: 'Nome',
         placeholder: 'Digite o nome',
         mask: 'text',
      },
      {
         dataIndex: 'cpf',
         nome: 'CPF/CNPJ',
         placeholder: 'Digite o CPF ou CNPJ',
         mask: 'pessoa',
      },
      {
         dataIndex: 'email',
         nome: 'Email',
         placeholder: 'Digite o email',
         mask: 'text',
      },
   ];

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getListaClientes(pagination.current, pagination.pageSize);
      }, 1);
   };

   const handleChangePerson = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   const getListaClientes = (currentPage, pageSize) => {
      setIsLoading(true);

      api_local
         .get('/relatorio/cliente', {
            params: {
               pagina: currentPage,
               tamanho: pageSize,
               ...termoBusca,
            },
         })
         .then(response => {
            if (response.status === 200) {
               setIsLoading(false);

               if (response.data.data.length < 1) {
                  toast.error('Nenhum cliente encontrado!');
               }

               const dados = response.data.data.map(cliente => {
                  const dataVinculoFormatada =
                     helpersUntil.converterDateStringForDateBr(
                        cliente.dataVinculo,
                     );
                  return {
                     key: cliente.clienteId,
                     id: cliente.clienteId,
                     nome: cliente.nome,
                     cpf: cliente.cpfCnpj,
                     email: cliente.email,
                     celular: cliente.celular,
                     dataVinculo: dataVinculoFormatada,
                     saldoPontos: cliente.saldo,
                     saldoPontosResgatados: cliente.resgatado,
                     detalhamento: (
                        <IconButton
                           onClick={() => handleOpenDetails(cliente)}
                           aria-label="info">
                           <ZoomInOutlined />
                        </IconButton>
                     ),
                  };
               });

               setDataSource(dados);

               setPagination(state => ({
                  ...state,
                  current: response.data.currentPage,
                  total: response.data.totalItems,
               }));
            }
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   const handleOpenDetails = async ({
      clienteId: _clienteId,
      lojaId: _lojaId,
   }) => {
      try {
         setIsLoading(true);

         setClienteId(_clienteId);
         setLojaId(_lojaId);

         const { data } = await api_local.get(
            `/relatorio/cliente/detalhe/${_clienteId}/${_lojaId}`,
         );
         openModal();
         setRelatorioDetalhes(data);
         console.log('->', data);
      } catch (error) {
      } finally {
         setIsLoading(false);
      }
   };

   //exportando relatorios
   const exportar = () => {
      setIsLoading(true);

      api_local
         .get('/relatorio/cliente/exportar', {
            responseType: 'arraybuffer',
            headers: {
               Accept:
                  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            },
         })
         .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatorio clientes.csv');
            document.body.appendChild(link);
            link.click();
            setIsLoading(false);
         })
         .catch(error => {
            console.log(error);
            setIsLoading(false);
         });
   };

   const exportarPontos = () => {
      setIsLoading(true);

      api_local
         .get(
            `/relatorio/cliente/historicoponto/exportar/${clienteId}/${lojaId}`,
            {
               responseType: 'arraybuffer',
               headers: {
                  Accept:
                     '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
               },
            },
         )
         .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatorio clientes.csv');
            document.body.appendChild(link);
            link.click();
            setIsLoading(false);
         })
         .catch(error => {
            console.log(error);
            setIsLoading(false);
         });
   };

   const exportarPremios = () => {
      setIsLoading(true);

      api_local
         .get(
            `/relatorio/cliente/historicopremio/exportar/${clienteId}/${lojaId}`,
            {
               responseType: 'arraybuffer',
               headers: {
                  Accept:
                     '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
               },
            },
         )
         .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Relatorio clientes.csv');
            document.body.appendChild(link);
            link.click();
            setIsLoading(false);
         })
         .catch(error => {
            console.log(error);
            setIsLoading(false);
         });
   };

   useEffect(() => {
      getListaClientes(pagination.current, pagination.pageSize);
   }, []);

   const CustomizedCol = ({ name, value }) => (
      <tr>
         <td
            colSpan={2}
            style={{
               display: 'flex',
               justifyContent: 'space-between',
            }}>
            <span style={{ fontWeight: 'bold' }}>{name}</span>
            <span>{value}</span>
         </td>
      </tr>
   );

   return (
      <>
         <ModalCustom
            titleModal="Detalhes do cliente"
            toggleFunc={openModal}
            isOpen={modalIsOpen}
            closeModal={closeModal}
            size="xxl">
            <Row style={{ textAlign: 'left' }}>
               <Col xs={4}>
                  <Table striped bordered>
                     <thead>
                        <tr>
                           <th style={{ textAlign: 'center' }}>
                              DETALHES DO CLIENTE
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <CustomizedCol
                           name="Identificação"
                           value={relatorioDetalhes.nome}
                        />
                        <CustomizedCol
                           name="CPF/CNPJ"
                           value={relatorioDetalhes.cpfCnpj}
                        />
                        <CustomizedCol
                           name="E-mail"
                           value={relatorioDetalhes.email}
                        />
                        <CustomizedCol
                           name="Celular"
                           value={relatorioDetalhes.celular}
                        />
                        <CustomizedCol
                           name="Data de Aniversário"
                           value={moment(
                              relatorioDetalhes.dataNascimento,
                           ).format('DD/MM/YYYY')}
                        />
                        <CustomizedCol
                           name="Filial de vínculo"
                           value={relatorioDetalhes.nomeLoja}
                        />
                        <CustomizedCol
                           name="Data de cadastro"
                           value={moment(relatorioDetalhes.dataVinculo).format(
                              'DD/MM/YYYY',
                           )}
                        />
                        <CustomizedCol
                           name="Última movimentação"
                           value={moment(
                              relatorioDetalhes.dataUltimaMovimentacao,
                           ).format('DD/MM/YYYY')}
                        />
                        <CustomizedCol
                           name="Valor consumido"
                           value={relatorioDetalhes.valorTotalConsumo}
                        />
                        <CustomizedCol
                           name="Pontos Dísponiveis"
                           value={relatorioDetalhes.pontuacaoDisponivel}
                        />
                        <CustomizedCol
                           name="Pontos Utilizados"
                           value={relatorioDetalhes.pontuacaoUtilizada}
                        />
                        <CustomizedCol
                           name="Pontos Expirados"
                           value={relatorioDetalhes.pontuacaoExpirada}
                        />
                     </tbody>
                  </Table>
               </Col>
               <Col xs={8}>
                  <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                     <h4 className="d-block">Histórico de pontos</h4>
                     <div>
                        <Button
                           className="top-0 end-0"
                           color="primary"
                           size="sm"
                           disabled={!relatorioDetalhes?.historicoPonto?.length}
                           onClick={exportarPontos}>
                           Exportar pontos
                        </Button>
                     </div>
                  </div>

                  <Table striped>
                     <thead>
                        <tr>
                           <th>Filial</th>
                           <th>Data</th>
                           <th>Horário</th>
                           <th>Valor Consumido</th>
                           <th>Pontos Creditados</th>
                           <th>Pontos Debitados</th>
                           <th>Expirado?</th>
                           <th>Descrição</th>
                           <th>Operador</th>
                           <th>Forma Pagamento</th>
                        </tr>
                     </thead>
                     <tbody>
                        {relatorioDetalhes?.historicoPonto?.map(h => (
                           <tr>
                              <td>{h.nomeLoja}</td>
                              <td>{moment(h.data).format('DD/MM/YYYY')}</td>
                              <td>{h.hora}</td>
                              <td>{h.valorConsumido}</td>
                              <td>{h.pontuacaoCreditada}</td>
                              <td>{h.pontuacaoDebitada}</td>
                              <td>{h.expirado}</td>
                              <td>{h.descricao}</td>
                              <td>{h.operador}</td>
                              <td>{h.formaPagamento !== ''
                                    ? h.formaPagamento
                                    : '-'}</td>
                           </tr>
                        ))}
                     </tbody>
                  </Table>
                  <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                     <h4 className="d-block">Histórico de prêmios</h4>
                     <div>
                        <Button
                           className="top-0 end-0"
                           color="primary"
                           size="sm"
                           disabled={
                              !relatorioDetalhes?.historicoPremio?.length
                           }
                           onClick={exportarPremios}>
                           Exportar prêmios
                        </Button>
                     </div>
                  </div>

                  <Table striped>
                     <thead>
                        <tr>
                           <th>Filial</th>
                           <th>Cupom</th>
                           <th>Prêmio</th>
                           <th>Data de Emissão</th>
                           <th>Validade do Cupom</th>
                           <th>Pontos Utilizados</th>
                           <th>Status do Cupom</th>
                           <th>Data do Resgate</th>
                           <th>Voucher resgatado por</th>
                        </tr>
                     </thead>
                     <tbody>
                        {relatorioDetalhes?.historicoPremio?.map(h => (
                           <tr>
                              <td>{h.nomeLoja}</td>
                              <td>{h.cupom}</td>
                              <td>{h.premio}</td>
                              <td>
                                 {moment(h.dataEmissao).format('DD/MM/YYYY')}
                              </td>
                              <td>
                                 {moment(h.dataValidade).format('DD/MM/YYYY')}
                              </td>
                              <td>{h.pontuacaoUtilizada}</td>
                              <td>{h.statusCupom}</td>
                              <td>
                                 {h.dataResgate !== '-'
                                    ? moment(h.dataResgate).format('DD/MM/YYYY')
                                    : '-'}
                              </td>
                              <td>{h.operador !== '-' ? h.operador : '-'}</td>
                           </tr>
                        ))}
                     </tbody>
                  </Table>
               </Col>
            </Row>
         </ModalCustom>
         <PageTitle
            heading="Meus Clientes"
            subheading="Gerar seus relatórios"
            icon="pe-7s-display1 icon-gradient bg-fideleco">
            <ModalCustom
               buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
               buttonIcon={
                  <i className="pe-7s-display1 btn-icon-wrapper font-weight-bold"></i>
               }
               buttonName={'Exportar Planilha'}
               buttonSize="lg"
               buttonColor="primary"
               toggleFunc={() => {
                  exportar();
               }}
               isOpen={false}
               size="lg"
            />
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col>
                  <Card className="main-card mb-2 text-left">
                     <CardBody className="table-relatorio">
                        <PaginationFilterTable
                           columns={columns}
                           rowKey={record => record.nome}
                           dataSource={dataSource}
                           pagination={pagination}
                           loading={isLoading}
                           onChange={handleTableChange}
                           handleSubmit={getListaClientes}
                           filterChildren={[
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.nome}
                                 onChange={e =>
                                    handleChangePerson('nome', e.target.value)
                                 }
                                 label="Nome"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.documento}
                                 onChange={e =>
                                    handleChangePerson(
                                       'documento',
                                       e.target.value,
                                    )
                                 }
                                 label="CPF/CNPJ"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.email}
                                 onChange={e =>
                                    handleChangePerson('email', e.target.value)
                                 }
                                 label="Email"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.celular}
                                 onChange={e =>
                                    handleChangePerson(
                                       'celular',
                                       e.target.value,
                                    )
                                 }
                                 label="Celular"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.dataCadastro}
                                 onChange={e =>
                                    handleChangePerson(
                                       'dataCadastro',
                                       e.target.value,
                                    )
                                 }
                                 label="Data de Cadastro"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.ultimaMovimentacao}
                                 onChange={e =>
                                    handleChangePerson(
                                       'ultimaMovimentacao',
                                       e.target.value,
                                    )
                                 }
                                 label="Última movimentação"
                              />,
                           ]}
                        />
                        {/* <Table
                           columns={columns}
                           data={dataSource}
                           rowKey={record => record.nome}
                           pagination={pagination}
                           filtros={filtros}
                           onChange={handleTableChange}
                        /> */}
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default MeusClientes;
