import React, { useState, useEffect } from 'react';

import Table from '../../../components/FilterTable';
import { Input } from 'antd';
import { useAuth } from '../../../customHooks/AuthContext';
import api from '../../../api/api-local';

//components
import Spinner from '../../../components/loading/Loading';
import PageTitle from '../../layout/AppMain/PageTitle';
import ModalCustom from '../../../components/Modal/Modal';

//libs
import { toast } from 'react-toastify';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { Row, Col, Card, CardBody, Button, Form } from 'reactstrap';
import ModalConfirmResgate from './ModalConfirmResgate';

const formataDataHora = data => {
   if (!data) return '';
   const horas = data.split(' ')[1];
   const date = data.split(' ')[0].split('-').reverse().join('/');

   return date + ' - ' + horas;
};

const ValidarPremiosClientes = () => {
   const { signOut } = useAuth();
   const [modalIsOpen, setIsOpen] = useState(false);
   const [modalConfirmResgate, setModalConfirmResgate] = useState(false);
   const [pesquisaAvancada, setPesquisaAvancada] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [voucherInfo, setVoucherInfo] = useState({});
   const [codigoVoucher, setCodigoVoucher] = useState('');
   const [allVouchers, setAllVouchers] = useState([]);

   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50'],
      total: 0,
   });

   useEffect(() => {
      getVouchers(1, 5);
   }, []);

   const getVouchers = (currentPage, pageSize) => {
      setIsLoading(true);
      api.get('/voucher/listar', {
         params: {
            pagina: currentPage,
            tamanho: pageSize,
         },
      })
         .then(response => {
            setPagination(state => ({
               ...state,
               current: response.data.currentPage,
               total: response.data.totalItems,
            }));

            response.data.data.map(voucher => {
               voucher.dataValidade = formataDataHora(voucher.dataValidade);
               return voucher;
            });

            setAllVouchers(response.data.data);
            setIsLoading(false);
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   const getVouchersPesquisaAvancada = (currentPage, pageSize) => {
      setIsLoading(true);

      // Verifica o termo de busca e determina se a pesquisa é por Nome ou Documento
      const letters = /^[A-zÀ-ú ]+$/i;
      const pesquisarPor = letters.test(pesquisaAvancada)
         ? 'nome'
         : 'documento';

      api.get('/voucher/listar', {
         params: {
            pagina: currentPage,
            tamanho: pageSize,
            [pesquisarPor]: pesquisaAvancada.trim(),
         },
      })
         .then(response => {
            if (response.data.data.length === 0) {
               toast.warning('Nenhum voucher encontrado.');
            }

            setPagination(state => ({
               ...state,
               current: response.data.currentPage,
               total: response.data.totalItems,
            }));

            response.data.data.map(voucher => {
               voucher.dataValidade = formataDataHora(voucher.dataValidade);
               return voucher;
            });

            setAllVouchers(response.data.data);
            setIsLoading(false);
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   // colunas da tabela
   const columns = [
      {
         title: 'ID',
         dataIndex: 'cupomId',
         sorter: (a, b) => (a.cupomId < b.cupomId ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '10%',
      },
      {
         title: 'Nome',
         dataIndex: 'nome',
         sorter: (a, b) => (a.nome < b.nome ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '30%',
      },
      {
         title: 'CPF/CNPJ',
         dataIndex: 'documento',
         sorter: (a, b) => (a.documento < b.documento ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '25%',
      },
      {
         title: 'Validade do Voucher',
         dataIndex: 'dataValidade',
         sorter: (a, b) => (a.dataValidade < b.dataValidade ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '25%',
      },
      {
         title: '',
         key: 'operation',
         fixed: 'right',
         width: '10%',
         render: row => <Button onClick={() => openModal(row)}>Validar</Button>,
      },
   ];
   // filtros da tabela
   const filtros = [
      {
         dataIndex: 'cupomId',
         nome: 'Voucher ID',
         placeholder: 'Digite o ID do voucher',
         mask: 'text',
      },
      {
         dataIndex: 'nome',
         nome: 'Nome',
         placeholder: 'Digite o nome do cliente',
         mask: 'text',
      },
      {
         dataIndex: 'documento',
         nome: 'CPF/CNPJ',
         placeholder: 'Digite o número do documento',
         mask: 'pessoa',
      },
      {
         dataIndex: 'dataValidade',
         nome: 'Validade',
         placeholder: 'Digite a data de validade',
         mask: 'date',
      },
   ];

   const confirmResgate = event => {
      event.preventDefault();
      if (codigoVoucher.trim() === '') {
         return toast.warn('Informe o código voucher para prosseguir.');
      }
      setModalConfirmResgate(true);
   };

   //functions
   const submitResgate = () => {
      if (codigoVoucher.trim() === '') {
         return toast.error('Informe o código do voucher');
      }

      setIsLoading(true);

      api.post('/voucher/validar', {
         cupomId: voucherInfo.cupomId,
         cupomChave: codigoVoucher,
      })
         .then(response => {
            toast.success(response.data.message);
            setIsOpen(false);

            getVouchers(pagination.current, pagination.pageSize);
         })
         .catch(error => {
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         })
         .finally(() => setIsLoading(false));
   };

   const openModal = async row => {
      setIsLoading(true);
      setCodigoVoucher('');
      try {
         const { data } = await api.get(`/voucher/${row.cupomId}`);
         row.premio = data.premio;
         row.cliente = data.cliente;
         row.dataGeracao = formataDataHora(data.dataGeracao);
         setVoucherInfo(row);
         setIsOpen(true);
         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);

         if (error.response.status === 401) {
            toast.warning('Sessão expirada. Entre novamente', {
               autoClose: 3000,
            });
            signOut();
         } else {
            toast.warning(error.response.data.error[0].message);
         }
      }
   };

   const closeModal = () => {
      setCodigoVoucher('');
      setIsOpen(false);
   };

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getVouchers(pagination.current, pagination.pageSize);
      }, 1);
   };

   return (
      <>
         <PageTitle
            heading="Validar prêmios"
            subheading="Valide os prêmios e premie seus clientes"
            icon="pe-7s-users icon-gradient bg-fideleco">
            <div>
               <ModalCustom
                  titleModal={`Confirme a operação - Valide as informações antes de validar o prêmio.`}
                  toggleFunc={openModal}
                  isOpen={modalIsOpen}
                  size="lg"
                  closeModal={closeModal}
                  style={{ display: 'none' }}>
                  <>
                     {modalConfirmResgate ? (
                        <ModalConfirmResgate
                           submitResgate={submitResgate}
                           setModalConfirmResgate={setModalConfirmResgate}
                        />
                     ) : null}
                     <div
                        key={voucherInfo.cupomId}
                        className="voucher-container">
                        {isLoading && <Spinner />}
                        <div className="voucher-card">
                           <div className="voucher-info-premio">
                              <div className="voucher-codigo">
                                 <label>Digite o código do voucher:</label>
                                 <input
                                    id="codigo"
                                    type="text"
                                    value={codigoVoucher}
                                    onChange={e =>
                                       setCodigoVoucher(e.target.value.trim())
                                    }
                                 />
                              </div>
                              <div className="voucher-info">
                                 <span>Data de resgate: </span>
                                 <p>{voucherInfo.dataGeracao}</p>
                              </div>
                              <div className="voucher-info">
                                 <span>Data de validade: </span>
                                 <p>{voucherInfo.dataValidade}</p>
                              </div>
                              <div className="voucher-resgatados">
                                 <span>Prêmio: </span>
                                 <p>{voucherInfo?.premio?.titulo}</p>
                                 <p>
                                    {voucherInfo?.premio?.descricaoDetalhada}
                                 </p>
                              </div>
                           </div>
                           <div className="voucher-info-pessoa">
                              <div className="voucher-nome">
                                 <p>{voucherInfo.nome}</p>
                              </div>
                              <div className="voucher-info">
                                 <p>{voucherInfo.documento}</p>
                              </div>
                              <div className="voucher-info">
                                 <p>{voucherInfo?.cliente?.email}</p>
                              </div>
                              <div className="voucher-info">
                                 <p>{voucherInfo?.cliente?.celular}</p>
                              </div>
                           </div>
                        </div>

                        <Form onSubmit={confirmResgate} noValidate>
                           <Button
                              disabled={isLoading}
                              color="primary mt-5"
                              type="submit"
                              className="btn-wide btn-pill btn-shadow btn-hover-shine"
                              size="lg">
                              Resgatar prêmio
                           </Button>
                        </Form>
                     </div>
                  </>
               </ModalCustom>
            </div>
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <h5>Pesquisa avançada:</h5>
                        <Input
                           placeholder="Pesquise por nome ou documento do cliente"
                           value={pesquisaAvancada}
                           onChange={e => setPesquisaAvancada(e.target.value)}
                           onKeyDown={e => {
                              if (e.keyCode === 13)
                                 getVouchersPesquisaAvancada(
                                    pagination.current,
                                    pagination.pageSize,
                                 );
                           }}
                        />
                     </CardBody>
                     <CardBody>
                        <div className="table-responsive">
                           <Table
                              filtros={filtros}
                              rowKey={record => record.nome}
                              columns={columns}
                              data={allVouchers}
                              pagination={pagination}
                              setPagination={setPagination}
                              onChange={handleTableChange}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default ValidarPremiosClientes;
