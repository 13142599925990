import React, { useEffect, useRef, useState } from 'react';

import { mask as masker, unMask } from 'remask';
import { Grid } from '@material-ui/core';
import { useField } from '@unform/core';

const InputUnform = props => {
   const {
      id,
      onBlur,
      type,
      size,
      name,
      mask,
      defaultValue,
      errorInput,
      valueInput,
      disabled,
      maxLength,
      ...rest
   } = props;

   const inputRef = useRef(null);
   const { fieldName, error, registerField } = useField(name);
   const [value, setValue] = useState();

   useEffect(() => {
      if (defaultValue) setValue(defaultValue);
   }, [defaultValue]);

   //Constrolando tipos de mascaras
   const onChange = ev => {
      const originalValue = unMask(ev.target.value);

      if (mask === 'celular') {
         const maskedValue = masker(originalValue, [
            '(99) 9999-9999',
            '(99) 9 9999-9999',
         ]);
         setValue(maskedValue);
         return;
      } else if (mask === 'pessoa') {
         const maskedValue = masker(originalValue, [
            '999.999.999-99',
            '99.999.999/9999-99',
         ]);
         setValue(maskedValue);
         return;
      } else if (mask === 'cep') {
         const maskedValue = masker(originalValue, ['99999-999']);
         setValue(maskedValue);
         return;
      } else if (mask === 'data') {
         const maskedValue = masker(originalValue, ['99/99/9999']);
         setValue(maskedValue);
      } else if (maxLength) {
         setValue(ev.target.value.slice(0, maxLength));
      } else {
         setValue(ev.target.value);
      }
   };

   useEffect(() => {
      registerField({
         name: fieldName,
         ref: inputRef.current,
         path: 'value',
      });
   }, [fieldName, registerField]);

   return (
      <Grid container spacing={1} alignItems="flex-end">
         <Grid item xs={size}>
            <input
               id={id}
               onBlur={onBlur}
               className="input-unform"
               ref={inputRef}
               onChange={onChange}
               value={value}
               defaultValue={defaultValue}
               disabled={disabled}
               name={name}
               error={errorInput}
               type={type}
               color="primary"
               maxLength={maxLength}
               fullWidth
               {...rest}
            />
            {error && <p className="is-danger">{error}</p>}
         </Grid>
      </Grid>
   );
};

export default InputUnform;
