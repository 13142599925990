import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useField } from 'unform';
import PropTypes from 'prop-types';
import { mask as masker, unMask } from 'remask';
// import { isNotEmpty } from '../../helpers/funcoes';

function Input({
   name,
   label,
   type,
   mask,
   initialValue,
   mostraSenha,
   setMostraSenha,
   iconSenha,
   autoComplete = 'on',
   tooltipMessage,
   erro,
   ...rest
}) {
   //states
   const [isFocused, setIsFocused] = useState(false);
   const [value, setValue] = useState(initialValue || '');

   //Variaveis
   const inputRef = useRef(null);
   const { fieldName, error, registerField } = useField(name);

   //Fuctions
   const handleInputFocus = useCallback(() => {
      setIsFocused(true);
   }, []);

   const handleInputBlur = useCallback(() => {
      setIsFocused(false);
   }, []);

   useEffect(() => {
      registerField({
         name: fieldName,
         ref: inputRef.current,
         path: 'value',
      });
   }, [fieldName, registerField]);

   const onChange = ev => {
      const originalValue = unMask(ev.target.value);

      if (mask === 'dinheiro') {
         const maskedValue = masker(originalValue, [
            '9,99',
            '99,99',
            '999,99',
            '9.999,99',
            '99.999,99',
            '999.999,99',
            '9.999.999,99',
            '99.999.999,99',
            '999.999.999,99',
         ]);
         setValue(maskedValue);
         return;
      } else {
         setValue(ev.target.value);
      }
   };

   return (
      <div className="input-componente">
         <label className="input-title">{label}</label>
         <div>
            {mask && mask === 'dinheiro' && <span className="mr-2">R$</span>}
            <input
               name={name}
               ref={inputRef}
               onChange={mask && onChange}
               value={mask && value}
               onFocus={handleInputFocus}
               onBlur={handleInputBlur}
               type={type}
               autoComplete={autoComplete}
               {...rest}
            />
         </div>
         {erro && <div className="erro">{erro}</div>}
      </div>
   );
}

Input.propTypes = {
   name: PropTypes.string.isRequired,
   type: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
   ]),
   placeholder: PropTypes.string,
   modifier: PropTypes.string,
   size: PropTypes.string,
   label: PropTypes.string,
   valid: PropTypes.bool,
   checked: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
   ]),
   handleChange: PropTypes.func,
   handleBlur: PropTypes.func,
   handleInput: PropTypes.func,
   value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.bool.isRequired,
   ]),
   customStyle: PropTypes.string,
   accept: PropTypes.string,
   customClass: PropTypes.string,
   customStyleGroup: PropTypes.string,
   className: PropTypes.string,
   disabled: PropTypes.bool,
   min: PropTypes.string,
   max: PropTypes.string,
   isRequired: PropTypes.bool,
   autoFocus: PropTypes.bool,
   maxlength: PropTypes.string,
   dataCy: PropTypes.string,
   id: PropTypes.string,
};

Input.defaultProps = {
   handleChange: () => {},
   handleBlur: () => {},
   handleInput: () => {},
   placeholder: '',
   label: '',
   customStyle: '',
   customClass: '',
   customStyleGroup: '',
   className: 'input-default',
   disabled: false,
   min: '',
   max: '',
   isRequired: false,
   autoFocus: false,
   maxlength: '',
   dataCy: '',
   id: '',
   checked: false,
};

export default React.memo(Input);
