export const Atalhos = [
   {
      title: 'Pontuar',
      subtitle: 'Acesse aqui para começar a pontuar',
      to: '/clientes/pontuar',
   },

   {
      title: 'Validar prêmio',
      subtitle: 'Valide seus prêmios e premie seus clientes',
      to: '/clientes/validar-premios',
   },

   {
      title: 'Gerenciar Prêmios',
      subtitle: 'Acesse aqui para gerenciar prêmios',
      to: '/fidelizar/gerenciar-premios',
   },

   {
      title: 'Estornar Pontuação',
      subtitle: 'Acesse aqui para configurar o estorno da sua pontuação',
      to: '/clientes/estornar-clientes',
   },

   {
      title: 'Gerenciar Filiais',
      subtitle: 'Acesse aqui para gerenciar filiais',
      to: '/configuracao/gerenciar-lojas',
   },

   {
      title: 'Gerenciar Banners',
      subtitle: 'Acesse aqui para gerenciar banners',
      to: '/configuracao/gerenciar-banner',
   },
];
