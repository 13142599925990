import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';

const TextareaUnform = props => {
   return (
      <Grid container spacing={1} alignItems="flex-end">
         <textarea
            {...props}
            style={{
               width: props.width || 'auto',
               height: props.height || 'auto',
               ...textareaStyles,
            }}
         />
      </Grid>
   );
};

const textareaStyles = {
   border: '1px solid #767676',
   borderRadius: '8px',
   padding: '8px',
   margin: '8px 2px 16px',
};

export default TextareaUnform;
