export const initialStateAddress = {
   cep: '',
   neighborhood: '',
   street: '',
   city: '',
   state: '',
   error: null,
}

export const reducerFormAddress = (state, action) => {
   switch (action.type) {
      case 'UPDATE_ADDRESS':
         return {
            ...state,
            ...action.payload,
            error: null
         }

      case 'UPDATE_FIELD':
         return {
            ...state,
            [action.payload.name]: action.payload.value,
            error: null
         }

      case 'FAIL':
         return {
            ...initialStateAddress,
            error: action.payload
         }

      case 'INITIAL_VALUES':
         return {
            ...initialStateAddress,
         }

      default:
         return state
   }
}
