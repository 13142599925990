import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useAuth } from '../../customHooks/AuthContext';
import { connect } from 'react-redux';
import { fideActions } from '../../reducers/FideDataOption';
import { useDispatch } from 'react-redux';
import api from '../../api/api-local';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import { useHistory } from 'react-router-dom';

const getNavStates = (indx, length) => {
   let styles = [];
   for (let i = 0; i < length; i++) {
      if (i < indx) {
         styles.push('done');
      } else if (i === indx) {
         styles.push('doing');
      } else {
         styles.push('todo');
      }
   }
   return { current: indx, styles: styles };
};

const checkNavState = (currentStep, stepsLength) => {
   if (currentStep > 0 && currentStep < stepsLength - 1) {
      return {
         showPreviousBtn: true,
         showNextBtn: true,
      };
   } else if (currentStep === 0) {
      return {
         showPreviousBtn: false,
         showNextBtn: true,
      };
   } else {
      return {
         showPreviousBtn: true,
         showNextBtn: false,
      };
   }
};

const MultiStep = props => {
   const dispatch = useDispatch();
   const history = useHistory();
   const { usuario } = useAuth();

   const [steps] = useState([
      { name: 'Configuração da Loja', component: <Step1 /> },
      { name: 'Personalizar Programa de Fidelidade', component: <Step3 /> },
      { name: 'Fim ', component: <Step5 /> },
   ]);

   const [state, setState] = useState({
      showPreviousBtn: false,
      showNextBtn: true,
      compState: 0,
      navState: getNavStates(0, steps.length),
   });

   const setNavState = next => {
      setState(state => ({
         ...state,
         navState: getNavStates(next, steps.length),
      }));

      if (next < steps.length) {
         setState(state => ({
            ...state,
            compState: next,
         }));
      }

      setState(state => {
         const checkNave = checkNavState(next, steps.length);
         return {
            ...state,
            showPreviousBtn: checkNave.showPreviousBtn,
            showNextBtn: checkNave.showNextBtn,
         };
      });
   };

   const verifyEtapaConfiguracao = () => {
      api.get('/plataforma/configuracao/statusjornadacadastro')
         .then(response => {
            const {
               data: {
                  configuracaoLojaConcluida,
                  configuracaoPersonalizacaoConcluida,
                  configuracaoPremioConcluida,
                  configuracaoProgramaConcluida,
               },
            } = response;

            const usuario = localStorage.getItem('@Fideleco:user');
            const parsedUsuario = JSON.parse(usuario);

            parsedUsuario.statusJornadaCadastroPlataforma = {
               configuracaoLojaConcluida,
               configuracaoPersonalizacaoConcluida,
               configuracaoPremioConcluida,
               configuracaoProgramaConcluida,
            };

            localStorage.setItem(
               '@Fideleco:user',
               JSON.stringify(parsedUsuario),
            );

            if (
               configuracaoLojaConcluida &&
               !configuracaoPersonalizacaoConcluida
            ) {
               handleNext(1);
            } else if (
               configuracaoPersonalizacaoConcluida &&
               !configuracaoLojaConcluida
            ) {
               handleNext(2);
            } else if (
               configuracaoLojaConcluida &&
               configuracaoPersonalizacaoConcluida
            ) {
               history.push('/home-admin');
            }
         })
         .catch(error => console.error(error));
   };

   // useEffect(() => {
   //    api.get('/etapaconfiguracaoplataforma/atual')
   //       .then(response => {
   //          setState(state => ({
   //             ...state,
   //             compState: response.data.etapaConfiguracaoPlataformaId - 1 || 0,
   //             navState: getNavStates(
   //                response.data.etapaConfiguracaoPlataformaId - 1,
   //                steps.length,
   //             ),
   //          }));
   //       })
   //       .catch(error => console.error(error));
   // }, []);

   const handleNext = (next = 0) => {
      dispatch(fideActions.valueNextStepWizard(false));

      if (state.compState == 1) {
         usuario.etapaConfiguracaoPlataforma.etapaConfiguracaoPlataformaId = 3;
      }

      localStorage.setItem('@Fideleco:user', JSON.stringify(usuario));
      setNavState(state.compState + next);
   };

   const handleBack = idStepToback => {
      dispatch(fideActions.valueNextStepWizard(false));

      setNavState((state.compState = idStepToback));
   };

   const getClassName = (className, i) => {
      return className + '-' + state.navState.styles[i];
   };

   const renderSteps = () => {
      // let disabled = false;
      return steps.map((s, i) => (
         <li
            className={getClassName('form-wizard-step', i)}
            // onClick={disabled && handleOnClick}
            key={i}
            value={i}>
            <em>{i + 1}</em>
            <span>{steps[i].name}</span>
         </li>
      ));
   };

   const { FideActionTypes } = props;
   return (
      <div>
         <ol className="forms-wizard">{renderSteps()}</ol>
         <div>{FideActionTypes.valueStepWizard}</div>
         {state.compState === 0 && (
            <Step1
               handleNext={verifyEtapaConfiguracao}
               handleBack={handleBack}
            />
         )}
         {state.compState === 1 && (
            <Step3
               handleNext={verifyEtapaConfiguracao}
               handleBack={handleBack}
            />
         )}
         {state.compState === 2 && (
            <Step5
               handleNext={verifyEtapaConfiguracao}
               handleBack={handleBack}
            />
         )}
      </div>
   );
};

function mapStateToProps(state) {
   const { FideActionTypes } = state;
   return {
      FideActionTypes,
   };
}

export default connect(mapStateToProps)(MultiStep);
