import React from 'react';
import { Button } from 'reactstrap';

const ModalConfirmResgate = ({ submitResgate, setModalConfirmResgate }) => {
   const handleConfirmResgate = () => {
      setModalConfirmResgate(false);
      submitResgate();
   };

   return (
      <div style={modalContainerStyles}>
         <div style={modalBoxStyles}>
            <p style={modalTitleStyles}>
               Por favor, confirme o resgate deste prêmio.
            </p>
            <Button
               color="primary mt-5"
               type="submit"
               className="btn-wide btn-pill btn-shadow btn-hover-shine"
               size="lg"
               style={{ minWidth: 144 }}
               onClick={handleConfirmResgate}>
               Confirmar
            </Button>
            <Button
               color="secondary mt-3 mb-4"
               type="submit"
               className="btn-wide btn-pill btn-shadow btn-hover-shine"
               size="lg"
               style={{ minWidth: 144 }}
               onClick={() => setModalConfirmResgate(false)}>
               Cancelar
            </Button>
         </div>
      </div>
   );
};

const modalContainerStyles = {
   display: 'fixed',
   position: 'absolute',
   top: '0',
   left: '0',
   width: '100%',
   height: '100%',
   zIndex: 9,
   backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const modalBoxStyles = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   backgroundColor: '#fff',
   boxShadow: '0 3px 18px rgba(0, 0, 0, 0.2)',
   padding: '40px 20px',
   borderRadius: '5px',
   width: '300px',
   maxWidth: '90%',
   height: '300px',
   maxHeight: '90%',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'flex-start',
   alignItems: 'center',
};

const modalTitleStyles = {
   color: '#000',
   fontSize: 20,
   fontWeight: 'bold',
   textAlign: 'center',
};

export default ModalConfirmResgate;
