import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../api/api-local';

//Components
import InputUnform from '../../../components/Formulario/InputUnform';
import TextareaUnform from '../../../components/Formulario/TextareaUnform';
import InputSeletecd from '../../../components/InputSeletecd';
import { useAuth } from '../../../customHooks/AuthContext';
import ModalCustom from '../../../components/Modal/Modal';
import Spinner from '../../../components/loading/Loading';
import PageTitle from '../../layout/AppMain/PageTitle';
import Table from '../../../components/FilterTable';
import InputFile from '../../../components/Formulario/InputFile';
import {
   initialStateAddress,
   reducerFormAddress,
} from '../../../store/reducerHooks/formReduceAdmin';

//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, Button, FormGroup } from 'reactstrap';
import { Select, Input as InputAntD, Checkbox } from 'antd';
import Tooltip from '../../../components/tooltip';
import { Form as FormUnform } from '@unform/web';
import { mask as masker, unMask } from 'remask';
import { FormControl } from '@material-ui/core';
import { toast } from 'react-toastify';
import * as cepData from 'cep-promise';
import { Scope } from '@unform/core';
import MenuItem from '@material-ui/core/MenuItem';
import * as Yup from 'yup';
import _ from 'lodash';
import LoadingButton from '../../../components/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { base64ToFile, urlToFile } from '../../../helpers/urlToFile';

const useStyles = makeStyles(theme => ({
   formControl: {
      marginRight: theme.spacing(1),
      width: '100%',
   },
   selectEmpty: {},
}));

const listaGmt = [
   { descricao: 'GMT -2 (Fernando de Noronha)', value: '-2' },
   {
      descricao:
         'GMT -3 (Brasilia, São Paulo, Salvador, Fortaleza, Belém, Maceió, Recife, etc)',
      value: '-3',
   },
   {
      descricao:
         'GMT -4 (Boa Vista, Campo Grande, Cuiabá, Manaus, Rio Branco, etc)',
      value: '-4',
   },
];

const ListaLoja = () => {
   const [addressState, dispatch] = useReducer(
      reducerFormAddress,
      initialStateAddress,
   );

   const [cep, setCep] = useState({
      blurCep: '',
   });

   const classes = useStyles();
   const { usuario, signOut } = useAuth();
   const formRef = useRef(null);
   const { Option } = Select;

   //state
   const [listValueSelectedsUpdate, setlistValueSelectedsUpdate] = useState();
   const [valueCheckComunicacao, setValueCheckComunicacao] = useState();
   const [listMeioComunicacao, setListMeioComunicacao] = useState([]);
   const [listValueSelecteds, setlistValueSelecteds] = useState();
   const [valueCpfCnpj, setValueCpfCnpj] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [dataSource, setDataSource] = useState([]);
   const [enderecoApi, setEnderecoApi] = useState();
   const [dataUpdate, setDataUpdate] = useState();
   const [listLojas, setListLojas] = useState([]);
   const [edit, setEdit] = useState(false);
   const [valuePath, setValuePath] = useState();
   const [plataforma, setPlataforma] = useState();
   const [valueGmt, setValueGmt] = useState('-3');
   const [erroGmt, setErroGmt] = useState(false);
   const [mainAppImgData, setMainAppImgData] = useState(null);
   const [secondaryAppImgData, setSecondaryAppImgData] = useState(null);
   const [mainAppPicture, setMainAppPicture] = useState(null);
   const [secondaryAppPicture, setSecondaryAppPicture] = useState(null);
   const [descricaoValue, setDescricaoValue] = useState('');
   const descricaoMaxLength = 500;

   const handleDescricaoChange = e => {
      const { value } = e.target;
      setDescricaoValue(value || '');
   };

   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const openModal = tipo => {
      setDataUpdate();
      if (tipo === 'cadastrar') {
         setlistValueSelectedsUpdate();
         setEdit(false);
      } else {
         setEdit(true);
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      setCep({ ...cep, blurCep: '' });
      dispatch({
         type: 'INITIAL_VALUES',
      });

      setIsOpen(false);
      setEdit(false);

      //resetando valores defaults
      setValueCpfCnpj();
      setValuePath();
      setValueGmt('');
      setMainAppImgData(null);
      setSecondaryAppImgData(null);
      setMainAppPicture(null);
      setSecondaryAppPicture(null);
      setDescricaoValue('');
   };

   function onChangeCheck(e) {
      setValueCheckComunicacao(e.target.checked);
   }

   const handleSubimit = async data => {
      setIsLoading(true);
      //Ajustando a lista de meios de comunicacao

      const lista = [];
      if (data.lojaMeioComunicacao) {
         Object.entries(data.lojaMeioComunicacao).map(dt => {
            if (dt[0] == 4 || dt[0] == 5) {
               lista.push({
                  meioComunicacaoId: parseInt(dt[0]),
                  valor: dt[1]
                     .replace('(', '')
                     .replace(')', '')
                     .replace(/\s/g, '')
                     .replace('-', ''),
               });
            } else {
               lista.push({
                  meioComunicacaoId: parseInt(dt[0]),
                  valor: dt[1],
               });
            }
         });

         /* SAIDA EXEMPLO
            [
               {"meioComunicacaoId":1, "meioComunicacaoDescricao":"E-mail", "valor":"matheus.bertoldosi@gmail.com"},
               {"meioComunicacaoId":5, "meioComunicacaoDescricao":"Celular", "valor":"68999896421"},
            ]
         */
      }

      const novaData = {
         segmentoId: 1,
         cpfCnpj: data.cpfCnpj,
         nomeRazaoSocial: data.razaoSocial,
         nomeFantasia:
            String(dataUpdate?.cpfCnpj).length < 15
               ? data.razaoSocial
               : data.nomeFantasia,
         descricao: JSON.stringify(descricaoValue),
         gmt: valueGmt,
         urlEcommerce: data.urlEcommerce,
         caminhoLoja: data.path,
         cep: data.cep,
         logradouro: data.logradouro,
         numero: data.numero,
         bairro: data.bairro,
         complemento: data.complemento,
         cidade: data.cidade,
         uf: data.uf,
         lojaMeioComunicacao: lista,
         lojaId: edit ? dataUpdate?.lojaId : '',
      };

      const formData = new FormData();
      formData.append('data', JSON.stringify(novaData));

      if (!!mainAppPicture) {
         formData.append('imagemDestaque', mainAppPicture);
      }
      if (!!secondaryAppPicture) {
         formData.append('imagemDetalhe', secondaryAppPicture);
      }

      try {
         // resetando os erros
         formRef.current.setErrors({});

         // validando os dados
         const schema = Yup.object().shape({
            cpfCnpj: Yup.string().required('CPF ou CNPJ é obrigatório'),
            nomeRazaoSocial: Yup.string().required(
               'Razão social é obrigatório',
            ),
            caminhoLoja: Yup.string().required(
               'Personalizar endereço da loja é obrigatório',
            ),
         });

         // passando os dados para o schema validar os inputs
         await schema.validate(novaData, {
            abortEarly: false,
         });

         //Obrigando o preenchimento do campo Fuso
         if (valueGmt === '') {
            setErroGmt(true);
            setIsLoading(false);
            return;
         } else {
            setErroGmt(false);
         }

         if (!edit) {
            //Realizando envio
            api.post('loja', formData)
               .then(response => {
                  setIsLoading(true);
                  if (response.status === 200) {
                     toast.success('Loja adicionado com sucesso');
                     //Atualizando o componente de listagem
                     getClientList(pagination.current, pagination.pageSize);
                     setIsLoading(false);
                     setIsOpen(false);
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         } else {
            //Realizando o update
            api.put('loja', formData)
               .then(response => {
                  setIsLoading(true);

                  if (response.status === 200) {
                     setIsOpen(false);
                     toast.success('Loja alterada com sucesso!');
                     //Atualizando componente de listagem
                     getClientList(pagination.current, pagination.pageSize);
                     setIsLoading(false);
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 2000,
                     });
                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         }
      } catch (err) {
         // pegando os erros
         if (err instanceof Yup.ValidationError) {
            // criando uma lista para guardar os erros
            const errorMessages = {};

            // percorrendo os errors localizados no form
            err.inner.forEach(error => {
               // add o erros na lista
               errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
            setIsLoading(false);
         }
      }
   };

   function handleValoresSelecionados(value) {
      const novaLista = [];
      listMeioComunicacao.map(meioComunicao =>
         value.map(v => {
            if (parseInt(v) === meioComunicao.meioComunicacaoId) {
               const meioComunicacaoId = String(
                  meioComunicao.meioComunicacaoId,
               );
               novaLista.push({
                  meioComunicacaoId: meioComunicacaoId,
                  descricao: meioComunicao.descricao,
               });
            }
         }),
      );
      setlistValueSelecteds(novaLista);
   }

   const updateLoja = loja => {
      setIsLoading(true);

      api.get(`loja/${loja.lojaId}`)
         .then(async response => {
            const { data } = response;

            if (data?.imagemDestaque && data?.imagemDetalhe) {
               const imagemDestaque = await urlToFile(
                  data.imagemDestaque,
                  'imagemDestaque',
               );

               const imagemDetalhe = await urlToFile(
                  data.imagemDetalhe,
                  'imagemDetalhe',
               );

               // seta imagens
               setMainAppImgData(`data:image/*;base64,${data.imagemDestaque}`);
               setSecondaryAppImgData(
                  `data:image/*;base64,${data.imagemDetalhe}`,
               );
               setMainAppPicture(imagemDestaque);
               setSecondaryAppPicture(imagemDetalhe);
            }

            //resetando valor de enreco para não inteferir no default value
            setEnderecoApi({});

            //Incluindo dados para usar como default
            setDataUpdate(data);
            setValueGmt(data.gmt);

            //alterando estados da modal
            setEdit(true);
            setIsOpen(true);

            //Setando uma lista somente de meio de comunicao ja preenchida pelo usuario para exibir
            setlistValueSelectedsUpdate(data.lojaMeioComunicacao);

            setDescricaoValue(JSON.parse(data?.descricao) || '');

            //Corrigindo a lista de meio de comunição para abrir os input de edicao
            const listaValuesUpdates = [];
            if (listValueSelectedsUpdate) {
               listValueSelectedsUpdate.map(item => {
                  listaValuesUpdates.push(String(item.meioComunicacaoId));
               });
            }
         })
         .catch(error => {
            console.error(error);
            toast.error(error.response.data.error[0].message);
         })
         .finally(() => setIsLoading(false));
   };

   const deleteLoja = loja => {
      try {
         const getId = loja.lojaId;
         api.delete(`loja/${getId}`)
            .then(response => {
               setIsLoading(true);

               if (response.status === 200) {
                  setTimeout(() => {
                     /*history.go(0);*/
                     setIsLoading(false);

                     toast.success('Loja excluído com sucesso');
                     //Atualizando componente de listagem
                     getClientList(pagination.current, pagination.pageSize);
                  }, 2000);
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      } catch (error) {
         console.log(error);
      }
   };

   const getClientList = async (currentPage, pageSize) => {
      setIsLoading(true);
      api.get('loja/paginacao', {
         params: {
            pagina: currentPage,
            tamanho: pageSize,
         },
      })
         .then(response => {
            const { data } = response;

            setListLojas(data.data);
            setDataSource(data.data);

            setPagination(state => ({
               ...state,
               current: data.currentPage,
               total: data.totalItems,
            }));
         })
         .catch(error => console.error(error))
         .finally(() => setIsLoading(false));
   };

   const getMeioComunicacao = async () => {
      try {
         const getApi = await api.get('/meiocomunicacao');
         if (getApi.status === 200) {
            setListMeioComunicacao(getApi.data);
         }
      } catch (error) {
         const erro = error.response.data.error;
         toast.warning(error.response.data.error[0].message);

         if (erro[0].message == 'Token inválido') {
            toast.error('Você precisa logar novamente!');
            localStorage.removeItem('@Fideleco:token');
            setTimeout(() => {
               document.location.reload(true);
            }, [3000]);
         }
      }
   };

   const buscarEnderecoCompleto = event => {
      //Salvando valor do campo em um estado para chamar a busca de cep
      setCep({ blurCep: event.target.value });
   };

   const columns = [
      {
         dataIndex: 'nomeRazaoSocial',
         title: 'Nome',
         filters: dataSource.map(loja => ({
            text: loja.nomeRazaoSocial,
            value: loja.nomeRazaoSocial,
         })),
         onFilter: (value, record) =>
            record.nomeRazaoSocial.indexOf(value) === 0,
         sorter: (a, b) => (a.nomeRazaoSocial < b.nomeRazaoSocial ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         dataIndex: 'cidade',
         title: 'Cidade',
         filters: dataSource.map(loja => {
            return {
               text: loja.cidade,
               value: loja.cidade,
            };
         }),
         onFilter: (value, record) => record.cidade.indexOf(value) === 0,
         sorter: (a, b) => (a.cidade < b.cidade ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         dataIndex: 'uf',
         title: 'Estado',
         filters: dataSource.map(loja => ({
            text: loja.uf,
            value: loja.uf,
         })),
         onFilter: (value, record) => record.uf.indexOf(value) === 0,
         sorter: (a, b) => (a.uf < b.uf ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: 150,
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 100,
         align: 'center',
         render: loja => (
            <div key={loja.lojaId}>
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon mr-2 text-info lnr-pencil mr-3"
                  onClick={() => {
                     updateLoja(loja);
                  }}
               />
               <i
                  style={{ cursor: 'pointer' }}
                  className="dropdown-icon text-danger lnr-trash mr-2"
                  onClick={() => {
                     deleteLoja(loja);
                  }}
               />
            </div>
         ),
      },
   ];

   const filtros = [
      {
         dataIndex: 'nomeRazaoSocial',
         nome: 'Nome',
         placeholder: 'Digite o nome',
         mask: 'text',
      },

      {
         dataIndex: 'cidade',
         nome: 'Cidade',
         placeholder: 'Digite a cidade',
         mask: 'text',
      },

      {
         dataIndex: 'uf',
         nome: 'Estado',
         placeholder: 'Digite o estado',
         mask: 'text',
      },
   ];

   //Verificando quantos digitos tem no campo cpf/cnpj para mostrar ou não nome e razao social
   const handlerChangeCpfCnpj = e => {
      const originalValue = unMask(e.target.value);
      const maskedValue = masker(originalValue, [
         '999.999.999-99',
         '99.999.999/9999-99',
      ]);
      setValueCpfCnpj(maskedValue);
   };

   const handleGmt = value => {
      if (value != '') {
         setValueGmt(value);
      } else {
         toast.info('Selecione uma opção');
      }
   };

   useEffect(() => {
      const cepFiltrado = cep?.blurCep?.replace('-');
      const getValueData = async cepValue => {
         if (cep.blurCep !== '') {
            try {
               const arrDataCep = await cepData(cepValue);
               dispatch({
                  type: 'UPDATE_ADDRESS',
                  payload: arrDataCep,
               });
               setEnderecoApi(arrDataCep);
            } catch (e) {
               dispatch({
                  type: 'FAIL',
                  payload:
                     'Ocorreu um erro para buscar o cep. Cep não encontrado!',
               });
            }
         }
      };

      getValueData(cepFiltrado);
   }, [cep.blurCep]);

   useEffect(() => {
      getClientList(pagination.current, pagination.pageSize);
      getMeioComunicacao();
   }, [edit]);

   useEffect(() => {
      const { plataforma } = JSON.parse(localStorage.getItem('@Fideleco:user'));
      setPlataforma(plataforma);
   }, []);

   const onMainPictureChange = e => {
      setMainAppPicture(e.target.files[0]);
      if (e.target.files) {
         const reader = new FileReader();

         reader.addEventListener('load', () => {
            setMainAppImgData(reader.result);
         });
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const onSecondaryPictureChange = e => {
      setSecondaryAppPicture(e.target.files[0]);
      if (e.target.files) {
         const reader = new FileReader();

         reader.addEventListener('load', () => {
            setSecondaryAppImgData(reader.result);
         });
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const handleTableChange = async pagination => {
      setPagination(pagination);

      setTimeout(() => {
         getClientList(pagination.current, pagination.pageSize);
      }, 1);
   };

   return (
      <>
         <PageTitle
            heading="Gerenciar Lojas"
            subheading="Gerencie suas lojas"
            icon="pe-7s-shopbag icon-gradient bg-fideleco">
            <div>
               <ModalCustom
                  titleModal={
                     edit ? 'Atualizar loja' : 'Cadastre uma nova loja'
                  }
                  buttonClass="mb-2 ml-2 btn-icon-vertical p-1 bg-fideleco font-weight-bold text-white"
                  buttonIcon={
                     <i
                        onClick={closeModal}
                        className="pe-7s-shopbag btn-icon-wrapper font-weight-bold"></i>
                  }
                  buttonName="Cadastrar loja"
                  buttonSize="lg"
                  toggleFunc={() => {
                     openModal('cadastrar');
                  }}
                  isOpen={modalIsOpen}
                  closeModal={closeModal}
                  size="lg">
                  <FormGroup>
                     <FormUnform
                        className="text-left"
                        ref={formRef}
                        onSubmit={handleSubimit}>
                        <Row className="mb-3" form>
                           <Col md={String(valueCpfCnpj).length < 15 ? 6 : 4}>
                              <FormGroup>
                                 <label>CPF ou CNPJ</label>
                                 <Tooltip
                                    texto="Informe o identificador de fidelidade do
                                    loja. Neste campo pode ser informado o CPF
                                    ou CNPJ para identifica-lo."
                                    id="person"
                                 />
                                 <InputUnform
                                    onChange={handlerChangeCpfCnpj}
                                    value={valueCpfCnpj}
                                    size={12}
                                    type="text"
                                    name="cpfCnpj"
                                    defaultValue={
                                       dataUpdate && dataUpdate.cpfCnpj
                                    }
                                 />
                              </FormGroup>
                           </Col>
                           <Col md={String(valueCpfCnpj).length < 15 ? 6 : 4}>
                              <FormGroup>
                                 {edit ? (
                                    <>
                                       {String(dataUpdate?.cpfCnpj).length <
                                       15 ? (
                                          <label>Nome</label>
                                       ) : (
                                          <label>Razão Social</label>
                                       )}
                                    </>
                                 ) : (
                                    <>
                                       {String(valueCpfCnpj).length < 15 ? (
                                          <label>Nome</label>
                                       ) : (
                                          <label>Razão Social</label>
                                       )}
                                    </>
                                 )}
                                 <Tooltip
                                    texto="Razão social ou o Nome é o registro
                                 sob o qual uma pessoa jurídica ou física se individualiza
                                 e exerce suas atividades."
                                    id="nomeRazaoSocial"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="razaoSocial"
                                    defaultValue={
                                       dataUpdate && dataUpdate.nomeRazaoSocial
                                    }
                                 />
                              </FormGroup>
                           </Col>
                           {edit ? (
                              <>
                                 {String(dataUpdate?.cpfCnpj).length <
                                 15 ? null : (
                                    <Col md={4}>
                                       <FormGroup>
                                          <label>Nome fantasia</label>
                                          <Tooltip
                                             texto="Nome fantasia, nome de fantasia, também
                                 chamado nome de marca, nome comercial ou
                                 nome de fachada, é a designação popular de
                                 título de estabelecimento utilizada por uma
                                 instituição, seja pública ou privada, sob o
                                 qual ela se torna conhecida do público."
                                             id="nomeFantasia"
                                          />
                                          <InputUnform
                                             size={12}
                                             type="text"
                                             name="nomeFantasia"
                                             defaultValue={
                                                dataUpdate &&
                                                dataUpdate.nomeFantasia
                                             }
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                              </>
                           ) : (
                              <>
                                 {String(valueCpfCnpj).length < 15 ? null : (
                                    <Col md={4}>
                                       <FormGroup>
                                          <label>Nome fantasia</label>
                                          <Tooltip
                                             texto="Nome fantasia, nome de fantasia, também
                                    chamado nome de marca, nome comercial ou
                                    nome de fachada, é a designação popular de
                                    título de estabelecimento utilizada por uma
                                    instituição, seja pública ou privada, sob o
                                    qual ela se torna conhecida do público."
                                             id="nomeFantasia"
                                          />
                                          <InputUnform
                                             size={12}
                                             type="text"
                                             name="nomeFantasia"
                                             defaultValue={
                                                dataUpdate &&
                                                dataUpdate.nomeFantasia
                                             }
                                          />
                                       </FormGroup>
                                    </Col>
                                 )}
                              </>
                           )}
                        </Row>

                        <Row className="mb-3" form>
                           <Col md={12}>
                              <FormGroup>
                                 <label>Descrição</label>
                                 <Tooltip
                                    texto="Essa descrição irá fica visível em seu hotsite, na aba Quem somos."
                                    id="descricao"
                                 />
                                 <TextareaUnform
                                    height="80px"
                                    width="100%"
                                    type="text"
                                    name="descricao"
                                    value={descricaoValue}
                                    onChange={handleDescricaoChange}
                                    maxLength={descricaoMaxLength}
                                 />
                                 <div
                                    style={{
                                       width: '100%',
                                       textAlign: 'right',
                                    }}>
                                    <p>
                                       {descricaoValue?.length || 0}/
                                       {descricaoMaxLength}
                                    </p>
                                 </div>
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mb-3" form>
                           <Col md={6}>
                              <FormGroup>
                                 <label>CEP</label>
                                 <Tooltip
                                    texto="Informe o CEP da loja para identificar sua
                                    localização geográfica."
                                    id="cep"
                                 />
                                 <InputUnform
                                    size={12}
                                    mask="cep"
                                    type="text"
                                    name="cep"
                                    defaultValue={dataUpdate && dataUpdate.cep}
                                    onBlur={buscarEnderecoCompleto}
                                 />
                              </FormGroup>
                           </Col>
                           <Col md={6}>
                              <FormGroup>
                                 <label>UF</label>
                                 <Tooltip
                                    texto="Informe o Estado (UF) referente ao endereço
                                    da loja."
                                    id="state"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    value={enderecoApi && enderecoApi.state}
                                    defaultValue={dataUpdate && dataUpdate.uf}
                                    name="uf"
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mb-3" form>
                           <Col md={6}>
                              <FormGroup>
                                 <label>Cidade</label>
                                 <Tooltip
                                    texto="Informe a Cidade referente ao endereço da
                                    loja."
                                    id="city"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="cidade"
                                    value={enderecoApi && enderecoApi.city}
                                    defaultValue={
                                       dataUpdate && dataUpdate.cidade
                                    }
                                 />
                              </FormGroup>
                           </Col>
                           <Col md={6}>
                              <FormGroup>
                                 <label>Bairro</label>
                                 <Tooltip
                                    texto="Informe o bairro referente ao endereço da
                                    loja."
                                    id="neighborhood"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="bairro"
                                    value={
                                       enderecoApi && enderecoApi.neighborhood
                                    }
                                    defaultValue={
                                       dataUpdate && dataUpdate.bairro
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mb-3" form>
                           <Col md={6}>
                              <FormGroup>
                                 <label>Logradouro</label>
                                 <Tooltip
                                    texto="Informe a rua referente ao endereço da
                                    loja."
                                    id="street"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="logradouro"
                                    value={enderecoApi && enderecoApi.street}
                                    defaultValue={
                                       dataUpdate && dataUpdate.endereco
                                    }
                                 />
                              </FormGroup>
                           </Col>
                           <Col md={6}>
                              <FormGroup>
                                 <label>Número</label>
                                 <Tooltip
                                    texto="Informe o Número referente ao endereço da
                                    loja."
                                    id="numero"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="numero"
                                    defaultValue={
                                       dataUpdate && dataUpdate.numero
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mb-3" form>
                           <Col md={12}>
                              <FormGroup>
                                 <label>Complemento</label>
                                 <Tooltip
                                    texto="Informe o Complemento referente ao endereço
                                    da loja."
                                    id="complemento"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="complemento"
                                    defaultValue={
                                       dataUpdate && dataUpdate.complemento
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mb-3" form>
                           <Col md={6}>
                              <FormGroup>
                                 <label>Personalizar endereço da loja</label>
                                 <Tooltip
                                    texto="Este campo permite a você, defenir como deverá ser rota da sua loja. "
                                    id="path"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    onChange={e => {
                                       setValuePath(
                                          e.target.value
                                             .toLowerCase()
                                             .replace(/\s/g, ''),
                                       );
                                    }}
                                    name="path"
                                    disabled={
                                       dataUpdate && dataUpdate.path
                                          ? true
                                          : false
                                    }
                                    defaultValue={dataUpdate && dataUpdate.path}
                                 />
                                 <label className="title_url_style">
                                    {plataforma?.urlHotsite + '/'}
                                    {valuePath
                                       ? valuePath
                                       : dataUpdate && dataUpdate.path}
                                 </label>
                              </FormGroup>
                           </Col>

                           <Col md={6}>
                              <FormGroup>
                                 <label>Endereço e-commerce</label>
                                 <Tooltip
                                    texto="Caso possua um site, coloque endereço para que seu cliente acesse"
                                    id="urlEcommerce"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="urlEcommerce"
                                    defaultValue={
                                       dataUpdate && dataUpdate.urlEcommerce
                                    }
                                 />
                              </FormGroup>
                           </Col>

                           <Col md={12} className="mb-5">
                              <FormGroup className="text-left">
                                 <label className="mb-2">
                                    Fuso horário(GMT)
                                 </label>

                                 <FormControl className={classes.formControl}>
                                    <Select
                                       data-cy="gmt-select"
                                       onChange={handleGmt}
                                       name="gmt"
                                       displayEmpty
                                       defaultValue={String(valueGmt) || ''}
                                       className={classes.selectEmpty}
                                       inputProps={{
                                          'aria-label': 'Without label',
                                       }}>
                                       <MenuItem
                                          className="text-left"
                                          disabled
                                          value="">
                                          <em>Selecione uma opção...</em>
                                       </MenuItem>
                                       {listaGmt.map(gmt => (
                                          <MenuItem
                                             data-cy={`gmt-item-${gmt.value}`}
                                             key={gmt.value}
                                             className="text-left"
                                             value={gmt.value}>
                                             <em>{gmt.descricao}</em>
                                          </MenuItem>
                                       ))}
                                    </Select>
                                    {erroGmt && (
                                       <p className="is-danger">
                                          Campo obrigatório
                                       </p>
                                    )}
                                 </FormControl>
                              </FormGroup>
                           </Col>
                        </Row>

                        {edit ? (
                           <>
                              {!valueCheckComunicacao && (
                                 <>
                                    {listValueSelectedsUpdate && (
                                       <Row className="mb-3">
                                          <Scope path="lojaMeioComunicacao">
                                             {listValueSelectedsUpdate &&
                                                listValueSelectedsUpdate.map(
                                                   item => {
                                                      return (
                                                         <Col md={6}>
                                                            <FormGroup>
                                                               <label>
                                                                  {
                                                                     item.meioComunicacaoDescricao
                                                                  }
                                                               </label>

                                                               <InputSeletecd
                                                                  key={toString(
                                                                     item.meioComunicacaoId,
                                                                  )}
                                                                  required
                                                                  name={
                                                                     item.meioComunicacaoId
                                                                  }
                                                                  defaultValue={
                                                                     item.valor
                                                                  }
                                                                  type="text"
                                                               />
                                                            </FormGroup>
                                                         </Col>
                                                      );
                                                   },
                                                )}
                                          </Scope>
                                       </Row>
                                    )}
                                 </>
                              )}

                              <Checkbox
                                 className="mb-3"
                                 checked={valueCheckComunicacao}
                                 onChange={onChangeCheck}>
                                 Deseja alterar sua lista de meios de
                                 comunicação?
                              </Checkbox>
                              <Tooltip
                                 texto="Ao optar por sim, você deverá adiconar todos os meios de cumunicação anteriores já adicinado, pois uma nova lista será incluída e ficará diponível no seu hotsite."
                                 id="valueCheckComunicacao"
                              />

                              {valueCheckComunicacao && (
                                 <>
                                    <Row className="mb-3" form>
                                       <Col md={12}>
                                          <FormGroup>
                                             <FormControl
                                                style={{ width: '100%' }}>
                                                <div>
                                                   <label>
                                                      Meios de Comunicação
                                                   </label>
                                                   <Tooltip
                                                      texto="Informe ao menos um meio de contato."
                                                      id="meioComunicacaoId"
                                                   />
                                                </div>
                                                <Select
                                                   mode="multiple"
                                                   allowClear
                                                   style={{ width: '100%' }}
                                                   placeholder="Selecione ao menos uma opção"
                                                   onChange={
                                                      handleValoresSelecionados
                                                   }>
                                                   {listMeioComunicacao.map(
                                                      item => {
                                                         return (
                                                            <Option
                                                               className="text-left"
                                                               key={
                                                                  item.meioComunicacaoId
                                                               }>
                                                               {item.descricao}
                                                            </Option>
                                                         );
                                                      },
                                                   )}
                                                </Select>
                                             </FormControl>
                                          </FormGroup>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Scope path="lojaMeioComunicacao">
                                          {listValueSelecteds?.map(item => {
                                             return (
                                                <Col md={6}>
                                                   <FormGroup>
                                                      <label>
                                                         {item.descricao}
                                                      </label>

                                                      <InputSeletecd
                                                         key={
                                                            item.meioComunicacaoId
                                                         }
                                                         required
                                                         name={
                                                            item.meioComunicacaoId
                                                         }
                                                         type="text"
                                                      />
                                                   </FormGroup>
                                                </Col>
                                             );
                                          })}
                                       </Scope>
                                    </Row>
                                 </>
                              )}
                           </>
                        ) : (
                           <>
                              <Row className="mb-3" form>
                                 <Col md={12}>
                                    <FormGroup>
                                       <FormControl style={{ width: '100%' }}>
                                          <div>
                                             <label>Meios de Comunicação</label>
                                             <Tooltip
                                                texto="Informe ao menos um meio de contato."
                                                id="meioComunicacaoId"
                                             />
                                          </div>
                                          <Select
                                             mode="multiple"
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecione ao menos uma opção"
                                             onChange={
                                                handleValoresSelecionados
                                             }>
                                             {listMeioComunicacao.map(item => {
                                                return (
                                                   <Option
                                                      className="text-left"
                                                      key={
                                                         item.meioComunicacaoId
                                                      }>
                                                      {item.descricao}
                                                   </Option>
                                                );
                                             })}
                                          </Select>
                                       </FormControl>
                                    </FormGroup>
                                 </Col>
                              </Row>

                              <Row>
                                 <Scope path="lojaMeioComunicacao">
                                    {listValueSelecteds?.map(item => {
                                       return (
                                          <Col md={6}>
                                             <FormGroup>
                                                <label>{item.descricao}</label>

                                                <InputSeletecd
                                                   key={item.meioComunicacaoId}
                                                   required
                                                   name={item.meioComunicacaoId}
                                                   type="text"
                                                />
                                             </FormGroup>
                                          </Col>
                                       );
                                    })}
                                 </Scope>
                              </Row>
                           </>
                        )}

                        <Row className="mt-5">
                           <Col
                              md={6}
                              className="d-flex flex-column justify-content-center align-items-center">
                              <p>Imagem principal para o App: </p>
                              <small>Tamanho indicado: 187x285 </small>
                              {mainAppImgData && (
                                 <div>
                                    <div className="banner-wrapper">
                                       <img
                                          style={{
                                             width: 187,
                                             height: 285,
                                             objectFit: 'cover',
                                             border: '4px dashed #3339',

                                             margin: '10px 0',
                                          }}
                                          className="playerProfilePic_home_tile"
                                          alt=""
                                          src={
                                             mainAppImgData ||
                                             `data:image/*;base64,${mainAppPicture}`
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                              <InputFile
                                 type="file"
                                 style={{
                                    width: '200px',
                                    padding: '10px',
                                    margin: '0',
                                    borderRadius: '5px',
                                    backgroundColor: 'transparent',
                                    color: '#495057',
                                    borderStyle: 'dashed',
                                    borderColor: '#495057',
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                 }}
                                 justifyContent="center"
                                 text={
                                    mainAppImgData
                                       ? 'Alterar imagem'
                                       : 'Escolher imagem'
                                 }
                                 size={12}
                                 handleChange={onMainPictureChange}
                                 accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                 name="mainPic"
                                 id="mainPic"
                              />
                           </Col>
                           <Col
                              md={6}
                              className="d-flex flex-column justify-content-center align-items-center">
                              <p>Imagem secundária para o App: </p>
                              <small>Tamanho indicado: 187x285 </small>
                              {secondaryAppImgData && (
                                 <div>
                                    <div className="banner-wrapper">
                                       <img
                                          style={{
                                             width: 187,
                                             height: 285,
                                             objectFit: 'cover',
                                             border: '4px dashed #3339',
                                             margin: '10px 0',
                                          }}
                                          className="playerProfilePic_home_tile"
                                          alt=""
                                          src={
                                             secondaryAppImgData ||
                                             `data:image/*;base64,${secondaryAppPicture}`
                                          }
                                       />
                                    </div>
                                 </div>
                              )}
                              <InputFile
                                 type="file"
                                 style={{
                                    width: '200px',
                                    padding: '10px',
                                    margin: '0',
                                    borderRadius: '5px',
                                    backgroundColor: 'transparent',
                                    color: '#495057',
                                    borderStyle: 'dashed',
                                    borderColor: '#495057',
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                 }}
                                 justifyContent="center"
                                 text={
                                    secondaryAppImgData
                                       ? 'Alterar imagem'
                                       : 'Escolher imagem'
                                 }
                                 size={12}
                                 handleChange={onSecondaryPictureChange}
                                 accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                 name="secondaryPic"
                                 id="secondaryPic"
                              />
                           </Col>
                        </Row>

                        <Row className="mt-5">
                           <Button color="link" onClick={closeModal}>
                              Cancelar
                           </Button>
                           <Button
                              color="primary"
                              type="submit"
                              disabled={isLoading}>
                              {isLoading ? (
                                 <>
                                    {edit ? 'Atualizando' : 'Criando'}{' '}
                                    {<LoadingButton size={15} />}
                                 </>
                              ) : (
                                 <>{edit ? 'Atualizar' : 'Criar'} </>
                              )}{' '}
                           </Button>
                        </Row>
                     </FormUnform>
                  </FormGroup>
                  {isLoading && <Spinner />}
               </ModalCustom>
            </div>
         </PageTitle>

         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <div className="table-responsive">
                           <Table
                              columns={columns}
                              data={dataSource}
                              rowKey={record => record.nome}
                              pagination={pagination}
                              filtros={filtros}
                              onChange={handleTableChange}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default React.memo(ListaLoja);
