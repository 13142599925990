import React from 'react';
import api from '../../../api/api-local';
import { urlToFile } from '../../../helpers/urlToFile';

const formatMeioComunicacaoArray = (ids, values) => {
   const meiosComunicacaoFormatados = [];

   ids.forEach(id => {
      if (values[id]) {
         meiosComunicacaoFormatados.push({
            meioComunicacaoId: +id,
            valor: values[id],
         });
      }
   });
   console.log('meiosComunicacaoFormatados', meiosComunicacaoFormatados);
   return meiosComunicacaoFormatados;
};

const serializeData = data => {
   const {
      lojaMeioComunicacaoId: meioComunicacaoIDs,
      lojaMeioComunicacaoDescricao: meioComunicacaoValores,
      cpfCnpj,
      nomeRazaoSocial,
      nomeFantasia,
      descricao,
      gmt,
      urlEcommerce,
      caminhoLoja,
      cep,
      logradouro,
      numero,
      bairro,
      complemento,
      cidade,
      uf,
      lojaId,
   } = data;

   const lojaMeioComunicacao = formatMeioComunicacaoArray(
      meioComunicacaoIDs,
      meioComunicacaoValores,
   );

   const sendData = {
      segmentoId: 1,
      cpfCnpj,
      nomeRazaoSocial,
      nomeFantasia: nomeFantasia || '',
      descricao,
      gmt,
      urlEcommerce,
      caminhoLoja,
      cep,
      endereco: logradouro,
      numero,
      bairro,
      complemento,
      cidade,
      uf,
      lojaMeioComunicacao,
   };

   if (lojaId) {
      sendData.lojaId = lojaId;
   }

   return sendData;
};

const useLoja = () => {
   const createLoja = async data => {
      const { imagemDestaqueFile, imagemDetalheFile } = data;
      const sendData = serializeData(data);

      const formdata = new FormData();
      formdata.append('data', JSON.stringify(sendData));

      if (!!imagemDestaqueFile) {
         formdata.append('imagemDestaque', imagemDestaqueFile);
      }

      if (!!imagemDetalheFile) {
         formdata.append('imagemDetalhe', imagemDetalheFile);
      }

      return new Promise((resolve, reject) =>
         api
            .post('/loja', formdata)
            .then(response => resolve(response))
            .catch(error => reject(error)),
      );
   };

   const editLoja = async data => {
      const { imagemDestaqueFile, imagemDetalheFile } = data;
      const sendData = serializeData(data);

      const formdata = new FormData();
      formdata.append('data', JSON.stringify(sendData));

      if (!!imagemDestaqueFile) {
         formdata.append('imagemDestaque', imagemDestaqueFile);
      }

      if (!!imagemDetalheFile) {
         formdata.append('imagemDetalhe', imagemDetalheFile);
      }

      return new Promise((resolve, reject) =>
         api
            .put('loja', formdata)
            .then(response => resolve(response))
            .catch(error => reject(error)),
      );
   };

   const getLoja = async lojaId => {
      return new Promise((resolve, reject) =>
         api
            .get(`loja/${lojaId}`)
            .then(async response => {
               const { data } = response;

               let imagemDestaqueFile = null;
               let imagemDetalheFile = null;

               if (data.imagemDestaque) {
                  imagemDestaqueFile = await urlToFile(
                     data.imagemDestaque,
                     'imagemDestaque',
                  );
               }

               if (data.imagemDetalhe) {
                  imagemDetalheFile = await urlToFile(
                     data.imagemDestaque,
                     'imagemDestaque',
                  );
               }

               data.imagemDestaque = data.imagemDestaque
                  ? `data:image/png;base64,${data.imagemDestaque}`
                  : null;
               data.imagemDetalhe = data.imagemDetalhe
                  ? `data:image/png;base64,${data.imagemDetalhe}`
                  : null;

               let lojaMeioComunicacaoId = [];
               let lojaMeioComunicacaoDescricao = {};

               data.lojaMeioComunicacao.forEach(
                  ({ meioComunicacaoId, valor }) => {
                     lojaMeioComunicacaoId.push(meioComunicacaoId);
                     lojaMeioComunicacaoDescricao[meioComunicacaoId] = valor;
                  },
               );

               data.caminhoLoja = data.path;
               delete data.lojaMeioComunicacao;
               delete data.path;

               const result = {
                  ...data,
                  lojaMeioComunicacaoId,
                  lojaMeioComunicacaoDescricao,
                  imagemDestaqueFile,
                  imagemDetalheFile,
               };

               resolve(result);
            })
            .catch(error => {
               reject(error);
            }),
      );
   };

   return { createLoja, editLoja, getLoja };
};

export default useLoja;
