import React from 'react';

const PageRegulamento = () => {
   return (
      <>
         <div className="central_menor pb30">
            <div
               id="divTextoReg"
               className="fontTahoma pt30 politica_regulamento pb30"
               style={{ textAlign: 'justify' }}>
               <div
                  id="dvTituloReg"
                  style={{
                     float: 'left',
                     fontWeight: 'bold',
                     textAlign: 'center',
                     width: '100%',
                     fontSize: '22px',
                  }}>
                  Regulamento do PROGRAMA DE FIDELIDADE
               </div>
               <div className="cb"></div>
               <br />
               <div className="cb"></div>
               <br />
               <p>
                  O PROGRAMA DE FIDELIDADE desenvolvido pela empresa TESTE
                  REGULAMENTO, inscrito no CNPJ sob o Nº 54.595.827/0001-14,
                  procura incentivar a utilização de seus serviços pelo CLIENTE
                  PARTICIPANTE e beneficiá-lo por sua preferência.
               </p>
               <br />
               <p>1. Condições Gerais:</p>
               <br />
               <p>
                  1.1. O CLIENTE PARTICIPANTE é responsável pelo conhecimento
                  deste Regulamento e pelas informações que forem prestadas para
                  o TESTE REGULAMENTO, devendo manter atualizados seus dados
                  cadastrais, especialmente seu e-mail, endereço e telefone para
                  contato.{' '}
               </p>
               <br />
               <p>
                  1.2. Poderão participar do PROGRAMA DE FIDELIDADE todas as
                  pessoas físicas que tiverem mais de 15 (quinze) anos de idade
                  e possuírem número/cartão de CPF (cadastro de pessoa física)
                  próprio.{' '}
               </p>
               <br />
               <p>
                  1.3. Para aderir ao PROGRAMA DE FIDELIDADE, o CLIENTE
                  PARTICIPANTE poderá realizar seu cadastro através do website
                  do PROGRAMA DE FIDELIDADE do TESTE REGULAMENTO. Será permitido
                  somente um cadastro por CLIENTE PARTICIPANTE. Ao completar o
                  seu cadastro com sucesso o CLIENTE PARTICIPANTE receberá 2
                  Pontos “Vips” por mérito.
               </p>
               <br />
               <p>
                  1.4. Ao realizar o seu cadastro para acumular a Pontuação
                  ("Vips") necessária para usufruir dos benefícios do PROGRAMA
                  DE FIDELIDADE, o CLIENTE PARTICIPANTE estará aceitando todas
                  as regras e condições estipuladas neste Regulamento.
               </p>
               <br />
               <p>
                  1.5. A Pontuação é de propriedade do TESTE REGULAMENTO e a
                  utilização destes somente poderá ser realizada em conformidade
                  com o previsto neste Regulamento.
               </p>
               <br />
               <p>
                  1.6. É expressamente vedado ao CLIENTE PARTICIPANTE praticar
                  todo e qualquer tipo de comercialização dos Pontos “Vips”
                  obtidos através deste Programa, inclusive, mas não se
                  limitando a vendas, cessões ou permutas de Pontuação. A
                  comprovação de tais práticas ensejará a imediata exclusão do
                  CLIENTE PARTICIPANTE do PROGRAMA DE FIDELIDADE e o
                  cancelamento de sua Pontuação, independente de serem tomadas
                  as medidas judiciais cabíveis.
               </p>
               <br />
               <p>
                  1.7. A Pontuação obtida na forma deste Regulamento é pessoal e
                  intransferível, sendo vedada sua transferência para terceiros,
                  a qualquer título, inclusive por sucessão ou herança, dessa
                  forma, no caso de falecimento do CLIENTE PARTICIPANTE titular
                  do PROGRAMA DE FIDELIDADE, a conta-corrente será encerrada e a
                  Pontuação existente será cancelada.
               </p>
               <br />
               <p>
                  1.8. TESTE REGULAMENTO se reserva o direito de suspender
                  temporariamente, a qualquer momento e mediante prévia
                  comunicação ao CLIENTE PARTICIPANTE, o resgate de Pontuações.
               </p>
               <br />
               <p>
                  1.9. TESTE REGULAMENTO poderá cancelar ou alterar o PROGRAMA
                  DE FIDELIDADE, bem como efetuar qualquer alteração neste
                  Regulamento, a qualquer momento e mediante aviso prévio de 30
                  (trinta) dias via website do PROGRAMA DE FIDELIDADE do TESTE
                  REGULAMENTO, em campanhas de e-mail marketing, mala-direta via
                  correio e, dependendo do caso, na mídia em geral, garantindo
                  aos participantes o direito de resgatar seus Pontos “Vips”
                  acumulados no prazo de 60 (sessenta) dias após a data de
                  encerramento do programa, optando pro trocá-los pelos prêmios
                  disponíveis no website do PROGRAMA DE FIDELIDADE.
               </p>
               <br />
               <p>
                  1.10. Na hipótese deste regulamento não poder ser cumprido em
                  parte ou em sua totalidade, por qualquer razão legal ou de
                  regulamentação o TESTE REGULAMENTO se isenta de
                  responsabilidade.
               </p>
               <br />
               <br />
               <p>2. Sobre a Pontuação:</p>
               <br />
               <p>
                  2.1. Pontuação por compras realizadas no TESTE REGULAMENTO: o
                  CLIENTE PARTICIPANTE titular devidamente cadastrado no
                  PROGRAMA DE FIDELIDADE na forma prevista neste Regulamento
                  ganhará 01 Ponto “Vip” a cada R$ 1,00 consumidos no TESTE
                  REGULAMENTO.
               </p>
               <br />
               <p>
                  2.2. O CLIENTE PARTICIPANTE poderá receber um total de 0,0
                  Pontos “Vips” diariamente, dentro do PROGRAMA DE FIDELIDADE do
                  TESTE REGULAMENTO.
               </p>
               <br />
               <p>
                  2.3. O CLIENTE PARTICIPANTE poderá ser pontuado 0 vezes,
                  diariamente, dentro do PROGRAMA DE FIDELIDADE do TESTE
                  REGULAMENTO.
               </p>
               <br />
               <p>
                  2.4. TESTE REGULAMENTO permanecerá como gestor e administrador
                  do PROGRAMA DE FIDELIDADE responsabilizando-se por efetuar os
                  créditos dos Pontos “Vips” conforme descrito neste
                  Regulamento.
               </p>
               <br />
               <p>
                  2.5. Na ocasião da realização de pedidos de produtos e consumo
                  aos atendentes TESTE REGULAMENTO, o CLIENTE PARTICIPANTE deve,
                  obrigatoriamente, informar o seu CPF (cadastro de pessoa
                  física) sob pena de perda do direito ao(s) mesmo(s).
               </p>
               <br />
               <p>
                  2.6. O lançamento da Pontuação na conta-corrente do CLIENTE
                  PARTICIPANTE por utilização de compra de produtos no TESTE
                  REGULAMENTO será realizado na mesma data do pagamento do
                  consumo, desde que o CLIENTE PARTICIPANTE tenha obedecido aos
                  procedimentos instituídos neste Regulamento.{' '}
               </p>
               <br />
               <p>
                  2.7. A Pontuação é cumulativa, ficando a critério exclusivo do
                  TESTE REGULAMENTO agregar promoções especiais, por períodos
                  determinados, no decorrer da vigência do PROGRAMA DE
                  FIDELIDADE, tanto para o acúmulo quanto para a realização do
                  resgate de prêmio, sendo que referidas promoções poderão ser
                  divulgadas através do website do PROGRAMA DE FIDELIDADE do
                  TESTE REGULAMENTO, bem como em campanhas de e-mail marketing,
                  mala-direta via correio e, dependendo do caso, na mídia em
                  geral.
               </p>
               <br />
               <p>
                  2.8. A Pontuação acumulada pelo CLIENTE PARTICIPANTE expirará
                  em 100 dias contados a partir do seu registro. Ficando assim
                  por inteira responsabilidade do CLIENTE PARTICIPANTE o resgate
                  de sua Pontuação acumulada até a data limite de expiração.{' '}
               </p>
               <br />
               <p>
                  2.9. Os Pontos “Vips” não resgatados até a data limite de
                  expiração serão perdidos. Desta forma não caberá ao CLIENTE
                  PARTICIPANTE qualquer requisição ou reclamação sobre os Pontos
                  “Vips” expirados, conforme descrito neste Regulamento.
               </p>

               <p>
                  2.10. TESTE REGULAMENTO se reserva o direito de cancelar a
                  Pontuação em caso de irregularidades, bem como de suspender o
                  resgate da Pontuação de CLIENTE PARTICIPANTE.
               </p>
               <br />
               <br />
               <br />
               <p>3. Resgate da Pontuação:</p>
               <br />
               <p>
                  3.1. A Pontuação obtida pelo CLIENTE PARTICIPANTE na forma
                  prevista neste Regulamento será lançada em conta-corrente e
                  poderá ser resgatada automaticamente pelo CLIENTE
                  PARTICIPANTE, por meio de requisição de resgate aos atendentes
                  do TESTE REGULAMENTO. O CLIENTE PARTICIPANTE deverá apresentar
                  no momento do resgate seu CUPOM VALE PRÊMIOS informando o
                  CÓDIGO VIP contido no mesmo, juntamente documento de
                  comprovação de identidade e CPF (cadastro de pessoa física).
               </p>
               <br />
               <p>
                  3.2. O resgate de pontuação deverá ser realizado
                  respeitando-se as regras definidas para o respectivo prêmio,
                  em exposição no website do PROGRAMA DE FIDELIDADE do TESTE
                  REGULAMENTO.{' '}
               </p>
               <br />
               <p>
                  3.3. O resgate somente poderá ser efetuado pelo próprio
                  CLIENTE PARTICIPANTE e sua Pontuação acumulada. Não serão
                  permitidos os resgates efetuados por terceiros, nem a
                  somatória de Pontuação de CLIENTE PARTICIPANTE, independente
                  de seu parentesco.
               </p>
               <br />
               <p>
                  3.4. Os produtos resgatados serão entregues somente na filial
                  TESTE REGULAMENTO onde foi efetuado o cadastro.
               </p>
               <br />
               <p>3.5. Não haverá cobrança de taxa de resgate.</p>
               <br />
               <br />
               <p>4. Indicação de amigos:</p>
               <br />
               <p>
                  4.1 O CLIENTE PARTICIPANTE poderá, a qualquer momento,
                  realizar a indicação de amigos para participarem do PROGRAMA
                  DE FIDELIDADE do TESTE REGULAMENTO.
               </p>
               <br />
               <p>
                  4.2 A cada indicação realizada, seguida de cadastro e
                  pontuação por compra pelo indicado, o CLIENTE PARTICIPANTE
                  receberá a quantia de 10 Pontos “Vips” por mérito.
               </p>
               <br />
               <p>
                  4.3 Para que o recebimento dos Pontos “Vips” por mérito de
                  indicação seja realizado, o e-mail informado pelo CLIENTE
                  PARTICIPANTE no momento da indicação do amigo deve ser o mesmo
                  utilizado para o cadastro do mesmo no PROGRAMA DE FIDELIDADE
                  do TESTE REGULAMENTO. Caso o e-mail informado não seja
                  exatamente o mesmo cadastrado pelo indicado, o sistema não
                  reconhecerá a indicação e consequentemente os Pontos “Vips”
                  por mérito não serão creditados à conta-corrente do CLIENTE
                  PARTICIPANTE.
               </p>
               <br />
               <br />
               <p>5. Validade do PROGRAMA DE FIDELIDADE:</p>
               <br />
               <p>
                  5.1. O PROGRAMA DE FIDELIDADE tem duração indeterminada, a
                  critério do TESTE REGULAMENTO.
               </p>
            </div>
         </div>

         <br />
         <a href="#" id="aImprimir" className="btn_laranja h5 text-white">
            <div
               className="tac text-center my-5 bg-warning px-2 py-2 rounded"
               style={{ maxWidth: '200px' }}>
               Imprimir
            </div>
         </a>
      </>
   );
};

export default PageRegulamento;
