import React from 'react';
import { useField } from 'formik';
import { Select as SelectANTD } from 'antd';
import { MenuItem } from '@material-ui/core';

const Select = props => {
   const options = props.options || [];
   const [_field, _helpers, meta] = useField(props);

   const onCallChange = e => {
      if (props.overrideOnChange) {
         return props.overrideOnChange(e);
      }
      meta.setValue(e);
   };

   return (
      <>
         <SelectANTD
            mode={props.mode || ''}
            data-cy="gmt-select"
            style={selectStyles}
            onChange={e => onCallChange(e)}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}>
            {options.map(option => {
               return (
                  <MenuItem
                     data-cy={`gmt-item-${option.value}`}
                     key={option.value}
                     className="text-left"
                     value={option.value}>
                     <em>{option.descricao}</em>
                  </MenuItem>
               );
            })}
         </SelectANTD>
         {meta.error && meta.touched && (
            <div className="input-validation-error">{meta.error}</div>
         )}
      </>
   );
};

const selectStyles = {
   width: '100%',
};

export default Select;
