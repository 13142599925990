import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../customHooks/AuthContext';

//Libs
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
   Nav,
   UncontrolledButtonDropdown,
} from 'reactstrap';

const MegaMenu = () => {
   const { usuario } = useAuth();
   const [state, setState] = useState({
      dropdownOpen: false,
      popoverOpen: false,
   });

   const { statusJornadaCadastroPlataforma } = usuario;

   const statusEnabled =
      statusJornadaCadastroPlataforma?.configuracaoLojaConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPersonalizacaoConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoPremioConcluida &&
      statusJornadaCadastroPlataforma?.configuracaoProgramaConcluida;

   function toggle() {
      setState({
         dropdownOpen: !state.dropdownOpen,
         popoverOpen: !state.popoverOpen,
      });
   }

   useEffect(() => {
      toggle = toggle.bind(this);
   }, []);

   if (!statusEnabled) return <></>;

   return (
      <Fragment>
         <Nav className="header-megamenu">
            <UncontrolledButtonDropdown nav inNavbar>
               <DropdownToggle nav>
                  <i className="nav-link-icon pe-7s-users mr-0"></i>
                  Meus clientes
                  <FontAwesomeIcon
                     className="mr-2 opacity-5 ml-1"
                     icon={faAngleDown}
                  />
               </DropdownToggle>
               <DropdownMenu className="rm-pointers">
                  <div className="dropdown-menu-header">
                     <div className="dropdown-menu-header-inner bg-fideleco">
                        <div className="menu-header-content">
                           <h5 className="menu-header-title text-white font-weight-bold">
                              Meus clientes
                           </h5>
                        </div>
                     </div>
                  </div>
                  <div className="scroll-area-xs">
                     <PerfectScrollbar>
                        <Link
                           className="text-decoration-none"
                           to="/clientes/pontuar">
                           <DropdownItem>Pontuar</DropdownItem>
                        </Link>

                        <Link
                           className="text-decoration-none"
                           to="/clientes/gerenciar-clientes">
                           <DropdownItem>Gerenciar clientes</DropdownItem>
                        </Link>

                        <Link
                           className="text-decoration-none"
                           to="/clientes/estornar-clientes">
                           <DropdownItem>Estorno de pontos</DropdownItem>
                        </Link>
                     </PerfectScrollbar>
                  </div>
               </DropdownMenu>
            </UncontrolledButtonDropdown>
            {usuario.lojas[0].perfilId === 1 && (
               <UncontrolledButtonDropdown nav inNavbar>
                  <DropdownToggle nav>
                     <i className="nav-link-icon pe-7s-star mr-0"></i>
                     Fidelizar
                     <FontAwesomeIcon
                        className="mr-2 opacity-5 ml-1"
                        icon={faAngleDown}
                     />
                  </DropdownToggle>
                  <DropdownMenu className="rm-pointers">
                     <div className="dropdown-menu-header">
                        <div className="dropdown-menu-header-inner bg-fideleco">
                           <div className="menu-header-content">
                              <h5 className="menu-header-title text-white font-weight-bold">
                                 Fidelizar
                              </h5>
                              <h6 className="menu-header-subtitle"> </h6>
                           </div>
                        </div>
                     </div>
                     <div className="scroll-area-xs">
                        <PerfectScrollbar>
                           <Link
                              className="text-decoration-none"
                              to="/fidelizar/gerenciar-premios">
                              <DropdownItem>Gerenciar prêmios</DropdownItem>
                           </Link>
                           <Link
                              className="text-decoration-none"
                              to="/fidelizar/pontuacao-lote">
                              <DropdownItem>Pontuar em lote</DropdownItem>
                           </Link>
                        </PerfectScrollbar>
                     </div>
                  </DropdownMenu>
               </UncontrolledButtonDropdown>
            )}
         </Nav>
      </Fragment>
   );
};

export default MegaMenu;
