import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form as UnForm } from 'unform';
import * as Yup from 'yup';
import api from '../../../api/api-local';
import cleanNumericValues from '../../../helpers/cleanNumericValues';
//Components
import useForm from '../../../customHooks/useForm';
import PageTitle from '../../layout/AppMain/PageTitle';
import InputIcon from '../../../components/Formulario/InputIcon';
import { toast } from 'react-toastify';
import { useAuth } from '../../../customHooks/AuthContext';
import Input from '../../../components/Formulario/Input';
import InputSelect from '../../../components/Formulario/InputSelect';
//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import validateFormCupom from '../../../helpers/validateFormCupom';
import Spinner from '../../../components/loading/Loading';

import {
   Radio,
   RadioGroup,
   FormControlLabel,
   FormControl,
   FormLabel,
   TextField,
   Select,
   MenuItem,
   InputLabel,
} from '@material-ui/core';
import { Col, Row, Button, Form, FormGroup, Card, CardBody } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';

const CPFMASK = '999.999.999-99?';
const CNPJMASK = '99.999.999/9999-99';
const FORMATCHARS = { 9: '[0-9]', '?': '[0-9]' };
const PontuarClientes = () => {
   const { signOut } = useAuth();
   const history = useHistory();

   const calculaFormRef = useRef();
   const initialValues = {
      formaPagamentoPlataformaId: '',
      valor: '',
   };
   const [cpfCnpj, setCpfCnpj] = useState({
      person: '',
      mask: '',
      lengthCaracter: null,
   });
   const [findClient, setFindClient] = useState([]);
   const [clienteSelecionado, setClienteSelecionado] = useState([]);
   const [pontuacaoFinal, setPontuacaoFinal] = useState({
      saldoFinal: '',
      valorPonto: '',
      saldoAtual: '',
   });
   const [comboFormasPagamento, setComboFormasPagamento] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [erros, setErros] = useState({ valor: null, formaPagamento: null });
   const [valores, setValores] = useState({});

   const getPessoaPontuacao = termoBusca => {
      setClienteSelecionado([]);
      if (termoBusca) {
         setIsLoading(true);
         const termoBuscaFormatado = termoBusca.replace(/[^\w\s]/gi, '').trim();
         const letras = /^[A-zÀ-ú ]+$/i;
         const numeros = /\d+/g;

         const pesquisarPor = letras.test(termoBuscaFormatado)
            ? 'nome'
            : numeros.test(termoBuscaFormatado) && 'documento';

         api.get(`cliente/filtro/nomedocumento`, {
            params: {
               [pesquisarPor]: termoBuscaFormatado,
            },
         })
            .then(response => {
               if (response.status === 200) {
                  setIsLoading(false);
                  setFindClient(response.data);
                  setPontuacaoFinal({
                     ...pontuacaoFinal,
                     saldoAtual: response.data[0].saldo,
                  });
               }
            })
            .catch(error => {
               if (error.response) {
                  if (error.response?.status === 401) {
                     toast.error('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     setIsLoading(false);
                     signOut();
                  } else {
                     setIsLoading(false);
                     toast.error(error.response.data.error[0].message);
                  }
               } else {
                  toast.error('Nenhum cliente encontrado');
               }
            });
      } else {
         toast.warning('Informe nome ou documento para realizar a busca.');
      }
   };

   const selecionarCliente = id => {
      setIsLoading(true);
      const clienteSelecionado = findClient.filter(
         cliente => cliente.clienteId === id && cliente,
      );
      setClienteSelecionado(clienteSelecionado);

      setPontuacaoFinal({
         valorPontuacaoFinal: '',
         saldoAtual: clienteSelecionado[0].saldo,
      });
      setIsLoading(false);
   };

   const checkPerson = useCallback(() => {
      getPessoaPontuacao(cpfCnpj.person);
   }, [cpfCnpj]);

   const verificaPontuacao = useCallback(async data => {
      const clienteId = clienteSelecionado[0].clienteId;
      const urlParams = `pontuacao/calcularpontuacaoclienteloja`;

      const erroValor = data.valor === '' ? 'Campo obrigatório' : null;
      const erroFormaPagamento =
         data.formaPagamentoPlataformaId.state.value === null
            ? 'Campo obrigatório'
            : null;

      if (erroValor) {
         setErros(state => ({ ...state, valor: erroValor }));
      } else {
         setErros(state => ({ ...state, valor: null }));
      }
      if (erroFormaPagamento) {
         setErros(state => ({ ...state, formaPagamento: erroFormaPagamento }));
      } else {
         setErros(state => ({ ...state, formaPagamento: null }));
      }

      const valorSemLetras = cleanNumericValues(data.valor);

      if (!erroValor && !erroFormaPagamento) {
         setIsLoading(true);
         setValores({
            valor: valorSemLetras,
            formaPagamentoPlataformaId:
               data.formaPagamentoPlataformaId.state.value.value,
         });
         api.get(urlParams, {
            params: {
               clienteId: clienteId,
               formaPagamentoPlataformaId:
                  data.formaPagamentoPlataformaId.state.value.value,
               valor: valorSemLetras,
            },
         })
            .then(response => {
               if (response.status === 200) {
                  const { saldoAtual, saldoFinal, valorPonto } = response.data;
                  setIsLoading(false);
                  setPontuacaoFinal({
                     saldoAtual,
                     saldoFinal,
                     valorPonto,
                  });
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.warning('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.warning(error.response.data.error[0].message);
               }
            });
      }
   });

   const submitPontuacaoManual = () => {
      valores.clienteId = clienteSelecionado[0].clienteId;

      const urlParams = 'pontuacao/manual';
      setIsLoading(true);
      api.post(urlParams, valores)
         .then(response => {
            toast.success('Pontuação adicionada com sucesso!');
            if (response.status === 200) {
               setIsLoading(false);
               setTimeout(() => {}, 2000);
            }
         })
         .catch(error => {
            setIsLoading(false);
            toast.error(error.response.data.error[0].message);
         });
   };

   const handleChangePerson = event => {
      const { value } = event.target;
      const formattedValue = value.replace(/\D/g, '');
      setCpfCnpj({
         mask: CNPJMASK,
         person: value,
         lengthCaracter: formattedValue.length,
      });
   };
   // const formaPagamento = () => {
   //    api.get(`formapagamentoplataforma`).then((response) => {
   //       if (response.status === 200) {
   //          console.log(response.data)
   //          setComboFormasPagamento(response.data)
   //       }
   //    });
   // }
   useEffect(() => {
      api.get(`formapagamentoplataforma`).then(response => {
         if (response.status === 200) {
            setComboFormasPagamento(response.data);
         }
      });
   }, [pontuacaoFinal]);

   useEffect(() => {}, [cpfCnpj]);
   const verifyLengthClient = findClient.length > 0;

   const onBuscarClienteEnter = e => {
      if (e.keyCode === 13) {
         getPessoaPontuacao(e.target.value);
      }
   };
   return (
      <>
         <PageTitle
            heading="Pontuação"
            subheading="Pontue seus clientes"
            icon="pe-7s-gift icon-gradient bg-fideleco"></PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <Row form>
                           <Col md={4}>
                              <FormGroup>
                                 <InputMask
                                    //mask={'999.999.999-99'}
                                    value={cpfCnpj.person}
                                    onChange={handleChangePerson}
                                    //formatChars={FORMATCHARS}
                                    //maskChar="_"
                                    //placeholder="___.___.___-__"
                                    type="string">
                                    {inputProps => (
                                       <TextField
                                          {...inputProps}
                                          value={cpfCnpj.person}
                                          onChange={handleChangePerson}
                                          style={{ width: '100%' }}
                                          label="Busque por CPF/CNPJ ou Nome"
                                          onKeyDown={e =>
                                             onBuscarClienteEnter(e)
                                          }
                                       />
                                    )}
                                 </InputMask>
                              </FormGroup>
                           </Col>
                           <Col md={8} style={{}}>
                              <div className="d-flex justify-content-end">
                                 <div className="">
                                    <Button
                                       color="primary"
                                       onClick={checkPerson}
                                       className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                       size="lg"
                                       style={{ minWidth: '228px' }}>
                                       Buscar Cliente
                                    </Button>
                                 </div>
                              </div>
                           </Col>
                        </Row>

                        {verifyLengthClient ? (
                           clienteSelecionado.length === 0 ? (
                              <>
                                 <Form noValidate className="text-left">
                                    {findClient.map((i, index) => (
                                       <Row
                                          form
                                          className="border-bottom my-4 pb-4"
                                          key={index}>
                                          <Col md={6} className="mb-4">
                                             {verifyLengthClient > 0 && (
                                                <span className="text-primary font-weight-bold mb-5">
                                                   Confirme os dados:
                                                </span>
                                             )}
                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   Nome:
                                                </span>
                                                {i.nome}
                                             </div>
                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   CPF/CNPJ:
                                                </span>
                                                {i.cpfCnpj}
                                             </div>
                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   Saldo:
                                                </span>
                                                {i.saldo}
                                             </div>

                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   Classificação:
                                                </span>
                                                {
                                                   i.classificacaoCliente
                                                      .descricao
                                                }
                                             </div>
                                          </Col>
                                          <Col md={6}>
                                             <div className="d-flex justify-content-end align-items-center">
                                                <Button
                                                   color="primary"
                                                   type="button"
                                                   onClick={() =>
                                                      selecionarCliente(
                                                         i.clienteId,
                                                      )
                                                   }
                                                   className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                                   size="lg"
                                                   style={{
                                                      minWidth: '228px',
                                                   }}>
                                                   Selecionar cliente
                                                </Button>
                                             </div>
                                          </Col>
                                       </Row>
                                    ))}
                                 </Form>
                              </>
                           ) : (
                              <>
                                 {clienteSelecionado.map(cliente => (
                                    <>
                                       <Row>
                                          <Col md={3}>
                                             {verifyLengthClient > 0 && (
                                                <span className="text-primary font-weight-bold mb-5">
                                                   Confirme os dados:
                                                </span>
                                             )}
                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   Nome:
                                                </span>{' '}
                                                {cliente.nome}
                                             </div>
                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   CPF/CNPJ:
                                                </span>
                                                {cliente.cpfCnpj}
                                             </div>

                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   Saldo:
                                                </span>
                                                {cliente.saldo}
                                             </div>

                                             <div>
                                                <span className="font-weight-bold mr-1">
                                                   Classificação:
                                                </span>
                                                {
                                                   cliente.classificacaoCliente
                                                      .descricao
                                                }
                                             </div>
                                          </Col>
                                       </Row>

                                       <UnForm
                                          ref={calculaFormRef}
                                          onSubmit={verificaPontuacao}
                                          className="border-bottom my-4 pb-4">
                                          <Row>
                                             <Col md={4} className="mb-4">
                                                <Input
                                                   name="valor"
                                                   label="Valor:"
                                                   type="text"
                                                   mask="dinheiro"
                                                   className={`input-contato ${
                                                      erros.valor ? 'erro' : ''
                                                   }`}
                                                   erro={erros.valor}
                                                />
                                             </Col>
                                             <Col md={4} className="mb-4">
                                                <InputSelect
                                                   options={comboFormasPagamento.map(
                                                      forma => {
                                                         return {
                                                            value: forma.formaPagamentoPlataformaId,
                                                            label: forma.descricao,
                                                         };
                                                      },
                                                   )}
                                                   name="formaPagamentoPlataformaId"
                                                   label="Forma de Pagamento"
                                                   erro={erros.formaPagamento}
                                                />
                                             </Col>
                                             <Col md={4}>
                                                <div className="d-flex justify-content-end align-items-center">
                                                   <Button
                                                      color="primary"
                                                      type="submit"
                                                      className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                                      size="lg"
                                                      style={{
                                                         minWidth: '228px',
                                                      }}>
                                                      Calcular pontuação
                                                   </Button>
                                                </div>
                                             </Col>
                                          </Row>
                                       </UnForm>

                                       {pontuacaoFinal.saldoFinal && (
                                          <Row>
                                             <Col md={3} className="mb-4">
                                                <h5
                                                   style={{
                                                      fontFamily:
                                                         'Objective-italic',
                                                      alignSelf: 'center',
                                                   }}>
                                                   Valor em pontos:{' '}
                                                   {pontuacaoFinal.valorPonto}
                                                </h5>
                                             </Col>
                                             <Col md={3} className="mb-4">
                                                <h5
                                                   style={{
                                                      fontFamily:
                                                         'Objective-italic',
                                                      alignSelf: 'center',
                                                   }}>
                                                   Saldo Atual:{' '}
                                                   {pontuacaoFinal.saldoAtual}
                                                </h5>
                                             </Col>
                                             <Col md={3} className="mb-4">
                                                <h5
                                                   style={{
                                                      fontFamily:
                                                         'Objective-italic',
                                                      alignSelf: 'center',
                                                   }}>
                                                   Saldo Final:{' '}
                                                   {pontuacaoFinal.saldoFinal}
                                                </h5>
                                             </Col>
                                             <Col md={3}>
                                                <div className="d-flex justify-content-end">
                                                   <div className="">
                                                      <Button
                                                         color="primary"
                                                         onClick={
                                                            submitPontuacaoManual
                                                         }
                                                         type="button"
                                                         className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                                         size="lg"
                                                         style={{
                                                            minWidth: '228px',
                                                         }}>
                                                         Adicionar Pontuação
                                                      </Button>
                                                   </div>
                                                </div>
                                             </Col>
                                          </Row>
                                       )}
                                    </>
                                 ))}
                              </>
                           )
                        ) : null}
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default PontuarClientes;
