import React from 'react';
import { useField } from 'formik';

// formatters
import {
   formatCpfCnpj,
   formatCEP,
   formatCelular,
   formatTelefone,
} from '../../helpers/formatters';

const InputFormik = props => {
   const [field, meta, helpers] = useField(props);

   const onCallChange = e => {
      if (props.overrideOnChange) {
         return props.overrideOnChange(e);
      }

      if (props.onChange) {
         props.onChange(e);
      }

      if (props.cpfCnpj) {
         return helpers.setValue(formatCpfCnpj(e.target.value));
      }

      if (props.cep) {
         return helpers.setValue(formatCEP(e.target.value));
      }

      if (props.celular) {
         return helpers.setValue(formatCelular(e.target.value));
      }

      if (props.telefone) {
         return helpers.setValue(formatTelefone(e.target.value));
      }

      return field.onChange(e);
   };

   return (
      <>
         <input
            id={props.name}
            // ref={ref}
            {...field}
            {...props}
            disabled={props.disabled}
            onChange={e => onCallChange(e)}
            className={`input-formik ${props.disabled ? 'disabled' : ''} ${
               meta.error && meta.touched ? 'validation-error' : ''
            }`}
            autoComplete="off"
         />
         <label htmlFor={props?.id || props?.name} />
         {meta.error && meta.touched && (
            <div className="input-validation-error">{meta.error}</div>
         )}
      </>
   );
};

export default InputFormik;
