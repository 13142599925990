import React, {
   useState,
   useEffect,
   useReducer,
   useRef,
   createContext,
} from 'react';
import { Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import api from '../../../api/api-local';
import Context from '../../../context/Context';
import { connect } from 'react-redux';
import { fideActions } from '../../../reducers/FideDataOption';
//Components
import InputUnform from '../../../components/Formulario/InputUnform';
import InputSeletecd from '../../../components/InputSeletecd';
import { useAuth } from '../../../customHooks/AuthContext';
import Spinner from '../../../components/loading/Loading';
import { mask as masker, unMask } from 'remask';

import {
   initialStateAddress,
   reducerFormAddress,
} from '../../../store/reducerHooks/formReduceAdmin';

//Libs
import { Row, Col, Card, CardBody, Button, FormGroup } from 'reactstrap';
import { Select, Input as InputAntD, Checkbox } from 'antd';
import Tooltip from '../../../components/tooltip';
import { Form as FormUnform } from '@unform/web';
import { FormControl } from '@material-ui/core';
import { toast } from 'react-toastify';
import * as cepData from 'cep-promise';
import { Scope } from '@unform/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { StepControllerContainer } from '../styles';

const useStyles = makeStyles(theme => ({
   formControl: {
      marginRight: theme.spacing(1),
      width: '100%',
   },
   selectEmpty: {},
}));

const listaGmt = [
   { descricao: 'GMT -2 (Fernando de Noronha)', value: '-2' },
   {
      descricao:
         'GMT -3 (Brasilia, São Paulo, Salvador, Fortaleza, Belém, Maceió, Recife, etc)',
      value: '-3',
   },
   {
      descricao:
         'GMT -4 (Boa Vista, Campo Grande, Cuiabá, Manaus, Rio Branco, etc)',
      value: '-4',
   },
];

const WizardLoja = ({ handleNext }) => {
   const dispatch = useDispatch();
   const classes = useStyles();

   const UserContext = createContext(undefined);
   const [userNextStep, setUserNextStep] = useState(undefined);
   const [addressState, dispatching] = useReducer(
      reducerFormAddress,
      initialStateAddress,
   );
   const [valueNextStep, setValueNextStep] = useState(0);

   const [cep, setCep] = useState({
      blurCep: '',
   });

   const { usuario, signOut } = useAuth();
   const history = useHistory();
   const formRef = useRef(null);
   const { Option } = Select;

   //state
   const [listValueSelectedsLoaded, setListValueSelectedsLoaded] =
      useState(false);
   const [listValueSelectedsUpdate, setlistValueSelectedsUpdate] = useState();
   const [valueCheckComunicacao, setValueCheckComunicacao] = useState();
   const [listMeioComunicacao, setListMeioComunicacao] = useState([]);
   const [listValueSelecteds, setlistValueSelecteds] = useState();
   const [listLabelsSelecteds, setListLabelsSelecteds] = useState([]);
   const [valueCpfCnpj, setValueCpfCnpj] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [dataSource, setDataSource] = useState([]);
   const [enderecoApi, setEnderecoApi] = useState();
   const [dataUpdate, setDataUpdate] = useState();
   const [listLojas, setListLojas] = useState([]);
   const [edit, setEdit] = useState(false);
   const [valuePath, setValuePath] = useState();
   const [plataforma, setPlataforma] = useState();
   const [valueGmt, setValueGmt] = useState('-3');
   const [erroGmt, setErroGmt] = useState(false);

   ///////////////////////////////////////////// ADD E UPDATE LOJA ////////////////////////////////////////////////////

   function onChangeCheck(e) {
      setValueCheckComunicacao(e.target.checked);
   }

   const handleSubimit = async data => {
      setIsLoading(true);
      //Ajustando a lista de meios de comunicacao
      const lista = [];
      if (data.lojaMeioComunicacao) {
         Object.entries(data.lojaMeioComunicacao).map(dt => {
            if (dt[0] == 5) {
               lista.push({
                  meioComunicacaoId: parseInt(dt[0]),
                  valor: dt[1]
                     .replace('(', '')
                     .replace(')', '')
                     .replace(/\s/g, '')
                     .replace('-', ''),
               });
            } else {
               lista.push({
                  meioComunicacaoId: parseInt(dt[0]),
                  valor: dt[1],
               });
            }
         });
      }

      const novaData = {
         segmentoId: 1,
         cpfCnpj: data.cpfCnpj,
         nomeRazaoSocial: data.razaoSocial,
         nomeFantasia: data.nomeFantasia,
         descricao: data.descricao,
         gmt: valueGmt,
         urlEcommerce: data.urlEcommerce,
         caminhoLoja: data.path,
         cep: data.cep,
         logradouro: data.logradouro,
         numero: data.numero,
         bairro: data.bairro,
         complemento: data.complemento,
         cidade: data.cidade,
         uf: data.uf,
         lojaMeioComunicacao: lista,
      };

      try {
         // resetando os erros
         formRef.current.setErrors({});

         // validando os dados
         const schema = Yup.object().shape({
            cpfCnpj: Yup.string().required('CPF ou CNPJ é obrigatório'),
            razaoSocial: Yup.string().required('Razão social é obrigatório'),
            path: Yup.string().required(
               'Personalizar endereço da loja é obrigatório',
            ),
         });

         await schema.validate(data, {
            abortEarly: false,
         });

         //Obrigando o preenchimento do campo Fuso
         if (valueGmt === '') {
            setErroGmt(true);
            return;
         } else {
            setErroGmt(false);
         }

         novaData.lojaId = usuario.lojas[0].lojaId;

         if (!edit) {
            //Realizando envio
            api.put('loja', novaData)
               .then(response => {
                  setIsLoading(true);
                  if (response.status === 200) {
                     console.log('response', response);
                     dispatch(fideActions.valueNextStepWizard(true));
                     setValueNextStep(1);
                     setIsLoading(false);
                     usuario.etapaConfiguracaoPlataforma.etapaConfiguracaoPlataformaId = 2;
                     localStorage.setItem(
                        '@Fideleco:user',
                        JSON.stringify(usuario),
                     );

                     toast.success('Loja adicionado com sucesso');
                     handleNext(1);
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         } else {
            novaData.lojaId = dataUpdate.lojaId;
            //Realizando o update
            api.put('loja', novaData)
               .then(response => {
                  setIsLoading(true);

                  if (response.status === 200) {
                     setIsOpen(false);
                     toast.success('Loja alterada com sucesso!');
                     setIsLoading(false);
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.warning('Sessão expirada. Entre novamente', {
                        autoClose: 2000,
                     });
                     signOut();
                  } else {
                     toast.warning(error.response.data.error[0].message);
                  }
               });
         }
      } catch (err) {
         // pegando os erros
         if (err instanceof Yup.ValidationError) {
            // criando uma lista para guardar os erros
            const errorMessages = {};

            // percorrendo os errors localizados no form
            err.inner.forEach(error => {
               // add o erros na lista
               errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
            setIsLoading(false);
         }
      }
   };

   function handleValoresSelecionados(value) {
      const novaLista = [];

      listMeioComunicacao.map(meioComunicao =>
         value.map(v => {
            if (v === meioComunicao.meioComunicacaoId) {
               const meioComunicacaoId = String(
                  meioComunicao.meioComunicacaoId,
               );

               novaLista.push({
                  meioComunicacaoId: meioComunicacaoId,
                  descricao: meioComunicao.descricao,
                  valor: '',
               });
            }
         }),
      );

      for (let key in novaLista) {
         for (let item in listValueSelecteds) {
            if (
               novaLista[key].meioComunicacaoId ==
               listValueSelecteds[item].meioComunicacaoId
            ) {
               novaLista[key].valor = listValueSelecteds[item].valor;
            }
         }
      }

      setlistValueSelecteds(novaLista);
   }

   function onMeioComunicacaoChange(data) {
      for (let item in listValueSelecteds) {
         if (data.id == listValueSelecteds[item].meioComunicacaoId) {
            listValueSelecteds[item].valor = data.valor;
         }
      }
   }

   const getClientList = async () => {
      try {
         const getApi = await api.get('loja');
         if (getApi.status === 200) {
            const { data } = getApi;
            const defaultMeiosComunicacao = [];
            setListValueSelectedsLoaded(false);

            if (data?.length) {
               setDataUpdate({
                  ...data[0],
               });

               data[0].lojaMeioComunicacao.forEach(meioComuicacao => {
                  defaultMeiosComunicacao.push({
                     descricao: meioComuicacao.meioComunicacaoDescricao,
                     meioComunicacaoId: meioComuicacao.meioComunicacaoId,
                     valor: meioComuicacao.valor,
                  });
               });

               setlistValueSelecteds(defaultMeiosComunicacao);
               dispatch(fideActions.valueNextStepWizard(true));
               setValueNextStep(1);
               setIsLoading(false);
            }
            setListLojas(data);
            setDataSource(data);
            console.log(data);
            setListValueSelectedsLoaded(true);
         }
      } catch (error) {}
   };

   const getMeioComunicacao = async () => {
      try {
         const getApi = await api.get('/meiocomunicacao');
         if (getApi.status === 200) {
            setListMeioComunicacao(getApi.data);
         }
      } catch (error) {
         const erro = error.response.data.error;

         if (erro[0].message == 'Token inválido') {
            toast.error('Você precisa logar novamente!');
            localStorage.removeItem('@Fideleco:token');
            setTimeout(() => {
               document.location.reload(true);
            }, [3000]);
         }
      }
   };

   const buscarEnderecoCompleto = event => {
      //Salvando valor do campo em um estado para chamar a busca de cep
      setCep({ blurCep: event.target.value });
   };

   //Verificando quantos digitos tem no campo cpf/cnpj para mostrar ou não nome e razao social
   const handlerChangeCpfCnpj = e => {
      const originalValue = unMask(e.target.value);
      const maskedValue = masker(originalValue, [
         '999.999.999-99',
         '99.999.999/9999-99',
      ]);
      setValueCpfCnpj(maskedValue);
   };

   const handleGmt = value => {
      if (value != '') {
         setValueGmt(value);
      } else {
         toast.info('Selecione uma opção');
      }
   };

   useEffect(() => {
      const cepFiltrado = cep?.blurCep?.replace('-');
      const getValueData = async cepValue => {
         if (cep.blurCep !== '') {
            try {
               const arrDataCep = await cepData(cepValue);
               dispatching({
                  type: 'UPDATE_ADDRESS',
                  payload: arrDataCep,
               });
               setEnderecoApi(arrDataCep);
            } catch (e) {
               dispatching({
                  type: 'FAIL',
                  payload:
                     'Ocorreu um erro para buscar o cep. Cep não encontrado!',
               });
            }
         }
      };

      getValueData(cepFiltrado);
   }, [cep.blurCep]);

   useEffect(() => {
      getClientList();
      getMeioComunicacao();
   }, [edit]);

   useEffect(() => {
      const { plataforma } = JSON.parse(localStorage.getItem('@Fideleco:user'));
      setPlataforma(plataforma);
   }, []);

   return (
      <Context.Provider value={valueNextStep}>
         <FormGroup>
            <FormUnform
               className="text-left m-2"
               ref={formRef}
               onSubmit={handleSubimit}>
               <Row className="mb-3" form>
                  <Col md={String(valueCpfCnpj).length < 15 ? 6 : 4}>
                     <FormGroup>
                        <label>CPF ou CNPJ</label>
                        <Tooltip
                           texto="Informe o identificador de fidelidade do
                                    loja. Neste campo pode ser informado o CPF
                                    ou CNPJ para identifica-lo."
                           id="person"
                        />
                        <InputUnform
                           onChange={handlerChangeCpfCnpj}
                           value={
                              valueCpfCnpj || (dataUpdate && dataUpdate.cpfCnpj)
                           }
                           size={12}
                           type="text"
                           name="cpfCnpj"
                           defaultValue={dataUpdate && dataUpdate.cpfCnpj}
                        />
                     </FormGroup>
                  </Col>
                  <Col md={String(valueCpfCnpj).length < 15 ? 6 : 4}>
                     <FormGroup>
                        {edit ? (
                           <>
                              {String(dataUpdate?.cpfCnpj).length < 15 ? (
                                 <label>Nome</label>
                              ) : (
                                 <label>Razão Social</label>
                              )}
                           </>
                        ) : (
                           <>
                              {String(valueCpfCnpj).length < 15 ? (
                                 <label>Nome</label>
                              ) : (
                                 <label>Razão Social</label>
                              )}
                           </>
                        )}
                        <Tooltip
                           texto="Razão social ou o Nome é o registro
                                 sob o qual uma pessoa jurídica ou física se individualiza
                                 e exerce suas atividades."
                           id="nomeRazaoSocial"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="razaoSocial"
                           defaultValue={
                              dataUpdate && dataUpdate.nomeRazaoSocial
                           }
                        />
                     </FormGroup>
                  </Col>
                  {edit ? (
                     <>
                        {String(dataUpdate?.cpfCnpj).length < 15 ? null : (
                           <Col md={4}>
                              <FormGroup>
                                 <label>Nome fantasia</label>
                                 <Tooltip
                                    texto="Nome fantasia, nome de fantasia, também
                                 chamado nome de marca, nome comercial ou
                                 nome de fachada, é a designação popular de
                                 título de estabelecimento utilizada por uma
                                 instituição, seja pública ou privada, sob o
                                 qual ela se torna conhecida do público."
                                    id="nomeFantasia"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="nomeFantasia"
                                    defaultValue={
                                       dataUpdate && dataUpdate.nomeFantasia
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        )}
                     </>
                  ) : (
                     <>
                        {String(valueCpfCnpj).length < 15 ? null : (
                           <Col md={4}>
                              <FormGroup>
                                 <label>Nome fantasia</label>
                                 <Tooltip
                                    texto="Nome fantasia, nome de fantasia, também
                                    chamado nome de marca, nome comercial ou
                                    nome de fachada, é a designação popular de
                                    título de estabelecimento utilizada por uma
                                    instituição, seja pública ou privada, sob o
                                    qual ela se torna conhecida do público."
                                    id="nomeFantasia"
                                 />
                                 <InputUnform
                                    size={12}
                                    type="text"
                                    name="nomeFantasia"
                                    defaultValue={
                                       dataUpdate && dataUpdate.nomeFantasia
                                    }
                                 />
                              </FormGroup>
                           </Col>
                        )}
                     </>
                  )}
               </Row>

               <Row className="mb-3" form>
                  <Col md={12}>
                     <FormGroup>
                        <label>Descrição</label>
                        <Tooltip
                           texto="Essa descrição irá fica visível em seu hotsite, na aba Quem somos."
                           id="descricao"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="descricao"
                           maxLength="80"
                           defaultValue={dataUpdate && dataUpdate.descricao}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row className="mb-3" form>
                  <Col md={6}>
                     <FormGroup>
                        <label>CEP</label>
                        <Tooltip
                           texto="Informe o CEP da loja para identificar sua
                                    localização geográfica."
                           id="cep"
                        />
                        <InputUnform
                           size={12}
                           mask="cep"
                           type="text"
                           name="cep"
                           value={dataUpdate && dataUpdate.cep}
                           onBlur={buscarEnderecoCompleto}
                        />
                     </FormGroup>
                  </Col>
                  <Col md={6}>
                     <FormGroup>
                        <label>UF</label>
                        <Tooltip
                           texto="Informe o Estado (UF) referente ao endereço
                                    da loja."
                           id="state"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           value={enderecoApi && enderecoApi.state}
                           defaultValue={dataUpdate && dataUpdate.uf}
                           name="uf"
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row className="mb-3" form>
                  <Col md={6}>
                     <FormGroup>
                        <label>Cidade</label>
                        <Tooltip
                           texto="Informe a Cidade referente ao endereço da
                                    loja."
                           id="city"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="cidade"
                           value={
                              (enderecoApi && enderecoApi.city) ||
                              (dataUpdate && dataUpdate.cidade)
                           }
                           defaultValue={dataUpdate && dataUpdate.cidade}
                        />
                     </FormGroup>
                  </Col>
                  <Col md={6}>
                     <FormGroup>
                        <label>Bairro</label>
                        <Tooltip
                           texto="Informe o bairro referente ao endereço da
                                    loja."
                           id="neighborhood"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="bairro"
                           value={
                              (enderecoApi && enderecoApi.neighborhood) ||
                              (dataUpdate && dataUpdate.bairro)
                           }
                           defaultValue={dataUpdate && dataUpdate.bairro}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row className="mb-3" form>
                  <Col md={6}>
                     <FormGroup>
                        <label>Logradouro</label>
                        <Tooltip
                           texto="Informe a rua referente ao endereço da
                                    loja."
                           id="street"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="logradouro"
                           value={
                              (enderecoApi && enderecoApi.street) ||
                              (dataUpdate && dataUpdate.endereco)
                           }
                           defaultValue={dataUpdate && dataUpdate.endereco}
                        />
                     </FormGroup>
                  </Col>
                  <Col md={6}>
                     <FormGroup>
                        <label>Número</label>
                        <Tooltip
                           texto="Informe o Número referente ao endereço da
                                    loja."
                           id="numero"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="numero"
                           value={dataUpdate && dataUpdate.numero}
                           defaultValue={dataUpdate && dataUpdate.numero}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row className="mb-3" form>
                  <Col md={12}>
                     <FormGroup>
                        <label>Complemento</label>
                        <Tooltip
                           texto="Informe o Complemento referente ao endereço
                                    da loja."
                           id="complemento"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="complemento"
                           value={dataUpdate && dataUpdate.complemento}
                           defaultValue={dataUpdate && dataUpdate.complemento}
                        />
                     </FormGroup>
                  </Col>
               </Row>

               <Row className="mb-3" form>
                  <Col md={6}>
                     <FormGroup>
                        <label>Personalizar endereço da loja</label>
                        <Tooltip
                           texto="Este campo permite a você, defenir como deverá ser rota da sua loja. "
                           id="path"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           onChange={e => {
                              setValuePath(
                                 e.target.value
                                    .toLowerCase()
                                    .replace(/\s/g, ''),
                              );
                           }}
                           name="path"
                           disabled={
                              dataUpdate && dataUpdate.path ? true : false
                           }
                           value={dataUpdate && dataUpdate.path}
                           defaultValue={dataUpdate && dataUpdate.path}
                        />
                        <label className="title_url_style">
                           {plataforma?.urlHotsite + '/'}
                           {valuePath
                              ? valuePath
                              : dataUpdate && dataUpdate.path}
                        </label>
                     </FormGroup>
                  </Col>

                  <Col md={6}>
                     <FormGroup>
                        <label>Endereço e-commerce</label>
                        <Tooltip
                           texto="Caso possua um site, coloque endereço para que seu cliente acesse"
                           id="urlEcommerce"
                        />
                        <InputUnform
                           size={12}
                           type="text"
                           name="urlEcommerce"
                           defaultValue={dataUpdate && dataUpdate.urlEcommerce}
                        />
                     </FormGroup>
                  </Col>

                  <Col md={12} className="mb-5">
                     <FormGroup className="text-left">
                        <label className="mb-2">Fuso horário(GMT)</label>

                        <FormControl className={classes.formControl}>
                           <Select
                              onChange={handleGmt}
                              name="gmt"
                              displayEmpty
                              defaultValue={String(valueGmt) || ''}
                              className={classes.selectEmpty}
                              inputProps={{
                                 'aria-label': 'Without label',
                              }}>
                              <MenuItem className="text-left" disabled value="">
                                 <em>Selecione uma opção...</em>
                              </MenuItem>
                              {listaGmt.map(gmt => (
                                 <MenuItem
                                    key={gmt.value}
                                    className="text-left"
                                    value={gmt.value}>
                                    <em>{gmt.descricao}</em>
                                 </MenuItem>
                              ))}
                           </Select>
                           {erroGmt && (
                              <p className="is-danger">Campo obrigatório</p>
                           )}
                        </FormControl>
                     </FormGroup>
                  </Col>
               </Row>

               {edit ? (
                  <>
                     {!valueCheckComunicacao && (
                        <>
                           {listValueSelectedsUpdate && (
                              <Row className="mb-3">
                                 <Scope path="lojaMeioComunicacao">
                                    {listValueSelectedsUpdate &&
                                       listValueSelectedsUpdate.map(item => {
                                          return (
                                             <Col md={6}>
                                                <FormGroup>
                                                   <label>
                                                      {
                                                         item.meioComunicacaoDescricao
                                                      }
                                                   </label>

                                                   <InputSeletecd
                                                      key={toString(
                                                         item.meioComunicacaoId,
                                                      )}
                                                      required
                                                      name={
                                                         item.meioComunicacaoId
                                                      }
                                                      defaultValue={item.valor}
                                                      type="text"
                                                   />
                                                </FormGroup>
                                             </Col>
                                          );
                                       })}
                                 </Scope>
                              </Row>
                           )}
                        </>
                     )}

                     <Checkbox
                        className="mb-3"
                        checked={valueCheckComunicacao}
                        onChange={onChangeCheck}>
                        Deseja alterar sua lista de meios de comunicação?
                     </Checkbox>
                     <Tooltip
                        texto="Ao optar por sim, você deverá adiconar todos os meios de cumunicação anteriores já adicinado, pois uma nova lista será incluída e ficará diponível no seu hotsite."
                        id="valueCheckComunicacao"
                     />

                     {valueCheckComunicacao && (
                        <>
                           <Row className="mb-3" form>
                              <Col md={12}>
                                 <FormGroup>
                                    <FormControl style={{ width: '100%' }}>
                                       <div>
                                          <label>Meios de Comunicação</label>
                                          <Tooltip
                                             texto="Informe ao menos um meio de contato."
                                             id="meioComunicacaoId"
                                          />
                                       </div>
                                       <Select
                                          mode="multiple"
                                          allowClear
                                          style={{ width: '100%' }}
                                          placeholder="Selecione ao menos uma opção"
                                          onChange={handleValoresSelecionados}>
                                          {listMeioComunicacao.map(item => {
                                             return (
                                                <Option
                                                   className="text-left"
                                                   key={item.meioComunicacaoId}>
                                                   {item.descricao}
                                                </Option>
                                             );
                                          })}
                                       </Select>
                                    </FormControl>
                                 </FormGroup>
                              </Col>
                           </Row>

                           <Row>
                              <Scope path="lojaMeioComunicacao">
                                 {listValueSelecteds?.map(item => {
                                    return (
                                       <Col md={6}>
                                          <FormGroup>
                                             <label>{item.descricao}</label>

                                             <InputSeletecd
                                                key={item.meioComunicacaoId}
                                                required
                                                name={item.meioComunicacaoId}
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                    );
                                 })}
                              </Scope>
                           </Row>
                        </>
                     )}
                  </>
               ) : (
                  <>
                     <Row className="mb-3" form>
                        <Col md={12}>
                           <FormGroup>
                              <FormControl style={{ width: '100%' }}>
                                 <div>
                                    <label>Meios de Comunicação</label>
                                    <Tooltip
                                       texto="Informe ao menos um meio de contato."
                                       id="meioComunicacaoId"
                                    />
                                 </div>
                                 {listValueSelectedsLoaded && (
                                    <Select
                                       mode="multiple"
                                       allowClear
                                       style={{ width: '100%' }}
                                       placeholder="Selecione ao menos uma opção"
                                       onChange={handleValoresSelecionados}
                                       defaultValue={listValueSelecteds?.map(
                                          item => {
                                             return item.meioComunicacaoId;
                                          },
                                       )}>
                                       {listMeioComunicacao.map(item => {
                                          return (
                                             <Option
                                                className="text-left"
                                                value={item.meioComunicacaoId}
                                                key={item.meioComunicacaoId}>
                                                {item.descricao}
                                             </Option>
                                          );
                                       })}
                                    </Select>
                                 )}
                              </FormControl>
                           </FormGroup>
                        </Col>
                     </Row>

                     <Row>
                        <Scope path="lojaMeioComunicacao">
                           {listValueSelecteds?.map(item => {
                              return (
                                 <Col md={6}>
                                    <FormGroup>
                                       <label>{item.descricao}</label>

                                       <InputSeletecd
                                          key={item.meioComunicacaoId}
                                          required
                                          name={item.meioComunicacaoId}
                                          type="text"
                                          defaultValue={item.valor}
                                          onChangeExtra={event =>
                                             onMeioComunicacaoChange({
                                                id: item.meioComunicacaoId,
                                                valor: event,
                                             })
                                          }
                                       />
                                    </FormGroup>
                                 </Col>
                              );
                           })}
                        </Scope>
                     </Row>
                  </>
               )}

               <StepControllerContainer>
                  <Popconfirm
                     title="Você tem certeza? Você perderá todos os campos preenchidos"
                     onConfirm={() => history.replace('/')}
                     okText="Sim"
                     cancelText="Permanecer no cadastro">
                     <Button
                        color="primary"
                        type="submit"
                        className="btn-wide btn-pill btn-shadow btn-hover-shine mr-2"
                        size="lg">
                        Cancelar
                     </Button>
                  </Popconfirm>

                  <Button
                     color="primary"
                     type="submit"
                     className="btn-wide btn-pill btn-shadow btn-hover-shine"
                     size="lg">
                     Salvar
                  </Button>
               </StepControllerContainer>
            </FormUnform>
         </FormGroup>
      </Context.Provider>
   );
};

export default WizardLoja;
