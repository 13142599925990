import React, { useState } from 'react';
import api from '../../../api/api-local';

//Components
import validatePassword from '../../../helpers/validateFormDataAlterarSenha';
import InputIcon from '../../../components/Formulario/InputIcon';
import { useAuth } from '../../../customHooks/AuthContext';
import Spinner from '../../../components/loading/Loading';
import PageTitle from '../../layout/AppMain/PageTitle';
import useForm from '../../../customHooks/useForm';
import Tooltip from '../../../components/tooltip';

//Files
import RevelaSenhaIcon from '../../../assets/icons/eye-open-red.svg';
import EscondeSenhaIcon from '../../../assets/icons/eye-close-red.svg';

//libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { toast } from 'react-toastify';
import { Col, Row, Button, Form, Card, CardBody } from 'reactstrap';

const AlterarSenha = () => {
   const { usuario } = useAuth();
   const initialValues = {
      senhaAtual: '',
      senhaNova: '',
   };

   const [isLoading, setIsLoading] = useState(false);
   const [mostraSenha, setMostraSenha] = useState(false);
   const [mostraSenhaNova, setMostraSenhaNova] = useState(false);

   const { values, handleChange, handleSubmit } = useForm(
      initialValues,
      formControlUser,
      validatePassword,
   );

   function formControlUser() {
      setIsLoading(true);
      api
         .post('usuario/alterarsenha', values)
         .then(response => {
            if (response.status === 200) {
               setIsLoading(false);
               toast.success('Senha alterada com sucesso!');
            }
         })
         .catch(error => {
            error.response.data.error.map(i => {
               setIsLoading(false);
               toast.error(<div><br /> {i.message}</div>, {
                  position: "top-right",
                  autoClose: 100000000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
            });
         });
   }

   return (
      <>
         <PageTitle
            heading="Alterar Senha"
            subheading="Mude a senha do seu usuário de acesso!"
            icon="pe-7s-door-lock icon-gradient bg-fideleco"></PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <h6>
                           <b>Email:</b> {usuario.email}
                        </h6>
                        <h6>
                           <b>Usuário:</b> {usuario.nome}
                        </h6>

                        <Form onSubmit={handleSubmit} className="mt-5">
                           <Row form>
                              <Col md={4} className="mb-3">
                                 <label className="m-0 p-0">
                                    Digite sua senha atual
                                 </label>
                                 <InputIcon
                                    name="senhaAtual"
                                    value={values.senhaAtual || ''}
                                    type={mostraSenha ? 'text' : 'password'}
                                    size={12}
                                    handleChange={handleChange}
                                    id="senhaAtual">
                                    <img
                                       src={
                                          mostraSenha
                                             ? RevelaSenhaIcon
                                             : EscondeSenhaIcon
                                       }
                                       alt="Mostrar senha"
                                       className="mostra-senha-icon senha-login"
                                       onClick={() =>
                                          setMostraSenha(!mostraSenha)
                                       }
                                    />
                                 </InputIcon>
                              </Col>

                              <Col md={4} className="mb-3">
                                 <label className="m-0 p-0">
                                    Digite sua nova senha
                                 </label>
                                 
                                 <div className="d-flex align-items-center">
                                    <InputIcon
                                       type={
                                          mostraSenhaNova ? 'text' : 'password'
                                       }
                                       size={12}
                                       name="senhaNova"
                                       handleChange={handleChange}
                                       value={values.senhaNova || ''}
                                       id="senhaNova">
                                       <img
                                          src={
                                             mostraSenhaNova
                                                ? RevelaSenhaIcon
                                                : EscondeSenhaIcon
                                          }
                                          alt="Mostrar senha"
                                          className="mostra-senha-icon senha-login"
                                          onClick={() =>
                                             setMostraSenhaNova(
                                                !mostraSenhaNova,
                                             )
                                          }
                                       />
                                    </InputIcon>
                                 </div>
                              </Col>
                           </Row>

                           <div className="d-flex align-items-center mt-5">
                              <div className="mr-auto">
                                 <Button
                                    color="primary"
                                    type="submit"
                                    className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                    size="lg">
                                    Alterar senha
                                 </Button>
                              </div>
                           </div>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default AlterarSenha;
