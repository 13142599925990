import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../api/api-local';

//components
import { useAuth } from '../../../customHooks/AuthContext';
import ModalCustom from '../../../components/Modal/Modal';
import Spinner from '../../../components/loading/Loading';
import PageTitle from '../../layout/AppMain/PageTitle';
import { helpersUntil } from '../../../helpers/until';
import Table from '../../../components/FilterTable';

import PaginationFilterTable from '../../../components/PaginationFilterTable';

//libs
import { toast } from 'react-toastify';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { TextField } from '@material-ui/core';

import {
   Row,
   Col,
   Card,
   CardBody,
   UncontrolledButtonDropdown,
   DropdownItem,
   Button,
   Form,
   FormGroup,
} from 'reactstrap';

const EstornarClientes = () => {
   //consts
   const history = useHistory();
   const { signOut } = useAuth();
   //states
   const [getInfoClient, setInfoClient] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [dataSource, setDataSource] = useState([]);
   const [edit, setEdit] = useState(false);
   const [termoBusca, setTermoBusca] = useState('');
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   //funtions
   const submitEstorno = event => {
      event.preventDefault();
      try {
         if (edit) {
            const valuesOk = {
               clienteLojaExtratoId: getInfoClient[0].clienteLojaExtratoId,
            };
            setIsLoading(true);
            api.post('pontuacao/estorno/processar', valuesOk)
               .then(response => {
                  if (response.status === 200) {
                     setIsOpen(false);
                     setIsLoading(false);
                     toast.success('Cliente estornado com sucesso!');
                     setTimeout(() => {
                        history.go(0);
                     }, 2000);
                  }
               })
               .catch(error => {
                  setIsLoading(false);
                  if (error.response.status === 401) {
                     toast.error('Sessão expirada. Entre novamente', {
                        autoClose: 3000,
                     });
                     signOut();
                  } else {
                     toast.error(error.response.data.error[0].message);
                  }
               });
         }
      } catch (error) {
         console.log(error);
      }
   };

   const openModal = () => {
      setIsOpen(true);
   };

   const closeModal = () => {
      setIsOpen(false);
   };

   function dateFormatter(data) {
      return <span>{data}</span>;
   }

   const handleChangeTermo = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getListPontosEstorno(pagination.current, pagination.pageSize);
      }, 1);
   };

   const columns = [
      {
         title: 'Nome',
         dataIndex: 'nome',
         className: 'column-date',
         filters: dataSource.map(pontos => ({
            text: pontos.nome,
            value: pontos.nome,
         })),
         onFilter: (value, record) => record.nome.indexOf(value) === 0,
         sorter: (a, b) => (a.nome < b.nome ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
      },

      {
         title: 'CPF/CNPJ',
         dataIndex: 'cpfCnpj',

         filters: dataSource.map(pontos => ({
            text: pontos.cpfCnpj,
            value: pontos.cpfCnpj,
         })),
         onFilter: (value, record) => record.cpfCnpj.indexOf(value) === 0,
         sorter: (a, b) => (a.cpfCnpj < b.cpfCnpj ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
      },

      {
         title: 'Data',
         dataIndex: 'data',
         filters: dataSource.map(pontos => ({
            text: pontos.data,
            value: pontos.data,
         })),
         onFilter: (value, record) => record.data.indexOf(value) === 0,
         sorter: (a, b) => (a.data < b.data ? -1 : 1),
         render: data => dateFormatter(data),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
      },

      {
         title: 'Ponto(s)',
         dataIndex: 'ponto',
         filters: dataSource.map(pontos => ({
            text: pontos.ponto,
            value: pontos.ponto,
         })),
         onFilter: (value, record) => record.ponto.indexOf(value) === 0,
         sorter: (a, b) => (a.ponto < b.ponto ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         align: 'center',
         width: 20,
      },

      {
         title: 'Ações',
         key: 'operation',
         width: 50,
         align: 'center',
         render: ponto => (
            <div key={ponto.clienteLojaExtratoId}>
               <div className="d-block w-100 text-center">
                  <UncontrolledButtonDropdown>
                     <DropdownItem onClick={() => estornarPonto(ponto)}>
                        <span>Estornar</span>
                     </DropdownItem>
                  </UncontrolledButtonDropdown>
               </div>
            </div>
         ),
      },
   ];

   const filtros = [
      {
         dataIndex: 'nome',
         nome: 'Nome',
         placeholder: 'Digite o nome',
         mask: 'text',
      },
      {
         dataIndex: 'cpfCnpj',
         nome: 'CPF/CNPJ',
         placeholder: 'Digite o CPF ou CNPJ',
         mask: 'pessoa',
      },
      {
         dataIndex: 'data',
         nome: 'Data',
         placeholder: 'Digite a data',
         mask: 'date',
      },
   ];

   const estornarPonto = ponto => {
      setEdit(true);
      setIsOpen(true);
      try {
         setIsLoading(true);
         const getId = ponto.clienteLojaExtratoId;
         api.get(`pontuacao/estorno/${getId}`)
            .then(response => {
               setIsLoading(false);
               if (response.status === 200 && getId) {
                  setInfoClient(Array(response.data));
               }
            })
            .catch(error => {
               setIsLoading(false);
               if (error.response.status === 401) {
                  toast.error('Sessão expirada. Entre novamente', {
                     autoClose: 3000,
                  });
                  signOut();
               } else {
                  toast.error(error.response.data.error[0].message);
               }
            });
      } catch (error) {
         console.log(error);
      }
   };

   const getListPontosEstorno = async (currentPage, pageSize) => {
      try {
         setIsLoading(true);
         const getApi = await api.get('pontuacao/estorno/paginacao', {
            params: {
               pagina: currentPage,
               tamanho: pageSize,
               ...termoBusca,
            },
         });
         if (getApi.status === 200) {
            setIsLoading(false);
            const { data } = getApi;

            data.data.map(i => {
               const dataFormatada = helpersUntil.converterDateStringForDateBr(
                  i.data,
               );
               i.data = dataFormatada;
               return i;
            });

            setDataSource(data.data);

            setPagination(state => ({
               ...state,
               current: data.currentPage,
               total: data.totalItems,
            }));
         }
      } catch (error) {
         toast.error(error.response.data.error[0].message);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      getListPontosEstorno(pagination.current, pagination.pageSize);
   }, [edit]);

   return (
      <>
         <PageTitle
            heading="Estorno de pontos"
            subheading="Estorne os pontos dos seus clientes"
            icon="pe-7s-users icon-gradient bg-fideleco ">
            <div>
               <ModalCustom
                  titleModal={`Confirme a operação - Valide as informações antes de confirmar o estorno.`}
                  toggleFunc={openModal}
                  isOpen={modalIsOpen}
                  size="lg"
                  closeModal={closeModal}
                  style={{ display: 'none' }}>
                  {getInfoClient.map((i, index) => (
                     <div key={index} className="section-container">
                        <div className="section-info">
                           <div className="section-info-pessoa">
                              <div className="info">
                                 <span>Nome:</span>
                                 <p>{i.nome}</p>
                              </div>
                              <div className="info">
                                 <span>CPF:</span>
                                 <p>{i.cpfCnpj}</p>
                              </div>
                              <div className="info">
                                 <span>E-mail:</span>
                                 <p>{i.email}</p>
                              </div>
                              <div className="info">
                                 <span>Celular:</span>
                                 <p>{i.celular}</p>
                              </div>
                           </div>
                           <div className="section-info-pontuacao">
                              <div className="info">
                                 <span>Data:</span>
                                 <p>{i.data}</p>
                              </div>
                              <div className="info">
                                 <span>Hora:</span>
                                 <p>{i.hora}</p>
                              </div>
                              <div className="info">
                                 <span>Operador:</span>
                                 <p>{i.usuario}</p>
                              </div>
                           </div>
                        </div>
                        <div className="box-ponto">
                           <span>{i.ponto}</span>
                           {i.ponto == '1' ? <p>Ponto</p> : <p>Pontos</p>}
                           <Form onSubmit={submitEstorno} noValidate>
                              <Button
                                 color="primary"
                                 type="submit"
                                 className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                 size="lg">
                                 Estornar
                              </Button>
                           </Form>
                        </div>
                     </div>
                  ))}
                  <Form
                     onSubmit={submitEstorno}
                     noValidate
                     className="text-left">
                     <Row form>
                        <Col md={6}>
                           <FormGroup></FormGroup>
                        </Col>
                     </Row>
                  </Form>
               </ModalCustom>
            </div>
         </PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <div className="table-responsive">
                           <PaginationFilterTable
                              columns={columns}
                              rowKey={record => record.nome}
                              dataSource={dataSource}
                              pagination={pagination}
                              loading={isLoading}
                              onChange={handleTableChange}
                              handleSubmit={getListPontosEstorno}
                              filterChildren={[
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.nome}
                                    onChange={e =>
                                       handleChangeTermo('nome', e.target.value)
                                    }
                                    label="Nome"
                                 />,
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.documento}
                                    onChange={e =>
                                       handleChangeTermo(
                                          'documento',
                                          e.target.value,
                                       )
                                    }
                                    label="Documento"
                                 />,
                                 <TextField
                                    style={{ width: '100%' }}
                                    value={termoBusca.celular}
                                    onChange={e =>
                                       handleChangeTermo(
                                          'celular',
                                          e.target.value,
                                       )
                                    }
                                    label="Celular"
                                 />,
                              ]}
                           />
                        </div>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default EstornarClientes;
