import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../customHooks/AuthContext';

import api from '../../../api/api-local';

import { Popconfirm } from 'antd';

//Components
import PageTitle from '../../layout/AppMain/PageTitle';
import PaginationFilterTable from '../../../components/PaginationFilterTable';

//Libs
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Spinner from '../../../components/loading/Loading';

import { TextField } from '@material-ui/core';

import { Col, Row, Button, FormGroup, Card, CardBody } from 'reactstrap';
import InputMask from 'react-input-mask';

const GerenciarClientes = () => {
   const { signOut } = useAuth();
   const [termoBusca, setTermoBusca] = useState({});
   const [isLoading, setIsLoading] = useState(false);
   const [clientes, setClientes] = useState([]);
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      total: 0,
   });

   const getCliente = (currentPage, pageSize) => {
      setIsLoading(true);

      api.get(`cliente/paginacao`, {
         params: {
            pagina: currentPage,
            tamanho: pageSize,
            ...termoBusca,
         },
      })
         .then(response => {
            if (response.status === 200) {
               setIsLoading(false);

               if (response.data.data.length < 1) {
                  toast.error('Nenhum cliente encontrado!');
                  setClientes([]);
               }

               const dados = response.data.data.map(cliente => {
                  return {
                     id: cliente.clienteId,
                     nome: cliente.nome,
                     cpfCnpj: cliente.cpfCnpj,
                     email: cliente.email,
                     celular: cliente.celular,
                     dataNascimento: cliente.dataNascimento,
                     saldo: cliente.saldo,
                  };
               });

               setClientes(dados);

               setPagination(state => ({
                  ...state,
                  current: response.data.currentPage,
                  total: response.data.totalItems,
               }));
            }
         })
         .catch(error => {
            setIsLoading(false);
            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   const handleChangePerson = (props, value) => {
      setTermoBusca({
         ...termoBusca,
         [props]: value,
      });
   };

   const desvinculaCliente = clienteId => {
      setIsLoading(true);

      api.put(`/clienteloja/desvincular/${clienteId}`)
         .then(response => {
            if (response.status === 200) {
               toast.success(response.data.message);
               setClientes(
                  clientes.filter(
                     cliente => cliente.id !== clienteId && cliente,
                  ),
               );
               setIsLoading(false);
            }

            setIsLoading(false);
         })
         .catch(error => {
            setIsLoading(false);

            if (error.response.status === 401) {
               toast.warning('Sessão expirada. Entre novamente', {
                  autoClose: 3000,
               });
               signOut();
            } else {
               toast.warning(error.response.data.error[0].message);
            }
         });
   };

   const columns = [
      {
         title: 'Nome',
         dataIndex: 'nome',
         filters: clientes.map(cliente => ({
            text: cliente.nome,
            value: cliente.nome,
         })),
         onFilter: (value, record) => record.nome.indexOf(value) === 0,
         sorter: (a, b) => (a.nome < b.nome ? -1 : 1),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '20%',
      },
      {
         title: 'CPF/CNPJ',
         dataIndex: 'cpfCnpj',
         filters: clientes.map(cliente => ({
            text: cliente.cpfCnpj,
            value: cliente.cpfCnpj,
         })),
         onFilter: (value, record) => record.cpfCnpj.indexOf(value) === 0,
         sorter: (a, b) => (a.cpfCnpj < b.cpfCnpj ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '20%',
      },
      {
         title: 'Email',
         dataIndex: 'email',
         filters: clientes.map(cliente => ({
            text: cliente.email,
            value: cliente.email,
         })),
         onFilter: (value, record) => record.email.indexOf(value) === 0,
         sorter: (a, b) => (a.email < b.email ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '20%',
      },
      {
         title: 'Celular',
         dataIndex: 'celular',
         filters: clientes.map(cliente => ({
            text: cliente.celular,
            value: cliente.celular,
         })),
         onFilter: (value, record) => record.celular.indexOf(value) === 0,
         sorter: (a, b) => (a.celular < b.celular ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '20%',
      },
      {
         title: 'Saldo',
         dataIndex: 'saldo',
         sorter: (a, b) => (a.saldo < b.saldo ? -1 : 1),
         sortDirections: ['descend', 'ascend'],
         width: '10%',
      },
      {
         title: '',
         key: 'operation',
         fixed: 'right',
         width: '10%',
         render: row => (
            <Popconfirm
               title="Você tem certeza? Deseja desvincular este usuário?"
               onConfirm={() => desvinculaCliente(row.id)}
               okText="Sim"
               cancelText="Não">
               <Button onClick={() => {}}>Remover vínculo</Button>
            </Popconfirm>
         ),
      },
   ];

   const handleTableChange = async (pagination, filters, sorter) => {
      setPagination(pagination);

      setTimeout(() => {
         getCliente(pagination.current, pagination.pageSize);
      }, 1);
   };

   useEffect(() => {
      getCliente(pagination.current, pagination.pageSize);
   }, []);

   return (
      <>
         <PageTitle
            heading="Gerenciar clientes"
            subheading="Gerencie seus clientes"
            icon="pe-7s-user-female icon-gradient bg-fideleco"></PageTitle>
         <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}>
            <Row>
               <Col md="12">
                  <Card className="main-card mb-3 text-left">
                     <CardBody>
                        <PaginationFilterTable
                           columns={columns}
                           rowKey={record => record.nome}
                           dataSource={clientes}
                           pagination={pagination}
                           loading={isLoading}
                           onChange={handleTableChange}
                           handleSubmit={getCliente}
                           filterChildren={[
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.nome}
                                 onChange={e =>
                                    handleChangePerson('nome', e.target.value)
                                 }
                                 label="Nome"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.documento}
                                 onChange={e =>
                                    handleChangePerson(
                                       'documento',
                                       e.target.value,
                                    )
                                 }
                                 label="CPF/CNPJ"
                              />,
                              <TextField
                                 style={{ width: '100%' }}
                                 value={termoBusca.email}
                                 onChange={e =>
                                    handleChangePerson('email', e.target.value)
                                 }
                                 label="Email"
                              />,
                           ]}
                        />
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </CSSTransitionGroup>
         {isLoading && <Spinner />}
      </>
   );
};

export default GerenciarClientes;
