import { mask as masker, unMask } from 'remask';

export function formatCpfCnpj(value) {
   const originalValue = unMask(value);
   const maskedValue = masker(originalValue, [
      '999.999.999-99',
      '99.999.999/9999-99',
   ]);
   return maskedValue;
}

export function formatCEP(value) {
   const originalValue = unMask(value);
   const maskedValue = masker(originalValue, ['99999-999']);
   return maskedValue;
}

export function formatCelular(value) {
   const originalValue = unMask(value);
   const maskedValue = masker(originalValue, ['(99) 9 9999-9999']);
   return maskedValue;
}

export function formatTelefone(value) {
   const originalValue = unMask(value);
   const maskedValue = masker(originalValue, [
      '(99) 9999-9999',
      '(99) 9 9999-9999',
   ]);
   return maskedValue;
}
