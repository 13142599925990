import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

const PageTitle = props => {
   const {
      enablePageTitleIcon,
      enablePageTitleSubheading,
      styleText,
      children,
      heading,
      icon,
      subheading,
   } = props;

   return (
      <div className="app-page-title">
         <div className="page-title-wrapper justify-content-between">
            <div className="page-title-heading">
               <div
                  className={cx('page-title-icon ml-0 mr-3', {
                     'd-none': !enablePageTitleIcon,
                  })}>
                  <i className={icon} />
               </div>
               <div className={`text-left `} style={styleText}>
                  {heading}
                  <div
                     className={cx('page-title-subheading', {
                        'd-none': !enablePageTitleSubheading,
                     })}>
                     {subheading}
                  </div>
               </div>
            </div>
            {children}
         </div>
      </div>
   );
};

const mapStateToProps = state => ({
   enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
   enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
